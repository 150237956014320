import type { FC } from 'react'

import { BORDER_RADIUS } from 'components/Button/border-radius'
import { getUnit } from 'domain/unit'
import styled from 'styled-components'
import { formatNumericValue, formatNumericWithPrecision } from 'utils/number'

interface ProductQuantityProps {
  amount: number
  precision?: number
  unit: string
  disabled?: boolean
  className?: string
  'data-test-id'?: string
}

const _ProductQuantity: FC<ProductQuantityProps> = ({
  amount,
  precision,
  unit,
  className,
  'data-test-id': dataTestId,
}) => {
  const formattedValue = precision
    ? formatNumericWithPrecision(amount, precision)
    : formatNumericValue(amount)

  /** Fallback to raw unit, if no mapping */
  const formattedUnit = getUnit(unit) || unit

  return (
    <div className={className} data-test-id={dataTestId}>
      <span>
        <span>{formattedValue}</span>
        {formattedUnit && <span>{formattedUnit}</span>}
      </span>
    </div>
  )
}

export const ProductQuantity = styled(_ProductQuantity)(
  {
    pointerEvents: 'none',
    minWidth: '50px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  ({ theme }) => ({
    ...BORDER_RADIUS,
    padding: theme.spacings.xSmall,
    span: {
      ...theme.variants.body1.medium,

      '&:nth-child(2)': {
        ...theme.variants.caption.regular,
        marginLeft: theme.spacings.xxxSmall,
      },
    },
  }),
  ({ theme, disabled }) => ({
    backgroundColor: disabled ? theme.color.grey200 : undefined,
    color: theme.colors.grey800,
  }),
)
