import type { ChildProps } from '../Navigation'
import type { NavigationItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSortParam } from 'services/Search/hooks/use-sort-param'
import { sortToQueryValue } from 'services/Search/utils/sort'
import styled, { css } from 'styled-components'

interface SubCategoryChildItemNameProps {
  isActive?: boolean
}

const StyledSubCategoryChildWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: -12px;
  padding: 24px 0;
  background-color: ${({ theme }) => theme.color.lightestGrey100};
  width: 100%;
`

const body2 = css(({ theme }) => theme.variants.body2.regular)

const StyledSubCategoryChildItemName = styled.div<SubCategoryChildItemNameProps>`
  ${body2};
  padding-left: 20px;
  text-decoration: ${({ isActive }) => isActive && 'underline'};
  width: 100%;
  margin-bottom: 24px;
`

const StyledSubCategoryChildItem = styled.a`
  color: inherit;
`
const StyledSubCategoryChildLinkWrapper = styled.div<SubCategoryChildItemNameProps>`
  ${body2};
  padding-left: 20px;
  width: 100%;
  margin-bottom: 0px;
`

const StyledSubCategoryChildLink = styled.a`
  ${body2};
  color: ${({ theme }) => theme.color.action};
  display: inline-block;
  width: 100%;
`

interface SubCategoryChildProps {
  items: NavigationItem[]
  child: ChildProps
}

export const SubCategoryChild: FC<SubCategoryChildProps> = ({ items, child }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { slugs } = router.query
  const active = (slug: string): boolean =>
    Array.isArray(slugs) ? slugs.join('/') === slug : false

  const [activeSort] = useSortParam()
  const sortParam = useMemo(() => {
    const sort = sortToQueryValue(activeSort)
    return sort ? `?sort=${sort}` : ''
  }, [activeSort])

  if (items) {
    return (
      <StyledSubCategoryChildWrapper data-test-id="product-navigation-subcategory-child">
        {items.map((item) => (
          <Link
            href={`/tuotteet/${item?.slug as string}${sortParam}`}
            key={item?.id as string}
            passHref
          >
            <StyledSubCategoryChildItem
              data-test-id="product-navigation-subcategory-child-item"
              onClick={() => {
                navigationVisibleVar(false)
                searchVisibleVar(false)
              }}
            >
              <StyledSubCategoryChildItemName
                isActive={active(item?.slug as string)}
                data-test-id="product-navigation-subcategory-child-item-name"
              >
                {item.name}
              </StyledSubCategoryChildItemName>
            </StyledSubCategoryChildItem>
          </Link>
        ))}
        <StyledSubCategoryChildLinkWrapper data-test-id="product-navigation-subcategory-child-item-link">
          <Link
            href={`/tuotteet/[...slugs]${sortParam}`}
            as={`/tuotteet/${child?.slug}${sortParam}`}
            passHref
          >
            <StyledSubCategoryChildLink
              data-test-id="product-navigation-subcategory-child-show-all-link"
              onClick={() => {
                navigationVisibleVar(false)
                searchVisibleVar(false)
              }}
            >
              {t('Show all')}
            </StyledSubCategoryChildLink>
          </Link>
        </StyledSubCategoryChildLinkWrapper>
      </StyledSubCategoryChildWrapper>
    )
  }
  return null
}
