import type { FC } from 'react'
import type { IInputElementFields, ILinkContainer, ISection } from 'types/contentful/generated'

import { SDS_SIZE_BORDER_RADIUS_XXSMALL } from '@s-group/design-system-tokens/web/tokens/size.es6'
import { maxWidthFromTheme, minWidthFromTheme } from '@sok/design-system'
import { SectionTitle, TextBlock } from 'components/common/Titles'
import { NextSeo } from 'next-seo'
import { useCallback } from 'react'
import styled from 'styled-components'
import { LAYOUT_MAX_WIDTH } from 'styles/layout'
import { contentfulImageToOgImage } from 'utils/content-pages/images'
import { useButtonListHref } from 'utils/hooks/store/use-button-list-href'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { useContentImpressionTracking } from 'utils/tracking/hooks/use-content-impression-tracking'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { ContentfulButton } from './ContentfulButton'
import { ButtonList } from './row-component/button-list'

export enum HeroBannerVariant {
  Default = 'DEFAULT',
  FullWidth = 'FULL_WIDTH',
  LeftText = 'LEFT_TEXT',
}

type VariantProp = { variant: HeroBannerVariant }
type FontColorProp = { fontColor?: string }

const StyledHeroContainer = styled.div<VariantProp>(({ variant, theme }) => ({
  marginBottom: variant === HeroBannerVariant.FullWidth ? '48px' : '-6px',
  position: 'relative',

  ['ol']: {
    margin: `${theme.spacings.xSmall} 0`,
    ['li']: {
      margin: `0 0 0 ${theme.spacings.medium}`,
    },
  },
}))

const getMobileHeroImageHeight = ({ variant }: VariantProp): string => {
  switch (variant) {
    case HeroBannerVariant.FullWidth:
    case HeroBannerVariant.LeftText:
      return '400px'
    case HeroBannerVariant.Default:
      return '500px'
  }
}

const getHeroImageHeight = ({ variant }: VariantProp): string => {
  switch (variant) {
    case HeroBannerVariant.FullWidth:
      return '400px'
    case HeroBannerVariant.LeftText:
      return '480px'
    case HeroBannerVariant.Default:
      return '600px'
  }
}

const StyledHeroImg = styled.img<VariantProp>(
  ({ variant, theme }) => ({
    height: getMobileHeroImageHeight({ variant }),
    objectFit: 'cover',
    objectPosition: '50% 50%',
    width: '100%',

    [minWidthFromTheme(theme).tablet]: {
      height: getHeroImageHeight({ variant }),
    },

    [minWidthFromTheme(theme).desktop]: {
      borderRadius: SDS_SIZE_BORDER_RADIUS_XXSMALL,
    },
  }),
  ({ variant, theme }) =>
    variant === HeroBannerVariant.LeftText
      ? {
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',

          [minWidthFromTheme(theme).tablet]: {
            height: getHeroImageHeight({ variant }),
            position: 'static',
          },
        }
      : {},
)

const StyledImageOverlay = styled.div(({ theme }) => ({
  display: 'none',

  [minWidthFromTheme(theme).tablet]: {
    backgroundImage: `linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(148, 148, 148, 0.171354) 80.21%,
      rgba(196, 196, 196, 0) 100%
      )`,
    display: 'block',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  [minWidthFromTheme(theme).desktop]: {
    borderRadius: `0 0 ${SDS_SIZE_BORDER_RADIUS_XXSMALL} ${SDS_SIZE_BORDER_RADIUS_XXSMALL}`,
  },
}))

const StyledHeroImageWrapper = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%',
})

const StyledInfoCardContentFrontPage = styled.div<VariantProp & FontColorProp>(
  ({ fontColor, theme, variant }) => {
    const isLeftText = variant === HeroBannerVariant.LeftText

    return {
      alignItems: isLeftText ? 'flex-start' : 'center',
      backgroundColor: 'transparent',
      boxSizing: 'border-box',
      color: isLeftText ? fontColor ?? theme.color.fontBlack : theme.color.fontBlack,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: isLeftText ? getMobileHeroImageHeight({ variant }) : undefined,
      padding: isLeftText ? `${theme.spacings.xxxLarge} ${theme.spacings.medium}` : '10% 10%',
      position: isLeftText ? undefined : 'absolute',
      textAlign: isLeftText ? 'left' : 'center',
      top: '10%',
      width: '100%',

      [minWidthFromTheme(theme).tablet]: {
        backgroundColor: 'transparent',
        color: fontColor ?? theme.color.fontBlack,
        height: '100%',
        left: isLeftText ? undefined : 0,
        padding: '0 20%',
        top: 0,
      },

      [TextBlock]: {
        margin: '20px 0 40px 0',
      },
    }
  },
  ({ theme, variant }) =>
    variant === HeroBannerVariant.LeftText && {
      [minWidthFromTheme(theme).tablet]: {
        minHeight: 'none',
        padding: '0',
        position: 'absolute',
        width: '50%',
      },
    },
)

const StyledInfoCardContentForContentPage = styled.div<FontColorProp>(({ fontColor, theme }) => ({
  color: theme.color.fontBlack,
  maxWidth: LAYOUT_MAX_WIDTH,
  paddingLeft: theme.spacings.medium,
  paddingTop: theme.spacings.large,
  position: 'relative',
  width: '100%',

  [minWidthFromTheme(theme).tablet]: {
    alignItems: 'flex-start',
    bottom: '10%',
    color: fontColor || theme.color.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'absolute',
  },
}))

const Centered = styled.div<{ padding?: boolean }>(
  ({ theme }) => ({
    margin: '0 auto',
    maxWidth: LAYOUT_MAX_WIDTH,
    position: 'relative',
    width: '100%',

    [minWidthFromTheme(theme).tablet]: {
      position: 'static',
    },
  }),
  ({ padding, theme }) =>
    padding && {
      [minWidthFromTheme(theme).tablet]: {
        padding: `0 ${theme.spacings.xxLarge}`,
      },
    },
)

const StyledArticleTitle = styled.div(({ theme }) => ({
  ...theme.variants.body2.medium,
  letterSpacing: '0.015em',
  marginBottom: '20px',
  textTransform: 'uppercase',

  [maxWidthFromTheme(theme).mobile]: {
    color: theme.color.fontBlack,
    marginBottom: '10px',
    marginTop: '36px',
    position: 'relative',
  },
}))

const StyledCardTitle = styled.h1(({ theme }) => ({
  ...theme.variants.title2,
  marginBottom: '20px',

  [minWidthFromTheme(theme).desktop]: {
    ...theme.variants.title3,
    marginBottom: 0,
  },
}))

const StyledDate = styled.div(({ theme }) => theme.variants.body1.regular)

const StyledButtonListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

const ButtonContent: FC<{
  buttonList?: ILinkContainer[]
  cta?: IInputElementFields
  trackAction: () => void
}> = ({ buttonList, cta, trackAction }) => {
  const href = useButtonListHref()

  if (cta) {
    // Pass buttonList href here as well for "shop-prisma-button" support
    return <ContentfulButton cta={cta} href={href} onClick={trackAction} />
  }

  if (buttonList) {
    return (
      <StyledButtonListContainer>
        {buttonList.map(
          (button) =>
            button?.fields?.links && (
              <ButtonList items={button?.fields?.links} key={button?.sys?.id} href={href} />
            ),
        )}
      </StyledButtonListContainer>
    )
  }

  return null
}

const HeroInfoContainer: FC<{
  section: ISection
  variant: HeroBannerVariant
}> = ({ section, variant }) => {
  const { tagTitle, cards, backgroundImage: bgImage } = section.fields
  const infoCard = cards && cards[0]

  const { name, functionality, fontColor, title, body, articleCategoryName, date, buttonList } =
    infoCard?.fields ?? {}
  const cta = functionality?.[0]?.fields
  const parsedBody = useParsedMarkdown(body)

  const trackAction = useCallback((): void => {
    internalPromotion(
      PromotionPosition.FRONTPAGE_HERO,
      name,
      'select_promotion',
      bgImage && bgImage.fields.title,
    )
  }, [bgImage, name])

  if (variant === HeroBannerVariant.FullWidth) {
    return (
      <Centered>
        <StyledInfoCardContentForContentPage id={tagTitle} fontColor={fontColor}>
          {articleCategoryName && <StyledArticleTitle>{articleCategoryName}</StyledArticleTitle>}
          {title && <StyledCardTitle>{title}</StyledCardTitle>}
          {date && <StyledDate>{date}</StyledDate>}
        </StyledInfoCardContentForContentPage>
      </Centered>
    )
  }

  return (
    <Centered padding={variant === HeroBannerVariant.LeftText}>
      <StyledInfoCardContentFrontPage variant={variant} fontColor={fontColor}>
        {variant === HeroBannerVariant.LeftText ? (
          <StyledCardTitle>{title}</StyledCardTitle>
        ) : (
          <SectionTitle>{title}</SectionTitle>
        )}
        <TextBlock>{parsedBody}</TextBlock>

        <ButtonContent buttonList={buttonList} cta={cta} trackAction={trackAction} />
      </StyledInfoCardContentFrontPage>
    </Centered>
  )
}

export const HeroBanner: FC<{
  section: ISection
  variant: HeroBannerVariant
}> = ({ section, variant }) => {
  const { cards, backgroundImage: bgImage, imageAltTag, imageTitleTag } = section.fields
  const infoCard = cards && cards[0]

  const { name, functionality } = infoCard?.fields ?? {}
  const bgImageFile = bgImage?.fields?.file?.url

  const promotionPosition = PromotionPosition.FRONTPAGE_HERO
  // track only if CTA is shown
  const trackImpression = useCallback(() => {
    if (variant !== HeroBannerVariant.FullWidth && functionality && functionality.length > 0) {
      internalPromotion(promotionPosition, name, 'view_promotion', bgImage && bgImage.fields.title)
    }
  }, [promotionPosition, name, bgImage, variant, functionality])
  const ref = useContentImpressionTracking<HTMLDivElement>(trackImpression, promotionPosition)

  const isGif = (imageFileName?: string) => imageFileName && imageFileName.endsWith('.gif')

  const bgImageUrl = `https://${bgImageFile}${!isGif(bgImageFile) ? '?h=1000&fm=jpg' : ''}`

  const openGraph = {
    type: 'website',
    ...(bgImageFile
      ? {
          images: [
            {
              url: contentfulImageToOgImage(bgImageFile),
            },
          ],
        }
      : {}),
  }

  const styledHeroImg = (
    <StyledHeroImg
      alt={imageAltTag}
      role={imageAltTag ? undefined : 'presentation' /** not semantic if alt tag is missing */}
      src={bgImageUrl}
      title={imageTitleTag ? imageTitleTag : ''}
      variant={variant}
    />
  )

  const wrappedHeroImg =
    variant === HeroBannerVariant.FullWidth ? (
      <StyledHeroImageWrapper>
        {styledHeroImg}
        <StyledImageOverlay />
      </StyledHeroImageWrapper>
    ) : (
      styledHeroImg
    )

  return (
    <StyledHeroContainer variant={variant} ref={ref}>
      <NextSeo openGraph={openGraph} />
      {wrappedHeroImg}
      <HeroInfoContainer section={section} variant={variant} />
    </StyledHeroContainer>
  )
}
