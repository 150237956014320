import type { ProductCardLoadingProps } from './ProductCardLoading'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { HeartOutline16 } from '@sok/design-system'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import { ProductPrice } from 'components/product-price/ProductPrice'
import { ProductAvailabilityInfo } from 'components/ProductAvailabilityInfo'
import { StyledProductImageContainer } from 'components/ProductImage'
import { ProductLabels } from 'components/ProductLabels'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'

import { FavoriteBtn } from './ExtraActions'
import {
  _ProductCard,
  _ProductCardError,
  defaultStyles,
  lessOpacity,
  ProductDetails,
} from './ProductCard'
import {
  defaultLoadingStyles,
  DetailsLoader,
  FavoriteLoader,
  ImageLoader,
  PriceLoader,
  QuantityModifierLoader,
} from './ProductCardLoading'
import { ProductName } from './ProductName'
import { QuantityModifier } from './QuantityModifier'

/**
 * Default Card Component Styles
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5586%3A313343
**/
const cardStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({ disabled, theme }) => ({
  ...BORDER_RADIUS,
  border: `1px solid ${theme.colors.grey200}`,
  gridTemplateColumns: '1fr min-content',
  gridTemplateRows: 'min-content 140px 130px min-content',
  rowGap: theme.spacings.xxxSmall,
  paddingBottom: 0,
  padding: theme.spacings.xSmall,
  ...lessOpacity(disabled),

  [FavoriteBtn]: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },

  [ProductLabels]: {
    gridColumn: '2 / 3',
    gridRow: '2 / 3',
    justifySelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacings.xxxSmall,
    columnGap: 0,
    height: '90%',
    overflow: 'hidden',

    li: {
      marginLeft: 0,
      borderRadius: 16,
      backgroundColor: theme.colors.white,
    },
  },

  [StyledProductImageContainer]: {
    gridColumn: '1 / 3',
    gridRow: '2 / 3',
    width: '100%',
    height: '100%',
    maxWidth: 140,
    maxHeight: 140,
    justifySelf: 'center',
    paddingRight: 0,
  },

  [ProductDetails]: {
    display: 'grid',
    gridTemplateRows: '48px 28px 40px',
    marginTop: theme.spacings.xSmall,
    gridColumn: '1 / 3',
    gridRow: '3 / 4',

    [ProductName]: {
      gridRow: 1,
      order: -1,

      '&:hover': {
        color: theme.colors.grey800,
      },
    },

    [ProductAvailabilityInfo]: {
      /** Don't display ProductAvailabilityInfo if ProductCard is disabled */
      display: disabled ? 'none' : 'revert',
      order: 0,
      margin: `${theme.spacings.xxxSmall} 0`,
      gridRow: 2,
    },

    [ProductPrice]: {
      order: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      gridRow: 3,
    },
  },

  [QuantityModifier]: {
    marginTop: theme.spacings.xxxSmall,
    gridRow: '4/ 5',
    gridColumn: '1 / 3',
    display: 'flex',
    flexDirection: 'row',
  },
})

const focusStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  '&:hover': {
    boxShadow: `0px 0px 20px rgba(0, 0, 0, 0.3)`,
    cursor: 'pointer',
  },

  '&:focus': {
    outline: `3px solid ${theme.colors.primary100}`,
  },
})

const Success = styled(_ProductCard)(defaultStyles, cardStyles, focusStyles)
const Error = styled(_ProductCardError)(defaultStyles, cardStyles, lessOpacity(true))

const { domain } = getConfig()

const _CardLoading: FC<ProductCardLoadingProps> = ({
  className,
  'data-test-id': dataTestId = 'product-card-loading',
}) => {
  const { isSIdEnabled } = domain
  const shouldFavoriteBeVisible = isSIdEnabled

  return (
    <article className={className} data-test-id={dataTestId}>
      <ImageLoader />
      <DetailsLoader>
        <SkeletonLoader height="20px" />
      </DetailsLoader>
      {shouldFavoriteBeVisible && (
        <FavoriteLoader>
          <HeartOutline16 aria-hidden="true" />
        </FavoriteLoader>
      )}
      <PriceLoader>
        <SkeletonLoader height="24px" width="72px" />
        <SkeletonLoader height="16px" width="56px" />
      </PriceLoader>
      <QuantityModifierLoader />
    </article>
  )
}

/** Product Card Loading Styles for Card
 * @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=6373%3A318370
 */
const cardLoadingStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  gridTemplateColumns: '1fr min-content',
  gridTemplateRows: 'min-content 150px 42px min-content',
  rowGap: theme.spacings.xxxSmall,
  border: `1px solid ${theme.colors.grey200}`,
  borderBottom: 0,
  paddingBottom: 0,
  padding: theme.spacings.xSmall,

  [SkeletonLoader]: {
    ...BORDER_RADIUS,
  },

  [FavoriteLoader]: {
    gridRow: 1,
    gridColumn: 2,
    width: '40px',
    height: '40px',
    alignSelf: 'end',

    color: theme.colors.grey800,
  },

  [ImageLoader]: {
    gridRow: 2,
    gridColumn: '1 / 3',
    width: '100%',
    height: 124,
  },

  [QuantityModifierLoader]: {
    justifyContent: 'space-between',
    gridRow: 5,
    gridColumn: '1 / 3',

    ['&' + SkeletonLoader]: {
      '&:nth-child(2)': {
        width: '25%',
        height: '40px',
      },
    },
  },

  [DetailsLoader]: {
    gridRow: 3,
    gridColumn: '1 / 3',
  },
})

const Loading = styled(_CardLoading)(defaultLoadingStyles, cardLoadingStyles)

export const Card = { Loading, Success, Error }
