import type { FC } from 'react'
import type { IInputElementFields } from 'types/contentful/generated'

import { Button, ButtonSize } from '@sok/design-system'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'

interface ContentfulButtonProps {
  cta: IInputElementFields
  href?: string
  onClick?: () => void
}

export const ContentfulButton: FC<ContentfulButtonProps> = ({ cta, href, onClick }) => {
  if (cta && cta.label && cta.url) {
    const isPrismaButton = cta.fieldType === 'prisma' && href
    const validatedHref = getValidatedUrl(isPrismaButton ? href : cta.url)

    return (
      <Link href={validatedHref} passHref>
        <Button
          data-test-id={isPrismaButton ? 'shop-prisma-button' : undefined}
          size={ButtonSize.LARGE}
          onClick={onClick}
          target={cta.openLinkInNewTab ? '_blank' : undefined}
          text={cta.label}
        />
      </Link>
    )
  }

  return null
}
