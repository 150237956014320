import type { FC } from 'react'
import type { IInputElementFields, ISection } from 'types/contentful/generated'

import { Button, ButtonBlock, ButtonSize, ButtonType, minWidthFromTheme } from '@sok/design-system'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import styled from 'styled-components'

const StyledLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacings.large} ${({ theme }) => theme.spacings.medium};
  gap: ${({ theme }) => theme.spacings.medium};

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    flex-direction: row;
  }
`

interface LinkComponentProps {
  button1?: IInputElementFields
  button2?: IInputElementFields
}

const LinkComponents: FC<LinkComponentProps> = ({ button1, button2 }) => {
  if (button1 && button2) {
    return (
      <StyledLinkContainer>
        <Link href={getValidatedUrl(button1.url)} passHref>
          <Button
            blockOn={ButtonBlock.MOBILE_DOWN}
            size={ButtonSize.LARGE}
            target={button1.openLinkInNewTab ? '_blank' : undefined}
            type={ButtonType.DEFAULT}
            text={button1.label}
          />
        </Link>
        <Link href={getValidatedUrl(button2.url)} passHref>
          <Button
            blockOn={ButtonBlock.MOBILE_DOWN}
            size={ButtonSize.LARGE}
            target={button2.openLinkInNewTab ? '_blank' : undefined}
            type={ButtonType.DEFAULT}
            text={button2.label}
          />
        </Link>
      </StyledLinkContainer>
    )
  }

  return null
}

export const LinkToOtherPages: FC<{
  section: ISection
}> = ({ section }) => {
  const { functionality } = section.fields
  const button1 = functionality?.[0]?.fields
  const button2 = functionality?.[1]?.fields
  return <LinkComponents button1={button1} button2={button2} />
}
