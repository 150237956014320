import type { FC } from 'react'

import { ArrowLeftOutline16 } from '@sok/design-system'
import styled from 'styled-components'

const StyledIconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`

const StyledIcon = styled.div`
  display: flex;
  margin-left: 10px;
  width: 40px;
`

const StyledTitle = styled.div(({ theme }) => theme.variants.body2.regular, {
  marginLeft: '8px',
})

const StyledHeaderWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
`

const StyledIconWrapper = styled.div`
  display: flex;
  margin-left: 20px;
`

interface HeaderProps {
  icon: JSX.Element
  title: string | null | undefined
  onClick: () => void
}

export const Header: FC<HeaderProps> = ({ icon, title, onClick }) => (
  <StyledHeaderWrapper data-test-id="product-navigation-category-header" onClick={onClick}>
    <StyledIconWrapper data-test-id="product-navigation-category-header-back-arrow">
      <ArrowLeftOutline16 />
    </StyledIconWrapper>
    <StyledIconTitleWrapper data-test-id="product-navigation-category-header-title-icon">
      <StyledIcon data-test-id="product-navigation-category-header-icon">{icon}</StyledIcon>
      <StyledTitle data-test-id="product-navigation-category-header-title">{title}</StyledTitle>
    </StyledIconTitleWrapper>
  </StyledHeaderWrapper>
)
