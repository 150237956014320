import type { AccordionItem } from '@sok/design-system'
import type { FC } from 'react'
import type {
  IFooterFields,
  ILinkContainer,
  ILinkContainerFields,
} from 'types/contentful/generated'

import { Accordion } from '@sok/design-system'
import Link from 'next/link'
import { memo, useCallback } from 'react'

export type CurrentLink = { id: number | null; isOpen: boolean }

import {
  StyledIcon,
  StyledLinksContainer,
  StyledLinksRow,
  StyledRowContent,
  StyledRowTitle,
  StyledUsercentricsDialogToggle,
} from './styled-footer'

interface FooterLinksProps {
  footerLinks: IFooterFields['footerLinks']
  currentLink: CurrentLink
  toggleLinks: (id: number, isOpen: boolean) => void
  ['data-test-id']?: string
}

export const FooterLinks: FC<FooterLinksProps> = memo(
  ({ footerLinks, currentLink, toggleLinks, ['data-test-id']: dataTestId }) => {
    const getFooterLinks = (
      linkContainers: ILinkContainer[],
      current: CurrentLink,
    ): AccordionItem[] =>
      linkContainers
        .map((footerLink, index) => {
          return {
            id: `${index}`,
            title: footerLink?.fields?.title,
            content: (
              <FooterMobileItem
                links={footerLink?.fields?.links}
                currentLink={current}
                index={index}
              />
            ),
          }
        })
        .filter(Boolean)

    const onLinkClick = useCallback(
      (index: number) => toggleLinks(index, currentLink.id === index && currentLink.isOpen),
      [currentLink.id, currentLink.isOpen, toggleLinks],
    )

    return footerLinks?.length ? (
      <>
        <span data-test-id={`${dataTestId}-mobile`} className="desktop-hide">
          <Accordion
            data-test-id={`${dataTestId}-accordion`}
            items={getFooterLinks(footerLinks, currentLink)}
          />
        </span>
        <StyledLinksContainer data-test-id={dataTestId} className="desktop-only">
          {footerLinks.map(({ fields }, index) => (
            <StyledLinksRow
              data-test-id={`${dataTestId}-row-${index}`}
              key={index}
              onClick={() => onLinkClick(index)}
            >
              {fields.title && (
                <StyledRowTitle data-test-id={`${dataTestId}-row-title-${index}`}>
                  {fields.title}
                </StyledRowTitle>
              )}
              {fields.links && (
                <FooterMobileItem
                  data-test-id={`${dataTestId}-row-link-${index}`}
                  links={fields.links}
                  currentLink={currentLink}
                  index={index}
                />
              )}
            </StyledLinksRow>
          ))}
        </StyledLinksContainer>
      </>
    ) : null
  },
)

FooterLinks.displayName = 'FooterLinks'

interface FooterMobileItemProps {
  links: ILinkContainerFields['links']
  currentLink: CurrentLink
  index: number
  ['data-test-id']?: string
}

const FooterMobileItem: FC<FooterMobileItemProps> = memo(
  ({ links, ['data-test-id']: dataTestId }) => {
    return (
      <>
        {links?.map((link, linkIndex) => {
          const { icon, label, url, openLinkInNewTab } = link.fields ?? {}
          const iconSrc = icon?.fields?.file?.url

          const isConsentLink = url === 'showConsentChooser'

          if (isConsentLink) {
            return (
              <StyledRowContent key={linkIndex}>
                <StyledUsercentricsDialogToggle data-test-id={dataTestId}>
                  {label}
                </StyledUsercentricsDialogToggle>
              </StyledRowContent>
            )
          }

          return (
            <StyledRowContent key={linkIndex}>
              {iconSrc && (
                <StyledIcon src={iconSrc} alt={label} data-test-id={`${dataTestId}-icon`} />
              )}
              {url ? (
                <Link href={url} passHref>
                  {/**
                   * Disable ESLint automatically adding rel="noreferrer" for external links.
                   * These point to other S Group services and we want referrer analytics.
                   */
                  /* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a href={url} target={openLinkInNewTab ? '_blank' : ''} data-test-id={dataTestId}>
                    {label}
                  </a>
                </Link>
              ) : (
                <p data-test-id={`${dataTestId}-label`}>{label}</p>
              )}
            </StyledRowContent>
          )
        })}
      </>
    )
  },
)

FooterMobileItem.displayName = 'FooterMobileItem'
