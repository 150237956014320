import type { FC, HTMLAttributes } from 'react'

import styled from 'styled-components'

export interface LabelCounterProps extends HTMLAttributes<HTMLDivElement> {
  value: number
  size?: number
}

const _LabelCounter: FC<LabelCounterProps> = ({ value, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <i data-test-id="label-counter" {...rest}>
    <span>+{value}</span>
  </i>
)

_LabelCounter.displayName = 'LabelCounter'

export const LabelCounter = styled(_LabelCounter)<LabelCounterProps>(({ theme, size }) => ({
  ...theme.variants.caption.regular,
  alignItems: 'center',
  backgroundColor: theme.colors.grey200,
  borderRadius: '50%',
  display: 'flex',
  fontStyle: 'normal',
  fontSize: size,
  height: size,
  justifyContent: 'center',
  width: size,
  cursor: 'pointer',
  color: 'black',

  '> span': {
    fontSize: size === 24 ? 11 : 9,
  },
}))

LabelCounter.defaultProps = {
  role: 'presentation',
  size: 24,
}
