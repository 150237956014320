import { Variant } from 'constants/animation'
import { useReducedMotion } from 'framer-motion'

type ReducedMotion = Variant.reducedMotion

export function usePreferredMotion<V extends Variant>(variant?: V): ReducedMotion | V {
  const isReducedMotion = useReducedMotion()

  if (isReducedMotion) {
    return Variant.reducedMotion
  }

  return variant || (Variant.animate as V)
}
