import type { ThemedObjectStyleFunction } from 'domain/styled'

import { minWidthFromTheme } from '@sok/design-system'
import styled from 'styled-components'

import { _ProductCard, defaultStyles, ProductDetails } from './ProductCard'
import { ProductComment } from './ProductCommentModal'
import { ProductQuantity } from './ProductQuantity'
import { ReplaceCheckBox } from './ReplaceCheckBox'
import { rowStyles } from './Row'

/**
 * Modified Row Component Styles for OrderConfirmation
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5899%3A186192
**/
const orderConfirmationStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({ theme }) => ({
  gridTemplateRows: 'auto min-content 1fr',
  gridTemplateColumns: 'min-content 1fr min-content',
  columnGap: theme.spacings.xxSmall,

  [ProductDetails]: {
    gridRow: '1 / 3',
  },

  [minWidthFromTheme(theme).tablet]: {
    gridTemplateColumns: 'min-content 1fr auto min-content',
  },

  [ProductQuantity]: {
    gridRow: '1 / 3',
    gridColumn: 3,
    width: 40,
    height: 40,
    alignSelf: 'center',

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: 4,
    },
  },

  [ReplaceCheckBox]: {
    marginTop: theme.spacings.xSmall,
    paddingLeft: theme.spacings.xSmall,
    gridRow: 3,
    gridColumn: '1 / 4',

    '&.checkbox-small.ant-checkbox-wrapper': {
      justifyContent: 'flex-start',
      paddingLeft: 0,
    },

    [minWidthFromTheme(theme).tablet]: {
      marginTop: 0,
      paddingRight: theme.spacings.small,
      alignSelf: 'center',
      gridColumn: 3,
      gridRow: '1 / 3',
    },
  },

  [ProductComment]: {
    gridColumn: '1 / 4',
    marginTop: theme.spacings.xSmall,
    gridRow: 4,

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '1 / 5',
    },
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, orderConfirmationStyles)

export const OrderConfirmation = { Success }

OrderConfirmation.Success.defaultProps = {
  readOnlyImage: true,
  readOnlyName: true,
}
