import type { Asset } from 'contentful'
import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'

import { ContentfulImage } from '../ContentfulImage'
import { StyledSectionWrapper } from './common'

interface ImageAndQuoteWrapperProps {
  imagePosition?: string
}
interface QuoteBodyAndSourceWrapperProps {
  image?: Asset
  imagePosition?: string
}

interface QuoteImageContainerProps {
  imagePosition?: string
}
interface ImageWithQuoteProps {
  image?: Asset
  body?: string
  imagePosition?: string
  quoteSource?: string
}
interface ImageAndQuoteProps {
  section: ISection
}

const StyledImageAndQuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`
const StyledImageAndQuoteWrapper = styled.article<ImageAndQuoteWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 936px;
  flex-direction: ${({ imagePosition }) => (imagePosition === 'Bottom' ? 'column' : 'row')};

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    margin: 0px 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
  }
`
const StyledQuoteImageContainer = styled.div<QuoteImageContainerProps>`
  width: ${({ imagePosition }) => (imagePosition === 'Bottom' ? '100%' : 'calc(100% / 2 - 8px)')};
  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    margin-right: ${({ imagePosition }) => (imagePosition === 'Left' ? '8px' : '0px')};
    margin-left: ${({ imagePosition }) => (imagePosition === 'Right' ? '8px' : '0px')};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 32px;
  }
`
const StyledQuoteBodyAndSourceWrapper = styled.div<QuoteBodyAndSourceWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ image, imagePosition }) =>
    image && imagePosition !== 'Bottom' ? '0 28px' : '0 28px 32px'};
  width: ${({ image, imagePosition }) =>
    image && imagePosition !== 'Bottom' ? 'calc(100% / 2 - 8px)' : '100%'};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`
const StyledQuoteBody = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-style: italic;
  ${({ theme }) => css(theme.variants.title1)};
  line-height: 1.2;
`

const StyledQuoteSource = styled.div(({ theme }) => theme.variants.body2.regular)

const ImageWithQuote: FC<ImageWithQuoteProps> = ({ image, body, imagePosition, quoteSource }) => {
  return imagePosition === 'Left' ? (
    <StyledImageAndQuoteContainer>
      <StyledImageAndQuoteWrapper>
        {image ? (
          <StyledQuoteImageContainer imagePosition={imagePosition}>
            <StyledContentfulImage image={image} lazy />
          </StyledQuoteImageContainer>
        ) : (
          <></>
        )}

        <StyledQuoteBodyAndSourceWrapper image={image}>
          <StyledQuoteBody> {body} </StyledQuoteBody>
          <StyledQuoteSource>{quoteSource}</StyledQuoteSource>
        </StyledQuoteBodyAndSourceWrapper>
      </StyledImageAndQuoteWrapper>
    </StyledImageAndQuoteContainer>
  ) : (
    <StyledImageAndQuoteContainer>
      <StyledImageAndQuoteWrapper imagePosition={imagePosition}>
        <StyledQuoteBodyAndSourceWrapper image={image} imagePosition={imagePosition}>
          <StyledQuoteBody> {body} </StyledQuoteBody>
          <StyledQuoteSource>{quoteSource}</StyledQuoteSource>
        </StyledQuoteBodyAndSourceWrapper>
        {image ? (
          <StyledQuoteImageContainer imagePosition={imagePosition}>
            <StyledContentfulImage
              sourceSizes="(min-width: 768px) 50vw, 100vw"
              image={image}
              lazy
            />
          </StyledQuoteImageContainer>
        ) : (
          <></>
        )}
      </StyledImageAndQuoteWrapper>
    </StyledImageAndQuoteContainer>
  )
}

export const ImageAndQuote: FC<ImageAndQuoteProps> = ({ section }) => {
  const { cards, tagTitle } = section.fields
  if (cards && cards.length > 0) {
    const card = cards[0]
    const { body, imagePosition, quoteSource, image } = card.fields

    return (
      <StyledSectionWrapper id={tagTitle}>
        <ImageWithQuote
          image={image}
          imagePosition={imagePosition}
          body={body}
          quoteSource={quoteSource}
        />
      </StyledSectionWrapper>
    )
  }
  return <></>
}

const StyledContentfulImage = styled(ContentfulImage)({
  height: '100%',
  objectFit: 'cover',
})
