import type { RemoteNavigationQuery } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { maxWidthFromTheme, minWidthFromTheme, RecipeOutline24 } from '@sok/design-system'
import {
  BulbOutline24,
  FavoritesNavIcon,
  RecommendationsNavIcon,
  ShoppinglistsNavIcon,
} from 'components/icons'
import { motionFadeIn, Variant } from 'constants/animation'
import { motion } from 'framer-motion'
import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled, { useTheme } from 'styled-components'
import { NAVBAR_HEIGHT, NAVBAR_WIDTH } from 'styles/layout'
import { searchNavigationItem } from 'utils/common/search-navigation-item'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

import { Category } from './Category'
import { SubCategory } from './desktop/SubCategory'
import { NavigationSubMenu } from './mobile/NavigationSubMenu'

const { domain, featureFlags } = getConfig()

const StyledNavigationWrapper = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,

  height: `calc(100vh - ${NAVBAR_HEIGHT.mobileHalf}px)`,
  zIndex: 50,
  width: '100%',
  overflow: 'hidden',

  backgroundColor: `${theme.color.white}`,
  color: `${theme.color.fontBlack}`,

  [maxWidthFromTheme(theme).mobile]: {
    position: 'fixed',
    top: `${NAVBAR_HEIGHT.mobile}px`,
  },

  [minWidthFromTheme(theme).tablet]: {
    display: 'flex',
    top: `${NAVBAR_HEIGHT.desktop}px`,
    height: `calc(100vh - ${NAVBAR_HEIGHT.desktop})`,
  },

  [minWidthFromTheme(theme).desktop]: {
    top: `${NAVBAR_HEIGHT.desktop}px`,
    minHeight: `calc(min(1000px, 100vh) - ${NAVBAR_HEIGHT.desktop}px)`,
    maxHeight: '1500px',
    height: `calc(100vh - ${NAVBAR_HEIGHT.desktop}px)`,

    maxWidth: `${NAVBAR_WIDTH}px`,
    margin: '0 auto',
  },
}))

interface NavigationProps {
  data?: RemoteNavigationQuery
}

export interface ParentProps {
  id?: string | null | undefined
  slug?: string | null | undefined
}

export type ChildProps = ParentProps

export const Navigation: FC<NavigationProps> = ({ data }) => {
  // States
  const [parent, setParent] = useState<ParentProps>({ id: undefined, slug: undefined })
  const [child, setChild] = useState<ChildProps>({ id: undefined, slug: undefined })

  // Hooks
  const theme = useTheme()
  const { t } = useTranslation()
  const isDesktop = useMediaQuery({
    /** Have to love these variable names... */
    minWidth: theme.breakpoints.tablet,
  })
  const router = useRouter()
  // Reference variables

  const routerSlugs = Array.isArray(router.query?.slugs) ? router.query?.slugs : ''

  // Handlers
  const handleCategoryItemClick = (id: string, slug: string): void => {
    setParent({ id, slug })
    trackCustomEvent({
      category: EventCategory.NAVIGATION_ELEMENTS,
      action: EventAction.CLICK,
      label: `main-navigation-category_${slug}`,
    })
  }

  const handleServiceItemClick = (id: string, path: string): void => {
    trackCustomEvent({
      category: EventCategory.NAVIGATION_ELEMENTS,
      action: EventAction.CLICK,
      label: `main-navigation-service_${id}`,
    })
    router.push(path)
    navigationVisibleVar(false)
    searchVisibleVar(false)
  }
  const handleSubCategoryItemClick = (slug: string): void => {
    router.push(`/tuotteet/${slug}`, `/tuotteet/${slug}`)

    trackCustomEvent({
      category: EventCategory.NAVIGATION_ELEMENTS,
      action: EventAction.CLICK,
      label: `main-navigation-subcategory_${slug}`,
    })
  }
  const handleSubCategoryMobileItemClick = (slug: string): void => {
    child?.slug === slug
      ? setChild({ id: undefined, slug: undefined })
      : setChild({ id: undefined, slug })

    trackCustomEvent({
      category: EventCategory.NAVIGATION_ELEMENTS,
      action: EventAction.CLICK,
      label: `main-navigation-subcategory_${slug}`,
    })
  }

  const activeCategory = (subCategorySlug: string | null | undefined) => {
    if (parent?.slug !== undefined) {
      return parent?.slug === subCategorySlug
    }
    if (routerSlugs?.length > 0) {
      const joinedSlugs = Array.isArray(routerSlugs) ? [...routerSlugs].join('/') : routerSlugs
      return joinedSlugs === subCategorySlug
    }
    return false
  }

  const activeSubCategory = (subCategorySlug: string | null | undefined) => {
    const joinedSlugs = Array.isArray(routerSlugs) ? [...routerSlugs].join('/') : routerSlugs
    return joinedSlugs === subCategorySlug
  }

  useEffect(() => {
    if (Array.isArray(routerSlugs) && routerSlugs?.length > 0) {
      const parentSlug = routerSlugs?.[0]
      const childSlug = `${routerSlugs?.[0]}/${routerSlugs?.[1]}`
      setParent({ slug: parentSlug })
      setChild({ slug: childSlug })
    } else if (isDesktop) {
      // Pre-select first element only for desktop if nothing is selected/ no router slugs available
      setParent({ slug: data?.store?.navigation?.[0]?.slug as string })
    }
  }, [data, isDesktop, routerSlugs])

  if (!data?.store?.navigation) return null

  const slug = parent?.slug || routerSlugs?.[0]
  const navigationData = data.store.navigation
  const navigationItem = searchNavigationItem(navigationData, slug, 'slug')

  if (!isDesktop && parent?.slug) {
    return (
      <StyledNavigationWrapper
        data-test-id="product-navigation"
        initial={Variant.initial}
        animate={Variant.animate}
        exit={Variant.initial}
        variants={motionFadeIn}
      >
        <NavigationSubMenu
          data-test-id="product-navigation-mobile-submenu"
          id={navigationItem?.id}
          title={navigationItem?.name}
          parent={parent}
          child={child}
          items={navigationItem?.children ?? []}
          onHeaderClick={() => setParent({ id: undefined, slug: undefined })}
          onItemClick={handleSubCategoryMobileItemClick}
          isActive={activeSubCategory}
        />
      </StyledNavigationWrapper>
    )
  }

  return (
    <StyledNavigationWrapper
      data-test-id="product-navigation"
      initial={Variant.initial}
      animate={Variant.animate}
      exit={Variant.initial}
      variants={motionFadeIn}
    >
      <Category
        items={navigationData}
        serviceItems={[
          {
            id: 'favorites',
            name: t('My favorites'),
            path: '/suosikit',
            icon: <FavoritesNavIcon />,
            enabled: domain.isSIdEnabled,
          },
          {
            id: 'recommendations',
            name: t('Recommendations'),
            path: '/suosittelemme-sinulle',
            icon: <RecommendationsNavIcon />,
            enabled: domain.isSIdEnabled,
          },
          {
            id: 'shoppinglists',
            name: t('My Shopping lists'),
            path: '/ostoslistat',
            icon: <ShoppinglistsNavIcon />,
            enabled: domain.isSIdEnabled && featureFlags.shoppingLists,
          },
          {
            id: 'topical',
            name: t('menuDrawer_placeholder Topical'),
            path: '/sivu/ajankohtaista',
            icon: <BulbOutline24 />,
            enabled: true,
          },
          {
            id: 'recipes',
            name: t('menuDrawer_placeholder Recipes'),
            path: '/sivu/reseptit',
            icon: <RecipeOutline24 />,
            enabled: true,
          },
        ].filter((item) => item.enabled)}
        isActive={activeCategory}
        isDesktop={isDesktop}
        onItemClick={handleCategoryItemClick}
        onServiceItemClick={handleServiceItemClick}
      />

      {isDesktop && (
        <SubCategory
          parent={parent}
          items={navigationItem?.children}
          isActive={activeSubCategory}
          onItemClick={handleSubCategoryItemClick}
          slug={navigationItem?.slug}
        />
      )}
    </StyledNavigationWrapper>
  )
}
