import type { FC, MouseEvent } from 'react'

import { ChevronDown } from 'components/icons'
import { Duration, Easing, Variant } from 'constants/animation'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ScrollForMoreProps {
  className?: string
  onClick?: (e: MouseEvent) => void
}

const _ScrollForMore: FC<ScrollForMoreProps> = ({ className, onClick }) => {
  const { t } = useTranslation()

  return (
    <motion.div
      className={className}
      data-test-id="product-navigation-scrollForMore"
      onClick={onClick}
      // This doesn't need to be accessible because it only controls scroll
      role="presentation"
      initial={Variant.initial}
      animate={Variant.animate}
      exit={Variant.exit}
      variants={{
        initial: {
          y: 10,
        },
        animate: {
          y: 0,
          transition: {
            ease: Easing.entry,
          },
        },
        exit: {
          y: 10,
          transition: {
            ease: Easing.exit,
            duration: Duration.fastest,
          },
        },
      }}
    >
      <span>{t('See below for more')}</span>
      <ChevronDown />
    </motion.div>
  )
}

export const ScrollForMore = styled(_ScrollForMore)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,

  background: theme.colors.SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY,
  boxShadow: `0 -5px 5px -5px ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY}`,
  cursor: 'pointer',
  padding: '14px 0',
  textAlign: 'center',

  width: '100%',

  span: {
    ...theme.variants.body2.regular,
    color: theme.colors.SDS_BRAND_BUTTON_COLOR_TEXT_PRIMARY,
    marginRight: 8,
  },

  svg: {
    path: {
      fill: theme.colors.SDS_BRAND_BUTTON_COLOR_TEXT_PRIMARY,
    },
  },
}))
