import type { AvailableDeliverySlot } from './validation'
import type { ApolloError } from '@apollo/client'
import type {
  CreateOrderMutation,
  DomainOrder,
  PaymentMethod,
  PaymentStatus,
  RemoteDeliverySlotQuery,
  RemoteDeliverySlotQueryVariables,
  UpdateOrderMutation,
} from '@shared/gql/document-nodes'
import type { SentDomainOrder, ValidDraftDomainOrder } from 'domain/order'
import type { GraphQLError } from 'graphql'
import type { ValidationError } from 'validation/validators'

import { useApolloClient, useMutation } from '@apollo/client'
import {
  ClearCartAndOrderDetailsDocument,
  CreateOrderDocument,
  GetOrderByIdDocument,
  GetPaymentDetailsDocument,
  LocalDomainOrderDocument,
  RemoteDeliverySlotDocument,
  StartOrderEditDocument,
  UpdateOrderDocument,
} from '@shared/gql/document-nodes'
import { unknownError } from 'domain/error-messages/unknown-error'
import { maybeValidSentOrder } from 'domain/order'
import * as E from 'fp-ts/Either'
import { useCallback } from 'react'
import {
  useClearDeliverySlotReservationFromCache,
  useDeliverySlotReservation,
  useIsDeliverySlotReservationEnabled,
} from 'services/DeliverySlot/hooks'
import { trackSelectedSlotIsClosed } from 'services/DeliverySlot/tracking'
import {
  setLinkVerificationToken,
  useLinkVerificationToken,
} from 'services/LinkVerificationToken/use-link-verification-token'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { isPaymentPending } from 'utils/payment'
import { validationErrorOf } from 'validation/validators'

import { useGetMixedAsyncUserProfile } from '../hooks/use-get-mixed-async-user-profile'
import { useTrackTransactionCreate, useTrackTransactionEdit } from './analytics'
import { useCreatePayment } from './create-payment'
import { draftOrderMapper } from './mapper'
import { slotAvailabilityV } from './slot-validation'
import { orderV } from './validation'

// create new order from cached data
// read order from local and remote
// -> validate
// -> clear age restricted products, if slot disallows
// -> send to gql
// -> return bunch of validation errors (or server) on promise.reject or resolve to redirect url tuple
export const useOrderCreate = (): ((discountCode: string | null) => Promise<[string, string]>) => {
  const getOrder = useGetOrder()
  const createOrder = useCreateOrder()
  const clearOrderAfterSend = useClearOrderAfterSend()
  const getDeliverySlotAvailability = useGetOrderDeliverySlot()
  const trackCreate = useTrackTransactionCreate()

  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const remoteReservation = useDeliverySlotReservation()
  const clearReservationFromCache = useClearDeliverySlotReservationFromCache()
  const createPaymentAndRedirect = useCreatePaymentAndRedirectOrder('osto')
  const getPaymentData = useGetPaymentData()

  return useCallback(
    async (discountCode: string | null) => {
      const order = await getOrder()
      const deliverySlot = await getDeliverySlotAvailability(
        remoteReservation.type === 'SUCCESS'
          ? remoteReservation.data.deliverySlot.slotId
          : order.deliverySlotId,
      )

      return (
        validateOrder(isDeliverySlotReservationEnabled)(deliverySlot)(order)
          .then(createOrder(discountCode))
          .then(rejectNullable)
          // TODO: VOIK-7360 analytics should not be in the promise chain
          .then(trackCreate)
          .then((createdOrder) => {
            if (remoteReservation.type === 'SUCCESS') {
              clearReservationFromCache(remoteReservation.data.reservationId)
            }
            return createdOrder
          })
          .then(clearOrderAfterSend)
          .then(createPaymentAndRedirect(getPaymentData()))
      )
    },
    [
      clearOrderAfterSend,
      clearReservationFromCache,
      createOrder,
      createPaymentAndRedirect,
      getDeliverySlotAvailability,
      getOrder,
      getPaymentData,
      isDeliverySlotReservationEnabled,
      remoteReservation,
      trackCreate,
    ],
  )
}

// update existing order (with id and state other than new)
// read order from local and remote
// -> validate
// -> clear age restricted products, if slot disallows
// -> send to gql
// -> return bunch of validation errors (or server) on promise.reject or resolve to redirect url tuple
export const useOrderUpdate = (): ((discountCode: string | null) => Promise<[string, string]>) => {
  const getOrder = useGetOrder()
  // Fetch old order just for the sake of analytics?
  const getOldOrder = useGetOldOrder()
  const updateOrder = useUpdateOrder()
  const clearOrderAfterSend = useClearOrderAfterSend()
  const getDeliverySlotAvailability = useGetOrderDeliverySlot()
  const trackEdit = useTrackTransactionEdit()

  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const remoteReservation = useDeliverySlotReservation()
  const clearReservationFromCache = useClearDeliverySlotReservationFromCache()
  const createPaymentAndRedirect = useCreatePaymentAndRedirectOrder('muokkaus')
  const getPaymentData = useGetPaymentData()

  return useCallback(
    async (discountCode: string | null) => {
      const order = await getOrder()
      const oldOrder = await getOldOrder(order.id)
      const deliverySlot = await getDeliverySlotAvailability(
        remoteReservation.type === 'SUCCESS'
          ? remoteReservation.data.deliverySlot.slotId
          : order.deliverySlotId,
      )

      return validateOrder(isDeliverySlotReservationEnabled)(deliverySlot)(order)
        .then(validateOrRejectSentOrder)
        .then(updateOrder(discountCode))
        .then(rejectNullable)
        .then((updatedOrder) => trackEdit(updatedOrder, oldOrder))
        .then((createdOrder) => {
          if (remoteReservation.type === 'SUCCESS') {
            clearReservationFromCache(remoteReservation.data.reservationId)
          }
          return createdOrder
        })
        .then(clearOrderAfterSend)
        .then(createPaymentAndRedirect(getPaymentData()))
    },
    [
      clearOrderAfterSend,
      clearReservationFromCache,
      createPaymentAndRedirect,
      getDeliverySlotAvailability,
      getOldOrder,
      getOrder,
      getPaymentData,
      isDeliverySlotReservationEnabled,
      remoteReservation,
      trackEdit,
      updateOrder,
    ],
  )
}

const validateOrRejectSentOrder = async (
  order: DomainOrder | ValidDraftDomainOrder,
): Promise<SentDomainOrder> => {
  const validated = maybeValidSentOrder(order)
  if (E.isRight(validated)) {
    return validated.right
  }

  return reject([unknownError()])
}

// prevent edit mode to be active
// after order has been sent
// TODO: VOIK-6980
// think we should get rid of it ASAP
// copied from old
const useClearOrderAfterSend = () => {
  const isLoggedIn = useIsLoggedIn()
  const [clearCartAndOrderDetails] = useMutation(ClearCartAndOrderDetailsDocument)
  // With new orders using card payment - order clearing is done in payment landing page (Nets redirects back to that page).
  // With open orders the behaviour is the same as with the new orders
  // In case of order modification, cart is cleared after order update
  // with other payment methods, cart is always cleared after submitting the order
  return async <
    T extends { paymentMethod: PaymentMethod | null; paymentStatus: PaymentStatus | null } | null,
  >(
    x: T,
  ) => {
    const clearCartAfterOrderSubmit = x && !isPaymentPending(x)

    if (clearCartAfterOrderSubmit) {
      await clearCartAndOrderDetails({ variables: { isLoggedIn } })
    }

    return x
  }
}

// read order from local apollo cache, just in time before sending
// decorate with remote user profile data, this is not in local state but in cache
// and local query does not fetch it, it should be cached though, so no real remote call is made
// but it is possible, if for example order page implementation is changed
const useGetOrder = (): (() => Promise<DomainOrder>) => {
  const apolloClient = useApolloClient()
  const getMixedUserProfile = useGetMixedAsyncUserProfile()

  return async () => {
    const query = apolloClient.readQuery({
      query: LocalDomainOrderDocument,
    })

    if (!query?.domainOrder) {
      throw new OrderSubmitUnknownError()
    }

    const profile = await getMixedUserProfile()
    return {
      ...query.domainOrder,
      customer: profile,
    }
  }
}

const useGetPaymentData = () => {
  const apolloClient = useApolloClient()

  return () => {
    const paymentDetailsData = apolloClient.readQuery({ query: GetPaymentDetailsDocument })

    if (!paymentDetailsData?.paymentDetails) {
      throw new OrderSubmitUnknownError()
    }

    return paymentDetailsData.paymentDetails
  }
}

const useCreatePaymentAndRedirectOrder = (action: 'muokkaus' | 'osto') => {
  const createPayment = useCreatePayment()
  return (paymentData: { selectedPaymentCardId: string | null; savePaymentCard: boolean | null }) =>
    async <
      T extends {
        id: string
        paymentMethod: PaymentMethod | null
        paymentStatus: PaymentStatus | null
      },
    >(
      x: T,
    ): Promise<[string, string]> => {
      if (isPaymentPending(x)) {
        return [await createPayment(paymentData)(x), '']
      }

      return extractRedirectUrlFromResponseForAction(action)(x)
    }
}

// Fetch old order for the of analytics
const useGetOldOrder = () => {
  const apolloClient = useApolloClient()
  const linkVerificationToken = useLinkVerificationToken()

  return useCallback(
    async (orderId: string | null) => {
      if (!orderId) return null

      try {
        const orderQuery = await apolloClient.query({
          query: GetOrderByIdDocument,
          variables: { id: orderId, linkVerificationToken },
        })
        return orderQuery.data.order ?? null
      } catch {
        return null
      }
    },
    [apolloClient, linkVerificationToken],
  )
}

// CreateOrder
// sets responses order id into local state to set "editMode" as active...
// useMutation(CreateOrderDocument)
const useCreateOrder = (): ((
  discountCode: string | null,
) => (order: ValidDraftDomainOrder) => Promise<CreateOrderMutation['createOrder']>) => {
  const [createOrderMutation] = useMutation(CreateOrderDocument)
  const [setUpdatedOrderId] = useMutation(StartOrderEditDocument)

  // call CreateOrder on gql api with given parameters
  return (discountCode) => async (order) =>
    await createOrderMutation({
      variables: {
        order: draftOrderMapper.toDto({ ...order, discountCode }),
      },
    })
      .then(handleNetworkError)
      .then((x) => x?.createOrder)
      .then((x) => {
        if (!x) {
          throw new OrderSubmitUnknownError()
        }

        return x
      })
      .then((response) => {
        setLinkVerificationToken(response.linkVerificationToken)
        // update order id into local to inform app that the order is already sent
        return setUpdatedOrderId({
          variables: {
            orderId: response.id,
            orderStatus: response.orderStatus,
          },
        }).then(() => response)
      })
}

// update existing order
// useMutation(UpdateOrderDocument)
const useUpdateOrder = (): ((
  discountCode: string | null,
) => (order: SentDomainOrder) => Promise<UpdateOrderMutation['updateOrder']>) => {
  const [updateOrderMutation] = useMutation(UpdateOrderDocument)

  return (discountCode: string | null) => async (order: SentDomainOrder) => {
    const { data, errors } = await updateOrderMutation({
      variables: {
        id: order.id,
        order: draftOrderMapper.toDto({ ...order, discountCode }),
      },
    })

    if (!data?.updateOrder) {
      throw new OrderSubmitUnknownError()
    }
    if (errors) {
      throw errors[0]
    }
    return data?.updateOrder
  }
}

// Legacy of old toimitus
//
// our apps internal thing for redirecting when order processing is successfull.
const extractRedirectUrlFromResponseForAction =
  (action: 'muokkaus' | 'osto') =>
  (x: { id: string }): [string, string] =>
    [
      `/tilaus/[orderId]?pageRef=toimitus&action=${action}`,
      `/tilaus/${x.id}?pageRef=toimitus&action=${action}`,
    ]

// Order validation
// Promise.reject is used to reject invalid order
// no other use for promise, except to dodge the usage of E.Either in promise chain
type ValidateOrder = (
  deliverySlot: AvailableDeliverySlot,
) => (order: DomainOrder) => Promise<ValidDraftDomainOrder>

const validateOrder =
  (isDeliverySlotReservationEnabled = false): ValidateOrder =>
  (deliverySlot) =>
  async (order) => {
    const validation = orderV(isDeliverySlotReservationEnabled)(deliverySlot)(order)

    if (E.isRight(validation)) {
      return validation.right
    }

    // convert form validation errors into singular message with key
    // of first item in path, to be displayed in error modal.
    return reject(validation.left)
  }

// fetch deliverySlot by id and run it through validation
// rejected promise contains error messages, resolves always to valid slot
const useGetOrderDeliverySlot = (): ((slotId: string | null) => Promise<AvailableDeliverySlot>) => {
  const client = useApolloClient()

  return (slotId) =>
    slotId
      ? client
          .query<RemoteDeliverySlotQuery, RemoteDeliverySlotQueryVariables>({
            query: RemoteDeliverySlotDocument,
            variables: { slotId },
          })
          .then(handleNetworkError)
          .then((x) => x?.deliverySlot)
          .then((data) => (data ? data : reject([validationErrorOf('NoSlotDataReceived')])))
          .then(trackSlotState)
          .then(slotAvailabilityV)
          .then((data) => (E.isLeft(data) ? reject(data.left) : data.right))
      : reject([
          {
            message: 'NoDeliverySlotSelected',
            tag: 'ValidationError',
            path: [],
          },
        ])
}

const trackSlotState = <T extends { isClosed: boolean }>(x: T): T => {
  if (x.isClosed) {
    trackSelectedSlotIsClosed()
  }
  return x
}

const rejectNullable = <T>(x: T | null): Promise<T> =>
  x == null ? reject([unknownError()]) : Promise.resolve(x)

// guard to always throw validation errors
const reject = (errors: ValidationError[]): Promise<never> => Promise.reject(errors)

const handleNetworkError = <T>({
  data,
  errors,
}: {
  data?: T
  errors?: readonly ApolloError[] | readonly GraphQLError[] | undefined
}): T | undefined => {
  if (errors) {
    throw errors[0]
  }

  return data
}

class OrderSubmitUnknownError extends Error {
  constructor(public readonly kind = 'orderSubmitError') {
    super()
  }
}
