import { useMutation } from '@apollo/client'
import { getConfig } from '@shared/config'
import { CreatePaymentDocument, DeviceType } from '@shared/gql/document-nodes'
import { isBrowser } from 'react-device-detect'

const { stage } = getConfig()

const detectDevice = (): DeviceType => (isBrowser ? DeviceType.Desktop : DeviceType.Mobile)
const getRedirectUrl = (): {
  customWebstoreRedirectUrl: string | null
} => ({
  ...((stage === 'production'
    ? {}
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { customWebstoreRedirectUrl: window ? window.location.origin : '' }) as any),
})

export const useCreatePayment = () => {
  const [createPaymentMutation] = useMutation(CreatePaymentDocument)

  return (paymentData?: {
      selectedPaymentCardId: string | null
      savePaymentCard: boolean | null
    }) =>
    <T extends { id: string }>(x: T): Promise<string> =>
      createPaymentMutation({
        variables: {
          orderId: x.id,
          device: detectDevice(),
          ...getRedirectUrl(),

          /**
           * schema is typed wrong,
           * if null is given, the api throws error
           * */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cardId: (paymentData?.selectedPaymentCardId || undefined) as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          shouldSavePaymentCard: (paymentData?.savePaymentCard || undefined) as any,
        },
      }).then((response) => {
        if (response.errors) {
          throw response.errors[0]
        }

        if (response.data?.createPayment?.redirectUrl) {
          return response.data.createPayment.redirectUrl
        }

        throw new CreatePaymentUnknownError()
      })
}

class CreatePaymentUnknownError extends Error {
  constructor(public readonly kind = 'createPaymentError') {
    super()
  }
}
