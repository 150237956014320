/* eslint-disable jsx-a11y/alt-text */

import type { FC } from 'react'

type N = 'skaupat' | 'herkku'

interface NavigationIconProps {
  height?: number
  width?: number
}

export const createNavigationIconFactory =
  (namespace: N) =>
  (
    displayName: string,
    filename: string,
    defaultWidth = 36,
    defaultHeight = 36,
  ): FC<NavigationIconProps> => {
    const NavigationIcon = ({ height = defaultHeight, width = defaultWidth }) => (
      <img
        data-test-id={`${filename}-icon`}
        height={height}
        role={'presentation'}
        src={`/icons/nav/${namespace}/${filename}.svg`}
        width={width}
      />
    )

    NavigationIcon.displayName = `navigationIcon(${displayName})`

    return NavigationIcon
  }
