import type { TrackingInteractionEvent } from './tracking-interaction-event'
import type { GA4EcommerceEvent } from '@shared/domain/tracking/ga4-event'

import { ga4ProductMapper } from './event-product-mapper'
import { dataLayer } from './tag-manager'

export const GA4_trackProductClick = ({
  listName,
  listId,
  availabilityLabel,
  products,
  trackingContext,
  searchProvider,
  listPosition = 0,
}: TrackingInteractionEvent) => {
  const event: GA4EcommerceEvent = {
    event: 'select_item',
    ecommerce: {
      productListContext: trackingContext,
      item_list_name: listName,
      item_list_id: listId || listName,
      totalResults: products.length,
      searchProvider,
      items: products.map((x, i) =>
        ga4ProductMapper(
          listName || '',
          listId || null,
          availabilityLabel,
        )({
          ...x,
          // for some reason, index should be start from 1.
          index: 1 + i + listPosition,
        }),
      ),
    },
  }

  dataLayer({ ecommerce: null })
  dataLayer(event)
}
