import type { NextRouter } from 'next/router'
import type { ReactNode } from 'react'

import { makeVar } from '@apollo/client'
import { getConfig } from '@shared/config'
import { NOTIFICATIONS_LOCALSTORAGE_KEY } from '@shared/domain/local-storage/notifications'
import { AlertType } from '@sok/design-system'
import { Trans } from 'react-i18next'
import { IS_BROWSER } from 'utils/is-browser'

export { AlertType } from '@sok/design-system'

const { featureFlags } = getConfig()

export type NotificationGroup =
  | 'global'
  | 'shoppinglist'
  | 'favorites'
  | 'new-feature-announcement'
  | 'servicebanner-marketing'

export type Notification =
  | NotificationAreaMessage
  | IntroductionModalMessage
  | ServiceBannerMarketingMessage
  | IntroductionTooltipMessage

export type NotificationAreaMessage = {
  __kind: 'NotificationAreaMessage'
  id: string
  group: NotificationGroup // definition of group vs id needs to be refactored?
  customIcon?: ReactNode
  isDismissable?: boolean
  message: ReactNode
  type: AlertType
  button?: {
    label: string
    onClick: (router: NextRouter) => () => void
  }
}

export type IntroductionModalMessage = {
  __kind: 'IntroductionModal'
  id: string
  group: NotificationGroup // definition of group vs id needs to be refactored?
  pages: {
    title: string
    image: ReactNode
    text: string
  }[]
}

export type ServiceBannerMarketingMessage = {
  __kind: 'ServiceBannerMarketingMessage'
  id: string
  group: NotificationGroup
}

export type IntroductionTooltipMessage = {
  __kind: 'IntroductionTooltip'
  id: string
  group: NotificationGroup // definition of group vs id needs to be refactored?
  message: ReactNode
}

// The follow part is designed so that this can be replaced with a remote one in the future.

const getDismissedState = (): string[] => {
  if (IS_BROWSER) {
    try {
      const storedList = localStorage.getItem(NOTIFICATIONS_LOCALSTORAGE_KEY)
      if (storedList) {
        const list: [] = JSON.parse(storedList)
        if (Array.isArray(list)) return list
      }
    } catch (e) {
      // Silent failure.
    }
  }
  return []
}

const storeDismissedState = (ids: string[]) => {
  if (IS_BROWSER) {
    try {
      localStorage.setItem(NOTIFICATIONS_LOCALSTORAGE_KEY, JSON.stringify(ids))
    } catch (e) {
      // Silent failure.
    }
  }
}

// Tries to add a notification; ignores it if the id is in the dismissed list, adds or replaces an existing one by the notification id.
const add = (notification: Notification) => {
  const { dismissed, notifications } = notificationsVar()
  if (
    !dismissed.includes(notification.id) &&
    !notifications.some(({ id, group }) => id === notification.id && group === notification.group)
  ) {
    notificationsVar({
      dismissed,
      notifications: [...notifications.filter(({ id }) => id !== notification.id), notification],
    })
  }
}

// Dismiss a notification and close it, the notification will not re-appear.
const dismiss = (notificationId: string) => {
  const { dismissed, notifications } = notificationsVar()
  if (!dismissed.includes(notificationId)) {
    const nextDismissed = [...dismissed, notificationId]
    notificationsVar({
      notifications,
      dismissed: nextDismissed,
    })
    storeDismissedState(nextDismissed)
  }
}

// Close a notification.
const close = (notificationId: string) => {
  const { dismissed, notifications } = notificationsVar()
  notificationsVar({
    notifications: notifications.filter((entry) => entry.id !== notificationId),
    dismissed,
  })
}

export const Notifications = {
  close,
  add,
  dismiss,
}

const notifications: Notification[] = []

if (featureFlags.shoppingLists) {
  notifications.push({
    group: 'shoppinglist',
    id: 'shoppinglist-intro-notification',
    __kind: 'NotificationAreaMessage',
    isDismissable: true,
    type: AlertType.INFO,
    customIcon: <></>,
    message: <Trans>Shoppinglist list view notification</Trans>,
  })
  notifications.push({
    group: 'favorites',
    id: 'favorites-shoppinglist-intro-notification',
    __kind: 'NotificationAreaMessage',
    type: AlertType.INFO,
    customIcon: <></>,
    isDismissable: true,
    message: <Trans>Shoppinglist Favorites view notification</Trans>,
    button: {
      label: 'Ostoslistoihin',
      onClick: (router: NextRouter) => () => {
        router.push('/ostoslistat').then(() => {
          dismiss('shoppinglist-intro-notification')
          dismiss('favorites-shoppinglist-intro-notification')
        })
      },
    },
  })
}

if (featureFlags.shoppingListsIntroduction) {
  notifications.push({
    group: 'shoppinglist',
    id: 'shoppinglist-introduction',
    __kind: 'IntroductionModal',
    pages: [
      {
        title: 'Shoppinglists introduction_1 title',
        image: <img src="/icons/shoppinglists.svg" alt="" />,
        text: 'Shoppinglists introduction_1 text',
      },
      {
        title: 'Shoppinglists introduction_2 title',
        image: <img src="/icons/shoppinglists-2.svg" alt="" />,
        text: 'Shoppinglists introduction_2 text',
      },
      {
        title: 'Shoppinglists introduction_3 title',
        image: <img src="/icons/shoppinglists-3.svg" alt="" />,
        text: 'Shoppinglists introduction_3 text',
      },
    ],
  })
}

export const notificationsVar = makeVar<{ notifications: Notification[]; dismissed: string[] }>({
  notifications,
  dismissed: getDismissedState(),
})
