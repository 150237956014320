import type { ButtonProps } from '@sok/design-system'

/**
 * Product Card Extra Actions:
 * - <DeleteBtn />
 * - <CommentBtn />
 * - <FavoriteBtn />
 */
import {
  Button,
  ButtonSize,
  ButtonType,
  CommentFilled16,
  CommentOutline16,
  DeleteOutline16,
  HeartOutline16,
} from '@sok/design-system'
import { HeartFilled16 } from 'components/icons'
import styled from 'styled-components'

export const DeleteBtn = styled(Button).attrs<ButtonProps>((props) => ({
  ...props,
  icon: <DeleteOutline16 />,
  size: ButtonSize.MIDDLE,
  type: ButtonType.TEXT_REGULAR,
  dataTestId: 'delete-button',
}))({})

interface CommentBtnProps extends ButtonProps {
  title: string
  isFilled?: boolean
}

export const CommentBtn = styled(Button).attrs<CommentBtnProps>(
  ({ isFilled, theme, title, 'data-test-id': dataTestId = 'comment-button' }) => ({
    title,
    'data-test-id': dataTestId,
    'aria-pressed': isFilled,
    icon: isFilled ? <CommentFilled16 color={theme.color.action} /> : <CommentOutline16 />,
    size: ButtonSize.MIDDLE,
    type: ButtonType.TEXT_REGULAR,
  }),
)<CommentBtnProps>({})

interface FavoriteBtnProps extends ButtonProps {
  title: string
  isFavorite?: boolean
  hasPopUp?: boolean
}

export const FavoriteBtn = styled(Button).attrs<FavoriteBtnProps>(
  ({ isFavorite, hasPopUp, theme, title, 'data-test-id': dataTestId = 'favorite-button' }) => ({
    title,
    'aria-pressed': isFavorite,
    'aria-haspopup': hasPopUp,
    'data-test-id': dataTestId,
    icon: isFavorite ? <HeartFilled16 color={theme.color.favoriteColor} /> : <HeartOutline16 />,
    size: ButtonSize.MIDDLE,
    type: ButtonType.TEXT_REGULAR,
  }),
)<FavoriteBtnProps>({})
