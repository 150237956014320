import type { IFooterFields, IPageLayoutFields, IServiceBannerFields } from './generated'
import type { Dispatch, SetStateAction } from 'react'

export interface GetContentfulContent {
  footer: IFooterFields | null
  frontPage: IPageLayoutFields | null
  serviceBanners: ServiceBannerEntry[] | null
}

export interface IContentfulContext extends GetContentfulContent {
  xrayMode: boolean | null
  setXrayMode: Dispatch<SetStateAction<boolean | null>> | null
}

export type ServiceBannerEntry = { id: string; banner: IServiceBannerFields }

export const enum BannerType {
  Error = 'error',
  Info = 'info',
  Marketing = 'marketing',
}
