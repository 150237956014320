import type { Validator } from './validation'

import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'

export const nonNullable =
  <T>(path: readonly string[]): Validator<T | undefined | null, T> =>
  (x: T | undefined | null) =>
    pipe(
      x,
      E.fromNullable([
        {
          tag: 'validationError',
          message: 'notNil',
          path,
        },
      ]),
    )
