import type { ServiceSubscriptions } from './hooks/account/use-get-service-subscriptions'
import type { RemoteData } from 'domain/remote-data'

import { ServiceSubscriptionServiceType } from '@shared/gql/document-nodes'

export const SERVICE_SUBSCRIPTIONS = [
  ServiceSubscriptionServiceType.ProductPurchaseDataCollectionBarring,
  ServiceSubscriptionServiceType.AnalyticsAndProfilingBarring,
]

export const getPurchaseDataAndProfilingDenied = (
  serviceSubscriptions: RemoteData<Error, ServiceSubscriptions>,
): boolean => {
  // Non-logged users will be set as 'true' (will be additionally checked from Usercentrics consent)
  if (serviceSubscriptions.type === 'NOT_ASKED') return false

  if (serviceSubscriptions.type === 'SUCCESS') {
    return serviceSubscriptions.data.purchaseDataAndProfilingDenied
  }

  return true
}
