import type { ApolloError, QueryResult } from '@apollo/client'
import type {
  RemoteFilteredProductsQuery,
  RemoteFilteredProductsQueryVariables,
  RemoteNavigationQuery,
} from '@shared/gql/document-nodes'
import type { SearchAnalytics } from 'domain/search'

import { searchNavigationItem } from 'utils/common/search-navigation-item'
import { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'

type Navigation = NonNullable<RemoteNavigationQuery['store']>['navigation']

type Data = QueryResult<RemoteFilteredProductsQuery, RemoteFilteredProductsQueryVariables>['data']
type Error = ApolloError
type Variables = RemoteFilteredProductsQueryVariables

const getCategorySlugAndId = (
  context: TrackingContext,
  variables: Variables,
  navigation?: Navigation,
) => {
  if (context === TrackingContext.SEARCH_RESULTS) {
    const trackingContext = `${TrackingContext.SEARCH_RESULTS}: ${variables.queryString}`
    return { categoryId: trackingContext, categorySlug: trackingContext }
  }

  if (context === TrackingContext.AUTOSUGGEST_SEARCHBOX) {
    const trackingContext = `${TrackingContext.AUTOSUGGEST_SEARCHBOX}: ${variables.queryString}`
    return { categoryId: trackingContext, categorySlug: trackingContext }
  }

  const { slug } = variables

  return {
    categorySlug:
      (navigation && slug && searchNavigationItem(navigation, slug, 'slug')?.slug) ||
      /** Report "tuotteet" on the root category page */
      (context === TrackingContext.PRODUCT_CATEGORY ? 'tuotteet' : undefined),
    categoryId:
      (navigation && slug && searchNavigationItem(navigation, slug, 'slug')?.id) || undefined,
  }
}

const formatFilters = (filters: Variables['filters']) => {
  if (!filters) return undefined
  if (!Array.isArray(filters)) return { [filters.key]: filters.value }
  return filters.reduce<Record<string, string[]>>(
    (result, { key, value }) =>
      Object.assign(result, { [key]: key in result ? result[key].concat(value) : value }),
    {},
  )
}

export const getSearchAnalyticsData = ({
  context,
  data,
  error,
  navigation,
  variables,
}: {
  context: TrackingContext
  data?: Data
  error?: Error
  navigation?: Navigation
  variables: Variables
}): SearchAnalytics => ({
  ...getCategorySlugAndId(context, variables, navigation),
  context,
  error: error?.message,
  filters: formatFilters(variables.filters),
  limit: variables.limit,
  queryString: variables.queryString || undefined,
  searchProvider: variables.searchProvider || undefined,
  sort:
    variables.order && variables.orderBy ? `${variables.orderBy}:${variables.order}` : undefined,
  storeId: variables.storeId,
  total: data?.store?.products?.total,
})
