import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { useQuery } from '@apollo/client'
import { getConfig } from '@shared/config'
import { GetProductRecommendationsDocument } from '@shared/gql/document-nodes'
import { minWidthFromTheme } from '@sok/design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useMembershipNumber } from 'utils/hooks/account/use-membership-number'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useSkippableProductInfoQuery } from 'utils/hooks/use-skippable-product-info-query'

import { LoginPromptCard } from './personalized-recommendations/LoginPromptCard'
import { MembershipCardPrompt } from './personalized-recommendations/MembershipCardPrompt'
import { View } from './personalized-recommendations/View'

const { featureFlags } = getConfig()

export enum RecommendationType {
  RECENT_PURCHASES = 'recentPurchases',
  PURCHASE_HISTORY = 'purchaseHistory',
  OTHER = 'other',
}

interface Props {
  className?: string
  section: ISection | null | undefined
  type: RecommendationType
}

const _PersonalizedRecommendations: FC<Props> = ({ className, section, type }) => {
  const { storeId } = useStoreId()
  const isLoggedIn = useIsLoggedIn()
  const isClientSide = useIsClientSide()

  const includeRecentPurchases = type === RecommendationType.RECENT_PURCHASES
  const includePurchaseHistory = type === RecommendationType.PURCHASE_HISTORY
  const includeOther = type === RecommendationType.OTHER

  const { selectedStoreId } = useStoreId()

  const membershipNumber = useMembershipNumber()
  /** Assume membership number exists while loading to not display prompt unnecessarily */
  const hasMembershipNumber = membershipNumber.loading ? true : !!membershipNumber.data

  const recommendationsResult = useQuery(GetProductRecommendationsDocument, {
    skip: !isClientSide || !hasMembershipNumber,
    variables: { storeId, includeRecentPurchases, includePurchaseHistory, includeOther },
  })

  const products = recommendationsResult.data?.[type]?.products?.items ?? []
  const foundNoRecommendations =
    !recommendationsResult.called ||
    recommendationsResult.error ||
    (!recommendationsResult.loading && !products.length)

  const fallbackProductGrid = (isClientSide && !isLoggedIn) || foundNoRecommendations

  const productGridQuery = useSkippableProductInfoQuery({
    eans: section?.fields?.productGrid?.fields.products,
    includeAvailabilities: featureFlags.productAvailabilities && !!selectedStoreId,
    selectedStoreId: storeId,
  })

  const { t } = useTranslation()
  const title = useMemo(() => {
    if (section?.fields?.title) return section.fields.title
    switch (type) {
      case RecommendationType.RECENT_PURCHASES:
        return t('Recommendations - you might need')
      case RecommendationType.PURCHASE_HISTORY:
        return t('Recommendations - buy again')
      case RecommendationType.OTHER:
        return t('Recommendations - recommendations')
    }
  }, [section?.fields?.title, t, type])

  if (fallbackProductGrid) {
    const fallbackProducts = productGridQuery.data?.store?.products?.items ?? []
    const error = productGridQuery.error
    const loading = productGridQuery.loading || !productGridQuery.called

    if (error || (!loading && !fallbackProducts.length)) {
      return null
    }

    if (!isLoggedIn) {
      return (
        <View
          loading={loading}
          prefixCard={<LoginPromptCard />}
          listName={`${title} - fallback, not logged in`}
          className={className}
          title={title}
          products={fallbackProducts}
        />
      )
    }

    if (!hasMembershipNumber) {
      return (
        <View
          loading={loading}
          prefixCard={<MembershipCardPrompt />}
          listName={`${title} - fallback, logged in, no membership number`}
          className={className}
          title={title}
          products={fallbackProducts}
        />
      )
    }

    return (
      <View
        loading={loading}
        listName={`${title} - fallback`}
        className={className}
        title={title}
        products={fallbackProducts}
      />
    )
  }

  if (foundNoRecommendations) {
    return null
  }

  /** Make sure hydration from SSR looks nice */
  const displayPlaceholders =
    !isClientSide || !recommendationsResult.called || recommendationsResult.loading

  return (
    <View
      loading={displayPlaceholders}
      listName={`${title} - personalized`}
      className={className}
      title={title}
      products={products}
    />
  )
}

export const PersonalizedRecommendations = styled(_PersonalizedRecommendations)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `${theme.spacings.xxLarge} 0`,
  position: 'relative',

  [minWidthFromTheme(theme).largeDesktop]: {
    padding: 0,
  },
}))
