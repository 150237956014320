import { Button } from '@s-group/design-system-components'
import styled from 'styled-components'

export const AddTo = styled(Button).attrs({
  sizing: 'small',
  alternative: true,
  rounding: 'small',
  compact: true,
})(({ theme }) => ({
  svg: {
    // icon normally has wrong stroke applied to it
    // add override icon color to match icon styles of product card buttons
    stroke: theme.colors.SDS_BRAND_BUTTON_COLOR_ICON_ALTERNATIVE,
  },
}))
