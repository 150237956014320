import type {
  Filters as RemoteFilter,
  ObjectFacet,
  StringFacet,
  StructuredFacetInput,
} from '@shared/gql/document-nodes'

import { FacetKey, SortOrder } from '@shared/gql/document-nodes'

import { QUERY_STRING_PARAM } from './query-string'

/**
 * Filter parameter names exposed in the URL; these might be different
 * to the GraphQL query variables (FacetKeys), because they should shorter and
 * understandable. See also `filterToFacetKey` and `facetKeyToFilter`.
 */
export enum Filter {
  Brand = 'brand',
  Category = 'category',
  Label = 'label',
  Supplier = 'supplier',
}

/**
 * Type guard for string that is a `Filter`
 */
export const isFilter = (key: string): key is Filter =>
  Object.values(Filter).includes(key as Filter)

const FilterFacetKeyMap: [Filter, FacetKey][] = [
  [Filter.Brand, FacetKey.BrandName],
  [Filter.Category, FacetKey.Category],
  [Filter.Label, FacetKey.Labels],
  [Filter.Supplier, FacetKey.SupplierName],
]

/**
 * For a `Filter`, get the corresponding `FacetKey` or throw if not found.
 */
export const filterToFacetKey = (filter: Filter): FacetKey => {
  const found = FilterFacetKeyMap.find((item) => item[0] === filter)
  if (!found) throw new Error(`Missing FacetKey for Filter "${filter}"`)
  return found[1]
}

/**
 * For a `FacetKey`, get the corresponding `Filter` or throw if not found.
 */
export const facetKeyToFilter = (facetKey: FacetKey): Filter => {
  const found = FilterFacetKeyMap.find((item) => item[1] === facetKey)
  if (!found) throw new Error(`Missing Filter for FacetKey "${facetKey}"`)
  return found[0]
}

/**
 * Type guard for string that is a `FacetKey`
 */
export const isFacetKey = (key: string): key is FacetKey =>
  Object.values(FacetKey).includes(key as FacetKey)

/** From `URLSearchParams` return copy with only those that are filters, including the query string */
export const getFilterParams = (params: URLSearchParams): URLSearchParams => {
  const filteredParams = new URLSearchParams(params)
  for (const key of params.keys()) {
    if (!isFilter(key) && key !== QUERY_STRING_PARAM) {
      filteredParams.delete(key)
    }
  }
  filteredParams.sort()
  return filteredParams
}

/**
 * Parse a valid URLSearchParams object into a record
 * of filter values. The object will contain
 * known facets as the key, and their value will be
 * `string[]`, if the query has specified any.
 * These will be used as search filters.
 */
export const parseURLSearchParamsToFilters = (params: URLSearchParams): RemoteFilter[] => {
  const entries: Map<string, string[]> = new Map()
  for (const key of params.keys()) {
    const values = params.getAll(key)
    entries.set(key, values)
  }

  return Array.from(entries).flatMap(([key, value]) =>
    value.length && isFilter(key)
      ? /** Only include valid filter keys with a value */
        [{ key: filterToFacetKey(key), value }]
      : /** Empty array acts as a "filter" */
        [],
  )
}

/**
 * All the currently supported facets that can be rendered
 * as filters when using the `useProductSearch` hook.
 */
export const SUPPORTED_FACETS: StructuredFacetInput[] = [
  { key: FacetKey.BrandName, order: SortOrder.Asc },
  { key: FacetKey.Category },
  { key: FacetKey.Labels },
]

export type Facet = StringFacet | ObjectFacet

export const isStringFacet = (x: Facet): x is StringFacet => 'stringValue' in x

export const isObjectFacet = (x: Facet): x is ObjectFacet => 'objectValue' in x
