import type { ProductAvailabilities } from '@shared/gql/document-nodes'

import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'

import { getProductAvailabilityForDate } from '../utils/get-product-availability-for-date'

/**
 * Get current product availability for selected delivery date, "Today" if not selected,
 * or `null` if data not available.
 */
export const useCurrentProductAvailability = (
  availabilities?: ProductAvailabilities[] | null,
): Pick<ProductAvailabilities, 'date' | 'label' | 'score'> | null | undefined => {
  const availabilityDate = useDeliveryDate()
  return getProductAvailabilityForDate(availabilities, availabilityDate)
}
