import { minWidthFromTheme } from '@sok/design-system'
import styled from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { Card } from './Card'

const ProductGrid = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
  gap: '0.5rem',
  margin: '0.25rem',

  [minWidthFromTheme(theme).tablet]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },

  [minWidthFromTheme(theme).desktop]: {
    margin: 0,
  },
}))

const ProductCardsSkeletonLoader = () => {
  const isClient = useIsClientSide()
  return isClient ? (
    <ProductGrid role="list" data-test-id="loading-category-products">
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
      <Card.Loading />
    </ProductGrid>
  ) : null
}

export const ProductCardsGrid = {
  Grid: ProductGrid,
  Loader: ProductCardsSkeletonLoader,
}
