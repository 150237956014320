import type { AvailableDeliverySlot } from './validation'
import type { DeliverySlot } from '@shared/gql/document-nodes'

import { AlcoholSellingStatus, SlotAvailability } from '@shared/gql/document-nodes'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { setValidationErrorMessage } from 'validation/set-validation-error-message'
import { nonNullable, setErrorType, validationErrorOf } from 'validation/validators'
import * as V from 'validation/validators'

type MaybeAvailableDeliverySlot = Pick<
  DeliverySlot,
  'isClosed' | 'availability' | 'deliveryMethod' | 'alcoholSellingAllowed'
>

export const slotAvailabilityV: V.Validator<MaybeAvailableDeliverySlot, AvailableDeliverySlot> = (
  slot,
) =>
  V.validate({
    isClosed: slot.isClosed
      ? E.left([
          pipe(
            validationErrorOf(
              'errorMessages_deliverySlotIsClosed',
              ['slotAvailability', 'isClosed'],
              'isClosed',
            ),
            setErrorType('deliverySlotIsClosed'),
          ),
        ])
      : E.right(false),
    // DISABLED AS SLOTS API CANNOT BE TRUSTED
    // this is different on DeliveryAreas/GetSlotById queries
    availability: E.right(SlotAvailability.Available),
    deliveryMethod: pipe(
      slot.deliveryMethod,
      nonNullable(['DeliveryMethod']),
      E.mapLeft(setValidationErrorMessage('errorMessages_deliveryMethod')),
    ),
    alcoholSellingAllowed: pipe(
      slot.alcoholSellingAllowed,
      nonNullable(['alcoholSellingAllowed']),
      E.map((x) => x === AlcoholSellingStatus.Allowed),
    ),
  })
