import {
  SDS_MOTION_DURATION_DEFAULT,
  SDS_MOTION_DURATION_FAST,
  SDS_MOTION_DURATION_FASTER,
  SDS_MOTION_DURATION_FASTEST,
  SDS_MOTION_DURATION_MODERATE,
  SDS_MOTION_DURATION_SLOW,
  SDS_MOTION_EASING_DEFAULT,
  SDS_MOTION_EASING_ELASTIC,
  SDS_MOTION_EASING_ENTRY,
  SDS_MOTION_EASING_EXIT,
  SDS_MOTION_EASING_EXPRESSIVE,
  SDS_MOTION_EASING_LINEAR,
} from '@s-group/design-system-tokens/web/tokens/motion.es6'

function easingStringToArray(string: string) {
  return string.split(',').map((value) => parseFloat(value))
}

// Design tokens are imported as strings
// framer-motion wants an array
// also shorten the naming for ease of use
export const Easing = {
  default: easingStringToArray(SDS_MOTION_EASING_DEFAULT),
  entry: easingStringToArray(SDS_MOTION_EASING_ENTRY),
  exit: easingStringToArray(SDS_MOTION_EASING_EXIT),
  expressive: easingStringToArray(SDS_MOTION_EASING_EXPRESSIVE),
  elastic: easingStringToArray(SDS_MOTION_EASING_ELASTIC),
  linear: easingStringToArray(SDS_MOTION_EASING_LINEAR),
}

function msToSeconds(ms: string) {
  return parseInt(ms) / 1000
}

// Tokens are as milliseconds
// They work (almost) as is for css animations
// Framer motions uses seconds so must convert
export const Duration = {
  default: msToSeconds(SDS_MOTION_DURATION_DEFAULT),
  slow: msToSeconds(SDS_MOTION_DURATION_SLOW),
  moderate: msToSeconds(SDS_MOTION_DURATION_MODERATE),
  fast: msToSeconds(SDS_MOTION_DURATION_FAST),
  faster: msToSeconds(SDS_MOTION_DURATION_FASTER),
  fastest: msToSeconds(SDS_MOTION_DURATION_FASTEST),
}

export enum Variant {
  initial = 'initial',
  animate = 'animate',
  hover = 'hover',
  exit = 'exit',
  reducedMotion = 'reducedMotion',
}

export const motionSlideRight = {
  [Variant.initial]: {
    x: '-100%',
    transition: {
      ease: Easing.exit,
      duration: Duration.faster,
    },
  },
  [Variant.animate]: {
    x: 0,
    transition: {
      ease: Easing.default,
      duration: Duration.default,
    },
  },
  [Variant.reducedMotion]: {
    x: 0,
    transition: {
      x: {
        duration: 0,
      },
    },
  },
}

export const motionSlideDown = {
  [Variant.initial]: {
    y: '-100%',
    transition: {
      ease: Easing.exit,
      duration: Duration.faster,
    },
  },
  [Variant.animate]: {
    y: 0,

    transition: {
      ease: Easing.default,
      duration: Duration.default,
    },
  },
  [Variant.reducedMotion]: {
    y: 0,

    transition: {
      y: {
        duration: 0,
      },
    },
  },
}

export const motionFadeIn = {
  [Variant.initial]: {
    opacity: 0,

    transition: {
      duration: Duration.default,
    },
  },
  [Variant.animate]: {
    opacity: 1,

    transition: {
      ease: Easing.default,
      duration: Duration.fastest,
    },
  },
}
