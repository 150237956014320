import type { HierarchyPathItem, Maybe, Product } from '@shared/gql/document-nodes'
import type { RefObject } from 'react'
import type { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'

import { useEffect } from 'react'
import { isNonNullable } from 'utils/nullable'
import { pushData } from 'utils/tracking/custom-events/custom-events'
import { mapGa4Availabilities } from 'utils/tracking/event-product-mapper'
import { GA4_trackImpressions } from 'utils/tracking/track-product-impressions'

import { useDeliveryDate } from '../../hooks/delivery/use-delivery-date'
import { useIsIntersecting } from '../../hooks/use-is-intersecting'

const INTERSECTION_OBSERVER_OPTS = { once: true }

export type Item = Maybe<
  Pick<Product, 'id' | 'name' | 'price'> & {
    availabilities?: Product['availabilities']
    hierarchyPath: Maybe<Pick<HierarchyPathItem, 'slug'>[]>
  }
>

type ProductsImpressionTrackingOptions = {
  listName: string
  trackingContext: TrackingContext
  items?: Item[]
  ref?: RefObject<HTMLElement>
  shouldTrackCategory?: boolean
}

export const useProductsImpressionTracking = (options: ProductsImpressionTrackingOptions): void => {
  const { listName, items, trackingContext, ref, shouldTrackCategory } = options

  const isIntersecting = useIsIntersecting(ref, INTERSECTION_OBSERVER_OPTS)
  const availabilityDate = useDeliveryDate()

  useEffect(() => {
    if ((ref && !isIntersecting) || !items?.length) return
    if (shouldTrackCategory) {
      pushData({
        viewedCategory: {
          id: listName,
          slug: listName,
        },
      })
    }
    GA4_trackImpressions({
      products: items.filter(isNonNullable).map(mapGa4Availabilities(availabilityDate)),
      listName,
      trackingContext,
    })
  }, [availabilityDate, isIntersecting, items, listName, ref, shouldTrackCategory, trackingContext])
}
