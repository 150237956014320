import type { FC } from 'react'
import type { IHeroCardsCarousel } from 'types/contentful/generated'

import { useTranslation } from 'react-i18next'
import { useGetCitrusAdBannerX } from 'services/CitrusAd/hooks/use-get-citrusad-bannerx'
import styled from 'styled-components'
import { CitrusAdPlacement, CitrusAdSlot } from 'types/citrusad'

import { withContentfulXray } from '../../../../hocs/with-contentful-xray'
import { CitrusAdBannerX } from '../citrusad/CitrusAdBanner'
import { ContentCarousel } from './content-carousel/ContentCarousel'
import { SlideContainer } from './content-carousel/SlideContainer'
import { HeroCarouselSlide } from './hero-carousel/HeroCarouselSlide'

interface HeroCarouselProps {
  section: IHeroCardsCarousel
}

const _HeroCarousel: FC<HeroCarouselProps> = ({ section }) => {
  const { t } = useTranslation()
  const { cards, hasCitrusAdSlot, displayFeatured, carouselAccessibilityLabel } = section.fields
  const adOptions = { placement: CitrusAdPlacement.BANNERX_HOME, hasAdSlot: hasCitrusAdSlot }
  const slide2AdData = useGetCitrusAdBannerX({
    ...adOptions,
    slotId: CitrusAdSlot.HOME_HERO_CAROUSEL_2,
  })
  const slide4AdData = useGetCitrusAdBannerX({
    ...adOptions,
    slotId: CitrusAdSlot.HOME_HERO_CAROUSEL_4,
  })

  if (cards && cards.length > 0) {
    return (
      <ContentCarousel
        id={section.sys.id}
        infiniteLoop
        featured={displayFeatured}
        carouselAriaLabel={carouselAccessibilityLabel ?? t('Hero carousel')}
      >
        {cards.map((card, i) => {
          if (
            i === 1 &&
            hasCitrusAdSlot &&
            (slide2AdData.loading || slide2AdData.skipLoadAd || slide2AdData.banner)
          ) {
            return (
              <SlideContainer
                key={`${section.sys.id}-slide-${i}`}
                label={slide2AdData.banner?.ctaText ?? t('Loading...')}
              >
                {slide2AdData.loading || slide2AdData.skipLoadAd ? (
                  <Placeholder
                    key="placeholder"
                    data-test-id={`herocarousel-ca-placeholder-${i}`}
                  />
                ) : null}
                {slide2AdData.banner ? <CitrusAdBannerX citrusAdBannerData={slide2AdData} /> : null}
              </SlideContainer>
            )
          }
          if (
            i === 3 &&
            hasCitrusAdSlot &&
            (slide4AdData.loading || slide4AdData.skipLoadAd || slide4AdData.banner)
          ) {
            return (
              <SlideContainer
                key={`${section.sys.id}-slide-${i}`}
                label={slide4AdData.banner?.ctaText ?? t('Loading...')}
              >
                {slide4AdData.loading || slide4AdData.skipLoadAd ? (
                  <Placeholder
                    key="placeholder"
                    data-test-id={`herocarousel-ca-placeholder-${i}`}
                  />
                ) : null}
                {slide4AdData.banner ? <CitrusAdBannerX citrusAdBannerData={slide4AdData} /> : null}
              </SlideContainer>
            )
          }
          return (
            <SlideContainer
              key={`${section.sys.id}-slide-${i}`}
              featured={i === 0 && displayFeatured}
              label={card.fields.functionality?.fields.label ?? t('Slide {{i}}', { i })}
            >
              <HeroCarouselSlide.Card
                tracking={{
                  trackingPosition: `FRONTPAGE_HERO_CAROUSEL_${i + 1}`,
                  trackingName: card.fields.entryName,
                  trackingCreative: card.fields.image?.fields?.title,
                  trackingLabel: card.fields.label,
                }}
                cta={{
                  url: card.fields.functionality?.fields?.url,
                  label: card.fields.functionality?.fields?.label,
                  openLinkInNewTab: card.fields.functionality?.fields?.openLinkInNewTab,
                }}
                withBackground
              >
                <HeroCarouselSlide.Image image={card.fields.image} />
                <HeroCarouselSlide.Label label={card.fields.label} />
                <HeroCarouselSlide.CTALabel />
              </HeroCarouselSlide.Card>
            </SlideContainer>
          )
        })}
      </ContentCarousel>
    )
  }
  return null
}

const Placeholder = styled.div(({ theme }) => ({
  borderRadius: '4px',
  height: '100%',
  backgroundColor: theme.colors.grey300,
}))

export const HeroCarousel = withContentfulXray(_HeroCarousel)
