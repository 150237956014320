import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'

export const StyledHorizontalContentGrid = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacings.xxLarge};
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: ${({ theme }) => theme.spacings.xxLarge};
  }

  margin: 0 ${({ theme }) => theme.spacings.medium};
`

interface StyledContentGridItemProps {
  overlay?: boolean
  imagePosition?: boolean
}

export const StyledContentGridItem = styled.article<StyledContentGridItemProps>`
  display: flex;
  width: 100%;
  max-width: 460px;
  height: auto;
  background-color: ${({ theme }) => theme.color.white};
  padding-bottom: ${({ theme }) => theme.spacings.medium};

  ${({ overlay }) =>
    overlay &&
    css`
      position: relative;
      background-color: ${({ theme }) => theme.colors.black};
      padding-bottom: 0;
    `};

  ${({ imagePosition }) =>
    imagePosition &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `};

  .content-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 ${({ theme }) => theme.spacings.large};
  }

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    &:only-child {
      margin: 0 auto;
    }
  }
`

export const StyledItemTitle = styled.h3`
  ${({ theme }) => css(theme.variants.paragraph2.regular)};
  margin: ${({ theme }) => `${theme.spacings.medium} 0 ${theme.spacings.small}`};
  text-align: center;
`

export const StyledItemBody = styled.p`
  ${({ theme }) => css(theme.variants.body2.regular)};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  padding: 0 ${({ theme }) => theme.spacings.medium};
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    margin-bottom: ${({ theme }) => theme.spacings.large};
  }
`

export const StyledTextContent = styled.div`
  text-align: left;
`
