import type { ParsedUrlQuery } from 'querystring'

export const QUERY_STRING_PARAM = 'queryString'

/**
 * The Next.js Router query params are either strings,
 * or arrays of strings with multiple values.
 */
export const normalizeQueryString = (queryString: ParsedUrlQuery[string]): string =>
  (Array.isArray(queryString) ? queryString[0] : queryString) ?? ''
