import type { FC } from 'react'
import type { ISectionFields } from 'types/contentful/generated'

import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { Card } from './image-and-text/Card'
import { StyledSectionWrapper } from './image-and-text/styled'

export interface ImageAndTextProps {
  fields: ISectionFields
}

export const ImageAndText: FC<ImageAndTextProps> = ({ fields }) => {
  const cardFields = fields?.cards?.[0]?.fields ?? {}
  const { functionality, body } = cardFields
  const cta = functionality?.[0]?.fields
  const parsedBody = useParsedMarkdown(body)

  return (
    <StyledSectionWrapper
      key={fields.tagTitle}
      id={fields.tagTitle}
      data-test-id="image-and-text-section"
    >
      <Card
        data-test-id="image-and-text-card"
        id={fields.tagTitle}
        fields={{ ...cardFields, cta, parsedBody }}
      />
    </StyledSectionWrapper>
  )
}
