import type { LinkButtonProps } from '@s-group/design-system-components'
import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { Icon } from '@s-group/design-system-components'
import { IconCommerceShoppingcart, IconStatusErrorFilled } from '@s-group/design-system-icons'
import { ProductType } from '@shared/gql/document-nodes'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { getMissingItems } from 'utils/cart/filter-missing-items'
import { useGetClientCartItemsByType } from 'utils/cart/use-get-client-cart-items-by-type'
import { useGetCartTotal } from 'utils/hooks/cart'
import { trackCartClick } from 'utils/tracking/navigation'

import { IconWithLabel } from './components/IconWithLabel'
import { NavigationLinkButton } from './NavigationActions'

const CartStatus: FC<{ cartItems: ClientCartItem[] }> = ({ cartItems }) => {
  const theme = useTheme()

  const hasMissingItems = getMissingItems(cartItems).length > 0
  const hasItems = cartItems.length > 0

  return (
    <IconWithLabel
      icon={<IconCommerceShoppingcart />}
      label={
        (hasMissingItems && (
          <IconStatusErrorFilled color={theme.colors.SDS_BRAND_COLOR_ELEMENT_ERROR} width={16} />
        )) ||
        (hasItems && <IconWithLabel.Label>{cartItems.length}</IconWithLabel.Label>)
      }
    />
  )
}

const _NavigationShoppingCart: FC<LinkButtonProps> = (props) => {
  const totalCartPrice = useGetCartTotal()
  const cartItems = useGetClientCartItemsByType(ProductType.Product)
  const { t } = useTranslation()

  // show count of different items in cart
  const itemsCount = cartItems.length
  const formattedTotalPrice = totalCartPrice.format()

  return (
    <Link href="/yhteenveto" passHref>
      <NavigationLinkButton
        data-test-id="global-nav-cart"
        aria-label={t('Items in cart. Total Price', {
          itemsCount,
          totalPrice: formattedTotalPrice,
        })}
        icon={<Icon svg={<CartStatus cartItems={cartItems} />} />}
        onClick={trackCartClick}
        {...props}
      >
        {itemsCount > 0 ? (
          <span data-test-id="global-nav-cart-total-price">{formattedTotalPrice}</span>
        ) : null}
      </NavigationLinkButton>
    </Link>
  )
}

export const NavigationShoppingCart = styled(_NavigationShoppingCart)({})
