import { UsercentricsDialogToggle } from '@s-group/react-usercentrics'
import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'

export const StyledUsercentricsDialogToggle = styled(UsercentricsDialogToggle)(({ theme }) => ({
  ...theme.variants.body1.regular,
  background: 'none',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  padding: 0,
  textAlign: 'left',

  '&:disabled': {
    color: 'inherit !important' /** override hover/active colors */,
    cursor: 'not-allowed',
    opacity: 0.5,
    textDecoration: 'line-through',
  },
}))

export const StyledLinksRow = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.small};
`

export const StyledRowTitle = styled.div`
  ${({ theme }) => css(theme.variants.paragraph2.regular)};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    ${({ theme }) => css(theme.variants.paragraph1.regular)};
  }
`

export const StyledRowContent = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings.small};
  align-items: center;

  a {
    cursor: pointer;
    text-decoration: none;
  }
  a,
  p {
    ${({ theme }) => css(theme.variants.body1.regular)};
    color: ${({ theme }) => theme.color.darkGrey800};
  }

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    max-width: 300px;
  }
`

export const StyledLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    max-width: 1400px;
    margin: 0 auto;
  }
`

export const StyledCopyright = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacings.xLarge} 0;
  padding-bottom: ${({ theme }) => theme.spacings.xxxLarge};
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    padding-bottom: 0;
  }
`

export const StyledDownloadMobileApp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.spacings.large} 0;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacings.medium};
`

export const StyledCopyrightBody = styled.span`
  text-align: center;
  ${({ theme }) => css(theme.variants.body1.regular)};
`

export const StyledIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacings.small};
  height: ${({ theme }) => theme.spacings.small};
`

export const StyledFooterImage = styled.img`
  height: ${({ theme: { brandName, spacings } }) =>
    brandName === 'herkku' ? spacings.xxLarge : spacings.large};
  margin: ${({ theme }) => theme.spacings.small} 0;
`
