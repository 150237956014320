import type { MaybeProductListItem as MaybeProductListItemType } from 'domain/product-list'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { minWidthFromTheme } from '@sok/design-system'
import { FavoriteButtonContainer } from 'favorites/components/FavoriteButtonContainer'
import Link from 'next/link'
import { useCallback } from 'react'
import { useCurrentProductAvailability } from 'services/Product/hooks/use-current-product-availibility'
import { useProductUserEvent } from 'services/ProductUserEvent/product-user-event'
import styled from 'styled-components'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { getProductLink } from 'utils/products/product-link'
import {
  TrackingContextProvider,
  useTrackingContext,
} from 'utils/tracking/components/TrackingContextProvider'
import { pushData } from 'utils/tracking/custom-events/custom-events'
import { GA4_trackProductClick } from 'utils/tracking/track-product-click'
import { useGetInitialDeliverySelections } from 'views/delivery-modal/method-and-store/use-get-initial-delivery-selections'

import { TrackingContext } from '../utils/tracking/interfaces/data-layer-events'
import { ProductCard } from './Product'
import { ProductAvailabilityInfo } from './ProductAvailabilityInfo'
import { StyledProductImageContainer } from './ProductImage'
import { ProductLabels } from './ProductLabels'
import { AddToCart } from './ShoppingCart/AddToCart'

export const StyledImgContainer = styled(StyledProductImageContainer)`
  height: 180px;
  width: 200px;
  padding: ${({ theme }) => theme.spacings.small};

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    height: 212px;
    width: 212px;
  }
`

interface ProductListItemProps {
  disabled?: boolean
  product: MaybeProductListItemType
  className?: string
  'data-test-id'?: string
}

const { domain } = getConfig()

const _ProductListItem: FC<ProductListItemProps> = ({
  disabled = false,
  product,
  className,
  'data-test-id': dataTestId,
}) => {
  const trackingMetadata = useTrackingContext()

  const { sendProductUserClickEvent } = useProductUserEvent()
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  const { initialDeliveryMethod } = useGetInitialDeliverySelections()
  const isHomeDelivery = initialDeliveryMethod === DeliveryMethod.HomeDelivery

  const allowSelling = product?.isAgeLimitedByAlcohol
    ? !isHomeDelivery && !!alcoholSellingAllowed
    : true

  const currentAvailability = useCurrentProductAvailability(product?.availabilities)

  const handleProductClickEvent = useCallback(() => {
    // This is used in the conditional render below, so product will be avaibale
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id, name, price, hierarchyPath } = product!
    if (product && name && price && hierarchyPath && hierarchyPath.length > 0) {
      const productListName = trackingMetadata.listName || ''

      pushData({
        productListContext: trackingMetadata.trackingContext,
        productListName,
        viewedCategory: {
          id: productListName,
          slug: productListName,
        },
      })

      GA4_trackProductClick({
        ...trackingMetadata,
        availabilityLabel: currentAvailability?.label,
        products: [product],
      })
    }
    sendProductUserClickEvent(id)
  }, [currentAvailability?.label, product, sendProductUserClickEvent, trackingMetadata])

  if (!product) {
    return null
  }

  const { isSIdEnabled } = domain

  return (
    <div className={className} data-test-id={dataTestId} role="listitem">
      <ProductCard.Card.Success
        handleClick={handleProductClickEvent}
        data={product}
        status={
          product.availabilities && (
            <ProductAvailabilityInfo availabilities={product.availabilities} />
          )
        }
        disabled={disabled}
      >
        <ProductLabels
          data-test-id="product-card__productLabels"
          product={{
            isAgeLimitedByAlcohol: product.isAgeLimitedByAlcohol,
            packagingLabelCodes: product.packagingLabelCodes,
            frozen: product.frozen,
          }}
          size={24}
          truncate
        />
        {isSIdEnabled && <FavoriteButtonContainer ean={product.ean || ''} />}
        <TrackingContextProvider
          availabilityLabel={currentAvailability?.label}
          trackingContext={trackingMetadata?.trackingContext || TrackingContext.PRODUCTLIST_ITEM}
        >
          {allowSelling && <AddToCart disabled={disabled} product={product} />}
        </TrackingContextProvider>
      </ProductCard.Card.Success>
    </div>
  )
}

const _ProductListItemFailure: FC<{
  className?: string
  ['data-test-id']?: string
  ean: string
  name: string
}> = ({ ean, className, 'data-test-id': dataTestId, name }) => {
  return (
    <div className={className} data-test-id={dataTestId} aria-disabled>
      <ProductCard.Card.Error name={name}>
        <FavoriteButtonContainer ean={ean} />
      </ProductCard.Card.Error>
    </div>
  )
}

const productListItemStyle: ThemedObjectStyleFunction = () => ({
  '&:hover': {
    [ProductCard.Card.Success]: {
      [StyledImgContainer]: {
        transform: 'scale(1.15)',
        transition: 'transform .2s',
      },
    },
  },
})

export const ProductListItem = styled(_ProductListItem)(productListItemStyle)
export const ProductListItemFailure = styled(_ProductListItemFailure)(productListItemStyle)

ProductListItem.displayName = 'ProductListItem'

interface ProductListItemLoadingProps {
  ean: string
  className?: string
  'data-test-id'?: string
}

const _ProductListItemLoading: FC<ProductListItemLoadingProps> = ({
  'data-test-id': dataTestId,
  ean,
  className,
}) => (
  <Link href={getProductLink({ ean })} passHref>
    <div className={className} data-test-id={dataTestId}>
      <ProductCard.Card.Loading />
    </div>
  </Link>
)

export const ProductListCarouselItemLoading = styled(_ProductListItemLoading)(productListItemStyle)
