import { OrderStatus, ProductType as PT } from '@shared/gql/document-nodes'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'

import { Customer } from './customer'
import { getDiffInDays } from './date-time'
import { PaymentMethodType, PaymentStatusType } from './payment'

export const ProductType = t.union([
  t.literal(PT.Product),
  t.literal(PT.ServiceFee),
  t.literal(PT.PackagingProduct),
  t.literal(PT.Deposit),
  t.literal(PT.DeliveryProduct),
  t.literal(PT.Coupon),
])

const CartItem = t.type({
  id: t.string,
  ean: t.string,
  name: t.string,
  itemCount: t.number,
  replace: t.boolean,
  inStoreSelection: t.boolean,
  additionalInfo: t.union([t.string, t.null]),
  productType: ProductType,
  price: t.number,
  basicQuantityUnit: t.union([t.string, t.null]),
  priceUnit: t.union([t.string, t.null]),
  isAgeLimitedByAlcohol: t.union([t.boolean, t.null]),
})

const OrderStatusType = t.union([
  t.literal(OrderStatus.Cancelled),
  t.literal(OrderStatus.Done),
  t.literal(OrderStatus.InProgress),
  t.literal(OrderStatus.Modified),
  t.literal(OrderStatus.New),
  t.literal(OrderStatus.Open),
])

const SendableOrder = t.type({
  __typename: t.literal('DomainOrder'),
  storeId: t.string,
  customer: Customer,
  cartItems: t.array(CartItem),
  reservationId: t.union([t.string, t.null]),
  deliverySlotId: t.string,
  deliveryAreaId: t.string,
  additionalInfo: t.union([t.string, t.null, t.undefined]),
  comment: t.union([t.string, t.null, t.undefined]),
  discountCode: t.union([t.string, t.null, t.undefined]),
  deliveryAddress: t.type({
    __typename: t.literal('DomainDelivery'),
    address: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    postalCode: t.union([t.string, t.null]),
  }),
  payment: t.type({
    __typename: t.literal('DomainPayment'),
    paymentMethod: PaymentMethodType,
    paymentStatus: t.union([PaymentStatusType, t.null]),
    invoiceNumber: t.union([t.null, t.string]),
  }),
})

export const ValidDraftDomainOrder = t.intersection([
  SendableOrder,
  t.type({
    id: t.union([t.string, t.null, t.undefined]),
    orderStatus: t.union([OrderStatusType, t.null, t.undefined]),
  }),
])
export type ValidDraftDomainOrder = t.TypeOf<typeof ValidDraftDomainOrder>

export const SentDomainOrder = t.intersection([
  SendableOrder,
  t.type({
    id: t.string,
    orderStatus: OrderStatusType,
  }),
])

export type SentDomainOrder = t.TypeOf<typeof SentDomainOrder>

export const isSentOrder = (x: {
  id: string | null | undefined
  orderStatus: OrderStatus | null | undefined
}): boolean =>
  !!x.id &&
  !!x.orderStatus &&
  [
    OrderStatus.Cancelled,
    OrderStatus.Done,
    OrderStatus.InProgress,
    OrderStatus.Modified,
    OrderStatus.Open,
    OrderStatus.New,
  ].includes(x.orderStatus)

export const maybeValidSentOrder = <T>(x: T): E.Either<T, SentDomainOrder> => {
  const result = SentDomainOrder.decode(x)
  return E.isLeft(result) ? E.left(x) : E.right(result.right)
}

export enum OrderError {
  API_NOT_AVAILABLE = 'API_NOT_AVAILABLE',
  AUTH_RESPONSE_INCORRECT_ORDER_ID = 'AUTH_RESPONSE_INCORRECT_ORDER_ID',
  CANCELLED = 'CANCELLED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  NO_ORDER = 'NO_ORDER',
  NO_RESPONSE_CODE_OR_TRANSACTION_ID = 'NO_RESPONSE_CODE_OR_TRANSACTION_ID',
  TERMINAL_NOT_OK = 'TERMINAL_NOT_OK',
  NO_ACCESS = 'NO_ACCESS',
}

const isCancelledOrder = <
  T extends {
    orderStatus: OrderStatus
  },
>(
  order: T,
): order is T & { orderStatus: OrderStatus.Cancelled } =>
  order.orderStatus === OrderStatus.Cancelled

const isDeliveryDateInPast = ({ deliveryDate }: { deliveryDate: string }): boolean =>
  getDiffInDays(deliveryDate) < 0

type PartialOrder = { orderStatus: OrderStatus; deliveryDate: string }

export const isPastOrder = (order: PartialOrder) =>
  isCancelledOrder(order) || isDeliveryDateInPast(order)

export const isOpenOrder = <T extends { orderStatus: OrderStatus }>(order: {
  orderStatus: OrderStatus
}): order is T & { orderStatus: OrderStatus.Open } => order.orderStatus === OrderStatus.Open

export const isModifiableOrder = <T extends { isModifiable: boolean }>(order: {
  isModifiable: boolean
}): order is T & { isModifiable: true } => order.isModifiable === true
