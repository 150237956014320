import type { ProductData } from './ProductCard'
import type { ChangeEvent, FC, MouseEvent } from 'react'

import { Button } from '@s-group/design-system-components'
import { SDS_SPACE_MEDIUM } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { TextAreaInput } from '@sok/design-system'
import { Modal } from 'components/Modal/Modal'
import { ProductLabels } from 'components/ProductLabels'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from 'services/Modal'
import styled from 'styled-components'

import { DeleteBtn } from './ExtraActions'
import { Row as ProductCardRow } from './Row'

interface ProductCommentModalProps {
  className?: string
  data: ProductData
  value: string
}

const _ProductCommentModal: FC<ProductCommentModalProps> = ({ className, value, data }) => {
  const modal = useModal<string>()

  const { t } = useTranslation()
  const [comment, setComment] = useState(value)

  const handleOnSave = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    modal.onSubmit(comment)
  }
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }
  const handleDelete = () => {
    setComment('')
    modal.onSubmit('')
  }

  return (
    <Modal.CommentModal
      dataTestId="product-comment_modal"
      onClose={modal.onCancel}
      footer={
        <StyledButtonWrapper>
          <Button onClick={handleOnSave} data-test-id="save-btn" primary>
            {t('Add comment')}
          </Button>
        </StyledButtonWrapper>
      }
    >
      <div className={className}>
        <ProductCardRow.Success
          data={data}
          data-test-id="product-card-modal__productCard"
          readOnlyName
          readOnlyImage
        >
          <ProductLabels
            product={{
              isAgeLimitedByAlcohol: data.isAgeLimitedByAlcohol,
              packagingLabelCodes: data.packagingLabelCodes,
              frozen: data.frozen,
            }}
            size={16}
          />
        </ProductCardRow.Success>
        <TextAreaContainer>
          <StyledTextAreaInput
            showCount
            placeholder={t('Add comment placeholder')}
            rows={4}
            maxLength={250}
            onChange={handleOnChange}
            value={comment}
          />
          <DeleteBtn
            data-test-id="product-card-modal__delete"
            text={t('Delete the comment')}
            onClick={handleDelete}
          />
        </TextAreaContainer>
      </div>
    </Modal.CommentModal>
  )
}

export const ProductCommentModal = styled(_ProductCommentModal)(
  {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
  ({ theme }) => ({
    marginTop: SDS_SPACE_MEDIUM,
    gap: theme.spacings.medium,

    [ProductCardRow.Success]: {
      padding: 0,
      width: '100%',
      borderBottom: 0,
    },
  }),
)

const TextAreaContainer = styled.div(
  {
    width: '100%',

    [DeleteBtn]: {
      float: 'right',
      padding: 0,
    },
  },
  ({ theme }) => ({
    [DeleteBtn]: {
      ...theme.variants.body1.regular,
      color: theme.colors.grey800,
      margin: `${theme.spacings.xSmall} 0 ${theme.spacings.xxxSmall} 0`,
    },
  }),
)

const StyledTextAreaInput = styled(TextAreaInput)(
  {
    '&.ant-input-textarea-show-count::after': {
      float: 'left',
    },
  },
  ({ theme }) => ({
    '> .ant-input': {
      ...theme.variants.body2.regular,
    },

    '&.ant-input-textarea-show-count::after': {
      ...theme.variants.body1.regular,
      marginTop: theme.spacings.medium,
      color: theme.colors.grey400,
    },
  }),
)

const StyledButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const ProductComment = styled.p(({ theme }) => ({
  ...theme.variants.body1.regular,
  borderRadius: '6px',
  backgroundColor: theme.colors.grey100,
  padding: theme.spacings.xxSmall,
  width: '100%',
  color: theme.colors.grey800,
  quotes: `${'"“"'} ${'"”"'}`,
  wordBreak: 'break-word',
}))
