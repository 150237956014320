import { Link } from '@s-group/design-system-components'
import { useTranslation } from 'react-i18next'

import { StyledReadMoreSection } from './styled-login-content'

export const LoginReadMoreLink = () => {
  const { t } = useTranslation()

  return (
    <StyledReadMoreSection>
      <Link href="/sivu/s-kayttajatili" standalone>
        {t('Read more')}
      </Link>
    </StyledReadMoreSection>
  )
}
