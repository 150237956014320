import type { FC } from 'react'
import type { IInputElementFields } from 'types/contentful/generated'

import { Button, ButtonSize } from '@sok/design-system'
import { ArrowLink } from 'components/common/Buttons/Buttons'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import { useCallback } from 'react'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

interface CommonContentProps {
  cta: IInputElementFields
  position: string
  title?: string
}

export const CommonContent: FC<CommonContentProps> = ({ cta, position, title }) => {
  const promotionPosition =
    PromotionPosition[`HORIZONTAL_GRID${position}` as keyof typeof PromotionPosition]
  const track = useCallback(() => {
    internalPromotion(promotionPosition, `${title}_${promotionPosition}`, 'select_promotion', title)
  }, [promotionPosition, title])

  return cta.fieldType === 'primaryButton' ? (
    <Link href={getValidatedUrl(cta.url)} passHref>
      <Button
        onClick={track}
        size={ButtonSize.MIDDLE}
        text={cta.label}
        target={cta.openLinkInNewTab ? '_blank' : undefined}
      />
    </Link>
  ) : (
    <ArrowLink
      onClick={track}
      href={getValidatedUrl(cta.url)}
      title={cta.label || ''}
      openLinkInNewTab={cta.openLinkInNewTab}
      block
    />
  )
}
