import type { Price } from 'domain/price'
import type { FC } from 'react'

import { InfoOutline16, Popover, PopoverTrigger } from '@sok/design-system'
import { toFormattedDate } from 'domain/date-time'
import { formattedPrice } from 'domain/price'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

interface Lowest30DayPriceProps {
  price: Price
  className?: string
  'data-test-id'?: string
  validUntil?: string
}

const _Lowest30DayPrice: FC<Lowest30DayPriceProps> = ({
  className,
  'data-test-id': dataTestId = '',
  price,
  validUntil,
}) => {
  const isClientSide = useIsClientSide()
  const theme = useTheme()
  const { t } = useTranslation()

  const validUntilText = validUntil
    ? t('Price valid until {{date}}', {
        date: toFormattedDate(validUntil, { weekday: false }),
      })
    : null

  return (
    <div className={className} data-test-id={dataTestId}>
      {formattedPrice(price)}
      {isClientSide && (
        <PopoverContainer>
          <Popover
            content={
              <TextContainer data-test-id={`${dataTestId}--popover`}>
                {t('Lowest price for the 30 days before the campaign')}.
                {validUntilText && (
                  <>
                    {' '}
                    <time dateTime={validUntil}>{validUntilText}</time>
                  </>
                )}
              </TextContainer>
            }
            placement="top"
            trigger={PopoverTrigger.HOVER}
          >
            <StyledIconContainer data-test-id={`${dataTestId}--info`}>
              <InfoOutline16 color={theme.colors.grey800} />
            </StyledIconContainer>
          </Popover>
        </PopoverContainer>
      )}
    </div>
  )
}

_Lowest30DayPrice.displayName = 'Lowest30DayPrice'

export const Lowest30DayPrice = styled(_Lowest30DayPrice)(
  ({ theme }) => theme.variants.body2.medium,
  ({ theme }) => ({
    color: theme.colors.grey800,
    textDecoration: 'line-through',
    fontWeight: theme.fontWeights.regular,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacings.xxSmall,
    [StyledIconContainer]: {
      marginLeft: theme.spacings.xxSmall,
    },
  }),
)

export const PopoverContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const TextContainer = styled.div({
  time: {
    display: 'inline',
  },
})

const StyledIconContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})
