import type { FC, MouseEvent } from 'react'

import { ArrowRightOutline16, Button, ButtonIconPosition, ButtonType } from '@sok/design-system'
import Link from 'next/link'
import styled, { css } from 'styled-components'

const StyledPageLink = styled.a`
  ${({ theme }) => css(theme.variants.body1.medium)};
  color: ${({ theme }) => theme.color.action};
  :hover {
    color: ${({ theme }) => theme.color.actionVariant};
  }
`

interface ArrowLinkProps {
  href: string
  padding?: string
  margin?: string
  title: string
  onClick?: (e: MouseEvent) => void
  openLinkInNewTab?: boolean
  block?: boolean
}

interface StyledArrowProps {
  padding?: string
  margin?: string
}

const StyledArrowLink = styled(Button)<StyledArrowProps>`
  &.sok-btn {
    ${({ theme }) => css(theme.variants.body1.medium)};
    padding: ${({ padding }) => padding};
    margin: ${({ margin }) => margin};
  }
`

export const ArrowLink: FC<ArrowLinkProps> = ({
  href,
  onClick,
  openLinkInNewTab,
  title,
  padding,
  margin,
  block,
}) => (
  <Link href={href} passHref>
    <StyledArrowLink
      type={ButtonType.LINK}
      onClick={onClick}
      target={openLinkInNewTab ? '_blank' : undefined}
      padding={padding}
      margin={margin}
      text={title}
      iconPosition={ButtonIconPosition.RIGHT}
      icon={<ArrowRightOutline16 />}
      block={block}
    />
  </Link>
)

interface GeneralLinkProps {
  url: string
  title: string
  external: boolean
  className?: string
  dataTestId?: string
}

export const GeneralLink: FC<GeneralLinkProps> = ({
  url,
  title,
  external,
  className,
  dataTestId,
}) => (
  <StyledPageLink
    href={url}
    target={external ? '_blank' : '_self'}
    data-test-id={dataTestId}
    className={className}
  >
    {title}
  </StyledPageLink>
)
