interface Options {
  height?: number
  padded?: boolean
  src: string
  width: number
  quality?: number
}

export const sCloudProductImageLoader = ({
  height,
  padded,
  quality,
  src,
  width,
}: Options): string => {
  const widthAndHeight =
    padded && height ? [`w${width}h${height}@`] : [width && `w${width}`, height && `h${height}`]
  const options = [...widthAndHeight, quality && `q${quality}`].filter(Boolean).join('_')
  const optionString = options ? `/${options}` : ''
  return `https://cdn.s-cloud.fi/v1${optionString}/product/ean/${src}_kuva1.jpg`
}
