import { useMemo } from 'react'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'

import { normalizeQueryString, QUERY_STRING_PARAM } from '../utils/query-string'

/**
 * From the current URL Search Params, get a normalized `queryString: string` parameter.
 * The queryString (search term) should be joined from possible multiple values.
 * For example, `?queryString=nakki&queryString=makkara` becomes `nakki makkara`
 */
export const useQueryStringParam = (): string => {
  const { params } = useURLSearchParams()
  const queryString = params.getAll(QUERY_STRING_PARAM)
  return useMemo(() => normalizeQueryString(queryString), [queryString])
}
