import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { IconNavigationMenu } from '@s-group/design-system-icons'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { trackHomeClick, trackMenuClick } from 'utils/tracking/navigation'

import { NavigationActionButton, NavigationLinkButton } from './NavigationActions'
import { menuDrawerStateVar } from './TopNavigationContainer'

const _BrandLogo: FC<{ className?: string; onClick?: () => void; ['data-test-id']?: string }> = ({
  className,
  onClick,
  ['data-test-id']: dataTestId,
}) => {
  const { t } = useTranslation()
  return (
    <Link href="/" passHref>
      <NavigationLinkButton
        className={className}
        aria-label={t('Homepage')}
        data-test-id={dataTestId}
        onClick={onClick}
      >
        <img src="/icons/brand/s-kaupat.svg" alt="" />
      </NavigationLinkButton>
    </Link>
  )
}

export const BrandLogo = styled(_BrandLogo)({
  width: '84px',
  padding: 0,

  img: {
    overflow: 'hidden',
    width: '100%',
    height: '48px',
    objectFit: 'cover',
  },
})

const _NavigationMenu: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const isMenuOpen = useReactiveVar(menuDrawerStateVar) === 'menu'

  const handleClick = () => {
    trackMenuClick()
    menuDrawerStateVar(isMenuOpen ? 'closed' : 'menu')
  }

  return (
    <div className={className}>
      <NavigationActionButton
        data-test-id="global-nav-side-menu"
        icon={<IconNavigationMenu />}
        onClick={handleClick}
        title={t('Main menu')}
      />
      <BrandLogo onClick={trackHomeClick} data-test-id="global-nav-logo" />
    </div>
  )
}

export const NavigationMenu = styled(_NavigationMenu)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: 'inherit',
})
