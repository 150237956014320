import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { IconCommerceGroceries, IconNavigationChevronDown } from '@s-group/design-system-icons'
import {
  SDS_MOTION_DURATION_FAST,
  SDS_MOTION_EASING_DEFAULT,
} from '@s-group/design-system-tokens/web/tokens/motion.es6'
import { Navigation as ProductCategories } from 'components/Navigation'
import { Easing, Variant } from 'constants/animation'
import { AnimatePresence, motion } from 'framer-motion'
import { navigationVisibleVar } from 'lib/apollo/reactive-vars'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useMediaQuery } from 'react-responsive'
import styled, { useTheme } from 'styled-components'
import { customMinWidthFromTheme, NAVBAR_HEIGHT } from 'styles/layout'
import { trackProductCategoriesClick } from 'utils/tracking/navigation'

import { NavigationLinkButton } from './NavigationActions'
import { useNavigation } from './services/use-navigation'

const NavigationProductsTitle = styled.span(({ theme }) => ({
  ...theme.variants.body2.medium,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const AnimatedIcon = styled(IconNavigationChevronDown)<{ $isOpen?: boolean }>(
  ({ theme, $isOpen }) => ({
    display: 'none',

    [customMinWidthFromTheme(theme).tablet]: {
      position: 'absolute',
      right: 12,
      top: 12,
      display: 'inline-block',
      transform: `rotate(${$isOpen ? '180deg' : '0deg'}) translate(0, ${$isOpen ? '5px' : '0'} )`,
      transition: `${SDS_MOTION_DURATION_FAST} transform cubic-bezier(${SDS_MOTION_EASING_DEFAULT})`,
    },

    [customMinWidthFromTheme(theme).middleDesktop]: {
      position: 'relative',
      top: 3,
      right: 0,
    },
  }),
)

const Overlay = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  top: NAVBAR_HEIGHT.mobile,
  left: 0,
  right: 0,

  [customMinWidthFromTheme(theme).tablet]: {
    top: `${NAVBAR_HEIGHT.desktop}px`,
    zIndex: '-1',
  },

  height: '100vh',

  backgroundColor: theme.colors.SDS_BRAND_COLOR_BACKGROUND_STRONGER_GREY,
}))

const ProductNavigationDropdown = () => {
  const { data: navigationData } = useNavigation()

  return (
    <>
      <Overlay
        onClick={() => navigationVisibleVar(false)}
        initial={Variant.initial}
        animate={Variant.animate}
        exit={Variant.initial}
        variants={{
          [Variant.initial]: {
            opacity: 0,
            pointerEvents: 'initial',
          },
          [Variant.animate]: {
            opacity: 0.65,
            pointerEvents: 'none',

            transition: {
              ease: Easing.default,
            },
          },
        }}
      />
      <ProductCategories data={navigationData} />
    </>
  )
}

const ProductButton = styled(NavigationLinkButton)({ cursor: 'default' }, ({ theme }) => ({
  [customMinWidthFromTheme(theme).tablet]: {
    paddingRight: '40px',
  },

  [customMinWidthFromTheme(theme).middleDesktop]: {
    paddingRight: theme.spacings.xxxSmall,
  },
}))

const Container = styled.div<{ isVisible: boolean }>(
  {
    [NavigationProductsTitle]: { display: 'none' },
  },
  ({ theme, isVisible }) =>
    isVisible && {
      [NavigationProductsTitle]: { display: 'flex' },
      [ProductButton]: { display: 'none' },

      [customMinWidthFromTheme(theme).tablet]: {
        [NavigationProductsTitle]: { display: 'none' },
        [ProductButton]: { display: 'flex' },
      },
    },
)

const _NavigationProducts: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const isNavigationVisible = useReactiveVar(navigationVisibleVar)

  const theme = useTheme()
  const isTablet = useMediaQuery({ minWidth: theme.breakpoints.mobile })

  const toggleProductCategoryNavigation = () => {
    trackProductCategoriesClick()
    navigationVisibleVar(!isNavigationVisible)
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    toggleProductCategoryNavigation()
  }

  const handleSpaceOrEnter = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      toggleProductCategoryNavigation()
    }
  }

  return (
    <Container className={className} isVisible={isNavigationVisible}>
      <NavigationProductsTitle>{t('Products')}</NavigationProductsTitle>
      <OutsideClickHandler
        disabled={!isTablet || !isNavigationVisible}
        onOutsideClick={() => {
          navigationVisibleVar(false)
        }}
      >
        <Link href="/tuotteet" passHref>
          <ProductButton
            role="button"
            icon={<IconCommerceGroceries />}
            onClick={handleClick}
            onKeyDown={handleSpaceOrEnter}
            data-test-id="local-nav-products-container"
          >
            {t('Products')}
            <AnimatedIcon $isOpen={isNavigationVisible} />
          </ProductButton>
        </Link>
        <AnimatePresence mode="wait">
          {isNavigationVisible && <ProductNavigationDropdown />}
        </AnimatePresence>
      </OutsideClickHandler>
    </Container>
  )
}

export const NavigationProducts = styled(_NavigationProducts)({})
