import type { Product } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { QuantityModifier } from 'components/Product/QuantityModifier'
import { MAX_CARTITEM_COUNT } from 'domain/product'
import { useClientCartItemCount } from 'services/ClientCart/hooks/use-client-cart-item-count'
import { useItemCountHandler } from 'services/ClientCart/use-item-count-handler'

interface AddToCartProps {
  product: Pick<
    Product,
    'id' | 'basicQuantityUnit' | 'quantityMultiplier' | 'name' | 'isAgeLimitedByAlcohol'
  >
  disabled?: boolean
}

export const AddToCart: FC<AddToCartProps> = ({ product, disabled }) => {
  const cartItemCount = useClientCartItemCount(product.id)
  const handleChangeItemCount = useItemCountHandler(product, cartItemCount)

  return (
    <QuantityModifier
      disabled={disabled}
      quantityUnit={product.basicQuantityUnit ?? ''}
      itemAmountMultiplier={product.quantityMultiplier ?? 1}
      max={MAX_CARTITEM_COUNT}
      min={0}
      onChange={handleChangeItemCount}
      value={cartItemCount}
    />
  )
}
