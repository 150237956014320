import type { ChildProps, ParentProps } from '../Navigation'
import type { NavigationItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { motionSlideRight, Variant } from 'constants/animation'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { NavigationIcon } from '../NavigationIcon'
import { Header } from './Header'
import { SubCategory } from './SubCategory'

const StyledNavigationSubMenuWrapper = styled(motion.div)(
  ({ theme }) => theme.variants.body1.regular,
  ({ theme }) => ({
    backgroundColor: theme.color.white,
    color: theme.color.fontBlack,
  }),
)

interface NavigationSubMenuProps {
  id: string | null | undefined
  title: string | null | undefined
  items: NavigationItem[]
  parent: ParentProps
  child: ChildProps
  onHeaderClick: () => void
  onItemClick: (slug: string) => void
  isActive: (slug: string | null | undefined) => boolean
}

export const NavigationSubMenu: FC<NavigationSubMenuProps> = ({
  id,
  child,
  parent,
  isActive,
  items,
  onHeaderClick,
  onItemClick,
  title,
}) => (
  <StyledNavigationSubMenuWrapper
    initial={Variant.initial}
    animate={Variant.animate}
    exit={Variant.initial}
    variants={motionSlideRight}
  >
    <Header title={title} icon={<NavigationIcon id={id ?? parent.id} />} onClick={onHeaderClick} />
    <SubCategory
      parent={parent}
      child={child}
      isActive={isActive}
      items={items}
      onItemClick={onItemClick}
    />
  </StyledNavigationSubMenuWrapper>
)
