import type { ChangeEvent, FC, FocusEventHandler, KeyboardEventHandler } from 'react'

import { pipe } from 'fp-ts/function'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumericWithPrecision, isNumber, toPrecision } from 'utils/number'

const isNumericInput = (x: string) => /^([\d]*)$|(^[\d]*[.,]{1}[\d]*)$/.test(x)

interface Props {
  className?: string
  dataTestId: string
  max: number
  min: number
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange: (value: number) => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  precision: number
  value: number
  disabled?: boolean
}

const _NumericInput: FC<Props> = ({
  className,
  dataTestId,
  max,
  min,
  onChange,
  precision,
  value,
  disabled,
}) => {
  const { t } = useTranslation()
  const parseToNumericValue = (x: string): number =>
    pipe(
      x,
      (v: string) => v.replace(/,/g, '.'),
      (v) => parseFloat(v),
      (v) => (isNumber(v) ? v : 0),
      toPrecision(precision),
      (v) => Math.min(max, v),
      (v) => Math.max(min, v),
    )

  const [inputValue, setInputValue] = useState<string>(formatNumericWithPrecision(value, precision))

  useEffect(() => {
    setInputValue(formatNumericWithPrecision(value, precision))
  }, [precision, value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isNumericInput(e.target.value)) {
      setInputValue(e.target.value)
    }
  }

  const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    event.target.setSelectionRange(0, event.target.value.length)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    const newValue = parseToNumericValue(inputValue)
    if (newValue !== value) {
      onChange(newValue)
    }

    setInputValue(formatNumericWithPrecision(newValue, precision))
  }

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault()

    if (event.key === 'Enter') {
      event.currentTarget.blur()
    }
  }

  return (
    <input
      aria-label={t('Enter number to add to cart')}
      className={className}
      data-test-id={dataTestId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyUp={handleKeyUp}
      type="text"
      value={inputValue}
      disabled={disabled}
    />
  )
}

export const NumericInput = styled(_NumericInput)({})
