import type { FC } from 'react'

import { IconNavigationLinkExternal } from '@s-group/design-system-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const _ExternalLinkIcon: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <span className={className}>
      &nbsp;
      <IconNavigationLinkExternal size={'16'} aria-label={t('Link opens in new tab')} />
    </span>
  )
}

export const ExternalLinkIcon = styled(_ExternalLinkIcon)({
  position: 'relative',
  top: '2px',
  whiteSpace: 'nowrap',
})
