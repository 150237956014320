import type { FC, ReactNode } from 'react'

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children: ReactNode) => JSX.Element
  children: ReactNode
}

export const Wrap: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : <>{children}</>
}
