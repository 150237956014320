import type { CitrusAdBannerData, CitrusAdPlacement, CitrusAdSlot } from 'types/citrusad'

import { useQuery } from '@apollo/client'
import {
  useHasServiceConsent,
  useHasUserInteracted,
  useIsFailed,
} from '@s-group/react-usercentrics'
import { CitrusAdDocument } from '@shared/gql/document-nodes'
import { ServiceId } from 'config/usercentrics'
import { useMemo } from 'react'
import { useGetSelectedBrand } from 'utils/brand/use-get-selected-brand'
import { v4 as uuidv4 } from 'uuid'

import { useUsercentricsServiceSessionId } from '../../../utils/hooks/use-usercentrics-service-session-id'

interface GetCitrusAdBannerXParams {
  placement: CitrusAdPlacement
  slotId: CitrusAdSlot
  hasAdSlot?: boolean
}

export const useGetCitrusAdBannerX = ({
  placement,
  slotId,
  hasAdSlot,
}: GetCitrusAdBannerXParams): CitrusAdBannerData => {
  const brand = useGetSelectedBrand()
  const hasUserInteractedWithConsentDialog = useHasUserInteracted()
  const hasUsercentricsFailed = useIsFailed()

  const hasCitrusAdConsent = useHasServiceConsent(ServiceId.CitrusAd)
  const sessionId = useUsercentricsServiceSessionId(ServiceId.CitrusAd)
  const skipLoadAd = hasUsercentricsFailed
    ? false /** If Usercentrics failed to load, enable privacy-preserving ads */
    : hasCitrusAdConsent === null /** null if not loaded */ ||
      !hasUserInteractedWithConsentDialog ||
      !brand ||
      !hasAdSlot
  const adQueryVar = useMemo(
    () => ({
      brand,
      placement,
      /** Without consent, set random session id for every request */
      sessionId: sessionId ?? `ran_${uuidv4()}`,
      slotId,
      useCustomerId: !!hasCitrusAdConsent,
    }),
    [placement, slotId, sessionId, brand, hasCitrusAdConsent],
  )
  const { loading, data: citrusAdData } = useQuery(CitrusAdDocument, {
    variables: adQueryVar,
    skip: skipLoadAd,
    fetchPolicy: 'no-cache',
  })
  const banner =
    citrusAdData?.citrusAd?.banners && citrusAdData.citrusAd.banners[0]
      ? citrusAdData.citrusAd.banners[0]
      : undefined
  return { sessionId, skipLoadAd, loading, placement, slotId, banner }
}
