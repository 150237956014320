import type { ParentProps } from '../Navigation'
import type { Maybe, NavigationItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'
import type { IPageLayoutFields } from 'types/contentful/generated'

import { Button } from '@s-group/design-system-components'
import { CMS_NS, DEFAULT_NS } from '@shared/i18n'
import { TextBlock } from 'components/common/Titles'
import { Clear } from 'components/icons'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { motionSlideDown, Variant } from 'constants/animation'
import { motion } from 'framer-motion'
import { navigationVisibleVar } from 'lib/apollo/reactive-vars'
import Link from 'next/link'
import { range } from 'ramda'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPageLayoutContent } from 'services/Contentful/get-contentful-content'
import { useSortParam } from 'services/Search/hooks/use-sort-param'
import { sortToQueryValue } from 'services/Search/utils/sort'
import styled from 'styled-components'
import { usePreferredMotion } from 'utils/hooks/use-preferred-motion'
import {
  StyledArticleCTALink,
  StyledArticleGridContainer,
  StyledArticleGridTitle,
} from 'views/contentful/components/contentful-section/article-grid/styled'
import { PageLayoutSections } from 'views/contentful/components/PageLayoutSections'

const Title = styled.h3(({ theme }) => ({
  ...theme.variants.title2,
  padding: '2rem 2rem 0',
}))

const ContentContainer = styled.div(() => ({
  padding: '0 2rem',
  width: '100%',

  [StyledArticleGridTitle]: {
    display: 'none',
  },

  [StyledArticleGridContainer]: {
    marginBottom: 0,
  },

  [TextBlock]: {
    display: 'none',
  },

  [StyledArticleCTALink]: {
    display: 'none',
  },
}))

const StyledSubCategoryOuterWrapper = styled(motion.div)(({ theme }) => ({
  display: 'none',
  willChange: 'transform',

  '& + &': {
    marginTop: '36px',
  },

  '> *:first-child': {
    flexGrow: 1,
  },

  [`@media (min-width: ${theme.breakpoints.tablet})`]: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    width: '85%',
    alignItems: 'flex-start',
    overflow: 'auto',
  },
}))

const StyledSubCategoryWrapper = styled.div(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.tablet})`]: {
    columnCount: 3,
    columnGap: '10px',
    padding: 36,
    width: '100%',
    maxHeight: 'auto',
  },
}))

const StyledSubCategoryItem = styled.div(({ theme }) => ({
  [`@media (min-width: ${theme.breakpoints.tablet})`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    breakInside: 'avoid-column',
    pageBreakInside: 'avoid',
    width: 'auto',
    margin: 0,
  },
}))

const StyledButton = styled(Button)({
  margin: 5,
})

interface SubCategoryTitleProps {
  isActive?: boolean
}

const StyledSubCategoryTitle = styled.a<SubCategoryTitleProps>(({ theme, isActive }) => ({
  ...theme.variants.body2.medium,
  color: theme.color.primaryBrand,
  padding: 12,
  cursor: 'pointer',
  width: '100%',
  textDecoration: isActive ? 'underline' : 'initial',

  '&:hover': {
    textDecoration: 'underline',
  },
}))

const StyledSubCategoryChildTitle = styled.a<SubCategoryTitleProps>(({ theme, isActive }) => ({
  ...theme.variants.body2.regular,

  color: theme.color.fontBlack,
  cursor: 'pointer',
  paddingBottom: 12,
  paddingLeft: 12,
  textDecoration: isActive ? 'underline' : 'initial',
  width: 'auto',

  '&:hover': {
    textDecoration: 'underline',
  },

  '&:last-child': {
    paddingBottom: 20,
  },
}))

const StyledSubCategoryLink = styled.a(({ theme }) => ({
  ...theme.variants.body2.medium,
  color: theme.color.highlight500,
  cursor: 'pointer',
  padding: 12,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

interface SubCategoryProps {
  loading?: boolean
  items: Maybe<NavigationItem>[] | null | undefined
  parent?: ParentProps
  isActive: (slug: string | null | undefined) => boolean
  onItemClick: (slug: string) => void
  slug?: Maybe<string>
}

export const SubCategory: FC<SubCategoryProps> = ({
  loading,
  parent,
  items,
  isActive,
  onItemClick,
  slug,
}) => {
  const subCategoryRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation([DEFAULT_NS, CMS_NS])

  const [activeSort] = useSortParam()
  const sortParam = useMemo(() => {
    const sort = sortToQueryValue(activeSort)
    return sort ? `?sort=${sort}` : ''
  }, [activeSort])

  const animationVariant = usePreferredMotion()

  useEffect(() => {
    if (
      subCategoryRef.current !== null &&
      parent?.slug !== undefined &&
      subCategoryRef.current.scrollTo
    ) {
      subCategoryRef.current.scrollTo(0, 0)
    }
  }, [parent?.slug])

  const [content, setContent] = useState<IPageLayoutFields | null>()
  const [isContentLoading, setIsContentLoading] = useState(false)

  const showContentfulContent = slug?.startsWith('ajankohtaista')

  useEffect(() => {
    if (showContentfulContent) {
      const fetchContent = async () => {
        setIsContentLoading(true)
        const pageLayout = await getPageLayoutContent({ path: 'product-navigation-content' })
        setContent(pageLayout)
        setIsContentLoading(false)
      }

      fetchContent()
    }
  }, [setContent, showContentfulContent])

  if (loading) {
    const NUMBER_OF_PLACEHOLDERS = 8
    return (
      <StyledSubCategoryWrapper>
        {range(0, NUMBER_OF_PLACEHOLDERS).map((id) => (
          <StyledSubCategoryItem key={String(id)}>
            <StyledSubCategoryTitle>
              <SkeletonLoader />
            </StyledSubCategoryTitle>
          </StyledSubCategoryItem>
        ))}
      </StyledSubCategoryWrapper>
    )
  }

  if (!items) {
    return null
  }

  return (
    <StyledSubCategoryOuterWrapper
      ref={subCategoryRef}
      initial={Variant.initial}
      animate={animationVariant}
      exit={Variant.initial}
      variants={motionSlideDown}
    >
      <div>
        {showContentfulContent && (
          <>
            <Title>{t('cms:Inspiration')}</Title>
            <ContentContainer>
              {isContentLoading && <SkeletonLoader height={200} style={{ margin: '1rem 0' }} />}
              {content && (
                <PageLayoutSections sections={content.sections} context="menu-ajankohtaista" />
              )}
            </ContentContainer>
            <Title>{t('cms:Seasonal products')}</Title>
          </>
        )}
        <StyledSubCategoryWrapper data-test-id="product-navigation-subcategory">
          {items?.map((item) => (
            <StyledSubCategoryItem
              data-test-id="product-navigation-subcategory-item"
              key={item?.id as string}
            >
              <Link
                key={item?.id as string}
                href={`/tuotteet/${item?.slug as string}${sortParam}`}
                passHref
              >
                <StyledSubCategoryTitle
                  data-test-id="product-navigation-subcategory-item-title"
                  isActive={isActive(item?.slug)}
                  onClick={() => {
                    onItemClick(item?.slug as string)
                    navigationVisibleVar(false)
                  }}
                >
                  {item?.name}
                </StyledSubCategoryTitle>
              </Link>
              {!showContentfulContent &&
                item?.children?.map((child) => (
                  <Link
                    key={child?.id as string}
                    href={`/tuotteet/${child?.slug as string}${sortParam}`}
                    passHref
                  >
                    <StyledSubCategoryChildTitle
                      data-test-id="product-navigation-subcategory-child-item"
                      isActive={isActive(child?.slug)}
                      onClick={() => {
                        onItemClick(child?.slug as string)
                        navigationVisibleVar(false)
                      }}
                    >
                      {child?.name}
                    </StyledSubCategoryChildTitle>
                  </Link>
                ))}
            </StyledSubCategoryItem>
          ))}
          <StyledSubCategoryItem data-test-id="product-navigation-subcategory-item">
            <Link
              href={'/tuotteet/[...slugs]'}
              as={`/tuotteet/${parent?.slug}${sortParam}`}
              passHref
            >
              <StyledSubCategoryLink
                onClick={() => navigationVisibleVar(false)}
                data-test-id="product-navigation-subcategory-show-all-link"
              >
                {t('default:Show all')}
              </StyledSubCategoryLink>
            </Link>
          </StyledSubCategoryItem>
        </StyledSubCategoryWrapper>
      </div>
      <StyledButton
        data-test-id="product-navigation-subcategory-clear-icon"
        onClick={() => navigationVisibleVar(false)}
        icon={<Clear />}
        plain
        rounding="small"
      />
    </StyledSubCategoryOuterWrapper>
  )
}
