import type { IContentBlock, IContentBlockFields } from 'types/contentful/generated'

type Variation = IContentBlockFields['brandDefault']

const findVariation = (key: string) => (item: Variation) => {
  if (!item) return null
  return item.fields.storeVisibilityFilter?.includes(key)
}

export const getContentBlock = (
  block: IContentBlock,
  storeId?: string,
  storeCoop?: string,
): Variation => {
  const storeContent = () =>
    storeId ? block.fields.storeVariations?.find(findVariation(storeId)) : null
  const coOpContent = () =>
    storeCoop ? block.fields.coOpVariations?.find(findVariation(storeCoop)) : null

  return storeContent() || coOpContent() || block.fields.brandDefault
}
