import type { FC } from 'react'
import type { ICard, ISection } from 'types/contentful/generated'

import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import styled from 'styled-components'
import { CONTENTFUL_MEDIA_ROOT } from 'themes/global'
import { getOptimizedImageUrl } from 'utils/common/image-utils'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { StyledTextTitle } from './common'

const StyledSocialMediaLinksContainer = styled.div<SocialMediaLinksContainerProps>`
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledSocialMediaLinksWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledSocialMediaIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  margin: 0px 5px;
`
interface SocialMediaLinksContainerProps {
  id?: string
}
interface SocialMediaLinksProps {
  section: ISection
}

const getSocialMediaComponents: FC<{
  cards: ICard[]
  isClientSide: boolean
}> = ({ cards, isClientSide }) => {
  const contentfulImageUrl = `${CONTENTFUL_MEDIA_ROOT}/4xRZkTTLnHmF1uAAcWLWiG/8098f7575cd611df983a4d2f079682a3/herkku_04_photo-iiro-muttilainen_1.png?h=250`

  const components = cards?.map((card) => {
    if (!isClientSide) {
      return null
    }

    const { href: pageUrl } = window.location
    const { backgroundColor: iconBackgroundColor, image, title } = card.fields
    const imageUrl = getOptimizedImageUrl(image)
    const { id } = card.sys
    if (title === 'facebook') {
      return (
        <StyledSocialMediaIconContainer key={id}>
          <FacebookShareButton url={pageUrl} resetButtonStyle style={{ outline: 'none' }}>
            <FacebookIcon round bgStyle={{ fill: iconBackgroundColor }} size={40} />
          </FacebookShareButton>
        </StyledSocialMediaIconContainer>
      )
    }
    if (title === 'twitter') {
      return (
        <StyledSocialMediaIconContainer key={id}>
          <TwitterShareButton url={pageUrl} resetButtonStyle style={{ outline: 'none' }}>
            <TwitterIcon round bgStyle={{ fill: iconBackgroundColor }} size={40} />
          </TwitterShareButton>
        </StyledSocialMediaIconContainer>
      )
    }
    if (title === 'pinterest') {
      return (
        <StyledSocialMediaIconContainer key={id}>
          <PinterestShareButton
            media={imageUrl ? imageUrl : contentfulImageUrl}
            url={pageUrl}
            resetButtonStyle
            style={{ outline: 'none' }}
          >
            <PinterestIcon
              round
              iconFillColor={'white'}
              bgStyle={{ fill: iconBackgroundColor }}
              size={40}
            />
          </PinterestShareButton>
        </StyledSocialMediaIconContainer>
      )
    }
    return <></>
  })

  return <>{components}</>
}

export const SocialMediaLinks: FC<SocialMediaLinksProps> = ({ section }) => {
  const { title, cards, tagTitle } = section.fields
  const isClientSide = useIsClientSide()

  if (cards && cards.length > 0) {
    return (
      <StyledSocialMediaLinksContainer id={tagTitle}>
        {title ? <StyledTextTitle>{title}</StyledTextTitle> : <></>}
        <StyledSocialMediaLinksWrapper>
          {getSocialMediaComponents({ cards, isClientSide })}
        </StyledSocialMediaLinksWrapper>
      </StyledSocialMediaLinksContainer>
    )
  }
  return <></>
}
