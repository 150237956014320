import type { FC } from 'react'

import { useState } from 'react'
import { useContentfulContent } from 'services/Contentful/contentful-provider'
import styled from 'styled-components'

import { Switcher } from './Switcher'

interface ContentfulToolboxProps {
  className?: string
}

const _ContentfulToolbox: FC<ContentfulToolboxProps> = ({ className }) => {
  const { xrayMode, setXrayMode } = useContentfulContent()
  const [open, setOpen] = useState(false)
  const handleChange = () => {
    if (typeof setXrayMode === 'function') {
      setXrayMode(!xrayMode)
    }
  }
  return (
    <div className={className}>
      {open ? (
        <div className="toolbox">
          <h5>
            <span>
              <img src="/icons/contentful.svg" height={30} width={30} alt="contentful-toolbox" />
              Editorial Toolbox
            </span>
            <button onClick={() => setOpen(false)}>Close</button>
          </h5>
          <div>
            <Switcher handleChange={handleChange} label="Xray mode" checked={xrayMode ?? false} />
          </div>
        </div>
      ) : (
        <button className="open" onClick={() => setOpen(true)}>
          <img src="/icons/contentful.svg" height={40} width={40} alt="contentful-toolbox" />
        </button>
      )}
    </div>
  )
}

export const ContentfulToolbox = styled(_ContentfulToolbox)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacings.medium,
  right: theme.spacings.medium,
  zIndex: 10,
  ['button']: {
    borderRadius: '100px',
    border: 0,
    cursor: 'pointer',
    color: theme.colors.primary500,
  },
  ['.open']: {
    backgroundColor: theme.colors.white,
    padding: '10px',
    boxShadow: '0 8px 6px -3px rgba(0, 0, 0, 0.3)',
  },
  ['.toolbox']: {
    width: '350px',
    backgroundColor: theme.colors.white,
    padding: theme.spacings.small,
    borderRadius: '5px',
    boxShadow: '0 8px 6px -3px rgba(0, 0, 0, 0.3)',
  },
  ['h5']: {
    fontSize: theme.sizes.s.fontSize,
    fontWeight: theme.fontWeights.medium,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacings.small,
    borderBottom: `1px solid ${theme.colors.grey300}`,
    ['span']: {
      display: 'flex',
      alignItems: 'center',
    },
    ['button']: {
      backgroundColor: 'transparent',
    },
    ['img']: {
      marginRight: '10px',
    },
  },
}))
