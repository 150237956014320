import type { ComponentType } from 'react'
import type { IHeroCards, IHeroCardsCarousel, ISection } from 'types/contentful/generated'

import { getConfig } from '@shared/config'
import React from 'react'
import { useContentfulContent } from 'services/Contentful/contentful-provider'
import styled from 'styled-components'

const { contentful } = getConfig()

const CONTENTFUL_ENTRY_BASE_URL = `https://app.contentful.com/spaces/${contentful.space}/entries`

interface ContentfulXrayProps {
  section: ISection | IHeroCards | IHeroCardsCarousel
}
export function withContentfulXray<P>(WrappedComponent: ComponentType<P>) {
  if (!contentful.toolbox) {
    return WrappedComponent
  }

  const XrayControlled = (props: P & ContentfulXrayProps) => {
    const { section } = props
    const { xrayMode } = useContentfulContent()

    if (xrayMode) {
      return (
        <XraySection>
          <a
            className="section-name"
            target="_blank"
            href={`${CONTENTFUL_ENTRY_BASE_URL}/${section.sys.id}`}
            rel="noreferrer"
          >
            {section.sys.contentType.sys.id}
            {'displayname' in section.fields ? ` - ${section.fields.displayname}` : ''}
          </a>
          <WrappedComponent {...props} />
        </XraySection>
      )
    }
    return <WrappedComponent {...props} />
  }

  return XrayControlled
}

const XraySection = styled.div(({ theme }) => ({
  position: 'relative',
  outline: '1px dashed black',
  '.section-name': {
    position: 'absolute',
    zIndex: 10,
    padding: '8px 20px',
    borderRadius: '5px 0 0 0',
    backgroundColor: '#0681B6',
    color: theme.colors.white,
    bottom: 0,
    right: 0,
    '&:hover': {
      backgroundColor: '#046A97',
    },
  },
}))
