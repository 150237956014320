import type { Entry } from 'contentful'
import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { CheckableTag, TagSize, TagType } from '@sok/design-system'
import styled, { useTheme } from 'styled-components'

import { StyledTextTitle } from './common'

interface TagsProps {
  section: ISection
  sections?: Entry<{ tagTitle?: string }>[]
}

const StyledAnchorTagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`
const StyledAnchorTagsWrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0 20px 0px;
  }
`
const StyledAnchorTagsCollection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
`

const StyledAnchorTag = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 12px;
`

export const Tags: FC<TagsProps> = ({ section, sections }) => {
  const theme = useTheme()
  const { title: sectionTitle } = section.fields

  if (!sections || sections.length === 0) {
    return null
  }

  return (
    <StyledAnchorTagsContainer>
      <StyledAnchorTagsWrapper>
        <StyledTextTitle>{sectionTitle}</StyledTextTitle>
        <StyledAnchorTagsCollection>
          {sections
            .filter((item) => !!item.fields.tagTitle)
            .map((item, index) => {
              return (
                <StyledAnchorTag key={index} href={`#${item.fields.tagTitle}`}>
                  <CheckableTag
                    checked={false}
                    type={TagType.DEFAULT}
                    style={{
                      color: theme.color.fontBlack,
                      background: theme.color.backgroundEmphasis,
                    }}
                    size={TagSize.MIDDLE}
                  >
                    {item.fields.tagTitle}
                  </CheckableTag>
                </StyledAnchorTag>
              )
            })}
        </StyledAnchorTagsCollection>
      </StyledAnchorTagsWrapper>
    </StyledAnchorTagsContainer>
  )
}
