import type { FC, ReactNode } from 'react'
import type { ICardFields, IInputElementFields } from 'types/contentful/generated'

import { Button, ButtonSize, ButtonType } from '@sok/design-system'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import styled from 'styled-components'

import { ContentfulImage } from '../../ContentfulImage'
import { StyledCardImageWrapper, StyledContentTextBlock, StyledTextTitle } from '../common'
import { StyledCard, StyledCTA, StyledMeta } from './styled'

type CardFields = ICardFields & {
  cta?: IInputElementFields
  parsedBody?: ReactNode
}

interface CardProps {
  fields: CardFields
  id?: string
  ['data-test-id']?: string
}

export const Card: FC<CardProps> = ({ id, fields, ['data-test-id']: dataTestId }) => (
  <StyledCard
    id={id}
    backgroundColor={fields.backgroundColor}
    imagePosition={fields.imagePosition}
    elementSize={fields.elementSize}
    data-test-id={dataTestId}
  >
    <StyledCardImageWrapper>
      <StyledContentfulImage image={fields.image} data-test-id={`${dataTestId}-image`} lazy />
    </StyledCardImageWrapper>
    {(fields.title || fields.body) && (
      <StyledMeta data-test-id={`${dataTestId}-meta`}>
        {fields.title && (
          <StyledTextTitle data-test-id={`${dataTestId}-meta-title`}>
            {fields.title}
          </StyledTextTitle>
        )}
        {fields.body && (
          <StyledContentTextBlock data-test-id={`${dataTestId}-meta-body`}>
            {fields.parsedBody}
          </StyledContentTextBlock>
        )}
        {!!fields.cta?.label && (
          <Action data-test-id={`${dataTestId}-meta-action`} cta={fields.cta} />
        )}
      </StyledMeta>
    )}
  </StyledCard>
)

interface ActionProps {
  cta: IInputElementFields
  ['data-test-id']?: string
}

const Action: FC<ActionProps> = ({ cta, ['data-test-id']: dataTestId }) => {
  return (
    <Link href={getValidatedUrl(cta.url)} passHref>
      <StyledCTA target={cta.openLinkInNewTab ? '_blank' : undefined} data-test-id={dataTestId}>
        <Button
          data-test-id={`${dataTestId}-${cta.fieldType}`}
          size={ButtonSize.MIDDLE}
          type={cta.fieldType === 'primaryButton' ? ButtonType.PRIMARY : ButtonType.LINK}
          text={cta.label}
          style={cta.fieldType !== 'primaryButton' ? { paddingLeft: 0 } : undefined}
        />
      </StyledCTA>
    </Link>
  )
}

const StyledContentfulImage = styled(ContentfulImage)({
  height: '100%',
  objectFit: 'cover',
})
