import type { Facet } from '../utils/filters'
import type { QueryHookOptions, QueryResult } from '@apollo/client'
import type {
  Filters as RemoteFilter,
  RemoteFilteredProductsQuery,
  RemoteFilteredProductsQueryVariables,
  SearchProvider,
  SortKey,
  SortOrder,
  StructuredFacetInput,
} from '@shared/gql/document-nodes'
import type { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'

import { useQuery } from '@apollo/client'
import { useHasServiceConsent } from '@s-group/react-usercentrics'
import { getConfig } from '@shared/config'
import { RemoteFilteredProductsDocument } from '@shared/gql/document-nodes'
import { useNavigation } from 'components/Header/TopNavigation/services/use-navigation'
import { ServiceId } from 'config/usercentrics'
import { fallbackSearchProviderVar } from 'lib/apollo/reactive-vars'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useServiceSubscriptions } from 'utils/hooks/account/use-get-service-subscriptions'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'
import { useUsercentricsServiceSessionId } from 'utils/hooks/use-usercentrics-service-session-id'
import { removeNullableFields } from 'utils/remove-nullable-fields'
import {
  getPurchaseDataAndProfilingDenied,
  SERVICE_SUBSCRIPTIONS,
} from 'utils/service-subscriptions-util'
import { pushData } from 'utils/tracking/custom-events/custom-events'

import { useQueryStringParam } from '../hooks/use-query-string-param'
import { useSortParam } from '../hooks/use-sort-param'
import { getSearchAnalyticsData } from '../utils/analytics'
import { parseURLSearchParamsToFilters } from '../utils/filters'
import { SEARCH_LIMIT } from '../utils/search-limit'
import { useCurrentSearchProvider } from './use-search-provider'

const { featureFlags } = getConfig()

export type RemoteFilteredProductsQueryVariablesAndConsentPlatform =
  RemoteFilteredProductsQueryVariables & {
    isUsercentricsInitBlocked?: boolean
    isUsercentricsInitialized?: boolean
  }

type Options = QueryHookOptions<
  RemoteFilteredProductsQuery,
  RemoteFilteredProductsQueryVariablesAndConsentPlatform
>
type Result = QueryResult<RemoteFilteredProductsQuery, RemoteFilteredProductsQueryVariables>

/**
 * This should be low enough for the search to feel snappy
 * but not trigger needlessly while typing.
 */
const DEBOUNCE_MS = 500

interface Variables {
  /** The Analytics context */
  context: TrackingContext
  /** Whether to temporarily disable the search */
  disabled?: boolean
  /** What facet values to include in the search results */
  facets?: StructuredFacetInput[]
  /** What filters to use for the search */
  filters?: RemoteFilter[] | null
  /** Override inclusion of alcoholic products, instead of using default from delivery area */
  includeAgeLimitedByAlcohol?: boolean
  /* Whether search is coming from autosuggestSearchBox */
  loop54DirectSearch?: boolean
  /** Overrid default sort order direction, instead of using the URL param  */
  order?: SortOrder | null
  /** Overrid default sort order value, instead of using the URL param  */
  orderBy?: SortKey | null
  /** Index for paginated search, starting from 1 */
  page?: number
  /** Limit per page */
  limit?: number
  /** Override query string, instead of using the URL param */
  queryString?: string
  /** Limit the search into a single category slug. */
  slug?: string
  /* Which search provider is used */
  searchProvider?: SearchProvider | null
  /* Whether to use random one-time (non-trackable) id or user-specific id */
  useRandomId?: boolean
}

type ProductSearch = Pick<Result, 'data' | 'loading' | 'error'> & {
  called: boolean
  facets: Facet[] | null
  hasMore: boolean
  loadingMore: boolean
  loadMore: () => Promise<void>
  variables?: RemoteFilteredProductsQueryVariables & {
    fallbackSearchProvider?: SearchProvider | null
  }
}

/**
 * A helper hook around the `RemoteFilteredProducts` query.
 * Supply it with initial options, and the hook will handle the rest
 * by reading the URL search parameters. To update the search, you
 * should only update the URL.
 */
export const useProductSearch = (variables: Variables, options: Options = {}): ProductSearch => {
  const { selectedStoreId, storeId } = useStoreId()
  const { data: navigationData } = useNavigation()
  const isClientSide = useIsClientSide()

  const urlQueryString = useQueryStringParam()

  const queryString = useMemo(() => {
    return variables.queryString === undefined ? urlQueryString : variables.queryString
  }, [urlQueryString, variables.queryString])

  const [[orderBy, order]] = useSortParam()

  /** Filters going into the query */
  const { params } = useURLSearchParams()
  const remoteFilters = useMemo<RemoteFilter[] | null>(
    () =>
      variables.filters !== undefined ? variables.filters : parseURLSearchParamsToFilters(params),
    [params, variables.filters],
  )
  const limit = variables.limit ?? SEARCH_LIMIT
  const from = variables.page && variables.page > 1 ? limit * (variables.page - 1) : null
  const sessionId = useUsercentricsServiceSessionId(ServiceId.Loop54)
  const serviceSubscriptions = useServiceSubscriptions(SERVICE_SUBSCRIPTIONS)
  const purchaseDataAndProfilingDenied = getPurchaseDataAndProfilingDenied(serviceSubscriptions)

  const hasLoop54Consent = useHasServiceConsent(ServiceId.Loop54)
  const searchProviderFromHook = useCurrentSearchProvider()

  const availabilityDate = useDeliveryDate()

  const memoedVariables = useMemo<
    [boolean | undefined, RemoteFilteredProductsQueryVariablesAndConsentPlatform]
  >(
    () => [
      /** The `disabled` value should be debounced at the same time as query variables */
      variables.disabled,
      removeNullableFields({
        availabilityDate,
        facets: variables.facets || null,
        filters: remoteFilters?.length ? remoteFilters : null,
        from,
        generatedSessionId: sessionId,
        includeAgeLimitedByAlcohol: variables.includeAgeLimitedByAlcohol ?? true,
        includeAvailabilities: featureFlags.productAvailabilities && !!selectedStoreId,
        limit,
        loop54DirectSearch: variables.loop54DirectSearch,
        order: variables.order !== undefined ? variables.order : order,
        orderBy: variables.orderBy !== undefined ? variables.orderBy : orderBy,
        queryString,
        searchProvider:
          variables.searchProvider !== undefined
            ? variables.searchProvider
            : searchProviderFromHook,
        slug: variables.slug ?? null,
        storeId,
        useRandomId: variables.useRandomId || purchaseDataAndProfilingDenied || !hasLoop54Consent,
        fallbackToGlobal: null,
      }),
    ],
    [
      availabilityDate,
      from,
      limit,
      sessionId,
      hasLoop54Consent,
      order,
      orderBy,
      purchaseDataAndProfilingDenied,
      queryString,
      remoteFilters,
      searchProviderFromHook,
      selectedStoreId,
      storeId,
      variables.disabled,
      variables.facets,
      variables.includeAgeLimitedByAlcohol,
      variables.loop54DirectSearch,
      variables.order,
      variables.orderBy,
      variables.searchProvider,
      variables.slug,
      variables.useRandomId,
    ],
  )

  const [[disabledDebounced, variablesDebounced]] = useDebounce<
    [boolean | undefined, RemoteFilteredProductsQueryVariablesAndConsentPlatform]
  >(memoedVariables, DEBOUNCE_MS)

  const {
    called,
    data,
    error,
    fetchMore,
    loading,
    variables: outputVariables,
  } = useQuery(RemoteFilteredProductsDocument, {
    ...options,
    skip: disabledDebounced || (isClientSide && !variablesDebounced.availabilityDate),
    variables: variablesDebounced,
    onCompleted(queryData) {
      if (isClientSide && outputVariables) {
        pushData({
          search: getSearchAnalyticsData({
            context: variables.context,
            data: queryData,
            navigation: navigationData?.store?.navigation,
            variables: outputVariables,
          }),
        })
      }
      options.onCompleted?.(queryData)
    },
    onError(queryError) {
      if (isClientSide && outputVariables) {
        pushData({
          search: getSearchAnalyticsData({
            context: variables.context,
            error: queryError,
            navigation: navigationData?.store?.navigation,
            variables: outputVariables,
          }),
        })
      }
      options.onError?.(queryError)
    },
  })

  const fallbackSearchProvider = data?.store?.products?.searchProvider
  /** Set reactive var if received fallbackSearchProvider is non-nullish */
  if (fallbackSearchProvider) fallbackSearchProviderVar(fallbackSearchProvider)

  const facets = data?.store?.products?.structuredFacets || null

  /** Whether there are more results yet to be loaded */
  const hasMore = useMemo(() => {
    const total = data?.store?.products?.total || 0

    /** When using pagination, we only have `SEARCH_LIMIT` number of products at a time */
    if (variables.page && from) {
      return from + SEARCH_LIMIT < total
    }

    const itemsInApolloCache = data?.store?.products?.items?.length || 0
    return total > itemsInApolloCache
  }, [data?.store?.products?.items?.length, data?.store?.products?.total, from, variables.page])

  const [loadingMore, setLoadingMore] = useState(false)

  /** Load more paginated data */
  const loadMore = useCallback(async () => {
    if (hasMore && fetchMore) {
      setLoadingMore(true)
      await fetchMore({ variables: { from: data?.store?.products?.items?.length } })
      setLoadingMore(false)
    }
  }, [data?.store?.products?.items?.length, hasMore, fetchMore])

  return {
    called,
    data,
    error,
    facets,
    hasMore,
    loading,
    loadingMore,
    loadMore,
    variables: Object.assign({}, outputVariables, { fallbackSearchProvider }),
  }
}
