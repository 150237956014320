import { SDS_COLOR_WHITE } from '@s-group/design-system-tokens/web/tokens/colors.es6'
import { maxWidthFromTheme } from '@sok/design-system'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { customMinWidthFromTheme, NAVBAR_WIDTH } from 'styles/layout'

import { TopNavigation } from './TopNavigation'

export const NavigationContainer = styled.div({ position: 'sticky', top: 0, zIndex: 50 })

export const NavigationHeader = styled(motion.header)(({ theme }) => ({
  width: '100%',
  backgroundColor: SDS_COLOR_WHITE,
  borderBottom: `1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY}`,

  [customMinWidthFromTheme(theme).tablet]: {
    transform: 'none !important',
  },
}))

export const NavigationBaseLayout = styled.div(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',

  gridTemplateRows: '48px',
  rowGap: theme.spacings.xxSmall,
  columnGap: theme.spacings.xxSmall,

  gridAutoFlow: 'column',
  gridAutoColumns: 'max-content',

  maxWidth: NAVBAR_WIDTH,
  margin: '0 auto',

  padding: theme.spacings.xxSmall,

  [customMinWidthFromTheme(theme).tablet]: {
    padding: `0 ${theme.spacings.medium}`,
    gridTemplateRows: '80px',
  },

  [customMinWidthFromTheme(theme).middleDesktop]: {
    columnGap: theme.spacings.xSmall,
  },
}))

export type NavState = 'searchOpen' | 'productCategoriesOpen' | null

export const NavigationLayout = styled(NavigationBaseLayout)<{
  navState?: NavState
}>(
  ({ theme }) => ({
    [TopNavigation.DeliverySelect]: { gridArea: 'delivery-select' },
    [TopNavigation.BrandLogo]: { gridArea: 'menu' },
    [TopNavigation.Menu]: { gridArea: 'menu' },
    [TopNavigation.ProductCategories]: { gridArea: 'product-categories' },
    [TopNavigation.Search]: { gridArea: 'search', maxWidth: '640px' },
    [TopNavigation.ShoppingCart]: { gridArea: 'shopping-cart' },
    [TopNavigation.Sidepaneltoggle]: { gridArea: 'shopping-cart-toggle', display: 'none' },
    [TopNavigation.User]: { gridArea: 'user' },

    [TopNavigation.CloseSearchButton]: { display: 'none' },

    gridTemplateAreas: `
      "menu menu delivery-select user"
      "product-categories search search shopping-cart"
    `,
    gridTemplateColumns: 'min-content 1fr fit-content(120px) min-content',

    [customMinWidthFromTheme(theme).tablet]: {
      gridTemplateAreas: '"menu product-categories search . delivery-select user shopping-cart"',
      gridTemplateColumns:
        'min-content min-content 1fr 8px fit-content(120px) min-content min-content',
    },

    [customMinWidthFromTheme(theme).desktop]: {
      [TopNavigation.Sidepaneltoggle]: { display: 'block' },
      gridTemplateAreas:
        '"menu . product-categories search . delivery-select user shopping-cart shopping-cart-toggle"',
      gridTemplateColumns:
        'min-content 16px min-content 1fr 16px fit-content(280px) fit-content(156px) min-content min-content',
    },

    [customMinWidthFromTheme(theme).middleDesktop]: {
      gridTemplateColumns:
        'min-content 8px max-content 1fr 8px fit-content(280px) fit-content(156px) max-content min-content',
    },

    [customMinWidthFromTheme(theme).largeDesktop]: {
      gridTemplateColumns:
        'min-content 8px max-content minmax(500px, 1fr) 8px fit-content(420px) fit-content(156px) max-content min-content',
    },
  }),
  ({ navState, theme }) => {
    switch (navState) {
      case 'searchOpen':
        return {
          [maxWidthFromTheme(theme).mobile]: {
            [`> *:not(${TopNavigation.Search}):not(${TopNavigation.CloseSearchButton}):not(${TopNavigation.ShoppingCart})`]:
              {
                display: 'none',
              },
            [TopNavigation.CloseSearchButton]: { display: 'flex', gridArea: 'product-categories' },
          },
        }
      case 'productCategoriesOpen':
        return {
          [maxWidthFromTheme(theme).mobile]: {
            [`> *:not(${TopNavigation.ProductCategories}):not(${TopNavigation.CloseSearchButton}):not(${TopNavigation.ShoppingCart})`]:
              {
                display: 'none',
              },
            [TopNavigation.ProductCategories]: { gridArea: 'search' },
            [TopNavigation.CloseSearchButton]: { display: 'flex', gridArea: 'product-categories' },
          },
        }
    }
  },
)
