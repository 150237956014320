import type { Price } from './price'
import type { Product } from '@shared/gql/document-nodes'

import { EuroCents } from '@shared/domain/euro-cents'
import { isNonNullable } from 'utils/nullable'

import { getUnit } from './unit'

export type ProductPriceData = Pick<
  Product,
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'ean'
  | 'name'
  | 'price'
  | 'priceUnit'
  | 'storeId'
>

const getPrice = (
  price: number | null,
  priceUnit: string | null,
  approxPrice: boolean | null,
): Price | null =>
  isNonNullable(price)
    ? {
        value: EuroCents.fromEuros(price),
        unit: getUnit(priceUnit),
        isApproximate: !!approxPrice,
      }
    : null

export type ProductPrice = Pick<Product, 'approxPrice' | 'price' | 'priceUnit'>

export const getProductPrice = (product: ProductPrice): Price | null =>
  getPrice(product.price, product.priceUnit, product.approxPrice)

export type ProductComparisonPrice = Pick<Product, 'comparisonPrice' | 'comparisonUnit'>

/**
 * Product has comparison price info displayed if:
 *   1. comparison price is different than product price AND
 *   2. comparison unit is different than priceUnit.
 *
 * If they are the same, comparison price should not be displayed
 */
export const getProductComparisonPrice = (
  product: ProductPrice & ProductComparisonPrice,
): Price | null => {
  const { comparisonPrice, comparisonUnit, price, priceUnit } = product

  if (comparisonPrice === price && getUnit(comparisonUnit) === getUnit(priceUnit)) {
    return null
  }

  return getPrice(
    comparisonPrice,
    comparisonUnit,
    false /** comparison price is never approximate */,
  )
}

export type ProductDepositPrice = Partial<Pick<Product, 'depositPrice'>>

export const getProductDepositPrice = (product: ProductDepositPrice): Price | null => {
  /** This leaves out missing and zero price */
  if (!product.depositPrice) {
    return null
  }

  return getPrice(
    product.depositPrice,
    null /** deposit price has no unit */,
    false /** deposit price is never approximate */,
  )
}

type ProductLowest30DayPrice = {
  pricing: { lowest30DayPrice: number | undefined | null } | undefined | null
}

export const getProductLowest30DayPrice = (product: ProductLowest30DayPrice): Price | null => {
  if (!product.pricing?.lowest30DayPrice) {
    return null
  }

  return getPrice(
    product.pricing?.lowest30DayPrice,
    null /** lowest30DayPrice price has no unit */,
    false /** lowest30DayPrice price is never approximate */,
  )
}
