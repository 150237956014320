import type { FC, HTMLAttributes } from 'react'

import styled from 'styled-components'

export interface FrozenProps extends HTMLAttributes<SVGSVGElement> {
  background?: string
  className?: string
  foreground?: string
  size?: number
}

const _Frozen: FC<FrozenProps> = ({
  background: _background,
  foreground: _foreground,
  size: _size,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg viewBox="0 0 24 24" {...rest}>
    <path d="m11.9902 3.28003v17.43997" />
    <path d="m9.70996 4.75 2.31004 2.3 2.25-2.26" />
    <path d="m14.27 19.26-2.3-2.3-2.26004 2.26" />
    <path d="m19.5902 7.70996-15.18004 8.58004" />
    <path d="m17.1898 6.44995-.87 3.14 3.07.85005" />
    <path d="m6.79984 17.5601.87-3.14-3.08-.85" />
    <path d="m19.5902 16.29-15.18004-8.56002" />
    <path d="m19.4298 13.5801-3.13.88.86 3.07" />
    <path d="m4.5498 10.43 3.14-.88002-.86-3.07" />
  </svg>
)

_Frozen.displayName = 'Frozen'

/** White snowflake on a dark blue circle */
export const Frozen = styled(_Frozen)(({ background, foreground, size }) => ({
  backgroundColor: background,
  borderRadius: '50%',
  fill: 'none',
  height: size,
  stroke: foreground,
  strokeLinecap: 'round',
  strokeMiterlimit: 10,
  width: size,
}))

Frozen.defaultProps = {
  background: '#3974bc',
  foreground: '#fff',
  role: 'presentation',
  size: 24,
}
