import type { FC } from 'react'
import type { ISectionFields } from 'types/contentful/generated'

import { SectionTitle } from 'components/common/Titles'
import { openDeliveryModal } from 'services/Delivery/cache'
import styled from 'styled-components'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { ButtonList } from './row-component/button-list'
import { RowItem } from './row-component/row-item'
import { StyledButtonRow, StyledContainer, StyledItem, StyledRowItem } from './row-component/styled'

export interface RowComponentProps {
  fields: ISectionFields
  href: string
}

export const RowComponent: FC<RowComponentProps> = ({ fields, href }) => {
  const importantInfoBodyText = useParsedMarkdown(fields.body)

  return (
    <StyledContainer bgColor={fields.backgroundColor} count={fields.cards && fields.cards.length}>
      {fields.title && <SectionTitle>{fields.title}</SectionTitle>}

      {fields.body && importantInfoBodyText && (
        <TitleInfoText>{importantInfoBodyText}</TitleInfoText>
      )}

      {fields.cards && (
        <StyledRowItem flex={fields.cardsFlexbox}>
          {fields.cards.map((card) => (
            <li key={card.sys.id}>
              <RowItem card={card.fields} />
            </li>
          ))}
        </StyledRowItem>
      )}

      {fields.functionality?.length && (
        <StyledButtonRow>
          <ButtonList
            items={fields.functionality}
            openDeliveryModal={openDeliveryModal}
            href={href}
            topPadding={!fields.cards ? '0' : undefined}
          />
        </StyledButtonRow>
      )}
    </StyledContainer>
  )
}

const TitleInfoText = styled(StyledItem)(({ theme }) => ({
  marginTop: `-${theme.spacings.xxLarge}`,
  marginBottom: theme.spacings.xxLarge,
}))
