import type { ProductAvailabilities } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { Popover } from '@sok/design-system'
import { toFormattedDate } from 'domain/date-time'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentProductAvailability } from 'services/Product/hooks/use-current-product-availibility'
import { isBadAvailability } from 'services/Product/utils/is-bad-availability'
import styled from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

interface ProductAvailabilityInfoProps {
  className?: string
  'data-test-id'?: string
  availabilities: ProductAvailabilities[] | null
}

const _ProductAvailabilityInfo: FC<ProductAvailabilityInfoProps> = ({
  availabilities,
  className,
  'data-test-id': dataTestId = 'product-availability',
}) => {
  const currentAvailability = useCurrentProductAvailability(availabilities)

  const { t } = useTranslation()

  const ariaLabel = useMemo(() => {
    if (!currentAvailability?.date) return undefined
    const date = toFormattedDate(currentAvailability.date, { weekday: false, year: false })
    /** Remove trailing dot because the translation has ":" right after */
    return t('Low Availability on Date', { date: date.replace(/\.$/, '') })
  }, [currentAvailability, t])

  const isClientSide = useIsClientSide()
  const shouldBeVisible = isBadAvailability(currentAvailability)

  if (!isClientSide || !shouldBeVisible) {
    return null
  }

  return (
    <Popover className={className} content={<p>{ariaLabel}</p>} placement="top">
      <span
        aria-label={ariaLabel}
        data-date={currentAvailability?.date}
        data-score={currentAvailability?.score}
        data-test-id={dataTestId}
      >
        {t('Low Availability')}
      </span>
    </Popover>
  )
}

export const ProductAvailabilityInfo = styled(_ProductAvailabilityInfo)(({ theme }) => ({
  width: 'max-content',
  '> span': {
    ...theme.variants.caption.regular,
    alignItems: 'center',
    backgroundColor: theme.colors.info50,
    borderRadius: 2,
    color: theme.colors.info800,
    cursor: 'help',
    display: 'flex',
    lineHeight: 1,
    padding: `${theme.spacings.xxxSmall} ${theme.spacings.xxSmall}`,
  },
}))
