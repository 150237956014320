import type { ProductCardLoadingProps } from './ProductCardLoading'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { ProductAvailabilityInfo } from 'components/ProductAvailabilityInfo'
import { StyledProductImageContainer } from 'components/ProductImage'
import { ProductLabels } from 'components/ProductLabels'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'

import { _ProductCard, defaultStyles, lessOpacity, ProductDetails } from './ProductCard'
import {
  defaultLoadingStyles,
  DetailsLoader,
  ImageLoader,
  loadingRowStyles,
} from './ProductCardLoading'

/**
 * Default Row Component Styles
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=4358%3A426623
**/
export const rowStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({
  theme,
  disabled,
}) => ({
  gridTemplateColumns: 'min-content 1fr',
  padding: theme.spacings.xSmall,

  [StyledProductImageContainer]: {
    gridColumn: 1,
    gridRow: '1 / span 2',
    justifySelf: 'center',
    width: 82,
    paddingRight: theme.spacings.xxSmall,
    ...lessOpacity(disabled),
  },

  [ProductAvailabilityInfo]: {
    marginBottom: theme.spacings.xxxSmall,
    ...lessOpacity(disabled),
  },

  [ProductDetails]: {
    gridColumn: 2,
    gridRow: '1 / 2',
    width: '100%',
    alignSelf: 'center',
    ...lessOpacity(disabled),
  },

  [ProductLabels]: {
    gridColumn: 2,
    gridRow: 2,
    marginTop: theme.spacings.xxxSmall,
    ...lessOpacity(disabled),

    /** Ensures product-details aren't covered when labels are missing */
    overflow: 'hidden',
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles)

const _RowLoading: FC<ProductCardLoadingProps> = ({
  className,
  'data-test-id': dataTestId = 'product-row-loading',
}) => (
  <article className={className} data-test-id={dataTestId}>
    <ImageLoader />
    <DetailsLoader>
      <SkeletonLoader />
      <SkeletonLoader />
      <SkeletonLoader />
    </DetailsLoader>
  </article>
)

const Loading = styled(_RowLoading)(defaultLoadingStyles, loadingRowStyles)

export const Row = { Loading, Success }
