import type { Either } from 'fp-ts/Either'
import type { ValidationError } from 'validation/validators'

import { isLeft } from 'fp-ts/Either'

export const mapFpTsValidationErrors = <T>(
  maybeErrors: Either<ValidationError[], unknown>,
): Record<keyof T, string> | null =>
  isLeft(maybeErrors)
    ? maybeErrors.left.reduce((errors, issue) => {
        return { ...errors, [issue.path.join('.')]: issue.message }
      }, {} as Record<keyof T, string>)
    : null
