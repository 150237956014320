import type { Product } from '@shared/gql/document-nodes'
import type { FC, ReactNode } from 'react'

import { Popover } from '@sok/design-system'
import Image from 'next/image'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AgeRestricted, Frozen } from './icons/product'
import { LabelCounter } from './icons/product/LabelCounter'

export enum PRODUCT_LABEL_CODES {
  GOODS_FROM_FINLAND_BLUE_SWAN = 'GOODS_FROM_FINLAND_BLUE_SWAN',
  EU_ORGANIC_FARMING = 'EU_ORGANIC_FARMING',
  FINNISH_HEART_SYMBOL = 'FINNISH_HEART_SYMBOL',
  MADE_IN_FINLAND_FLAG_WITH_KEY = 'MADE_IN_FINLAND_FLAG_WITH_KEY',
  SVANEN = 'SVANEN',
  DESIGN_FROM_FINLAND = 'DESIGN_FROM_FINLAND',
  OFFICIAL_ECO_LABEL_SUN = 'OFFICIAL_ECO_LABEL_SUN',
  PUHTAASTI_KOTIMAINEN = 'PUHTAASTI_KOTIMAINEN',
  LAATUVASTUU = 'LAATUVASTUU',
  LABEL_OF_THE_ALLERGY_AND_ASTHMA_FEDERATION = 'LABEL_OF_THE_ALLERGY_AND_ASTHMA_FEDERATION',
}

const PUBLIC_PATH = '/icons/packaging-labels'

const SRC_BY_CODE: Record<PRODUCT_LABEL_CODES, string> = {
  [PRODUCT_LABEL_CODES.GOODS_FROM_FINLAND_BLUE_SWAN]: `${PUBLIC_PATH}/hyvaa-suomesta.svg`,
  [PRODUCT_LABEL_CODES.EU_ORGANIC_FARMING]: `${PUBLIC_PATH}/luomu.svg`,
  [PRODUCT_LABEL_CODES.FINNISH_HEART_SYMBOL]: `${PUBLIC_PATH}/sydanmerkki.svg`,
  [PRODUCT_LABEL_CODES.MADE_IN_FINLAND_FLAG_WITH_KEY]: `${PUBLIC_PATH}/avainlippu.svg`,
  [PRODUCT_LABEL_CODES.SVANEN]: `${PUBLIC_PATH}/joutsenmerkki.svg`,
  [PRODUCT_LABEL_CODES.DESIGN_FROM_FINLAND]: `${PUBLIC_PATH}/design-from-finland.svg`,
  [PRODUCT_LABEL_CODES.OFFICIAL_ECO_LABEL_SUN]: `${PUBLIC_PATH}/luomu-aurinko.svg`,
  [PRODUCT_LABEL_CODES.PUHTAASTI_KOTIMAINEN]: `${PUBLIC_PATH}/sirkkalehti.svg`,
  [PRODUCT_LABEL_CODES.LAATUVASTUU]: `${PUBLIC_PATH}/laatuvastuu.svg`,
  [PRODUCT_LABEL_CODES.LABEL_OF_THE_ALLERGY_AND_ASTHMA_FEDERATION]: `${PUBLIC_PATH}/allergia.svg`,
}

const isValidLabelCode = (x: string): x is keyof typeof SRC_BY_CODE => x in SRC_BY_CODE

export type LabelsProduct = Pick<
  Product,
  'isAgeLimitedByAlcohol' | 'frozen' | 'packagingLabelCodes'
>

export interface Props {
  className?: string
  'data-test-id'?: string
  disabled?: boolean
  product: LabelsProduct
  size?: number
  truncate?: boolean
}

const _ProductLabels: FC<Props> = ({
  className,
  product,
  size,
  ['data-test-id']: dataTestId,
  truncate,
}) => {
  const { t } = useTranslation()

  const labels = useMemo(
    () =>
      [
        product.isAgeLimitedByAlcohol && (
          <figure
            aria-label={t(`This product is forbidden`, { age: 18 })}
            title={t(`This product is forbidden`, { age: 18 })}
          >
            <AgeRestricted age={18} size={size} />
          </figure>
        ),
        product.frozen && (
          <figure aria-label={t`This product is frozen`} title={t`This product is frozen`}>
            <Frozen size={size} />
          </figure>
        ),
        product.packagingLabelCodes
          ? product.packagingLabelCodes
              .map((s) => s.trim())
              .filter(isValidLabelCode)
              .map((code) => <PackageLabelImage key={code} code={code} size={size} />)
          : false,
      ]
        .filter(Boolean)
        .flatMap((packagingLabels: ReactNode) => packagingLabels),
    [product.frozen, product.isAgeLimitedByAlcohol, product.packagingLabelCodes, size, t],
  )

  if (!labels.length) return null

  // Conditions: Always show 4 labels and truncate afterwards with a PopOver
  const LABEL_LIMIT = 4

  if (truncate && labels.length > LABEL_LIMIT) {
    const first = labels.slice(0, LABEL_LIMIT - 1)
    const remaining = labels.slice(LABEL_LIMIT - 1, labels.length)

    return (
      <ul className={className} data-test-id={dataTestId}>
        {first.map((d, i) => (
          <li key={i}>{d}</li>
        ))}
        <li>
          <Popover
            content={<Content data-test-id="popover-content">{remaining}</Content>}
            placement="bottomRight"
          >
            <LabelCounter value={remaining.length} size={size} />
          </Popover>
        </li>
      </ul>
    )
  }

  return (
    <ul className={className} data-test-id={dataTestId}>
      {labels.map((label, i) => (
        <li key={i}>{label}</li>
      ))}
    </ul>
  )
}

_ProductLabels.displayName = 'ProductLabels'

/**
 * A list of label icons to be shown for a product.
 * For example, "Lactose-free" or "Frozen".
 */
export const ProductLabels = styled(_ProductLabels)(({ disabled, theme, size }) => ({
  display: 'flex',
  filter: disabled ? 'grayscale(0.5)' : undefined,
  listStyle: 'none',
  willChange: 'transform' /** Safari visual bugs when hovering on `ProductCard` */,
  gap: theme.spacings.xxxSmall,

  li: {
    maxWidth: size,
    maxHeight: size,

    '> img': {
      display: 'block',
    },

    '> span': {
      display: 'block',
    },

    '> figure': {
      display: 'block',
      maxWidth: size,
      maxHeight: size,
    },
  },
}))

ProductLabels.defaultProps = {
  size: 24,
}

interface PackageLabelImageProps {
  code: PRODUCT_LABEL_CODES
  size?: number
}

const PackageLabelImage: FC<PackageLabelImageProps> = ({ size, code }) => {
  const { t } = useTranslation()

  return (
    <Image
      alt={t(code)}
      height={size}
      key={code}
      loading="lazy"
      role="figure"
      src={SRC_BY_CODE[code]}
      title={t(code)}
      width={size}
      unoptimized
    />
  )
}

const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacings.xxSmall,
}))
