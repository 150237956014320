import type { Price } from 'domain/price'
import type { FC } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface DepositPriceProps {
  price: Price
  className?: string
  'data-test-id'?: string
}

const _DepositPrice: FC<DepositPriceProps> = ({
  className,
  price,
  'data-test-id': dataTestId = 'deposit-price',
}) => {
  const { t } = useTranslation()

  return (
    <span className={className} data-test-id={dataTestId}>
      {t('Includes deposit {{price}}', { price: price.value.format() })}
    </span>
  )
}

_DepositPrice.displayName = 'DepositPrice'

export const DepositPrice = styled(_DepositPrice)(
  ({ theme }) => theme.variants.caption.regular,
  ({ theme }) => ({
    color: theme.colors.grey600,
  }),
)
