import type { IServiceBannerFields } from 'types/contentful/generated'

import { trackGA4CustomEvent } from 'utils/tracking/custom-events/custom-events'

export const trackServiceBannerClick = ({
  name,
  bannerType,
}: Pick<IServiceBannerFields, 'name' | 'bannerType'>): void => {
  trackGA4CustomEvent({
    eventName: `${bannerType}-servicebanner-click`,
    eventParams: { content_type: name },
  })
}

export const trackServiceBannerClose = ({
  name,
  bannerType,
}: Pick<IServiceBannerFields, 'name' | 'bannerType'>): void => {
  trackGA4CustomEvent({
    eventName: `${bannerType}-servicebanner-close`,
    eventParams: { content_type: name },
  })
}
