import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import styled, { css } from 'styled-components'

import { ContentfulImage } from '../ContentfulImage'

interface ImageWithDescriptionProps {
  section: ISection
}

interface ImageDescriptionProps {
  fontColor?: string
}

const StyledImageContainer = styled.div`
  width: 100%;
  margin-bottom: 48px;
`
const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 832px;
  margin: 0px auto;
`

const StyledImageDescription = styled.div<ImageDescriptionProps>`
  ${({ theme }) => css(theme.variants.body1.regular)};
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  color: ${({ theme, fontColor }) => (fontColor ? fontColor : theme.color.fontBlack)};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px 16px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px 8px;
  }
`

export const ImageWithDescription: FC<ImageWithDescriptionProps> = ({ section }) => {
  const { cards } = section.fields
  if (cards && cards.length > 0) {
    const card = cards[0]
    const { body: imageDescription, image, fontColor } = card.fields
    return (
      <StyledImageContainer>
        <StyledImageWrapper>
          <StyledContentfulImage image={image} lazy />
          <StyledImageDescription fontColor={fontColor}>{imageDescription}</StyledImageDescription>
        </StyledImageWrapper>
      </StyledImageContainer>
    )
  }
  return <></>
}

const StyledContentfulImage = styled(ContentfulImage)({
  objectFit: 'cover',
  objectPosition: '0 0',
  maxWidth: '100%',
})
