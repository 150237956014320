import type { FC } from 'react'

import { TextBlock } from 'components/common/Titles'
import React from 'react'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { StyledContentGridItem, StyledItemTitle, StyledTextContent } from './styled'

interface TextOnlyBlockProps {
  body: string
  title?: string
}

export const TextOnlyBlock: FC<TextOnlyBlockProps> = ({ body, title }) => {
  const parsedBody = useParsedMarkdown(body)
  return (
    <StyledContentGridItem className="content-grid-item">
      <StyledTextContent>
        {title && <StyledItemTitle>{title}</StyledItemTitle>}
        <TextBlock>{parsedBody}</TextBlock>
      </StyledTextContent>
    </StyledContentGridItem>
  )
}
