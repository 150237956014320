import type { ThemedObjectStyleFunction } from 'domain/styled'

import { minWidthFromTheme } from '@sok/design-system'
import { ProductLabels } from 'components/ProductLabels'
import styled from 'styled-components'
import { smallMobile } from 'styles/layout'

import { FavoriteBtn } from './ExtraActions'
import {
  _ProductCard,
  defaultStyles,
  ExtraActionsContainer,
  lessOpacity,
  ProductDetails,
} from './ProductCard'
import { QuantityModifier } from './QuantityModifier'
import { rowStyles } from './Row'

/* Modified Row Component Styles for Recipe Products
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5901%3A178950
**/
const recipeStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({ theme, disabled }) => ({
  borderBottom: 0,
  gridTemplateRows: 'auto min-content 1fr',
  gridTemplateColumns: 'min-content 1fr min-content',
  [smallMobile]: {
    gridTemplateColumns: 'min-content 1fr auto min-content',
  },

  [ProductDetails]: {
    gridColumn: '2 / 3',

    [smallMobile]: {
      gridColumn: '2 / 4',
    },
  },

  [ProductLabels]: {
    gridRow: 2,
    gridColumn: '2 / 4',
    alignSelf: 'center',

    li: {
      '& + li > div': {
        flexDirection: 'row',
      },
    },

    [smallMobile]: {
      gridColumn: '2 / 3',
    },
  },

  [QuantityModifier]: {
    ...lessOpacity(disabled),
    gridRow: 3,
    gridColumn: '2 / 4',
    marginTop: theme.spacings.xSmall,

    /*
        Fills up the extra space
        Note: This will be removed once quantity modifier with animation is implemented
      */
    [smallMobile]: {
      marginTop: 0,
      gridRow: 2,
      gridColumn: '3 / 5',
      justifySelf: 'end',
      alignSelf: 'center',
    },
  },

  [FavoriteBtn]: {
    gridColumn: 3,
    gridRow: '1 / 2',
    justifySelf: 'end',
    alignSelf: 'start',
    ...lessOpacity(disabled),

    [smallMobile]: {
      gridColumn: '4 / 5',
    },
  },

  [ExtraActionsContainer]: {
    gridColumn: '1 / 5',
    gridRow: 4,
    margin: `0 ${theme.spacings.xSmall}`,

    [minWidthFromTheme(theme).tablet]: {
      gridRow: '3 / 5',
    },
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, recipeStyles)

export const Recipe = { Success }
