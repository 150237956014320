import type { Maybe } from '@shared/gql/document-nodes'
import type { ImageProps } from 'next/image'
import type { FC } from 'react'
import type { WithTestId } from 'types/global'

import Image from 'next/image'
import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { sCloudProductImageLoader } from 'utils/next-image-loaders'

type PassedProps = Pick<
  ImageProps,
  'className' | 'layout' | 'loading' | 'objectFit' | 'objectPosition' | 'sizes'
>

interface ProductImageProps extends WithTestId, PassedProps {
  'data-test-id'?: string
  brand?: Maybe<string>
  disabled?: boolean
  ean: Maybe<string>
  height?: number
  productName?: Maybe<string>
  quality?: number
  width: number
}

const _ProductImage: FC<ProductImageProps> = ({
  className,
  brand: _brand,
  disabled: _disabled,
  ean,
  productName,
  quality,
  width,
  height = width,
  ...rest
}) => {
  const alt = productName || undefined
  const theme = useTheme()
  const [error, setError] = useState(!ean)

  const fallback = error || !ean

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      alt={alt}
      className={className}
      height={height}
      loader={sCloudProductImageLoader}
      onErrorCapture={() => setError(true)}
      quality={quality}
      referrerPolicy="strict-origin"
      src={fallback ? theme.logos.missingProductImage : ean}
      unoptimized={fallback}
      width={width}
    />
  )
}

export const ProductImage = styled(_ProductImage)(
  { flexShrink: 1 },
  ({ disabled }) => disabled && { filter: 'grayscale(0.5)' },
)

ProductImage.defaultProps = {
  objectFit: 'contain',
  quality: 75,
}

export const StyledProductImageContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
})
