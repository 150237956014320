import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { readableColor } from 'polished'
import styled from 'styled-components'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

interface ContainerProps {
  backgroundColor: string
}

const StyledNotificationContainer = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: 16px;
  color: ${({ backgroundColor, theme }) =>
    readableColor(backgroundColor, theme.color.fontBlack, theme.color.white)};
  a {
    text-decoration: underline;
    color: inherit;
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    /* Hide external link icon because it looks visually messy with the underline text decoration */
    .icon {
      display: none;
    }
  }
  img {
    vertical-align: middle;
    margin: 0 8px;
  }
`

const StyledNotificationBody = styled.div(({ theme }) => theme.variants.body2.regular)

export const NotificationBanner: FC<{
  section: ISection
}> = ({ section }) => {
  const { body, backgroundColor } = section.fields
  const parsedSectionBody = useParsedMarkdown(body)
  if (body && backgroundColor) {
    return (
      <StyledNotificationContainer backgroundColor={backgroundColor}>
        <StyledNotificationBody>{parsedSectionBody}</StyledNotificationBody>
      </StyledNotificationContainer>
    )
  }
  return null
}
