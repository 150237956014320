import type { FC } from 'react'
import type { CitrusAdBannerData } from 'types/citrusad'

import { useHasServiceConsent } from '@s-group/react-usercentrics'
import { ServiceId } from 'config/usercentrics'
import { getValidatedUrl } from 'domain/link-validation'
import { useCallback } from 'react'
import { useCitrusAdUserEvent } from 'services/CitrusAd/hooks/use-citrusad-user-event'
import styled from 'styled-components'
import { CitrusAdSlot } from 'types/citrusad'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { useContentImpressionTracking } from 'utils/tracking/hooks/use-content-impression-tracking'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { ArticleCardInnerContent } from '../contentful-section/article-grid/ArticleGridCard'
import { HeroCarouselSlide } from '../contentful-section/hero-carousel/HeroCarouselSlide'

interface CitrusAdBannerProps {
  className?: string
  citrusAdBannerData: CitrusAdBannerData
}

const getPromotionPosition = (
  slotId: CitrusAdBannerData['slotId'],
):
  | PromotionPosition.FRONTPAGE_HERO_CAROUSEL_2
  | PromotionPosition.FRONTPAGE_HERO_CAROUSEL_4
  | PromotionPosition.ARTICLE_GRID_PREFIX_
  | undefined => {
  if (slotId === CitrusAdSlot.HOME_HERO_CAROUSEL_2) {
    return PromotionPosition.FRONTPAGE_HERO_CAROUSEL_2
  }
  if (slotId === CitrusAdSlot.HOME_HERO_CAROUSEL_4) {
    return PromotionPosition.FRONTPAGE_HERO_CAROUSEL_4
  }
  if (slotId === CitrusAdSlot.HOME_MIDDLE) {
    return `${PromotionPosition.ARTICLE_GRID_PREFIX_}2` as PromotionPosition.ARTICLE_GRID_PREFIX_
  }
  return undefined
}

const getCitrusAdImageUrl = (link: string | null): string => {
  if (!link) return ''
  const id = link.split('/').pop()
  return `/api/lemon/${id}`
}

const _CitrusAdBannerX: FC<CitrusAdBannerProps> = ({ className, citrusAdBannerData }) => {
  const hasCitrusAdConsent = useHasServiceConsent(ServiceId.CitrusAd)
  const { sessionId, banner, slotId } = citrusAdBannerData
  const { sendCitrusAdUserImpressionEvent, sendCitrusAdUserClickEvent } = useCitrusAdUserEvent()
  const promotionPosition = getPromotionPosition(slotId)

  const trackBannerImpression = useCallback(
    (type: 'view_promotion_ca_iab' | 'view_promotion_ca', sendToCitrusAd?: boolean) => () => {
      if (!banner || !sessionId) return
      if (promotionPosition) {
        internalPromotion(
          promotionPosition,
          banner.citrusCampaignName ?? undefined,
          type,
          banner.ctaLink ?? undefined,
          'Sponsored',
          banner.citrusAdId,
        )
      }
      if (sendToCitrusAd) {
        sendCitrusAdUserImpressionEvent(banner.citrusAdId)
      }
    },
    [promotionPosition, banner, sessionId, sendCitrusAdUserImpressionEvent],
  )
  const regularRef = useContentImpressionTracking(
    trackBannerImpression('view_promotion_ca'),
    `regular_${banner?.citrusAdId}`,
  )
  const iabRef = useContentImpressionTracking(
    trackBannerImpression('view_promotion_ca_iab', true),
    `iab_${banner?.citrusAdId}`,
    true,
  )
  const setRefs = useCallback(
    (node: HTMLDivElement) => {
      regularRef.current = node
      iabRef.current = node
    },
    [regularRef, iabRef],
  )

  const handleBannerClick = useCallback(() => {
    if (banner && banner.citrusAdId) {
      if (promotionPosition) {
        internalPromotion(
          promotionPosition,
          banner.citrusCampaignName ?? undefined,
          'select_promotion_ca',
          banner.ctaLink ?? undefined,
          'Sponsored',
          banner.citrusAdId,
        )
      }

      sendCitrusAdUserClickEvent(banner.citrusAdId)
    }
  }, [banner, sendCitrusAdUserClickEvent, promotionPosition])

  if (!banner) return null
  const url = banner.ctaLink ? getValidatedUrl(banner.ctaLink) : ''

  const imageUrl = hasCitrusAdConsent
    ? banner.backgroundImage
    : getCitrusAdImageUrl(banner.backgroundImage)
  const logoImageUrl = hasCitrusAdConsent ? banner.heroImage : getCitrusAdImageUrl(banner.heroImage)

  return (
    <div className={className} ref={setRefs}>
      {slotId === CitrusAdSlot.HOME_HERO_CAROUSEL_2 ||
      slotId === CitrusAdSlot.HOME_HERO_CAROUSEL_4 ? (
        <HeroCarouselSlide.Card
          cta={{
            url,
            label: banner.ctaText ?? '',
            handleClick: handleBannerClick,
          }}
          tracking={null}
        >
          <HeroCarouselSlide.Image
            image={{
              url: imageUrl ?? '',
              title: banner.ctaText ?? '',
              alt: banner.ctaText ?? '',
            }}
          />
          <HeroCarouselSlide.LogoImage imageUrl={logoImageUrl ?? ''} />
          <HeroCarouselSlide.Label label="Sponsored" />
          <HeroCarouselSlide.CTALabel />
        </HeroCarouselSlide.Card>
      ) : null}
      {slotId === CitrusAdSlot.HOME_MIDDLE ? (
        <ArticleCardInnerContent
          handleClick={handleBannerClick}
          cta={{
            label: banner.ctaText || '',
            url,
            newTab: false,
          }}
          title={banner.headingText ?? ''}
          body={banner.bannerText ?? ''}
          image={{
            url: imageUrl || '',
            alt: banner.headingText || '',
          }}
        />
      ) : null}
    </div>
  )
}

export const CitrusAdBannerX = styled(_CitrusAdBannerX)({
  width: '100%',
  height: '100%',
})
