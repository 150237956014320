import { motion } from 'framer-motion'
import styled from 'styled-components'

export const MenuOverlay = styled(motion.div)<{ $isVisible: boolean }>(({ theme, $isVisible }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,

  height: '100vh',

  backgroundColor: theme.colors.SDS_BRAND_COLOR_BACKGROUND_STRONGER_GREY,
  opacity: $isVisible ? '0.65' : 0,
  pointerEvents: $isVisible ? 'initial' : 'none',

  transition: 'opacity 200ms',
}))
