import { createNavigationIconFactory } from './create-navigation-icon'

const createNavigationIcon = createNavigationIconFactory('skaupat')

export const SRecommended = createNavigationIcon('SRecommended', 'recommended')

export const SCounterProducts = createNavigationIcon('SCounterProducts', 'counter-products')

export const SFruitsAndVegetables = createNavigationIcon(
  'SFruitsAndVegetables',
  'fruits-vegetables',
)

export const SBreadsBiscuitsPastries = createNavigationIcon(
  'SBreadsBiscuitsPastries',
  'breads-biscuits-pastries',
)

export const SMeatVegetableProtein = createNavigationIcon(
  'SMeatVegetableProtein',
  'meat-vegetable-protein',
)

export const SFishAndSeaFood = createNavigationIcon('SFishAndSeaFood', 'fish-and-seafood')

export const SMilkEggsFats = createNavigationIcon('SMilkEggsFats', 'milk-eggs-fats')

export const SCheese = createNavigationIcon('SCheese', 'cheese')

export const SReadyFood = createNavigationIcon('SReadyFood', 'ready-food')

export const SOilSpicesSeasoning = createNavigationIcon(
  'SOilSpicesSeasoning',
  'oil-spices-seasoning',
)

export const SDryProductsAndBaking = createNavigationIcon(
  'SDryProductsAndBaking',
  'dry-products-baking',
)

export const SDrinks = createNavigationIcon('SDrinks', 'drinks')

export const SFrozenGoods = createNavigationIcon('SFrozenGoods', 'frozen-goods')

export const SSweetsAndSnacks = createNavigationIcon('SSweetsAndSnacks', 'sweets-snacks')

export const SNaturalProductsAndSupplements = createNavigationIcon(
  'SNaturalProductsAndSupplements',
  'natural-products-supplements',
)

export const SChildren = createNavigationIcon('SChildren', 'children')

export const SPets = createNavigationIcon('SPets', 'pets')

export const SHygieneAndHome = createNavigationIcon('SHygieneAndHome', 'hygiene-home')

export const SPartyAndCatering = createNavigationIcon('SPartyAndCatering', 'party-catering')

export const SHygieneAndCosmetics = createNavigationIcon('SHygieneAndCosmetic', 'hygiene-cosmetics')

export const SKitchenware = createNavigationIcon('SKitchenware', 'kitchenware')

export const SArtCrafts = createNavigationIcon('SArtCrafts', 'art-crafts')

export const SChristmas = createNavigationIcon('SChristmas', 'christmas')

export const SNewProducts = createNavigationIcon('SNewProducts', 'new-products')

export const SCleaning = createNavigationIcon('SCleaning', 'cleaning')

export const SHomeTextile = createNavigationIcon('SHomeTextile', 'home-textile')

export const SHomeAppliance = createNavigationIcon('SHomeAppliance', 'home-appliance')

export const SSports = createNavigationIcon('SSports', 'sports')

export const SPlaceholder = createNavigationIcon('SPlaceholder', 'placeholder')
