import type { Brand } from '@shared/domain/brand'
import type { FC, ReactNode } from 'react'

import { IconControlLocation } from '@s-group/design-system-icons'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { BrandStoreLogo } from 'components/BrandStoreLogo'
import { DeliverySelectionStatus } from 'components/icons/DeliverySelectionStatus'
import { toDateTime, toFormattedDate, toFormattedTime } from 'domain/date-time'
import { useTranslation } from 'react-i18next'
import {
  openSelectDeliveryMethodAndStoreModal,
  openSelectDeliverySlotModal,
} from 'services/Delivery/cache'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'
import { capitalize } from 'utils/common/capitalize'
import { getDeliveryMethodString } from 'utils/delivery/get-delivery-method-string'
import { trackDeliverySelectClick } from 'utils/tracking/navigation'

import { NavigationActionButton } from '../../NavigationActions'
import {
  BrandLogoContainer,
  ButtonContents,
  ButtonContentWrapper,
  CTALabel,
  DeliveryAreaInfo,
  DeliverySlotInfo,
  DeliveryTime,
  ErrorLabel,
  Spacer,
} from './styled'

const DeliveryAreaLabel: FC<{
  brand: Brand | null
  storeName: string | null
  isFastTrack: boolean
  deliveryMethod: DeliveryMethod
}> = ({ brand, storeName, deliveryMethod, isFastTrack }) => {
  const { t } = useTranslation()

  const deliveryMethodLabel = t(getDeliveryMethodString(deliveryMethod, isFastTrack))

  const ariaLabel =
    deliveryMethod === DeliveryMethod.HomeDelivery
      ? `${deliveryMethodLabel}: ${brand} ${storeName}.`
      : `${deliveryMethodLabel}: ${storeName}.`

  return (
    <DeliveryAreaInfo aria-label={ariaLabel}>
      <span data-test-id="selected-method">{deliveryMethodLabel}: </span>
      <span data-test-id="selected-store">{storeName}</span>
    </DeliveryAreaInfo>
  )
}

const DeliveryTimeLabel: FC<{
  startDateTime: string
  endDateTime: string
}> = ({ startDateTime, endDateTime }) => {
  const { t } = useTranslation()

  return (
    <DeliverySlotInfo data-test-id="selected-delivery-time">
      <time dateTime={toDateTime(startDateTime).toISODate() ?? ''}>
        {capitalize(toFormattedDate(startDateTime, { year: false }))}{' '}
      </time>
      <DeliveryTime>
        {t('At time')} <time dateTime={startDateTime}>{toFormattedTime(startDateTime)}</time>-
        <time dateTime={endDateTime}>{toFormattedTime(endDateTime)}</time>
      </DeliveryTime>
    </DeliverySlotInfo>
  )
}

const _SelectedBtn: FC<{
  className?: string
  onClick: () => void
  brand: Brand | null
  statusIcon: ReactNode
  children: ReactNode
}> = ({ className, onClick, brand, statusIcon, children }) => {
  const handleClick = () => {
    onClick()
    trackDeliverySelectClick()
  }

  return (
    <NavigationActionButton
      className={className}
      data-test-id="delivery-select"
      onClick={handleClick}
    >
      <ButtonContents>
        {brand && (
          <>
            <BrandLogoContainer aria-hidden="true">
              <BrandStoreLogo brand={brand} />
            </BrandLogoContainer>
            <Spacer />
          </>
        )}
        {statusIcon}
        <ButtonContentWrapper>{children}</ButtonContentWrapper>
      </ButtonContents>
    </NavigationActionButton>
  )
}

const SelectedBtn = styled(_SelectedBtn)(({ theme }) => ({
  border: `1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY}`,
  textAlign: 'left',
  flexFlow: 'row',
}))

const FastTrackDeliveryButton: FC<{
  className?: string
  brand: Brand | null
  nextSlotLabel: string | null
  storeName: string | null
  deliveryMethod: DeliveryMethod
  isSlotClosed: boolean
  isSlotUnavailable: boolean
}> = ({
  className,
  brand,
  nextSlotLabel,
  storeName,
  deliveryMethod,
  isSlotClosed,
  isSlotUnavailable,
}) => {
  return (
    <SelectedBtn
      className={className}
      brand={brand}
      onClick={openSelectDeliveryMethodAndStoreModal}
      statusIcon={
        <DeliverySelectionStatus
          deliveryMethod={deliveryMethod}
          status={(isSlotClosed && 'closed') || (isSlotUnavailable && 'alert') || 'complete'}
        />
      }
    >
      <DeliveryAreaLabel
        brand={brand}
        storeName={storeName}
        deliveryMethod={deliveryMethod}
        isFastTrack
      />

      {isSlotClosed || isSlotUnavailable ? (
        <ErrorLabel>{nextSlotLabel}</ErrorLabel>
      ) : (
        <CTALabel data-test-id="select-slot-cta">{nextSlotLabel}</CTALabel>
      )}
    </SelectedBtn>
  )
}

const DeliveryButton: FC<{
  className?: string
  brand: Brand | null
  startDateTime: string | null
  endDateTime: string | null
  nextSlotLabel: string | null
  storeName: string | null
  deliveryMethod: DeliveryMethod
  isSlotExpired: boolean
}> = ({
  className,
  brand,
  startDateTime,
  endDateTime,
  nextSlotLabel,
  storeName,
  deliveryMethod,
  isSlotExpired,
}) => {
  const { t } = useTranslation()

  const hasDeliveryDate = startDateTime && endDateTime

  return (
    <SelectedBtn
      className={className}
      onClick={openSelectDeliverySlotModal}
      brand={brand}
      statusIcon={
        <DeliverySelectionStatus
          deliveryMethod={deliveryMethod}
          status={hasDeliveryDate ? 'complete' : 'incomplete'}
        />
      }
    >
      <DeliveryAreaLabel
        brand={brand}
        storeName={storeName}
        deliveryMethod={deliveryMethod}
        isFastTrack={false}
      />

      {hasDeliveryDate ? (
        <DeliveryTimeLabel startDateTime={startDateTime} endDateTime={endDateTime} />
      ) : (
        <CTALabel data-test-id="select-slot-cta">
          {isSlotExpired ? t('Reservation expired') : nextSlotLabel}
        </CTALabel>
      )}
    </SelectedBtn>
  )
}

const _UnselectedBtn: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    openSelectDeliveryMethodAndStoreModal()
    trackDeliverySelectClick()
  }

  return (
    <NavigationActionButton
      className={className}
      data-test-id="delivery-select"
      icon={<IconControlLocation />}
      onClick={handleClick}
      plain={false}
    >
      {t('Select delivery method')}
    </NavigationActionButton>
  )
}

const StoreSelectButton = styled(_UnselectedBtn)(({ theme }) => ({
  color: theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
  whiteSpace: 'normal',
  flexFlow: 'row',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textAlign: 'left',

  ['.button-icon']: {
    display: 'none',
  },

  [customMinWidthFromTheme(theme).mobile]: {
    ['.button-icon']: {
      display: 'inline-flex',
    },
  },
}))

export const DeliverySelectButton = {
  FastTrackDeliveryButton,
  DeliveryButton,
  StoreSelectButton,
}
