import type { ApolloError } from '@apollo/client'

import { useQuery } from '@apollo/client'
import { GetBonusInfoDataDocument } from '@shared/gql/document-nodes'

import { useIsLoggedIn } from './use-is-logged-in'

interface MembershipNumber {
  data: string | null
  loading: boolean
  error: ApolloError | null
}

export const useMembershipNumber = (): MembershipNumber => {
  const isLoggedIn = useIsLoggedIn()
  const { data, loading, error } = useQuery(GetBonusInfoDataDocument, {
    skip: !isLoggedIn,
    ssr: false,
  })

  return {
    data: data?.bonusInfo?.membershipNumber || null,
    loading,
    error: error || null,
  }
}
