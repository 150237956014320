import type { SlideContainerProps } from './SlideContainer'
import type { PanInfo } from 'framer-motion'
import type { FC, ReactElement } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconNavigationArrowLeft, IconNavigationArrowRight } from '@s-group/design-system-icons'
import {
  SDS_SPACE_XSMALL,
  SDS_SPACE_XXXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'
import { motion, useAnimationControls } from 'framer-motion'
import { Children, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'
import { useCarouselGridCount } from 'utils/hooks/content-carousel/use-carousel-grid-count'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { SlideContainer } from './SlideContainer'
import * as Carousel from './utils/carousel'

interface IContentCarouselProps {
  id: string
  children: ReactElement<SlideContainerProps>[]
  infiniteLoop?: boolean
  featured?: boolean
  carouselAriaLabel: string
}

export const ContentCarousel: FC<IContentCarouselProps> = ({
  id,
  children,
  infiniteLoop,
  featured,
  carouselAriaLabel,
}) => {
  const containerRef = useRef<HTMLUListElement>(null)
  const autoScrollIntervalId = useRef<undefined | number>(undefined)
  const animationControl = useAnimationControls()
  const isClientSide = useIsClientSide()
  const [gridCount, updateGridCount] = useCarouselGridCount()

  const [hasFeaturedStyle, setHasFeaturedStyle] = useState<boolean | undefined>(false)
  const [currentGridIndex, setCurrentGridIndex] = useState<number>(0)
  const [interactionDisabled, setInteractionDisabled] = useState<boolean>(false)
  const [interacted, setInteracted] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const length = useMemo(() => Children.count(children), [children])
  const slideWidth = useMemo(() => (gridCount ? 100 / gridCount : null), [gridCount])
  const hasPrevious = useMemo(
    () => infiniteLoop || currentGridIndex > 0,
    [currentGridIndex, infiniteLoop],
  )
  const hasNext = useMemo(
    () => gridCount && (infiniteLoop || currentGridIndex + gridCount < length),
    [currentGridIndex, infiniteLoop, gridCount, length],
  )
  const startAnimation = useCallback(() => {
    if (!slideWidth) return
    return animationControl.start({
      x: `${-currentGridIndex * slideWidth}%`,
    })
  }, [animationControl, currentGridIndex, slideWidth])

  const nextItem = () => {
    if (!gridCount) return
    setInteractionDisabled(true)
    setCurrentGridIndex(Carousel.nextIndex(currentGridIndex, gridCount, length, hasFeaturedStyle))
  }

  const previousItem = () => {
    if (!gridCount) return
    setInteractionDisabled(true)
    setCurrentGridIndex(Carousel.prevIndex(currentGridIndex, gridCount, length, hasFeaturedStyle))
  }

  const initiateCarousel = useCallback(() => {
    const newGridCount = Carousel.getGridCount(window.innerWidth)
    const featuredStyle =
      featured && window.innerWidth >= Carousel.CAROUSEL_BREAKPOINTS.middleDesktop
    if (newGridCount !== gridCount || featuredStyle !== hasFeaturedStyle) {
      updateGridCount(newGridCount)
      setHasFeaturedStyle(featuredStyle)
      setCurrentGridIndex(Carousel.getInitialGridIndex(newGridCount, infiniteLoop))
      animationControl.stop()
      animationControl.set({ x: infiniteLoop ? '-200%' : 0 })
    }
  }, [animationControl, featured, gridCount, hasFeaturedStyle, updateGridCount, infiniteLoop])

  /**
   * Animation start whenever currentGridIndex is changed
   */
  useEffect(() => {
    if (!gridCount) return
    if (currentGridIndex) {
      startAnimation()
    }
  }, [startAnimation, gridCount, currentGridIndex])

  /**
   * Reinitiate if children set changes
   */
  useEffect(() => {
    initiateCarousel()
  }, [children, initiateCarousel])

  /**
   * Detect resizing of the element
   */
  useEffect(() => {
    const element = containerRef.current
    if (!element || !window.ResizeObserver) return
    const observer = new ResizeObserver(() => {
      if (autoScrollIntervalId.current) {
        window.clearInterval(autoScrollIntervalId.current)
      }
      initiateCarousel()
    })
    observer.observe(element)
    return () => {
      return void observer.disconnect()
    }
  }, [animationControl, initiateCarousel, infiniteLoop])

  /**
   * Autoscrolling
   */
  useEffect(() => {
    if (interacted && autoScrollIntervalId.current) {
      window.clearInterval(autoScrollIntervalId.current)
      return
    }
    if (!interacted && gridCount) {
      autoScrollIntervalId.current = window.setInterval(() => {
        if (infiniteLoop && window.document.hasFocus()) {
          setCurrentGridIndex((prevState) => {
            if (!prevState) return prevState
            return Carousel.nextIndex(prevState, gridCount, length, hasFeaturedStyle)
          })
        }
      }, 4000)
    }
    return () => window.clearInterval(autoScrollIntervalId.current)
  }, [infiniteLoop, gridCount, interacted, length, hasFeaturedStyle])

  const extraPreviousItems = useMemo(
    () => Carousel.getExtraPreviousItems(id, children, gridCount),
    [id, children, gridCount],
  )

  const extraNextItems = useMemo(
    () => Carousel.getExtraNextItems(id, children, gridCount),
    [id, children, gridCount],
  )

  const handleAnimationEnd = () => {
    if (!gridCount || !slideWidth) return
    setInteractionDisabled(false)
    if (infiniteLoop) {
      if (currentGridIndex <= gridCount) {
        // Stop animation and explicitly set transform value for infinite effect - previous
        const toIndex = hasFeaturedStyle ? currentGridIndex + length + 1 : currentGridIndex + length
        animationControl.stop()
        animationControl.set({
          x: `-${toIndex * slideWidth}%`,
        })
        setCurrentGridIndex(toIndex)
      } else if (currentGridIndex >= length + 2 * gridCount) {
        // Stop animation and explicitly set transform value for infinite effect - next
        const toIndex = hasFeaturedStyle ? currentGridIndex - length - 1 : currentGridIndex - length
        animationControl.stop()
        animationControl.set({ x: `-${toIndex * slideWidth}%` })
        setCurrentGridIndex(toIndex)
      }
    }
  }

  const handleDragEnd = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    setIsDragging(false)
    if (!gridCount || !containerRef.current) return
    const swipeIndex = Carousel.swipePower(info.offset.x)
    const indexChange = (info.offset.x * gridCount) / containerRef.current.clientWidth
    if (info.offset.x < 0) {
      swipeIndex > Carousel.swipeConfidenceThreshold
        ? setCurrentGridIndex(Math.ceil(currentGridIndex - indexChange))
        : startAnimation()
    }
    if (info.offset.x > 0) {
      swipeIndex > Carousel.swipeConfidenceThreshold
        ? setCurrentGridIndex(Math.floor(currentGridIndex - indexChange))
        : startAnimation()
    }
  }

  const handleDragStart = () => {
    setIsDragging(true)
    setInteracted(true)
    setInteractionDisabled(true)
  }

  // requirement for carousel to work not satified
  if (gridCount && length < gridCount) {
    return null
  }
  return (
    <CarouselWrapper
      id={id}
      role="region"
      aria-roledescription="carousel"
      aria-label={carouselAriaLabel}
      aria-live="polite"
      isDragging={isDragging}
      onMouseEnter={() => {
        setInteracted(true)
      }}
      onMouseLeave={() => {
        setInteracted(false)
      }}
    >
      <div className="wrapper">
        <div className="carousel-wrapper">
          <motion.ul
            ref={containerRef}
            drag="x"
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            dragListener={!interactionDisabled && !!infiniteLoop}
            className="carousel-content"
            initial={false}
            animate={animationControl}
            onAnimationComplete={handleAnimationEnd}
            transition={{
              x: interacted
                ? { type: 'spring', stiffness: 230, damping: 30 }
                : { type: 'spring', stiffness: 30, damping: 10 },
            }}
          >
            {isClientSide && infiniteLoop && extraPreviousItems.map((item) => item)}
            {Children.map(children, (item) => cloneElement(item, { gridCount }))}
            {isClientSide && infiniteLoop && extraNextItems.map((item) => item)}
          </motion.ul>
        </div>
      </div>

      {hasPrevious ? (
        <Control left>
          <Button
            primary
            rounding="pill"
            onClick={previousItem}
            aria-label="previous"
            disabled={interactionDisabled}
            compact
          >
            <IconNavigationArrowLeft />
          </Button>
        </Control>
      ) : null}
      {hasNext ? (
        <Control>
          <Button
            primary
            rounding="pill"
            onClick={nextItem}
            aria-label="next"
            disabled={interactionDisabled}
            compact
          >
            <IconNavigationArrowRight />
          </Button>
        </Control>
      ) : null}
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  position: 'relative',
  '.wrapper': {
    [customMinWidthFromTheme(theme).desktop]: {
      margin: `0 -${SDS_SPACE_XXXSMALL}`,
    },
    overflow: 'hidden',
  },
  [SlideContainer]: {
    pointerEvents: isDragging ? 'none' : 'auto',
  },
  '.carousel-wrapper': {
    width: '90%',
    margin: `${SDS_SPACE_XSMALL} auto 0`,
    overflow: 'visible',
    [customMinWidthFromTheme(theme).tablet]: {
      width: '100%',
    },
    [customMinWidthFromTheme(theme).desktop]: {
      marginTop: 0,
    },
  },
  '.carousel-content': {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'relative',
    touchAction: 'none',
    [customMinWidthFromTheme(theme).tablet]: {
      height: '320px',
    },
    'img, a': {
      userSelect: 'none',
      userDrag: 'none',
    },
    a: {
      height: '100%',
    },
  },
}))

const Control = styled.div<{ left?: boolean }>(({ theme, left }) => ({
  display: 'none',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: left ? SDS_SPACE_XSMALL : undefined,
  right: left ? undefined : SDS_SPACE_XSMALL,
  zIndex: 10,
  backgroundColor: 'rgba(255, 255, 255, 0)',
  border: 0,
  borderRadius: '50%',
  overflow: 'visible',
  [Button]: {
    height: '48px',
    width: '48px',
    minWidth: 0,
    padding: '12px',
  },
  [customMinWidthFromTheme(theme).desktop]: {
    left: left ? `-${SDS_SPACE_XSMALL}` : undefined,
    right: left ? undefined : `-${SDS_SPACE_XSMALL}`,
  },
  [customMinWidthFromTheme(theme).tablet]: {
    display: 'block',
  },
}))
