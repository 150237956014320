import type { FC } from 'react'

import { useMutation, useReactiveVar } from '@apollo/client'
import { ClearCartAndOrderDetailsDocument } from '@shared/gql/document-nodes'
import { useFullscreenLoader } from 'components/FullscreenLoader'
import { OrderCancelModal } from 'components/Modal/OrderCancelModal'
import { loadingOverlayVar } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleRequestErrors } from 'services/error-handler'
import { AlertType, Notifications } from 'services/Notifications'
import styled from 'styled-components'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { commonLogger } from 'utils/log/common-logger'
import { useCancelOrder } from 'utils/order/use-cancel-order'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'
import { useOrderUpdate } from 'views/delivery/order/order-submit'

import { CancelModificationWarning } from './CancelModificationWarning'
import { OrderModificationNotificationAlert } from './OrderModificationNotificationAlert'

export const OrderModificationNotificationContainer = () => {
  const router = useRouter()
  const orderEditState = useGetOrderEditState()
  const cancelOrder = useCancelOrder()
  const { onOrderHistoryClick } = useGetProfileActionHandlers()
  const [confirmCancel, setConfirmCancel] = useState(false)
  const { t } = useTranslation()
  const [cancelModificationModalVisible, setCancelModificationModalVisible] = useState(false)
  const [clearInfo] = useMutation(ClearCartAndOrderDetailsDocument)

  const fullscreenLoader = useFullscreenLoader()

  const isLoggedIn = useIsLoggedIn()
  const updateUserOrder = useOrderUpdate()

  const hideAlertIfLoading = useReactiveVar(loadingOverlayVar)

  const toggleOrderCancelModal = (): void => setConfirmCancel(!confirmCancel)

  const closeModalHandler = () => {
    setCancelModificationModalVisible(false)
  }

  const closeAndOpenOrderHistory = async () => {
    setConfirmCancel(false)
    if (isLoggedIn) {
      // Clear the cart and exit order editing mode before navigating to order history.
      await clearInfo({ variables: { isLoggedIn: true } })
      onOrderHistoryClick()
    }
  }

  const { orderStatus, isOrderEditActive, orderId } = orderEditState

  if (!isOrderEditActive) return <></>
  if (!orderId) throw new Error('No order id is present, can not update order')

  const paymentCardUpdateActionHandler = async (): Promise<void> => {
    fullscreenLoader.show('shopping-cart')
    try {
      const redirectUrls = await updateUserOrder(null)
      // The first url is Nets Terminal url
      await router.push(redirectUrls[0])
    } catch (e) {
      Notifications.add({
        __kind: 'NotificationAreaMessage',
        group: 'global',
        id: 'order-update-error',
        type: AlertType.ERROR,
        message: t('Order update error'),
      })
      commonLogger.errorSync({
        error: e,
        message: 'Order update failed on card update.',
      })
      handleRequestErrors([e])
    }
    fullscreenLoader.hide()
  }

  return hideAlertIfLoading ? null : (
    <>
      <OrderCancelModal
        modalOpen={confirmCancel}
        onConfirmCancellation={() => cancelOrder({ id: orderId })}
        onSecondaryAction={closeAndOpenOrderHistory}
        onClose={toggleOrderCancelModal}
        title={t('Cancel order process')}
        confirmLabel="Cancel order"
        secondaryActionLabel={isLoggedIn ? 'Navigate to order history' : 'Close'}
      >
        <OrderCancelModalContent isLoggedIn={isLoggedIn} />
      </OrderCancelModal>
      <CancelModificationWarning
        closeModal={closeModalHandler}
        modalVisible={cancelModificationModalVisible}
      />
      <OrderModificationNotificationAlert
        toggleOrderCancelModal={toggleOrderCancelModal}
        toggleCancelModificationModal={() => setCancelModificationModalVisible(true)}
        orderStatus={orderStatus}
        paymentCardUpdateActionHandler={paymentCardUpdateActionHandler}
        isOrderEditActive={isOrderEditActive}
      />
    </>
  )
}

const OrderCancelModalContent: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const { t } = useTranslation()
  return isLoggedIn ? (
    <>
      <StyledParagraph>{t('Order saved as draft due to missing payment info')}</StyledParagraph>
      <StyledParagraph>
        {t('Even if you cancel you will find the saved cart in order history')}
        <strong>{t('Note that delivery time might not be available')}</strong>
      </StyledParagraph>
    </>
  ) : (
    <StyledParagraph>
      {t('Even if you cancel you can still re-use the shopping list')}
    </StyledParagraph>
  )
}

const StyledParagraph = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  strong {
    display: block;
  }
`
