import type { FC, ReactNode } from 'react'

import { Button } from '@s-group/design-system-components'
import { SDS_SPACE_MEDIUM } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { ConfirmModal } from '@sok/design-system'
import { loadingOverlayVar } from 'lib/apollo/reactive-vars'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { OverlaySyncLoad } from '../common/OverlaySyncLoad'

const StyledDiv = styled.div`
  display: flex;
  gap: ${SDS_SPACE_MEDIUM};
`

enum CancelState {
  TO_CONFIRM,
  IN_PROGRESS,
}

interface OrderCancelModalProps {
  onConfirmCancellation: () => Promise<void>
  /** Optional secondary action, defaults to `onClose`. */
  onSecondaryAction?: () => void
  onClose: () => void
  modalOpen: boolean
  title: string
  confirmLabel?: string
  secondaryActionLabel?: string
  children?: ReactNode
}

export const OrderCancelModal: FC<OrderCancelModalProps> = ({
  onClose,
  onConfirmCancellation,
  onSecondaryAction = onClose,
  modalOpen,
  title,
  children,
  confirmLabel = 'Confirm',
  secondaryActionLabel = 'Close',
}) => {
  const [cancelState, setCancelState] = useState<CancelState>(CancelState.TO_CONFIRM)
  const { t } = useTranslation()

  if (cancelState === CancelState.IN_PROGRESS) {
    return <OverlaySyncLoad />
  }

  const handleCancel = async (): Promise<void> => {
    setCancelState(CancelState.IN_PROGRESS)
    loadingOverlayVar(true)
    await onConfirmCancellation()
    setCancelState(CancelState.TO_CONFIRM)
    onClose()
    loadingOverlayVar(false)
  }

  return (
    <ConfirmModal
      closeLabel={t('Close')}
      visible={modalOpen}
      title={title}
      onCancel={onClose}
      footer={
        <StyledDiv>
          <Button
            data-test-id="btn-cancel-order-confirm"
            key="submit"
            type="button"
            onClick={handleCancel}
            primary
            sizing="medium"
            rounding="small"
          >
            {t(confirmLabel)}
          </Button>
          <Button
            data-test-id="btn-cancel-order-cancel"
            key="back"
            type="button"
            onClick={onSecondaryAction}
            sizing="medium"
            rounding="small"
            style={{ margin: 0 }}
          >
            {t(secondaryActionLabel)}
          </Button>
        </StyledDiv>
      }
    >
      {children}
    </ConfirmModal>
  )
}
