import { SDS_FONT_WEIGHT_MEDIUM } from '@s-group/design-system-tokens/web/tokens/font.es6'
import { SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY } from '@s-group/design-system-tokens/web/tokens/s-kaupat/colors.es6'
import { SDS_SIZE_XSMALL } from '@s-group/design-system-tokens/web/tokens/size.es6'
import { SDS_SPACE_LARGE } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { minWidthFromTheme } from '@sok/design-system'
import styled, { css } from 'styled-components'

export const StyledLoginActions = styled.div`
  width: 100%;
`
export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    padding-top: ${({ theme }) => theme.spacings.small};
  }
  p {
    ${({ theme }) => css(theme.variants.body1.regular)};
    margin-top: ${({ theme }) => theme.spacings.medium};
    text-align: center;
  }
  h3 {
    ${({ theme }) => css(theme.variants.paragraph2.regular)};
    margin-top: ${({ theme }) => theme.spacings.xLarge};
    text-align: center;
  }
  div {
    ${({ theme }) => css(theme.variants.body1.regular)};
    text-align: center;
  }
`

export const StyledReadMoreSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  cursor: pointer;
  margin: ${SDS_SPACE_LARGE};

  a,
  a:visited {
    color: ${SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
    font-size: ${SDS_SIZE_XSMALL};
    font-weight: ${SDS_FONT_WEIGHT_MEDIUM};
  }
`
