import type { TrackingInteractionEvent } from './tracking-interaction-event'

import { ga4ProductMapper } from './event-product-mapper'
import { dataLayer } from './tag-manager'

export const GA4_addToCart = ({
  listName,
  listId,
  products,
  availabilityLabel,
  listPosition = 0,
  searchProvider,
  trackingContext,
}: TrackingInteractionEvent) => {
  dataLayer({ ecommerce: null })
  dataLayer({
    event: 'add_to_cart',
    ecommerce: {
      item_list_name: listName,
      item_list_id: listId || listName,
      productListContext: trackingContext,
      totalResults: products.length,
      searchProvider,
      items: products.map((x, i) =>
        ga4ProductMapper(
          listName || '',
          listId || null,
          availabilityLabel,
        )({
          ...x,
          // for some reason, index should be start from 1.
          index: 1 + i + listPosition,
        }),
      ),
    },
  })
}
export const GA4_removeFromCart = ({
  listName,
  listId,
  products,
  availabilityLabel,
  listPosition = 0,
  searchProvider,
  trackingContext,
}: TrackingInteractionEvent) => {
  dataLayer({ ecommerce: null })
  dataLayer({
    event: 'remove_from_cart',
    ecommerce: {
      item_list_name: listName,
      item_list_id: listId || listName,
      productListContext: trackingContext,
      totalResults: products.length,
      searchProvider,
      items: products.map((x, i) =>
        ga4ProductMapper(
          listName || '',
          listId || null,
          availabilityLabel,
        )({
          ...x,
          // for some reason, index should be start from 1.
          index: 1 + i + listPosition,
        }),
      ),
    },
  })
}
