import type { GA4Product } from '@shared/domain/tracking/ga4-event'
import type { ProductAvailabilities, ProductPricing } from '@shared/gql/document-nodes'

import { ProductAvailabilityLabel } from '@shared/gql/document-nodes'
import { getProductAvailabilityForDate } from 'services/Product/utils/get-product-availability-for-date'
import { isNonNullable } from 'utils/nullable'

export type AnalyticsCompatibleProduct = {
  id?: string | null
  ean?: string | null
  name?: string | null
  quantity?: number | null
  isReplaceable?: boolean | null
  price?: number | null
  // we are mix-matching name and slug between different types
  // should be unified to use name in all?
  hierarchyPath?: { name?: string; slug?: string }[] | null
  // Pricing requires only
  // currentPrice && regularPrice for analytics, but these are not queried
  pricing?: Partial<ProductPricing> | null
  availabilityLabel?: ProductAvailabilityLabel | null
  regularPrice?: number | null
  index?: number | null
}

export const ga4ProductMapper =
  (
    item_list_name: string,
    item_list_id: string | null,
    productAvailabilityLabel: ProductAvailabilityLabel | null = ProductAvailabilityLabel.Available,
  ) =>
  (product: AnalyticsCompatibleProduct): GA4Product => ({
    item_list_name,
    item_list_id: item_list_id || item_list_name,
    item_id: product?.id || '',
    item_ean: product?.ean,
    item_name: product?.name,
    quantity: product?.quantity,
    isReplaceable: product?.isReplaceable,
    index: product?.index,
    productAvailabilityLabel,
    ...mapToCategories(product.hierarchyPath),
    ...mapPricing(product),
  })

// input types are optional due to gql typings
// UNKNOWN label added for analytics purposes only, indicates a non calculated availability for item
export const mapGa4Availabilities =
  (availablityDate?: string | null) =>
  <T extends { availabilities?: ProductAvailabilities[] | null }>(
    x: T,
  ): T & { productAvailabilityLabel: ProductAvailabilityLabel | 'UNKNOWN' } => ({
    ...x,
    productAvailabilityLabel:
      getProductAvailabilityForDate(x.availabilities, availablityDate)?.label || 'UNKNOWN',
  })

const mapToCategories = (x?: { name?: string; slug?: string }[] | null) => {
  const [item_category, item_category2, item_category3, item_category4] =
    x
      ?.map((a) => a.name || a.slug)
      .filter(isNonNullable)
      // reverse order for easier analytics usage
      .reverse() || []

  return {
    item_category,
    item_category2,
    item_category3,
    item_category4,
  }
}

const mapPricing = (x?: AnalyticsCompatibleProduct | null) => ({
  // fallback to old pricing, if new pricing object is missing as it is not queried in all places
  price: x?.pricing?.currentPrice || x?.price,
  // hack regularPrice from cartItem and from product, as the types differ but are used interchangeably
  originalPricing: x?.pricing?.regularPrice || x?.regularPrice || null,
})
