export const getPrecisionOf = (x: number): number => Math.min(2, `${x}`.split('.')[1]?.length) || 0

const toString = (value: number) => `${value}`

export const toPrecision =
  (precision: number) =>
  (value: number): number =>
    Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)

export const formatNumericWithPrecision = (x: number, precision: number): string =>
  formatNumericValue(toPrecision(precision)(x))

export const formatNumericValue = (x: number) => toString(x).replace('.', ',')

export const isNumber = (x: unknown): x is number => Number.isFinite(x)
