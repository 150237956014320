import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { useGetCitrusAdBannerX } from 'services/CitrusAd/hooks/use-get-citrusad-bannerx'
import styled from 'styled-components'
import { CitrusAdPlacement, CitrusAdSlot } from 'types/citrusad'

import { CitrusAdBannerX } from '../citrusad/CitrusAdBanner'
import { ArticleGridCard } from './article-grid/ArticleGridCard'
import {
  StyledArticleGridContainer,
  StyledArticleGridTitle,
  StyledVerticalImageAndTextContainer,
} from './article-grid/styled'
import { StyledTextTitle } from './common'

export interface ArticleGridProps {
  section: ISection
  context?: 'menu-ajankohtaista'
}

const Placeholder = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.colors.grey300,
}))

export const ArticleGrid: FC<ArticleGridProps> = ({ section, context }) => {
  const citrusAdBannerData = useGetCitrusAdBannerX({
    placement: CitrusAdPlacement.BANNERX_HOME,
    slotId: CitrusAdSlot.HOME_MIDDLE,
    hasAdSlot: section.fields.isCitrusAdGrid,
  })
  return (
    <StyledArticleGridContainer
      id={section.fields.tagTitle}
      backgroundColor={section.fields.backgroundColor}
    >
      {section.fields.title && (
        <StyledArticleGridTitle>
          <StyledTextTitle>{section.fields.title}</StyledTextTitle>
        </StyledArticleGridTitle>
      )}

      <StyledVerticalImageAndTextContainer>
        {section.fields.cards?.map((card, index) => {
          if (
            index === 1 &&
            section.fields.isCitrusAdGrid &&
            (citrusAdBannerData.loading ||
              citrusAdBannerData.skipLoadAd ||
              citrusAdBannerData.banner)
          ) {
            if (citrusAdBannerData.loading || citrusAdBannerData.skipLoadAd) {
              return (
                <li key="ca-placeholder">
                  <Placeholder />
                </li>
              )
            }
            if (citrusAdBannerData.banner) {
              return (
                <li key="ca-article-card">
                  <CitrusAdBannerX citrusAdBannerData={citrusAdBannerData} />
                </li>
              )
            }
          }
          return (
            <li key={card.sys.id}>
              <ArticleGridCard index={index} card={card} context={context} />
            </li>
          )
        })}
      </StyledVerticalImageAndTextContainer>
    </StyledArticleGridContainer>
  )
}
