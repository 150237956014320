import type { FC, ReactNode } from 'react'

import { minWidthFromTheme } from '@sok/design-system'
import { ArrowRight24 } from 'components/icons'
import { ProductCard } from 'components/Product'
import Link from 'next/link'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSortParam } from 'services/Search/hooks/use-sort-param'
import { sortToQueryValue } from 'services/Search/utils/sort'
import styled, { css } from 'styled-components'
import { NAVBAR_HEIGHT } from 'styles/layout'

const StyledSingleSuggestionHeadingLink = styled.a`
  ${({ theme }) => css(theme.variants.body1.regular)};
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
  color: ${({ theme }) => theme.color.action};
  display: flex;
  height: 48px;
  justify-content: center;
  position: sticky;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.color.highlightHoverColor};
  }

  svg {
    margin-left: ${({ theme }) => theme.spacings.xxSmall};
    width: 1em;
  }
`

interface SearchLinkProps {
  onShowResults: (value: string) => void
  searchString: string
}

const SearchLink: FC<SearchLinkProps> = ({ onShowResults, searchString }) => {
  const { t } = useTranslation()
  const [activeSort] = useSortParam()

  const href = useMemo(() => {
    const newParams = new URLSearchParams({ queryString: searchString })
    const sort = sortToQueryValue(activeSort)
    if (sort) newParams.set('sort', sort)
    return `/hakutulokset?${newParams}`
  }, [activeSort, searchString])

  return (
    <Link href={href} passHref>
      <StyledSingleSuggestionHeadingLink
        data-test-id="autosuggest-result-heading"
        onClick={(event) => {
          // the onShowResults callback will redirect
          event.preventDefault()
          onShowResults(searchString)
        }}
      >
        <span>{`${t('Display all results for')}`}</span>
        <ArrowRight24 strokeColor="currentColor" />
      </StyledSingleSuggestionHeadingLink>
    </Link>
  )
}

interface SuggestionContainerProps extends SearchLinkProps {
  children: ReactNode
  className?: string
  searchInProgress: boolean
}

const _SuggestionsContainer: FC<SuggestionContainerProps> = ({
  children,
  className,
  onShowResults,
  searchInProgress,
  searchString,
}) => {
  return (
    <div className={className} data-test-id="autosuggest-result-container">
      {(searchInProgress || children) && (
        <SearchLink onShowResults={onShowResults} searchString={searchString} />
      )}
      {searchInProgress ? (
        <>
          <ProductCard.AutoSuggest.Loading />
          <ProductCard.AutoSuggest.Loading />
          <ProductCard.AutoSuggest.Loading />
          <ProductCard.AutoSuggest.Loading />
          <ProductCard.AutoSuggest.Loading />
        </>
      ) : (
        children
      )}
    </div>
  )
}

_SuggestionsContainer.displayName = 'SuggestionsContainer'

export const SuggestionsContainer = styled(_SuggestionsContainer)(({ theme }) => ({
  boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.2)',
  height: `calc(100vh - ${NAVBAR_HEIGHT.mobileHalf}px)`,
  left: 0,
  overflow: 'auto',
  position: 'fixed',
  top: NAVBAR_HEIGHT.mobile,
  width: '100%',
  backgroundColor: theme.colors.grey100,
  zIndex: 100,

  /** iOS bottom bar */
  '> :last-child': {
    marginBottom: 80,
  },

  ul: {
    listStyle: 'none',
  },

  [minWidthFromTheme(theme).tablet]: {
    position: 'absolute',
    top: 'calc(100% + 16px)',

    minWidth: '475px',
    left: '50%',
    transform: 'translateX(-50%)',

    backgroundColor: theme.colors.white,

    height: `calc(100vh - 124px - ${theme.spacings.xxSmall})`,
    /** header and 5.5 products so that it looks scrollable */
    maxHeight: 48 + 5.5 * 112,

    '&:empty': {
      display: 'none',
    },
  },

  [minWidthFromTheme(theme).desktop]: {
    /** iOS bottom bar */
    '> :last-child': {
      marginBottom: 'initial',
    },
  },
}))
