import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import styled, { css } from 'styled-components'
import { TEXT_CONTENT_MAX_WIDTH } from 'styles/layout'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { StyledSectionWrapper, StyledTextTitle } from './common'

const StyledListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0px 20px;
  }
`
const StyledListTextBlock = styled.div`
  ${({ theme }) => css(theme.variants.body1.regular)};
  word-break: break-word;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.action};
    &:hover {
      color: ${({ theme }) => theme.color.action};
    }
  }

  ol {
    position: relative;
    margin-left: 20px;
  }

  ol::before {
    content: '';
    top: -15px;
    bottom: 10px;
    left: -41px;
    position: absolute;
    height: 100%;
    width: 1px;
    border-left: 2px dashed ${({ theme }) => theme.colors.grey300};
  }

  ol > li {
    margin-bottom: 28px;
  }

  li > p::before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.color.primaryBrandLight};
    z-index: 1;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-left: -45px;
    margin-top: 5px;
  }
`
const StyledListWrapper = styled.article`
  max-width: ${TEXT_CONTENT_MAX_WIDTH}px;
`

export const List: FC<{
  section: ISection
}> = ({ section }) => {
  const { cards, tagTitle } = section.fields
  const card = cards?.[0]
  const { title, body } = card?.fields ?? {}
  const parsedBody = useParsedMarkdown(body)
  return (
    <StyledSectionWrapper id={tagTitle}>
      <StyledListContainer>
        <StyledListWrapper>
          <StyledTextTitle>{title}</StyledTextTitle>
          <StyledListTextBlock>{parsedBody}</StyledListTextBlock>
        </StyledListWrapper>
      </StyledListContainer>
    </StyledSectionWrapper>
  )
}
