import type { SlideContext } from './context'
import type { Brand } from '@shared/domain/brand'
import type { Asset } from 'contentful'
import type { FC, ReactNode } from 'react'
import type { IHeroCardFields } from 'types/contentful/generated'
import type { CustomImage } from 'utils/contentful/check-content-type'

import { SDS_COLOR_WHITE } from '@s-group/design-system-tokens/web/tokens/colors.es6'
import {
  SDS_FONT_LINEHEIGHT_HEADING_MEDIUM,
  SDS_FONT_SIZE_BODY_SMALL,
  SDS_FONT_SIZE_HEADING_MEDIUM,
  SDS_FONT_WEIGHT_BOLD,
} from '@s-group/design-system-tokens/web/tokens/font.es6'
import {
  SDS_SPACE_SMALL,
  SDS_SPACE_XLARGE,
  SDS_SPACE_XXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'
import { CMS_NS } from '@shared/i18n'
import { ArrowRightOutline16 } from '@sok/design-system'
import { NextLink } from 'components/NextLink'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'
import { useGetSelectedBrand } from 'utils/brand/use-get-selected-brand'
import { contentType } from 'utils/contentful/check-content-type'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { ContentfulImage } from '../../ContentfulImage'
import { BRAND_COLORS, LABEL_COLORS } from '../content-carousel/utils/slide'
import { HeroCarouselSlideContext, useHeroCarouselSlide } from './context'
import { Wrap } from './helper'
import { ImpressionTrack } from './ImpressionTrack'

interface HeroCarouselSlideProps extends SlideContext {
  children: ReactNode
  withBackground?: boolean
  className?: string
}
interface LabelProps {
  label: IHeroCardFields['label'] | 'Sponsored'
}
interface ImageProps {
  image: Asset | CustomImage
}
interface LogoImageProps {
  imageUrl: string
}

const Background = styled.div<{ brand: Brand; withBackground?: boolean }>(
  ({ brand, withBackground }) => ({
    height: '100%',
    backgroundColor: withBackground ? BRAND_COLORS[brand] : 'rgba(255, 255, 255, 0)',
  }),
)
const _HeroCarouselSlideComponent: FC<HeroCarouselSlideProps> = ({
  withBackground,
  tracking,
  cta,
  children,
  className,
}) => {
  const brand = useGetSelectedBrand()
  const shouldTrack = tracking !== null
  const handleClick = () => {
    if (cta.handleClick) {
      cta.handleClick()
      return
    }
    if (shouldTrack) {
      internalPromotion(
        PromotionPosition[tracking.trackingPosition as keyof typeof PromotionPosition],
        tracking.trackingName,
        'select_promotion',
        tracking.trackingCreative,
      )
    }
  }
  return (
    <HeroCarouselSlideContext.Provider value={{ tracking, cta }}>
      <div className={className} data-test-id="hero-carousel-slide">
        <Wrap condition={shouldTrack} wrapper={(el) => <ImpressionTrack>{el}</ImpressionTrack>}>
          <Wrap
            condition={!!cta.url}
            wrapper={(el) => (
              <NextLink
                onMouseDown={(e) => e.preventDefault()}
                href={cta.url ?? ''}
                aria-label={cta.label}
                onClick={handleClick}
                target={cta.openLinkInNewTab ? '_blank' : undefined}
              >
                {el}
              </NextLink>
            )}
          >
            <Background
              withBackground={withBackground}
              brand={brand}
              data-test-id="heroslide__background"
            >
              {children}
            </Background>
          </Wrap>
        </Wrap>
      </div>
    </HeroCarouselSlideContext.Provider>
  )
}
const HeroCarouselSlideComponent = styled(_HeroCarouselSlideComponent)(({ theme }) => ({
  position: 'relative',
  borderRadius: '4px',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '100%',
  [customMinWidthFromTheme(theme).tablet]: {
    height: '100%',
    paddingTop: 0,
  },
  '> *': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    [customMinWidthFromTheme(theme).tablet]: {
      position: 'relative',
    },
  },
  ['a']: {
    display: 'block',
  },
  ['.background-img']: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  ['.logo-img']: {
    position: 'absolute',
    width: '48px',
    top: '16px',
    right: '16px',
    borderRadius: '4px',
  },
  ['.cta']: {
    position: 'absolute',
    width: '100%',
    padding: `${SDS_SPACE_XLARGE} ${SDS_SPACE_SMALL} ${SDS_SPACE_SMALL}`,
    bottom: '-40px',
    left: 0,
    backgroundImage:
      'linear-gradient(360deg, rgba(0, 0, 0, 0.73) 0%, rgba(0, 0, 0, 0.644688) 25.52%, rgba(1, 0, 0, 0) 100%)',
    color: SDS_COLOR_WHITE,
    transition: 'bottom 0.5s ease',
    h3: {
      fontSize: SDS_FONT_SIZE_HEADING_MEDIUM,
      fontWeight: SDS_FONT_WEIGHT_BOLD,
      lineHeight: SDS_FONT_LINEHEIGHT_HEADING_MEDIUM,
      marginBottom: SDS_SPACE_SMALL,
    },
    span: {
      fontSize: SDS_FONT_SIZE_BODY_SMALL,
      opacity: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      transition: 'opacity 0.2s ease',
      svg: {
        marginLeft: SDS_SPACE_XXSMALL,
      },
    },
  },
  ':hover, :focus, :active': {
    '.cta': {
      [customMinWidthFromTheme(theme).tablet]: {
        bottom: 0,
        span: {
          opacity: 1,
        },
      },
    },
  },
}))

const StyledLabel = styled.div<{ label: IHeroCardFields['label'] | 'Sponsored' }>(
  ({ theme, label }) => ({
    position: 'absolute',
    left: '16px',
    top: '16px',
    borderRadius: '24px',
    backgroundColor: SDS_COLOR_WHITE,
    fontSize: theme.sizes.xxxs.fontSize,
    padding: '4px 10px',
    color: label ? LABEL_COLORS[label] : theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,
    fontWeight: 500,
  }),
)
const Label: FC<LabelProps> = ({ label }) => {
  const { t } = useTranslation(CMS_NS)
  if (!label) return null
  return <StyledLabel label={label}>{t(label)}</StyledLabel>
}
const Image: FC<ImageProps> = ({ image }) => {
  return contentType.isContentfulImage(image) ? (
    <ContentfulImage
      image={image}
      sourceSizes="(min-width: 768px) 50vw, (min-width: 1280px) 33.33vw, (min-width: 1920px) 25vw, 100vw"
      className="background-img"
    />
  ) : (
    <img className="background-img" src={image.url} alt={image.alt} title={image.title} />
  )
}
const LogoImage: FC<LogoImageProps> = ({ imageUrl }) => (
  <img className="logo-img" src={imageUrl} alt="Logo" />
)
const CTALabel: FC = () => {
  const { t } = useTranslation('cms')
  const ctx = useHeroCarouselSlide()
  if (!ctx?.cta.label) return null
  return (
    <div className="cta">
      <h3>{ctx?.cta.label}</h3>
      <span>
        {t('Learn more')}
        <ArrowRightOutline16 />
      </span>
    </div>
  )
}

export const HeroCarouselSlide = {
  Card: HeroCarouselSlideComponent,
  Label,
  Image,
  LogoImage,
  CTALabel,
}
