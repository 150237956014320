import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import styled, { css } from 'styled-components'

interface VideoProps {
  section: ISection
}

interface VideoContainerProps {
  id?: string
}

const StyledVideoContainer = styled.div<VideoContainerProps>`
  margin-bottom: 48px;
`
const StyledVideoOuterWrapper = styled.div`
  max-width: 832px;
  max-height: 480px;
  margin: 0px auto;
  overflow: hidden;
`

const StyledVideoInnerWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`

const StyledVideoDescription = styled.div`
  ${({ theme }) => css(theme.variants.body1.regular)};
  max-width: 832px;
  margin: 0px auto;
  margin-top: 16px;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px 16px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px 8px;
  }
`

export const Video: FC<VideoProps> = ({ section }) => {
  const { cards, tagTitle } = section.fields
  if (cards && cards.length > 0) {
    const card = cards[0]
    const { body: videoDescription, videoUrl } = card.fields
    const videoUrlWithOptions = `${videoUrl}?rel=0`
    return (
      <StyledVideoContainer id={tagTitle}>
        <StyledVideoOuterWrapper>
          <StyledVideoInnerWrapper>
            <iframe
              title="youtube iframe"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={videoUrlWithOptions}
              frameBorder="0"
              allow="fullscreen"
            ></iframe>
          </StyledVideoInnerWrapper>
        </StyledVideoOuterWrapper>
        <StyledVideoDescription>{videoDescription}</StyledVideoDescription>
      </StyledVideoContainer>
    )
  }
  return <></>
}
