import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { Product } from 'domain/product'
import type { Pricing } from 'domain/product-list'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC, ReactNode } from 'react'

import { getConfig } from '@shared/config'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import { NextLink } from 'components/NextLink'
import { ProductPrice } from 'components/product-price/ProductPrice'
import { ProductImage, StyledProductImageContainer } from 'components/ProductImage'
import styled from 'styled-components'
import { getProductLink } from 'utils/products/product-link'

import { ProductName } from './ProductName'

const { featureFlags } = getConfig()

type RequiredFields = Pick<
  Product,
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'ean'
  | 'frozen'
  | 'id'
  | 'isAgeLimitedByAlcohol'
  | 'name'
  | 'packagingLabelCodes'
  | 'price'
  | 'priceUnit'
  | 'quantityMultiplier'
  | 'storeId'
  | 'isGlobalFallback'
> &
  Pricing

type OptionalFields = Partial<
  Pick<Product, 'brandName' | 'depositPrice' | 'packagingLabels' | 'slug'>
> & { itemCount?: number }

type CustomFields = Pick<Partial<ClientCartItem>, 'replace' | 'additionalInfo'>

export type ProductData = RequiredFields & OptionalFields & CustomFields

interface ProductCardProps {
  'data-test-id'?: string
  children?: ReactNode
  className?: string
  data: ProductData
  disabled?: boolean
  handleClick?: () => void
  readOnlyImage?: boolean
  readOnlyName?: boolean
  status?: ReactNode /* This Refers to ProductAvailabilityInfo being passed down */
}

export const _ProductCard: FC<ProductCardProps> = ({
  children,
  className,
  'data-test-id': dataTestId = 'product-card',
  data,
  disabled,
  handleClick,
  readOnlyImage,
  readOnlyName,
  status,
}) => (
  <article
    className={className}
    data-disabled={disabled ? true : undefined}
    data-product-id={data.id}
    data-readonly={readOnlyImage && readOnlyName}
    data-test-id={dataTestId}
    key={data.id}
  >
    <StyledProductImageContainer>
      {readOnlyImage ? (
        <ProductImage
          ean={data.ean}
          productName={data.name}
          height={212}
          width={212}
          data-test-id={`${dataTestId}__productImage`}
        />
      ) : (
        <NextLink
          href={'/tuote/[...eanAndSlug]'}
          hrefAs={getProductLink({ ean: data?.ean, slug: data?.slug })}
          onClick={handleClick}
          data-test-id={`${dataTestId}__productImage`}
        >
          <ProductImage ean={data.ean} productName={data.name} height={212} width={212} />
        </NextLink>
      )}
    </StyledProductImageContainer>

    <ProductDetails>
      {featureFlags.productAvailabilities && status}

      {readOnlyName ? (
        <ProductName data-test-id={`${dataTestId}__productName`} lines={2}>
          {data.name}
        </ProductName>
      ) : (
        <NextLink
          as={NextLink}
          href={'/tuote/[...eanAndSlug]'}
          hrefAs={getProductLink({ ean: data?.ean, slug: data?.slug })}
          onClick={handleClick}
          data-test-id={`${dataTestId}__productName`}
        >
          <ProductName lines={2}>{data.name}</ProductName>
        </NextLink>
      )}
      <ProductPrice
        /** Show approximate price when product is not available in the current store */
        approximate={!!data.isGlobalFallback}
        product={data}
        data-test-id={`${dataTestId}__productPrice`}
        itemCount={data?.itemCount ?? 0}
      />
    </ProductDetails>
    {children}
  </article>
)
_ProductCard.displayName = 'ProductCard'

export const _ProductCardError: FC<{
  'data-test-id'?: string
  className?: string
  children: ReactNode
  name: string
}> = ({ className, 'data-test-id': dataTestId = 'product-card', children, name }) => (
  <article className={className} data-test-id={dataTestId} data-readonly>
    <StyledProductImageContainer>
      <ProductImage
        ean={null}
        height={212}
        width={212}
        data-test-id={`${dataTestId}__productImage`}
      />
    </StyledProductImageContainer>
    <ProductDetails>
      <ProductName data-test-id={`${dataTestId}__productName`} lines={2}>
        {name}
      </ProductName>
    </ProductDetails>
    {children}
  </article>
)
_ProductCardError.displayName = 'ProductCardFailure'

export const lessOpacity = (disabled?: boolean) => disabled && { opacity: 0.6 }

export const defaultStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({
  theme,
  disabled,
}) => ({
  ...BORDER_RADIUS,
  backgroundColor: theme.color.white,
  borderBottom: `1px solid ${theme.colors.grey100}`,
  color: theme.colors.grey50,
  cursor: disabled ? 'default' : 'auto',
  display: 'grid',
  opacity: 1,
  padding: '6px 8px',
  paddingBottom: theme.spacings.xxSmall,

  [StyledProductImageContainer]: {
    width: 124,
  },
})

export const ProductDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const ExtraActionsContainer = styled.div(({ theme }) => ({
  margin: `${theme.spacings.xSmall} 0 ${theme.spacings.xSmall} ${theme.spacings.xSmall}`,
}))
