import type { TrackingContext } from '../interfaces/data-layer-events'
import type { ProductAvailabilityLabel, SearchProvider } from '@shared/gql/document-nodes'
import type { FC, ReactNode } from 'react'

import { createContext, useContext, useMemo } from 'react'

const TrackingCtx = createContext<TrackingContextData | null>(null)

interface TrackingContextData {
  metadata?: Record<string, string>
  trackingContext?: TrackingContext
  availabilityLabel?: ProductAvailabilityLabel | null
  listName?: string
  listId?: string
  listPosition?: number
  searchProvider?: SearchProvider | null
}

export const TrackingContextProvider: FC<TrackingContextData & { children: ReactNode }> = ({
  children,
  trackingContext,
  availabilityLabel,
  listName,
  listId,
  listPosition,
  searchProvider,
}) => {
  const ctx = useContext(TrackingCtx)
  const newCtx = useMemo(() => {
    return {
      trackingContext: trackingContext || ctx?.trackingContext,
      availabilityLabel: availabilityLabel || ctx?.availabilityLabel,
      listName: listName || ctx?.listName,
      listId: listId || ctx?.listId,
      listPosition: listPosition || ctx?.listPosition,
      searchProvider: searchProvider || ctx?.searchProvider,
    }
  }, [
    availabilityLabel,
    ctx?.availabilityLabel,
    ctx?.listId,
    ctx?.listName,
    ctx?.listPosition,
    ctx?.searchProvider,
    ctx?.trackingContext,
    listId,
    listName,
    listPosition,
    searchProvider,
    trackingContext,
  ])

  return <TrackingCtx.Provider value={newCtx}>{children}</TrackingCtx.Provider>
}

export const useTrackingContext = () => {
  const ctx = useContext(TrackingCtx)
  if (!ctx) {
    throw new Error('No tracking ctx found')
  }
  return ctx
}
