import type { ValidDraftDomainOrder } from 'domain/order'

import { ProductType } from 'domain/order'
import { PaymentMethodType } from 'domain/payment'
import * as t from 'io-ts'

export const draftOrderMapper = {
  toDto: (x: ValidDraftDomainOrder): OrderInputDto => ({
    cartItems: cartItemMapper.toDto(x.cartItems),
    customer: {
      companyName: x.customer.companyName,
      email: x.customer.email,
      firstName: x.customer.firstName,
      bonusCard: x.customer.stockmannCardNumber || undefined,
      lastName: x.customer.lastName,
      phone: x.customer.phone,
      invoiceNumber: x.payment.invoiceNumber || '',
      address: x.deliveryAddress.address || '',
      city: x.deliveryAddress.city || '',
      postalCode: x.deliveryAddress.postalCode || '',
    },
    paymentMethod: x.payment.paymentMethod,
    comment: x.comment,
    storeId: x.storeId,
    reservationId: x.reservationId,
    deliverySlotId: x.deliverySlotId,
    discountCode: x.discountCode,
    additionalInfo: x.additionalInfo,
  }),
}

const cartItemMapper = {
  toDto: (xs: ValidDraftDomainOrder['cartItems']): OrderInputDto['cartItems'] =>
    xs.map((x) => ({
      additionalInfo: x.additionalInfo,
      ean: x.ean,
      id: x.id,
      itemCount: `${x.itemCount}`,
      replace: x.replace,
      productType: x.productType,
      price: x.price,
      name: x.name,
      basicQuantityUnit: x.basicQuantityUnit || '',
      priceUnit: x.priceUnit || '',
    })),
}

const CustomerInputDto = t.type({
  address: t.string,
  city: t.string,
  companyName: t.union([t.string, t.null, t.undefined]),
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phone: t.string,
  postalCode: t.string,
  invoiceNumber: t.union([t.string, t.null, t.undefined]),
  bonusCard: t.union([t.string, t.null, t.undefined]),
})

const OrderInputCartItemDto = t.type({
  id: t.string,
  ean: t.string,
  name: t.string,
  itemCount: t.string,
  replace: t.boolean,
  additionalInfo: t.union([t.string, t.null]),
  productType: ProductType,
  price: t.number,
  basicQuantityUnit: t.string,
  priceUnit: t.string,
})

const OrderInputDto = t.type({
  cartItems: t.array(OrderInputCartItemDto),
  customer: CustomerInputDto,
  paymentMethod: PaymentMethodType,
  storeId: t.string,
  reservationId: t.union([t.string, t.null]),
  deliverySlotId: t.string,
  discountCode: t.union([t.string, t.null, t.undefined]),
  additionalInfo: t.union([t.string, t.null, t.undefined]),
  comment: t.union([t.string, t.null, t.undefined]),
})
type OrderInputDto = t.TypeOf<typeof OrderInputDto>
