import type { Product } from '@shared/gql/document-nodes'
import type { Pricing } from 'domain/product-list'
import type {
  ProductComparisonPrice,
  ProductDepositPrice,
  ProductPrice,
} from 'domain/product-price'

import { getUnit } from 'domain/unit'
import { commonLogger } from 'utils/log/common-logger'

export type LoggableProduct = ProductPrice &
  ProductComparisonPrice &
  ProductDepositPrice &
  Pick<Product, 'name' | 'ean' | 'storeId'> &
  Pricing

const logWarnSync = (product: LoggableProduct, unit: string | null): void => {
  commonLogger.warnSync({
    message: `Trying to map '${unit}' to Unit but it is not a recognized unit type.`,
    data: {
      productName: product.name,
      productEan: product.ean,
      storeId: product.storeId,
    },
  })
}

export const logMissingUnits = (product: LoggableProduct): void => {
  const priceUnit = getUnit(product.priceUnit)

  if (!priceUnit) {
    logWarnSync(product, product.priceUnit)
  }

  const comparisonPrice = product.comparisonPrice
  const comparisonPriceUnit = getUnit(product.comparisonUnit)

  if (comparisonPrice && !comparisonPriceUnit) {
    logWarnSync(product, product.comparisonUnit)
  }
}
