import type { Validator } from './validation'

import * as E from 'fp-ts/Either'
import { flow } from 'fp-ts/function'

export const required =
  (path: readonly string[]): Validator<string | null | undefined, string> =>
  (value?: string | null) =>
    value != null && value.trim().length > 0
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'required',
          },
        ])

export const minLength =
  (path: readonly string[], min: number): Validator<string, string> =>
  (value) =>
    value.length >= min
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'minLength',
          },
        ])

export const maxLength =
  (path: readonly string[], max: number): Validator<string, string> =>
  (value) =>
    value.length <= max
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'maxLength',
          },
        ])

export const isNumeric =
  (path: readonly string[]): Validator<string, string> =>
  (value) =>
    value.match('^[0-9]+$')
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'isNumeric',
          },
        ])

export const isAlphabetic = (path: readonly string[]): Validator<string, string> =>
  flow(
    (x) => x.trim(),
    (x) =>
      x.match('^[a-zA-Z\u00C0-\u00FF-s ]+$')
        ? E.right(x)
        : E.left([
            {
              path,
              tag: 'validationError',
              message: 'isAlphabetic',
            },
          ]),
  )

export const isString =
  (path: readonly string[]): Validator<unknown, string> =>
  (value) =>
    typeof value === 'string'
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'isString',
          },
        ])
