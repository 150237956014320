import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { OrderStatus } from '@shared/gql/document-nodes'
import { Alert, AlertOutline16, AlertType, EditOutline16 } from '@sok/design-system'
import { orderModicationAlertVisibleVar } from 'lib/apollo/error-messages'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { StyledAlertLink } from './styles/styled-order-modification-notification-alert'

interface Props {
  toggleOrderCancelModal: () => void
  toggleCancelModificationModal: () => void
  paymentCardUpdateActionHandler: () => Promise<void>
  orderStatus: OrderStatus | null
  isOrderEditActive: boolean
}

export const OrderModificationNotificationAlert: FC<Props> = ({
  toggleOrderCancelModal,
  toggleCancelModificationModal,
  paymentCardUpdateActionHandler,
  orderStatus,
  isOrderEditActive,
}) => {
  const { t } = useTranslation()
  const isAlertvisible = useReactiveVar(orderModicationAlertVisibleVar)
  const isClientSide = useIsClientSide()

  if (orderStatus === OrderStatus.Open && isOrderEditActive) {
    if (!isAlertvisible && isClientSide) {
      return null
    }

    return (
      <Alert
        message={
          <div>
            {t('Order payment error please add a payment method')}
            &nbsp;
            <StyledAlertLink
              onClick={paymentCardUpdateActionHandler}
              data-test-id="order-modification-order-open-alert-action"
            >
              {t('Order payment error add payment card')}
            </StyledAlertLink>
            {t('Order payment error while order is editable')}
            <StyledAlertLink
              onClick={toggleOrderCancelModal}
              data-test-id="order-modification-order-cancel-alert-action"
            >
              {t('Order payment error cancel the order')}
            </StyledAlertLink>
            .
          </div>
        }
        type={AlertType.ERROR}
        customizeIcon={<AlertOutline16 />}
        closable
        onClose={() => orderModicationAlertVisibleVar(false)}
        data-test-id="order-modification-order-open-alert"
      />
    )
  } else if (isOrderEditActive) {
    return (
      <Alert
        message={
          <span data-test-id="edit-order-info">
            {t('Edit order banner info 1')}
            &nbsp;
            <StyledAlertLink>
              <Link href="/toimitus">{t('Edit order banner info 2')}</Link>
            </StyledAlertLink>
            &nbsp;
            {t('Edit order banner info 3')}
          </span>
        }
        type={AlertType.INFO}
        customizeIcon={<EditOutline16 />}
        actionButton
        actionText={t('Cancel')}
        data-test-id="order-modification-alert"
        actionHandler={toggleCancelModificationModal}
      />
    )
  }
  return <></>
}
