import type { ChildProps, ParentProps } from '../Navigation'
import type { NavigationItem } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { minWidthFromTheme } from '@sok/design-system'
import { ChevronDown, ChevronUp } from 'components/icons'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
import Link from 'next/link'
import { range } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSortParam } from 'services/Search/hooks/use-sort-param'
import { sortToQueryValue } from 'services/Search/utils/sort'
import styled, { css } from 'styled-components'

import { SubCategoryChild } from './SubCategoryChild'

interface SubCategoryItemProps {
  isActive?: boolean
}

const StyledSubCategoryWrapper = styled.div(
  {
    /** global nav + local nav + title bar */
    height: `calc(100vh - ${60 + 64 + 60}px)`,
    overflow: 'auto',
    width: '100vw',
  },
  ({ theme }) => ({
    [minWidthFromTheme(theme).tablet]: {
      /** global nav + local nav */
      height: `calc(100vh - ${60 + 80})`,
    },
  }),
)

const StyledSubCategoryItem = styled.div<SubCategoryItemProps>`
  border-bottom: ${({ theme }) => `1px solid ${theme.color.customLightGray}`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0;

  &:first-child {
    border-top: ${({ theme }) => `1px solid ${theme.color.customLightGray}`};
  }

  &:last-child {
    margin-bottom: 80px;
  }
`

const body2 = css(({ theme }) => theme.variants.body2.regular)

const StyledSubCategoryTitle = styled.a<SubCategoryItemProps>`
  ${body2};
  color: inherit;
  padding-left: 20px;
  text-decoration: ${({ isActive }) => isActive && 'underline'};
  width: 100%;
`

const StyledSubCategoryLink = styled.a`
  ${body2};
  color: ${({ theme }) => theme.color.action};
  margin-left: 20px;
`

const StyledChevronWrapper = styled.div`
  margin-right: 24px;
  margin-top: 5px;
`

interface CheverProps {
  active?: string
}

const StyledChevronUp = styled(ChevronUp)<CheverProps>`
  path {
    fill: ${({ theme, active }) =>
      active === 'active' ? theme.color.fontBlack : theme.color.lightGrey};
  }
`

const StyledChevronDown = styled(ChevronDown)`
  path {
    fill: ${({ theme }) => theme.color.lightGrey};
  }
`

const StyledSubCategoryTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

interface SubCategoryProps {
  items: NavigationItem[]
  loading?: boolean
  isActive: (slug: string | null | undefined) => boolean
  onItemClick: (slug: string) => void
  parent: ParentProps
  child: ChildProps
}

export const SubCategory: FC<SubCategoryProps> = ({
  loading,
  items,
  isActive,
  parent,
  child,
  onItemClick,
}) => {
  const { t } = useTranslation()
  const active = (slug: string): boolean => child?.slug === slug

  const [activeSort] = useSortParam()
  const sortParam = useMemo(() => {
    const sort = sortToQueryValue(activeSort)
    return sort ? `?sort=${sort}` : ''
  }, [activeSort])

  if (loading) {
    const NUMBER_OF_PLACEHOLDERS = 18
    return (
      <StyledSubCategoryWrapper data-test-id="product-navigation-subcategory-loading">
        {range(0, NUMBER_OF_PLACEHOLDERS).map((id) => (
          <StyledSubCategoryItem key={String(id)}>
            <SkeletonLoader width="200px" />
          </StyledSubCategoryItem>
        ))}
      </StyledSubCategoryWrapper>
    )
  }

  if (items) {
    return (
      <StyledSubCategoryWrapper data-test-id="product-navigation-subcategory">
        {items.map((item) => {
          const isEmptyCategory = !item?.children?.length

          return (
            <StyledSubCategoryItem
              data-test-id="product-navigation-subcategory-item"
              key={item?.id as string}
              isActive={isActive(item?.slug)}
              onClick={() => onItemClick(item?.slug as string)}
            >
              <StyledSubCategoryTitleWrapper>
                {isEmptyCategory ? (
                  <Link
                    href={`/tuotteet/[...slugs]${sortParam}`}
                    as={`/tuotteet/${item?.slug}${sortParam}`}
                    passHref
                  >
                    <StyledSubCategoryTitle
                      as={isEmptyCategory ? 'a' : 'div'}
                      isActive={isActive(item?.slug)}
                      data-test-id="product-navigation-subcategory-item-title"
                      onClick={() => {
                        navigationVisibleVar(false)
                        searchVisibleVar(false)
                      }}
                    >
                      {item?.name}
                    </StyledSubCategoryTitle>
                  </Link>
                ) : (
                  <StyledSubCategoryTitle
                    data-test-id="product-navigation-subcategory-item-title"
                    isActive={isActive(item?.slug)}
                  >
                    {item?.name}
                  </StyledSubCategoryTitle>
                )}

                {!isEmptyCategory && (
                  <StyledChevronWrapper>
                    {active(item?.slug as string) ? (
                      <StyledChevronUp active="active" />
                    ) : (
                      <StyledChevronDown />
                    )}
                  </StyledChevronWrapper>
                )}
              </StyledSubCategoryTitleWrapper>

              {!isEmptyCategory && active(item?.slug as string) && (
                <SubCategoryChild items={item?.children as NavigationItem[]} child={child} />
              )}
            </StyledSubCategoryItem>
          )
        })}
        <StyledSubCategoryItem data-test-id="product-navigation-subcategory-item">
          <Link
            href={`/tuotteet/[...slugs]${sortParam}`}
            as={`/tuotteet/${parent?.slug}${sortParam}`}
            passHref
          >
            <StyledSubCategoryLink
              onClick={() => {
                navigationVisibleVar(false)
                searchVisibleVar(false)
              }}
              data-test-id="product-navigation-subcategory-show-all-link"
            >
              {t('Show all')}
            </StyledSubCategoryLink>
          </Link>
        </StyledSubCategoryItem>
      </StyledSubCategoryWrapper>
    )
  }
  return null
}
