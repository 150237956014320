import type { FC, ReactNode } from 'react'

import { useCallback } from 'react'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { useContentImpressionTracking } from 'utils/tracking/hooks/use-content-impression-tracking'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { useHeroCarouselSlide } from './context'

export const ImpressionTrack: FC<{ children: ReactNode }> = ({ children }) => {
  const ctx = useHeroCarouselSlide()
  const promotionPosition =
    PromotionPosition[ctx?.tracking?.trackingPosition as keyof typeof PromotionPosition]
  const trackImpression = useCallback(() => {
    internalPromotion(
      promotionPosition,
      ctx?.tracking?.trackingName,
      'view_promotion',
      ctx?.tracking?.trackingCreative,
      ctx?.tracking?.trackingLabel,
    )
  }, [promotionPosition, ctx])
  const ref = useContentImpressionTracking<HTMLDivElement>(trackImpression, promotionPosition)
  return <div ref={ref}>{children}</div>
}
