import type { FC } from 'react'
import type { NextAvailableSlotType } from 'views/delivery-modal/method-and-store/delivery-option-info-card/NextAvailableSlot'

import { useQuery } from '@apollo/client'
import { getConfig } from '@shared/config'
import { getBrandByBrandName } from '@shared/domain/brand'
import {
  DeliveryMethod,
  GetDeliveryAreaWithNextSlotDocument,
  GetSelectedAreaIdDocument,
  SlotAvailability,
} from '@shared/gql/document-nodes'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import { SkeletonLoader } from 'components/SkeletonLoader'
import React, { useEffect } from 'react'
import {
  useIsDeliverySlotReservationEnabled,
  useSelectedDeliverySlot,
} from 'services/DeliverySlot/hooks'
import styled from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useNextSlotLabel } from 'views/delivery-modal/method-and-store/delivery-option-info-card/NextAvailableSlot'

import { DeliverySelectButton } from './components/DeliverySelectButton/DeliverySelectButton'

const { domain } = getConfig()

const DeliverySelectButtonLoader = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: 0,
})

const useGetDeliveryAreaWithNextSlot = (areaId: string | null) => {
  const {
    data: deliveryAreaResult,
    loading,
    startPolling,
    stopPolling,
  } = useQuery(GetDeliveryAreaWithNextSlotDocument, {
    skip: !areaId,
    variables: {
      id: areaId ?? '',
      includeNextSlot: true,
    },
  })

  const deliveryArea = deliveryAreaResult?.deliveryArea
  const deliveryMethod = deliveryArea?.deliveryMethod

  return {
    loading,
    startPolling,
    stopPolling,
    deliveryArea: {
      deliveryMethod,
      nextDeliverySlot: deliveryArea?.nextDeliverySlot as NextAvailableSlotType,
      isFastTrack: deliveryArea?.isFastTrack,
      brand: getBrandByBrandName(deliveryArea?.store?.brand),
      storeName:
        (deliveryMethod === DeliveryMethod.HomeDelivery
          ? deliveryArea?.store?.shortName
          : deliveryArea?.name) ?? null,
    },
  }
}

const _NavigationDeliverySelect: FC<{ className?: string }> = ({ className }) => {
  const isClientSide = useIsClientSide()

  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()

  const { data: selectedAreaIdData } = useQuery(GetSelectedAreaIdDocument)
  const selectedSlot = useSelectedDeliverySlot()

  const { isSIdEnabled } = domain

  const {
    startPolling,
    stopPolling,
    loading: deliveryAreaLoading,
    deliveryArea,
  } = useGetDeliveryAreaWithNextSlot(selectedAreaIdData?.selectedAreaId || null)

  useEffect(() => {
    if (selectedSlot.type === 'SUCCESS') {
      stopPolling()
    } else if (selectedSlot.type === 'NOT_ASKED') {
      startPolling(60_000 * 5)
    }
  }, [selectedSlot, startPolling, stopPolling])

  const nextSlotLabel = useNextSlotLabel(deliveryArea.nextDeliverySlot)

  if (!isSIdEnabled) {
    return null
  }

  if (!isClientSide || deliveryAreaLoading || selectedSlot.type === 'LOADING') {
    return (
      <DeliverySelectButtonLoader className={className}>
        <SkeletonLoader height={48} width={280} style={BORDER_RADIUS} />
      </DeliverySelectButtonLoader>
    )
  }

  if (deliveryArea.deliveryMethod) {
    if (deliveryArea.isFastTrack) {
      return (
        <DeliverySelectButton.FastTrackDeliveryButton
          className={className}
          brand={deliveryArea.brand}
          deliveryMethod={deliveryArea.deliveryMethod}
          storeName={deliveryArea.storeName}
          nextSlotLabel={nextSlotLabel}
          isSlotClosed={!!deliveryArea.nextDeliverySlot?.isClosed}
          isSlotUnavailable={
            deliveryArea.nextDeliverySlot?.availability !== SlotAvailability.Available
          }
        />
      )
    }

    return (
      <DeliverySelectButton.DeliveryButton
        className={className}
        brand={deliveryArea.brand}
        deliveryMethod={deliveryArea.deliveryMethod}
        storeName={deliveryArea.storeName}
        nextSlotLabel={nextSlotLabel}
        startDateTime={selectedSlot.type === 'SUCCESS' ? selectedSlot.data.startDateTime : null}
        endDateTime={selectedSlot.type === 'SUCCESS' ? selectedSlot.data.endDateTime : null}
        isSlotExpired={isDeliverySlotReservationEnabled && selectedSlot.type === 'FAILURE'}
      />
    )
  }

  return <DeliverySelectButton.StoreSelectButton className={className} />
}

export const NavigationDeliverySelect = styled(_NavigationDeliverySelect)({})
