import { BrandStoreLogo } from 'components/BrandStoreLogo'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'

export const BrandLogoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  width: '56px',

  [customMinWidthFromTheme(theme).desktop]: {
    width: '72px',
  },

  [BrandStoreLogo]: {
    width: '100%',
    objectFit: 'cover',
  },
}))

export const Spacer = styled.div(({ theme }) => ({
  height: '24px',
  borderRight: `1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY}`,
}))

export const ButtonContents = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  overflow: 'hidden',
  height: '48px',
  columnGap: '4px',

  [customMinWidthFromTheme(theme).desktop]: {
    columnGap: '8px',
  },
}))

export const ButtonContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  columnGap: theme.spacings.xxSmall,
  ...theme.variants.caption.regular,
  fontSize: '0.75rem',
  overflow: 'hidden',

  [DeliveryAreaInfo]: { display: 'none' },
  [ErrorLabel]: { display: 'none ' },
  [DeliveryTime]: { display: 'none' },
  [CTALabel]: { display: 'none' },

  [customMinWidthFromTheme(theme).desktop]: {
    [DeliveryAreaInfo]: { display: 'revert' },
    [ErrorLabel]: { display: 'revert ' },
    [DeliveryTime]: { display: 'revert' },
    [CTALabel]: { display: 'revert' },
  },
}))

export const DeliveryAreaInfo = styled.div({ textOverflow: 'ellipsis', overflow: 'hidden' })
export const DeliverySlotInfo = styled.div({ textOverflow: 'ellipsis', overflow: 'hidden' })
export const DeliveryTime = styled.span({})
export const CTALabel = styled.span(({ theme }) => ({
  color: theme.colors.SDS_BRAND_COLOR_TEXT_MEDIUM_PRIMARY,
}))
export const ErrorLabel = styled.span(({ theme }) => ({
  color: theme.colors.SDS_BRAND_COLOR_TEXT_ERROR,
}))
