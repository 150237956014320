import type { DateTime } from 'luxon'
import type { FC } from 'react'
import type { IAbTestingBlock, IContentBlock, IPageLayoutFields } from 'types/contentful/generated'

import { getConfig } from '@shared/config'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { toDateTime } from 'domain/date-time'
import dynamic from 'next/dynamic'
import { useExperimentVariant } from 'services/Experiment/use-experiment-variant'
import { contentType } from 'utils/contentful/check-content-type'
import { useContentBlockContent } from 'utils/hooks/use-content-block-content'

import { HeroCarousel } from './contentful-section/HeroCarousel'
import { ContentfulSection } from './ContentfulSection'
import { ContentfulToolbox } from './ContentfulToolbox'

const { contentful } = getConfig()

const LazyLiveUpdateSection = dynamic(
  async () => (await import('./LiveUpdateSection')).LiveUpdateSection,
  { ssr: false },
)

const ContentBlock: FC<{
  section: IContentBlock
  pageSections: IPageLayoutFields['sections']
}> = ({ section, pageSections }) => {
  const block = useContentBlockContent(section)
  if (block) {
    return <Section section={block} pageSections={pageSections} />
  }
  return null
}

export const Section: FC<{
  section: NonNullable<IPageLayoutFields['sections']>[number]
  pageSections: IPageLayoutFields['sections']
  context?: 'menu-ajankohtaista'
}> = ({ section, pageSections, context }) => {
  if (contentType.isPageSection(section)) {
    return <ContentfulSection section={section} pageSections={pageSections} context={context} />
  } else if (contentType.isHeroCardsCarousel(section)) {
    return <HeroCarousel section={section} />
  } else if (contentType.isContentBlock(section)) {
    return <ContentBlock section={section} pageSections={pageSections} />
  } else if (contentType.isAbTestingBlock(section)) {
    return <AbTestBlock section={section} pageSections={pageSections} />
  }
  return null
}

export const PageLayoutSections: FC<{
  sections: IPageLayoutFields['sections'] | null
  context?: 'menu-ajankohtaista'
}> = ({ sections, context }) => {
  if (!sections || sections.length === 0) return null

  return (
    <>
      {sections.map((section, index) =>
        contentful.livePreview ? (
          <LazyLiveUpdateSection section={section} pageSections={sections} key={index} />
        ) : (
          <Section section={section} pageSections={sections} key={index} context={context} />
        ),
      )}
      {contentful.toolbox ? <ContentfulToolbox /> : null}
    </>
  )
}

const AbTestSection: FC<{
  experiment: string
  expirationDate: DateTime
  variants: NonNullable<IPageLayoutFields['sections']>
  pageSections: IPageLayoutFields['sections']
}> = ({ experiment, variants, pageSections, expirationDate }) => {
  const variant = useExperimentVariant(experiment, variants, expirationDate)

  if (variant === null) {
    return <SkeletonLoader height="300px" width="100%" />
  }

  return <Section section={variant} pageSections={pageSections} />
}

const AbTestBlock: FC<{
  section: IAbTestingBlock
  pageSections: IPageLayoutFields['sections']
}> = ({ section, pageSections }) => {
  if (
    !section.fields.experimentId ||
    !section.fields.variants?.length ||
    !section.fields.expirationDate
  ) {
    return section.fields.variants?.length ? (
      <Section section={section.fields.variants[0]} pageSections={pageSections} />
    ) : null
  }

  return (
    <AbTestSection
      experiment={section.fields.experimentId}
      expirationDate={toDateTime(section.fields.expirationDate)}
      variants={section.fields.variants}
      pageSections={pageSections}
    />
  )
}
