import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { StyledSectionWrapper, TitleAndParagraphText } from './common'

interface TitleWithParagraphProps {
  section: ISection
}

export const TitleWithParagraph: FC<TitleWithParagraphProps> = ({ section }) => {
  const { cards, tagTitle } = section.fields
  const card = cards?.[0]
  const { title, body } = card?.fields ?? {}
  const parsedBody = useParsedMarkdown(body)
  const hasData = !!title || !!parsedBody

  if (hasData) {
    return (
      <StyledSectionWrapper id={tagTitle}>
        <TitleAndParagraphText title={title} body={parsedBody} />
      </StyledSectionWrapper>
    )
  }

  return null
}
