import { useMotionValue, useMotionValueEvent, useScroll, useTransform } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'

const inputRange = [0, 56]
const outputRange = [0, -56]

export const useStickyNav = (searchOpen: boolean) => {
  const { scrollY } = useScroll()
  const scrollDistance = useMotionValue(0)
  const prevScrollDistance = useRef<number>(0)
  const prevDirection = useRef<'up' | 'down'>()
  const scrollYOnDirectionChange = useRef(scrollY.get())
  const position = useTransform(scrollDistance, inputRange, outputRange)

  const theme = useTheme()
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.tablet })

  useEffect(() => {
    scrollDistance.set(searchOpen && !isDesktop ? inputRange[1] : inputRange[0])
  }, [searchOpen, scrollDistance, isDesktop])

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest < 0 || isDesktop || searchOpen) return

    const direction = scrollY.getPrevious() - latest > 0 ? 'up' : 'down'

    if (direction !== prevDirection.current) {
      prevScrollDistance.current = scrollDistance.get()
      scrollYOnDirectionChange.current = latest
    }

    if (direction === 'down') {
      scrollDistance.set(
        Math.min(
          prevScrollDistance.current + (latest - scrollYOnDirectionChange.current),
          inputRange[1],
        ),
      )
    } else {
      scrollDistance.set(
        Math.max(
          prevScrollDistance.current - (scrollYOnDirectionChange.current - latest),
          inputRange[0],
        ),
      )
    }

    prevDirection.current = direction
  })

  return position
}
