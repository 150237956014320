import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { SDS_SPACE_XXSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { minWidthFromTheme } from '@sok/design-system'
import { Modal } from 'components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import { useModal } from 'services/Modal'
import styled from 'styled-components'

export const LogoutConfirmation: FC = () => {
  const { t } = useTranslation()
  const modal = useModal()

  return (
    <Modal.Dialogue
      title={t('Confirm logout')}
      onClose={modal.onCancel}
      footer={
        <>
          <StyledButtons>
            <Button
              onClick={modal.onSubmit}
              data-test-id="logout-confirmation-button"
              rounding="small"
              primary
            >
              {t('Logout')}
            </Button>
            <Button onClick={modal.onCancel} rounding="small">
              {t('Back')}
            </Button>
          </StyledButtons>
        </>
      }
    >
      <div data-test-id="logout-confirmation-content">
        {t('Logout will clear your active shopping cart')}
      </div>
    </Modal.Dialogue>
  )
}

const StyledButtons = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: SDS_SPACE_XXSMALL,
  [minWidthFromTheme(theme).tablet]: {
    flexDirection: 'row',
  },
}))
