import type { FC } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import styled from 'styled-components'

const _MenuGroup: FC<{ className?: string; label?: string; children: React.ReactNode }> = ({
  className,
  label,
  children,
}) => {
  return (
    <div className={className}>
      {label && <h3>{label}</h3>}
      <ul>{children}</ul>
    </div>
  )
}

export const MenuGroup = styled(_MenuGroup)(({ theme }) => ({
  marginTop: 0,

  ul: {
    listStyle: 'none',
  },
  h3: {
    ...theme.variants.body2.medium,
    color: theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,
    padding: `${theme.spacings.small} 0`,
    display: 'flex',
    alignItems: 'center',
  },

  [`${MenuLink} a`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacings.small,
    ...theme.variants.body2.regular,
    color: theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,
    margin: `0 -${theme.spacings.small}`,
    padding: theme.spacings.small,

    'span.newFeature': {
      background: theme.colors.SDS_BRAND_COLOR_BACKGROUND_INFO,
      color: theme.colors.white,
      fontSize: theme.sizes.xxxs.fontSize,
      borderRadius: '24px',
      padding: '0 8px',
    },

    '&[aria-current=page]': {
      background: theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_GREY,
      fontWeight: 500,
    },

    '&:focus': {
      outline: `3px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY}`,
    },

    '@media (hover: hover)': {
      '&:hover': {
        background: theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_GREY,
      },
    },
  },
}))

export const MenuLinks = styled.ul(({ theme }) => ({
  marginTop: theme.spacings.xLarge,
  flexGrow: 1,
  ul: {
    listStyle: 'none',

    '> * + *': {
      marginTop: theme.spacings.xxSmall,
    },
  },
}))

const _MenuLink: FC<{
  className?: string
  children: React.ReactNode
  icon?: React.ReactNode
  href: string
  target?: string
  onClick?: () => void
}> = ({ className, children, icon, href, onClick, target }) => {
  const router = useRouter()
  const isActive = router.asPath.split('?')[0] === href

  return (
    <li className={className}>
      <Link
        href={href}
        target={target}
        legacyBehavior={false}
        onClick={onClick}
        aria-current={isActive ? 'page' : 'false'}
      >
        {icon}
        {children}
      </Link>
    </li>
  )
}

export const MenuLink = styled(_MenuLink)({})

export const MenuFooter = styled.div(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacings.xxSmall,
  ...theme.variants.body1.regular,

  p: { width: '100%' },
}))
