import type { Asset } from 'contentful'
import type { FC } from 'react'
import type { ICardFields } from 'types/contentful/generated'

import { minWidthFromTheme } from '@sok/design-system'
import { SubHeader, TextBlock } from 'components/common/Titles'
import { NextLink } from 'components/NextLink'
import styled from 'styled-components'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { ContentfulImage } from '../../ContentfulImage'

const StyledItemIcon = styled(ContentfulImage)(({ theme }) => ({
  height: '80px',
  width: '100%',
  objectFit: 'contain',
  [minWidthFromTheme(theme).tablet]: {
    height: '100px',
  },
}))

const RowIcon: FC<{ iconLink?: string; icon: Asset }> = ({ icon, iconLink }) => {
  return iconLink ? (
    <NextLink href={iconLink}>
      <StyledItemIcon image={icon} lazy />
    </NextLink>
  ) : (
    <StyledItemIcon image={icon} lazy />
  )
}

interface RowItemProps {
  card: ICardFields
  className?: string
}

const _RowItem: FC<RowItemProps> = ({ card, className }) => {
  const icon = card?.image
  const parsedDescription = useParsedMarkdown(card.body)

  return (
    <section className={className}>
      {icon && <RowIcon icon={icon} iconLink={card.imageLink} />}
      {!!card.title && <SubHeader>{card.title}</SubHeader>}
      {parsedDescription && <TextBlock>{parsedDescription}</TextBlock>}
    </section>
  )
}

_RowItem.displayName = 'RowItem'

export const RowItem = styled(_RowItem)(({ theme }) => ({
  [SubHeader]: {
    margin: `${theme.spacings.small} 0`,
  },

  [TextBlock]: {
    margin: `${theme.spacings.small} 0`,
  },
}))
