import type { ThemedObjectStyleFunction } from 'domain/styled'

import { minWidthFromTheme } from '@sok/design-system'
import { Lowest30DayPrice } from 'components/product-price/Lowest30DayPrice'
import { ProductLabels } from 'components/ProductLabels'
import styled from 'styled-components'
import { middleDesktop, smallMobile } from 'styles/layout'

import {
  _ProductCard,
  defaultStyles,
  ExtraActionsContainer,
  lessOpacity,
  ProductDetails,
} from './ProductCard'
import { ProductComment } from './ProductCommentModal'
import { QuantityModifier } from './QuantityModifier'
import { RemoveCartItem } from './RemoveCartItem'
import { rowStyles } from './Row'

/**
 * Modified Row Component Styles for ShoppingCartSummary
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5899%3A185789
**/
const cartStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({ theme, disabled }) => ({
  gridTemplateRows: 'auto min-content 1fr',
  gridTemplateColumns: 'min-content 1fr min-content',

  borderRadius: 0,
  borderBottom: `1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY}`,

  [smallMobile]: {
    gridTemplateColumns: 'min-content 1fr auto min-content',
  },

  [ProductDetails]: {
    gridColumn: '2 / 3',

    [smallMobile]: {
      gridColumn: '2 / 5',
    },

    [minWidthFromTheme(theme).mobile]: {
      gridColumn: '2 / 3',
    },
  },

  [Lowest30DayPrice]: {
    display: 'none',
  },

  [ProductLabels]: {
    gridRow: 2,
    gridColumn: '2 / 3',
    alignSelf: 'center',

    [minWidthFromTheme(theme).mobile]: {
      gridColumn: '2 / 3',
    },
  },

  [QuantityModifier]: {
    ...lessOpacity(disabled),
    gridRow: '3 / 4',
    gridColumn: '2 / 4',
    marginTop: theme.spacings.xxSmall,

    [minWidthFromTheme(theme).mobile]: {
      marginTop: '0',
    },

    /*
        Fills up the extra space
        Note: This will be removed once quantity modifier with animation is implemented
      */
    [smallMobile]: {
      marginTop: 0,
      gridRow: 2,
      gridColumn: '3 / 5',
      justifySelf: 'end',
      alignSelf: 'center',
    },

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '4 / 5',
      gridRow: '1 / 3',
      alignSelf: 'center',
    },
  },

  [RemoveCartItem]: {
    gridRow: 3,
    gridColumn: '2 / 3',
    marginTop: theme.spacings.xSmall,

    /*
        Fills up the extra space
        Note: This will be removed once quantity modifier with animation is implemented
      */
    [smallMobile]: {
      marginTop: 0,
      gridRow: 2,
      gridColumn: '3 / 5',
      justifySelf: 'end',
      alignSelf: 'center',
    },

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '4 / 5',
      gridRow: '1 / 4',
      alignSelf: 'center',
    },
  },

  [ExtraActionsContainer]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridColumn: '1 / 3',
    gridRow: '4 / 5',
    marginLeft: 0,
    marginBottom: 0,

    '> div': {
      display: 'flex',
      alignItems: 'flex-end',
      gap: theme.spacings.xxSmall,
    },

    [smallMobile]: {
      gridColumn: '1 / 5',
      gridRow: '3 / 4',
      alignSelf: 'center',
    },

    [middleDesktop]: {
      gridColumn: '3 / 4',
      gridRow: '1 / 4',
      alignSelf: 'center',
      margin: 0,
      columnGap: theme.spacings.xSmall,
      marginRight: theme.spacings.medium,
    },
  },

  [ProductComment]: {
    gridColumn: '1 / 3',
    gridRow: '5 / 6',
    marginTop: theme.spacings.xSmall,

    [smallMobile]: {
      gridColumn: '1 / 5',
    },
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, cartStyles)

export const Cart = { Success }

Cart.Success.defaultProps = {
  readOnlyImage: true,
  readOnlyName: true,
}
