import type { Maybe, NavigationItem } from '@shared/gql/document-nodes'
import type { NavigationItemTree } from 'types/gql/navigation-item-tree'
import type { NonNullableFields } from 'types/non-nullable-fields'

export const searchNavigationItem = (
  tree: NavigationItemTree,
  value: string | string[] | undefined,
  key = 'id',
  reverse = false,
): Maybe<NavigationItem> | undefined => {
  const stack = [...(tree as NavigationItem[])]

  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']()

    if (node?.[key as keyof NavigationItem] === value) {
      return node
    }

    if (node && node.children) {
      stack.push(...(node.children as NavigationItem[]))
    }
  }

  return undefined
}

export const isNavigationItem = (
  item?: Maybe<NavigationItem>,
): item is NonNullableFields<NavigationItem, 'id' | 'name' | 'slug'> =>
  !!item && item.__typename === 'NavigationItem' && !!item.id && !!item.name && !!item.slug
