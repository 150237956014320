import type { Unit } from './unit'
import type { EuroCents } from '@shared/domain/euro-cents'

import { getCartItemTotal } from './get-cart-items-total'

export interface Price {
  isApproximate: boolean
  unit: Unit | null
  value: EuroCents
}

export const formattedPrice = (x: Price): string =>
  x.isApproximate ? x.value.formatApproximate() : x.value.format()

export const formattedPriceWithUnit = (x: Price): string => `${formattedPrice(x)}/${x.unit ?? ''}`

interface FormattedPriceProps {
  approxPrice?: boolean | null
  itemCount: number | string | null
  price: number | null
}

export const formattedItemPrice = (price: FormattedPriceProps): string =>
  price.approxPrice ? getCartItemTotal(price).formatApproximate() : getCartItemTotal(price).format()
