import type { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { PromptCard } from './PromptCard'

export const MembershipCardPrompt: FC = () => {
  const { t } = useTranslation()

  return (
    <PromptCard
      caption={t('No S-bonus card found for recommendations')}
      icon="s-bonus-card"
      href="/suosittelemme-sinulle"
    />
  )
}
