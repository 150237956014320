import type { IContentBlock } from 'types/contentful/generated'

import { useQuery } from '@apollo/client'
import { RemoteStoreCoopInfoDocument } from '@shared/gql/document-nodes'
import { useRouter } from 'next/router'
import { getContentBlock } from 'utils/contentful/get-content-block'

import { useStoreId } from './store/use-store-id'

/**
 * Extract single content entry from ContentBlock contentful entry,
 * which contains content variants for different stores and co-ops.
 *
 * On store pages (/myymala/) the content is extracted based on store id in url.
 * On other pages the content is extracted based on the currently selected store id.
 */
export const useContentBlockContent = (section: IContentBlock) => {
  const { selectedStoreId } = useStoreId()
  const { asPath, query } = useRouter()
  const isStorePage = asPath.startsWith('/myymala/')

  const storeId = isStorePage ? query['slugAndId']?.[1] : selectedStoreId

  const { data } = useQuery(RemoteStoreCoopInfoDocument, {
    variables: { id: storeId || '' },
    skip: !storeId,
  })

  return getContentBlock(section, data?.store?.id, data?.store?.coOperative ?? undefined)
}
