import type { Product } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { ProductCard } from 'components/Product'
import { ProductAvailabilityInfo } from 'components/ProductAvailabilityInfo'
import { ProductLabels } from 'components/ProductLabels'
import { FavoriteButtonContainer } from 'favorites/components/FavoriteButtonContainer'
import { useCallback } from 'react'
import { useCurrentProductAvailability } from 'services/Product/hooks/use-current-product-availibility'
import { useProductUserEvent } from 'services/ProductUserEvent/product-user-event'
import {
  TrackingContextProvider,
  useTrackingContext,
} from 'utils/tracking/components/TrackingContextProvider'
import { GA4_trackProductClick } from 'utils/tracking/track-product-click'

import { AddToCart } from '../ShoppingCart/AddToCart'

interface Props {
  handleResetSearch: () => void
  isMobile?: boolean
  product: Pick<
    Product,
    | 'approxPrice'
    | 'availabilities'
    | 'basicQuantityUnit'
    | 'brandName'
    | 'comparisonPrice'
    | 'comparisonUnit'
    | 'countryName'
    | 'depositPrice'
    | 'ean'
    | 'frozen'
    | 'hierarchyPath'
    | 'id'
    | 'isAgeLimitedByAlcohol'
    | 'name'
    | 'packagingLabelCodes'
    | 'price'
    | 'price'
    | 'priceUnit'
    | 'quantityMultiplier'
    | 'slug'
    | 'storeId'
    | 'isGlobalFallback'
    | 'pricing'
  >
  suggestionIndex: number
}

const _ProductSuggestion: FC<Props> = ({ handleResetSearch, product, suggestionIndex }) => {
  const { sendProductUserClickEvent } = useProductUserEvent()
  const trackingContext = useTrackingContext()

  const availability = useCurrentProductAvailability(product.availabilities)

  const handleClick = useCallback(async () => {
    handleResetSearch()

    const { id, name, price, hierarchyPath } = product

    if (name && price && hierarchyPath && hierarchyPath.length > 0) {
      GA4_trackProductClick({
        ...trackingContext,
        listPosition: suggestionIndex,
        products: [product],
      })
    }
    await sendProductUserClickEvent(id)
  }, [handleResetSearch, product, sendProductUserClickEvent, suggestionIndex, trackingContext])

  if (!product) return null

  return (
    <ProductCard.AutoSuggest.Success
      data={product}
      status={<ProductAvailabilityInfo availabilities={product.availabilities} />}
      handleClick={handleClick}
      data-test-id="search-product-suggestion"
    >
      <ProductLabels
        data-test-id="search-product-suggestion__productLabels"
        product={{
          isAgeLimitedByAlcohol: product.isAgeLimitedByAlcohol,
          packagingLabelCodes: product.packagingLabelCodes,
          frozen: product.frozen,
        }}
        size={16}
        truncate
      />
      <FavoriteButtonContainer ean={product.ean || ''} />
      <TrackingContextProvider
        availabilityLabel={availability?.label || undefined}
        listPosition={suggestionIndex}
      >
        <AddToCart product={product} />
      </TrackingContextProvider>
    </ProductCard.AutoSuggest.Success>
  )
}

_ProductSuggestion.displayName = 'ProductSuggestion'

export const ProductSuggestion = _ProductSuggestion
