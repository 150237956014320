import type { ErrorMessage } from 'domain/error-messages/error-messages'

import { sequenceS } from 'fp-ts/Apply'
import { getMonoid } from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'

export interface ValidationError {
  message: string
  tag: string
  path: readonly string[]
  data?: Record<string, string>
}

export type Validator<T, ValidatedT> = (t: T) => E.Either<ValidationError[], ValidatedT>

export const validate = sequenceS(E.getApplicativeValidation(getMonoid<ValidationError>()))

export const maybeValidationErrors = <T>(
  x: E.Either<ValidationError[], T>,
): O.Option<Record<string, ValidationError>> =>
  pipe(
    x,
    E.mapLeft((xs) =>
      xs.reduce<Record<string, ValidationError>>((p, c) => {
        p[c.path.join('.')] = c
        return p
      }, {}),
    ),
    E.fold(
      (errors) => O.some(errors),
      () => O.none,
    ),
  )

export const validationErrorOf = (
  message: string,
  path: readonly string[] = [],
  tag: string = message,
): ValidationError => ({
  message,
  path,
  tag,
})

export const setErrorType = (errorType: ErrorMessage) => (x: ValidationError) => ({
  ...x,
  data: {
    ...(x.data || {}),
    errorType,
  },
})
