import type { ButtonProps, LinkButtonProps } from '@s-group/design-system-components'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { ButtonHTMLAttributes, FC } from 'react'

import { Button, LinkButton } from '@s-group/design-system-components'
import {
  SDS_FONT_LINEHEIGHT_BODY_SMALL,
  SDS_FONT_LINEHEIGHT_BODY_XSMALL,
  SDS_FONT_SIZE_BODY_SMALL,
  SDS_FONT_SIZE_BODY_XSMALL,
  SDS_FONT_WEIGHT_REGULAR,
} from '@s-group/design-system-tokens/web/tokens/font.es6'
import {
  SDS_SPACE_XXLARGE,
  SDS_SPACE_XXSMALL,
  SDS_SPACE_XXXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'
import React from 'react'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'

const StyledNavigationButton: ThemedObjectStyleFunction = ({ theme }) => ({
  position: 'relative',

  height: SDS_SPACE_XXLARGE,
  minWidth: SDS_SPACE_XXLARGE,
  padding: `0 ${SDS_SPACE_XXXSMALL}`,

  columnGap: SDS_SPACE_XXSMALL,
  flexFlow: 'column',

  fontSize: SDS_FONT_SIZE_BODY_XSMALL,
  lineHeight: SDS_FONT_LINEHEIGHT_BODY_XSMALL,
  fontWeight: SDS_FONT_WEIGHT_REGULAR,
  whiteSpace: 'nowrap',

  color: theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,

  ['.button-icon']: {
    color: 'inherit',
    paddingRight: 0,
  },

  [customMinWidthFromTheme(theme).middleDesktop]: {
    padding: `0 ${SDS_SPACE_XXSMALL}`,
    fontSize: SDS_FONT_SIZE_BODY_SMALL,
    lineHeight: SDS_FONT_LINEHEIGHT_BODY_SMALL,
    flexFlow: 'row',

    ['.button-icon']: {
      marginRight: 0,
    },
  },
})

const _NavigationActionButton: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> =
  React.forwardRef(({ children, ...props }, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button plain rounding="small" compact {...props} ref={ref}>
        {children}
      </Button>
    )
  })

_NavigationActionButton.displayName = '_NavigationActionButton'

const _NavigationLinkButton: FC<LinkButtonProps> = React.forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <LinkButton plain rounding="small" compact {...props} ref={ref}>
        {children}
      </LinkButton>
    )
  },
)

_NavigationLinkButton.displayName = '_NavigationLinkButton'

export const NavigationActionButton = styled(_NavigationActionButton)(StyledNavigationButton)
export const NavigationLinkButton = styled(_NavigationLinkButton)(StyledNavigationButton)
