import type { ThemedObjectStyleFunction } from 'domain/styled'

import { ComparisonPrice } from 'components/product-price/ComparisonPrice'
import { Lowest30DayPrice, PopoverContainer } from 'components/product-price/Lowest30DayPrice'
import { ProductPriceContainer } from 'components/product-price/ProductPrice'
import { ProductLabels } from 'components/ProductLabels'
import styled from 'styled-components'

import {
  _ProductCard,
  defaultStyles,
  ExtraActionsContainer,
  lessOpacity,
  ProductDetails,
} from './ProductCard'
import { ProductComment } from './ProductCommentModal'
import { QuantityModifier } from './QuantityModifier'
import { RemoveCartItem } from './RemoveCartItem'
import { rowStyles } from './Row'

/**
 * Modified Row Component Styles for ShopingSideCart
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5899%3A187051
**/
const sideCartStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({
  theme,
  disabled,
}) => ({
  gridTemplateRows: 'auto min-content 1fr',
  gridTemplateColumns: 'min-content 1fr min-content',

  [ProductDetails]: {
    gridColumn: '2 / 5',
    [ProductPriceContainer]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      [ComparisonPrice]: {
        paddingLeft: theme.spacings.xxSmall,
      },
    },
    [PopoverContainer]: {
      display: 'none',
    },
    [Lowest30DayPrice]: {
      display: 'none',
    },
  },

  [ExtraActionsContainer]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridColumn: '1 / 5',
    gridRow: '4 / 5',
    marginLeft: 0,
    marginBottom: 0,

    '> div': {
      display: 'flex',
      alignItems: 'flex-end',
      gap: theme.spacings.xxSmall,
    },
  },

  [ProductLabels]: {
    gridRow: 2,
    gridColumn: '2 / 3',
    alignSelf: 'center',

    '> li': {
      flexDirection: 'row',
    },
  },

  [QuantityModifier]: {
    ...lessOpacity(disabled),
    gridRow: 2,
    gridColumn: '3 / 5',
    justifySelf: 'end',
  },

  [RemoveCartItem]: {
    gridRow: 2,
    gridColumn: '3 / 5',
    justifySelf: 'end',
    alignSelf: 'center',
  },

  [ProductComment]: {
    marginTop: theme.spacings.xSmall,
    gridColumn: '1 / 5',
    gridRow: '5 / 6',
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, sideCartStyles)

export const SideCart = { Success }
