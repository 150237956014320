import type { VirtualPageview } from './interfaces/data-layer-events'

import { dataLayer } from './tag-manager'

export const trackPageview = (virtualPageview: VirtualPageview): void => {
  dataLayer({
    event: 'virtualPageview',
    virtualPageview,
  })
}
