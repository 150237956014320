import type { ValidationError } from './validators'

export const setValidationErrorMessage =
  (message: string) =>
  ([e]: ValidationError[]): ValidationError[] =>
    [
      {
        ...e,
        message,
      },
    ]
