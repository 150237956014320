import type { FC, HTMLAttributes } from 'react'

import styled from 'styled-components'

export interface AgeRestrictedProps extends HTMLAttributes<HTMLDivElement> {
  age: number
  background?: string
  foreground?: string
  size?: number
}

const _AgeRestricted: FC<AgeRestrictedProps> = ({
  age,
  background: _background,
  foreground: _foreground,
  size: _size,
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <i data-test-id="age-restricted" {...rest}>
    <span>{age}+</span>
  </i>
)

_AgeRestricted.displayName = 'AgeRestricted'

/** Red circle with a number inside, eg. "18+" */
export const AgeRestricted = styled(_AgeRestricted)<AgeRestrictedProps>(
  ({ background, foreground, size }) => ({
    alignItems: 'center',
    backgroundColor: background,
    borderRadius: '50%',
    color: foreground,
    display: 'flex',
    fontSize: size,
    fontStyle: 'normal',
    height: size,
    justifyContent: 'center',
    width: size,

    '> span': {
      // When the text position is just right
      transform: 'translateX(3%) scale(0.4)',
    },
  }),
)

AgeRestricted.defaultProps = {
  age: 18,
  background: '#f8c4ba',
  foreground: '#c6161a',
  role: 'presentation',
  size: 24,
}
