import type { FC } from 'react'
import type { NavigationItemTree } from 'types/gql/navigation-item-tree'

import { ChevronRight } from 'components/icons'
import { SkeletonLoader } from 'components/SkeletonLoader'
import { motionSlideRight, Variant } from 'constants/animation'
import { AnimatePresence, motion } from 'framer-motion'
import { range } from 'ramda'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { usePreferredMotion } from 'utils/hooks/use-preferred-motion'
import { isNonNullable } from 'utils/nullable'

import { ScrollForMore } from './desktop/ScrollForMore'
import { NavigationIcon } from './NavigationIcon'

interface CategoryItemProps {
  isActive?: boolean
}

const StyledCategoryWrapper = styled(motion.div)(({ theme }) => ({
  /** global nav + local nav */
  height: '100%',
  overflow: 'auto',
  width: '100%',

  [`@media (min-width: calc(${theme.breakpoints.tablet} + 1px))`]: {
    borderRight: `solid 1px ${theme.color.lightGrey}`,
    /** global nav + local nav */
    minWidth: 300,
    maxWidth: 400,
    overflowY: 'auto',
    position: 'relative',
    width: '25%',

    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
    },
  },
}))

const StyledChevronWrapper = styled.div({
  marginRight: 24,
  marginTop: 5,
})

interface ChevronProps {
  active?: string
}

const StyledChevron = styled(ChevronRight)<ChevronProps>`
  path {
    fill: ${({ theme }) => theme.color.lightGrey};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    path {
      fill: ${({ active, theme }) => active === 'true' && theme.color.fontBlack};
    }
  }
`

const body2 = css(({ theme }) => theme.variants.body2.regular)

const StyledCategoryTitle = styled.div<CategoryItemProps>`
  ${body2};
  margin-left: 16px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-weight: ${({ isActive, theme }) => isActive && theme.fontWeights.medium};
  }
`

const StyledCategoryItem = styled.div<CategoryItemProps>`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.lightestGrey100}`};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.xxSmall} 0;
  width: 100%;

  &:last-child {
    margin-bottom: 80px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border: none;
    background-color: ${({ isActive, theme }) => isActive && theme.color.lightestGrey100};

    &:hover {
      background-color: rgba(238, 238, 238, 0.5);
    }

    &:last-child {
      margin-bottom: initial;
    }

    &:active {
      background-color: ${({ theme }) => theme.color.lightestGrey100};
      ${StyledChevron} {
        path {
          fill: ${({ theme }) => theme.color.fontBlack};
        }
      }
      ${StyledCategoryTitle} {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }
  }
`

const StyledCategoryItemLoading = styled(StyledCategoryItem)`
  padding-left: 24px;
`

const StyledCategoryIconTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
`

const StyledCategoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-left: 24px;
  width: 36px;
`

const StyledSectionTitle = styled.div`
  ${body2};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: 24px;
  padding: ${({ theme }) => theme.spacings.xxSmall} 0;
  line-height: ${({ theme }) => theme.spacings.xLarge};
  cursor: default;
`

const isElementScrollable = (elem: HTMLElement | null): boolean => {
  if (!elem) return false
  const scrollHeight = elem.scrollHeight
  const height = elem.getBoundingClientRect().height
  return scrollHeight > height
}

const StyledNewFeatureIndicator = styled.div(({ theme }) => ({
  background: theme.colors.info800,
  color: theme.colors.white,
  fontSize: theme.sizes.xxxs.fontSize,
  margin: '0 8px',
  borderRadius: '24px',
  padding: '4px 8px',
}))

interface ServiceItem {
  id: string
  path: string
  name: string
  icon: JSX.Element
  enabled?: boolean
  newFeature?: boolean
}

interface CategoryProps {
  isActive: (slug: string | null | undefined) => boolean
  isDesktop?: boolean
  items: NavigationItemTree
  loading?: boolean
  onItemClick: (id: string, slug: string) => void
  onServiceItemClick: (id: string, path: string) => void
  serviceItems: ServiceItem[]
}

const CategoryComponent: FC<CategoryProps> = ({
  isActive,
  isDesktop,
  items,
  loading,
  onItemClick,
  onServiceItemClick,
  serviceItems,
}) => {
  const { t } = useTranslation()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [isScrollable, setIsScrollable] = useState(false)
  const observerRef = useRef<ResizeObserver | null>(null)

  const animationVariant = usePreferredMotion(Variant.animate)

  // Detect whether to show the Scroll indicator after resizing,
  // if `ResizeObserver` is supported.
  useEffect(() => {
    setIsScrollable(isElementScrollable(wrapperRef.current))

    if (window.ResizeObserver && wrapperRef.current && !observerRef.current) {
      const observer = new ResizeObserver(() => {
        setIsScrollable(isElementScrollable(wrapperRef.current))
      })
      observer.observe(wrapperRef.current)
      observerRef.current = observer

      return () => void observer.disconnect()
    }
  }, [])

  const [scrolledToTop, setScrolledToTop] = useState(true)

  const handleScroll = useCallback(() => {
    if (wrapperRef.current) {
      setScrolledToTop(wrapperRef.current.scrollTop <= 0)
    }
  }, [])

  const handleScrollToBottom = useCallback(() => {
    wrapperRef.current?.scrollBy({ behavior: 'smooth', top: wrapperRef.current?.scrollHeight })
  }, [])

  if (loading) {
    const NUMBER_OF_PLACEHOLDERS = 18
    return (
      <StyledCategoryWrapper data-test-id="product-navigation-category-loading">
        {range(0, NUMBER_OF_PLACEHOLDERS).map((id) => (
          <StyledCategoryItemLoading key={String(id)}>
            <SkeletonLoader width="300px" height="22px" />
          </StyledCategoryItemLoading>
        ))}
      </StyledCategoryWrapper>
    )
  }

  if (!items) {
    return null
  }

  return (
    <StyledCategoryWrapper
      ref={wrapperRef}
      data-test-id="product-navigation-category"
      onScroll={handleScroll}
      initial={Variant.initial}
      animate={animationVariant}
      exit={Variant.initial}
      variants={motionSlideRight}
    >
      {serviceItems.length > 0 && <StyledSectionTitle>{t('Services')}</StyledSectionTitle>}
      {serviceItems.map((item) => (
        <StyledCategoryItem
          data-test-id="product-navigation-service-item"
          key={item.id}
          onClick={() => onServiceItemClick(item.id, item.path)}
        >
          <StyledCategoryIconTitleWrapper>
            <StyledCategoryIcon>{item.icon}</StyledCategoryIcon>
            <StyledCategoryTitle>{item.name}</StyledCategoryTitle>
            {item.newFeature && (
              <StyledNewFeatureIndicator>{t('Menu new feature')}</StyledNewFeatureIndicator>
            )}
          </StyledCategoryIconTitleWrapper>
          <StyledChevronWrapper>
            <StyledChevron />
          </StyledChevronWrapper>
        </StyledCategoryItem>
      ))}

      <StyledSectionTitle>{t('Product categories')}</StyledSectionTitle>

      {items.filter(isNonNullable).map((item) => (
        <StyledCategoryItem
          data-test-id="product-navigation-category-item"
          key={item.id}
          isActive={isActive(item.slug)}
          onClick={() => onItemClick(item.id ?? '', item.slug ?? '')}
        >
          <StyledCategoryIconTitleWrapper>
            <StyledCategoryIcon>
              <NavigationIcon id={item.id} />
            </StyledCategoryIcon>
            <StyledCategoryTitle isActive={isActive(item.slug)}>{item.name}</StyledCategoryTitle>
          </StyledCategoryIconTitleWrapper>
          <StyledChevronWrapper>
            <StyledChevron active={String(isActive(item.slug))} />
          </StyledChevronWrapper>
        </StyledCategoryItem>
      ))}

      <AnimatePresence>
        {isDesktop && isScrollable && scrolledToTop && (
          <ScrollForMore onClick={handleScrollToBottom} />
        )}
      </AnimatePresence>
    </StyledCategoryWrapper>
  )
}

CategoryComponent.displayName = 'Category'

export const Category = memo(CategoryComponent)
