import { trackCustomEvent } from './custom-events/custom-events'
import { EventAction, EventCategory } from './interfaces/data-layer-events'

export const trackMenuClick = () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_menu`,
  })
}

export const trackMenuItemClick = (itemTitle: string) => () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header-menu_${itemTitle ? itemTitle.toLowerCase() : ''}`,
  })
}

export const trackHomeClick = () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_home-logo`,
  })
}

export const trackProductCategoriesClick = () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_navigation`,
  })
}

export const trackDeliverySelectClick = () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_delivery-method-selector`,
  })
}

export const trackUserClick = (isLoggedIn: boolean) => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_profile-${isLoggedIn ? 'logged_in' : 'logged_out'}`,
  })
}

export enum NavProfileItem {
  LOGGED_IN = 'logged-in',
  LOGGED_OUT = 'logged_out',
  ORDERS = 'orders',
  PROFILE = 'profile',
  FAVORITES = 'favorites',
  SHOPPINGLISTS = 'shoppinglists',
  SMART_CART = 'smart-cart',
  LOG_OUT = 'log-out',
  LOGGED_OUT_LOGIN = 'logged-out-login',
  LOGGED_OUT_CREATE_ACCOUNT = 'logged-out-create_account',
}

export const trackUserItemClick = (item: NavProfileItem) => () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header-profile_${item}`,
  })
}

export const trackCartClick = () => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_shopping-cart`,
  })
}

export const trackSidepanelToggleClick = (isSidepanelVisible: boolean) => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_side-cart-${isSidepanelVisible ? 'hide' : 'show'}`,
  })
}
