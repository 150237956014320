import type { FC } from 'react'
import type { ServiceBannerEntry } from 'types/contentful'
import type { IServiceBannerFields } from 'types/contentful/generated'

import { useQuery, useReactiveVar } from '@apollo/client'
import {
  IconCommunicationMegaphone,
  IconNavigationClose,
  IconStatusErrorFilled,
  IconStatusInfoFilled,
} from '@s-group/design-system-icons'
import {
  SDS_FONT_LINEHEIGHT_BODY_SMALL,
  SDS_FONT_SIZE_BODY_SMALL,
} from '@s-group/design-system-tokens/web/tokens/font.es6'
import {
  SDS_MOTION_DURATION_FASTEST,
  SDS_MOTION_EASING_DEFAULT,
} from '@s-group/design-system-tokens/web/tokens/motion.es6'
import {
  SDS_BRAND_COLOR_BACKGROUND_ERROR,
  SDS_BRAND_COLOR_BACKGROUND_INFO,
  SDS_BRAND_COLOR_BACKGROUND_SUCCESS,
  SDS_BRAND_COLOR_TEXT_ERROR,
  SDS_BRAND_COLOR_TEXT_INFO,
  SDS_BRAND_COLOR_TEXT_SUCCESS,
} from '@s-group/design-system-tokens/web/tokens/s-kaupat/colors.es6'
import {
  SDS_SPACE_MEDIUM,
  SDS_SPACE_SMALL,
  SDS_SPACE_XXSMALL,
} from '@s-group/design-system-tokens/web/tokens/space.es6'
import { getConfig } from '@shared/config'
import { RemoteStoreCoopInfoDocument } from '@shared/gql/document-nodes'
import { maxWidthFromTheme, minWidthFromTheme } from '@sok/design-system'
import { NextLink } from 'components/NextLink'
import { getValidatedUrl } from 'domain/link-validation'
import { navigationVisibleVar } from 'lib/apollo/reactive-vars'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Notifications, notificationsVar } from 'services/Notifications'
import styled from 'styled-components'
import { clampLines } from 'styles/clamp-lines'
import { SERVICE_INFORMATION_BANNER_HEIGHT } from 'styles/layout'
import { useGetSelectedBrand } from 'utils/brand/use-get-selected-brand'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

import { getTargetedServiceBanner } from './get-targeted-service-banner'
import { trackServiceBannerClick, trackServiceBannerClose } from './tracking'

interface ServiceBannerProps {
  bannerItems: ServiceBannerEntry[]
  isSearchOpen: boolean
  isProductNavigationVisible: boolean
  className?: string
}

const { featureFlags } = getConfig()

export const criticalErrorBanners = (banners: ServiceBannerEntry[]) => {
  return banners
    .filter((a) => a.banner.bannerType === 'error')
    .filter((item) => item.banner.isCritical)
}

const _ServiceBanner: FC<ServiceBannerProps> = ({ bannerItems }) => {
  const bannersForPlatform = featureFlags.serviceBannerFeatureFlag
    ? bannerItems.filter((b) => !b?.banner.hideOnPlatform?.includes('web'))
    : bannerItems
  const { dismissed } = useReactiveVar(notificationsVar)
  const isNavigationVisible = useReactiveVar(navigationVisibleVar)

  const store = useStoreId()
  const { data } = useQuery(RemoteStoreCoopInfoDocument, {
    variables: { id: store.selectedStoreId },
    skip: !store,
  })
  const isClientSide = useIsClientSide()
  const brand = useGetSelectedBrand()
  const [allBanners, setAllBanners] = useState<ServiceBannerEntry[]>(bannersForPlatform)

  const coOp = data?.store?.coOperative

  // Filter dismissed banned
  useEffect(() => {
    const nondismissed = allBanners.filter(
      (item) =>
        !(item.banner.bannerType === 'marketing' && dismissed.includes(`banner-${item.id}`)),
    )
    setAllBanners(nondismissed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissed])

  const selectedBanner = useMemo(
    () =>
      getTargetedServiceBanner({
        brand,
        storeId: store.selectedStoreId,
        coOp,
        content: allBanners,
      }),
    [allBanners, brand, coOp, store.selectedStoreId],
  )

  // Add marketing banner to Notifications service
  useEffect(() => {
    if (selectedBanner?.banner.bannerType === 'marketing') {
      Notifications.add({
        group: 'servicebanner-marketing',
        id: `banner-${selectedBanner.id}`,
        __kind: 'ServiceBannerMarketingMessage',
      })
    }
  }, [selectedBanner])

  const getBannerInfo = (bannerType: string) => {
    switch (bannerType) {
      case 'error':
        return {
          icon: <IconStatusErrorFilled />,
          background: SDS_BRAND_COLOR_BACKGROUND_ERROR,
          color: SDS_BRAND_COLOR_TEXT_ERROR,
        }
      case 'marketing':
        return {
          icon: <IconCommunicationMegaphone />,
          background: SDS_BRAND_COLOR_BACKGROUND_SUCCESS,
          color: SDS_BRAND_COLOR_TEXT_SUCCESS,
        }
      case 'info':
      default:
        return {
          icon: <IconStatusInfoFilled />,
          background: SDS_BRAND_COLOR_BACKGROUND_INFO,
          color: SDS_BRAND_COLOR_TEXT_INFO,
        }
    }
  }

  const onServiceBannerClose = useCallback(() => {
    if (!selectedBanner) return
    Notifications.dismiss(`banner-${selectedBanner.id}`)
    trackServiceBannerClose(selectedBanner.banner)
    const nondismissed = allBanners.filter((item) => item.id !== selectedBanner.id)
    setAllBanners(nondismissed)
  }, [allBanners, selectedBanner])

  // Render only in client-side
  if (!isClientSide || !selectedBanner) return null

  const { bannerType } = selectedBanner.banner
  const { background, icon, color } = getBannerInfo(bannerType)

  if (isNavigationVisible) return null

  return (
    <ServiceBannerContainer background={background} color={color} data-test-id={'service-banner'}>
      <StyledContentWrapper>
        <IconContainer>{icon}</IconContainer>
        <BannerMessage banner={selectedBanner.banner} />
      </StyledContentWrapper>
      {bannerType === 'marketing' && (
        <CloseIconContainer data-test-id="service-banner-close">
          <IconNavigationClose onClick={onServiceBannerClose} />
        </CloseIconContainer>
      )}
    </ServiceBannerContainer>
  )
}

const BannerMessage = ({ banner }: { banner: IServiceBannerFields }) => {
  const body = useParsedMarkdown(banner.bannerContent)
  const url = useParsedMarkdown(banner.linkToContent)
  const onBannerMessageClick = useCallback(() => trackServiceBannerClick(banner), [banner])

  if (featureFlags.serviceBannerFeatureFlag && banner.bannerCopy !== undefined) {
    return (
      <StyledWrapper marketingBanner={banner.bannerType === 'marketing'}>
        <StyledBody>{banner.bannerCopy}</StyledBody>
        {banner.linkUrl && banner.linkText && (
          <StyledLink>
            <StyledNextLink
              aria-label={banner.linkAccessibilityLabel}
              href={getValidatedUrl(banner.linkUrl)}
              onClick={onBannerMessageClick}
            >
              {banner.linkText}
            </StyledNextLink>
          </StyledLink>
        )}
      </StyledWrapper>
    )
  }

  return (
    <StyledWrapper marketingBanner={banner.bannerType === 'marketing'}>
      <StyledBody>{body}</StyledBody>
      {url ?? <StyledLink>{url}</StyledLink>}
    </StyledWrapper>
  )
}

const StyledContentWrapper = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${SDS_SPACE_XXSMALL} ${SDS_SPACE_MEDIUM}`,
})

const StyledWrapper = styled.div<{ marketingBanner: boolean }>(
  ({ marketingBanner }) => ({
    display: 'flex',
    height: '34px',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: marketingBanner ? 32 : 0,

    ['a']: {
      color: 'inherit',
      fontWeight: '500',
      paddingLeft: 4,
    },
  }),
  ({ theme }) => ({
    [maxWidthFromTheme(theme).mobile]: {
      fontSize: SDS_FONT_SIZE_BODY_SMALL,
      lineHeight: SDS_FONT_LINEHEIGHT_BODY_SMALL,
      flexDirection: 'column',
      width: '100%',
      height: 'unset',
      alignItems: 'unset',
    },
  }),
)

const StyledBody = styled.div(({ theme }) => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...clampLines(2),

    [maxWidthFromTheme(theme).mobile]: {
      width: '100%',
      maxWidth: 'unset',
    },
  }
})

const CloseIconContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: SDS_SPACE_MEDIUM,
  top: 0,
  marginLeft: SDS_SPACE_SMALL,
  height: '100%',
})

const IconContainer = styled.div({
  paddingRight: SDS_SPACE_SMALL,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledLink = styled.span(
  {
    display: 'inline',
    whiteSpace: 'nowrap',
    paddingLeft: 4,
  },

  ({ theme }) => ({
    [maxWidthFromTheme(theme).mobile]: {
      width: '100%',
      paddingLeft: 0,
    },
  }),
)

const StyledNextLink = styled(NextLink)({
  fontWeight: 500,
  color: 'inherit',
})

interface ServiceBannerContainerProps {
  background: string
  color: string
}

const ServiceBannerContainer = styled.div<ServiceBannerContainerProps>(
  ({ theme, background, color }) => {
    return {
      background,
      color,
      position: 'relative',

      [minWidthFromTheme(theme).desktop]: {
        marginBottom: `-${SDS_SPACE_MEDIUM}`,
      },
    }
  },
)

export const ServiceBanner = styled(_ServiceBanner)(
  ({ theme, isSearchOpen, isProductNavigationVisible }) => {
    return {
      height: `${SERVICE_INFORMATION_BANNER_HEIGHT}px`,
      marginTop: isProductNavigationVisible ? `-${SERVICE_INFORMATION_BANNER_HEIGHT}px` : '0',
      transition: `${SDS_MOTION_DURATION_FASTEST} margin cubic-bezier(${SDS_MOTION_EASING_DEFAULT})`,

      [maxWidthFromTheme(theme).mobile]: {
        justifyContent: 'flex-start',

        ['> div']: {
          justifyContent: 'center',
        },
      },

      [maxWidthFromTheme(theme).tablet]: {
        marginTop:
          isProductNavigationVisible || isSearchOpen
            ? `-${SERVICE_INFORMATION_BANNER_HEIGHT}px`
            : '0',
      },
    }
  },
)
