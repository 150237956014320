import type { Price } from 'domain/price'
import type { FC } from 'react'

import { formattedPriceWithUnit } from 'domain/price'
import styled from 'styled-components'

interface ComparisonPriceProps {
  price: Price
  className?: string
  'data-test-id'?: string
}

const _ComparisonPrice: FC<ComparisonPriceProps> = ({
  price,
  className,
  'data-test-id': dataTestId = 'comparison-price',
}) => (
  <div className={className} data-test-id={dataTestId}>
    {formattedPriceWithUnit(price)}
  </div>
)

export const ComparisonPrice = styled(_ComparisonPrice)(
  ({ theme }) => theme.variants.caption.regular,
  ({ theme }) => ({
    color: theme.colors.grey600,
    textTransform: 'lowercase',
  }),
)
