import type { FC, ReactNode } from 'react'
import type {
  IntroductionTooltipMessage,
  Notification,
  NotificationGroup,
} from 'services/Notifications'

import { useReactiveVar } from '@apollo/client'
import { Button } from '@s-group/design-system-components'
import { IconNavigationChevronLeft } from '@s-group/design-system-icons'
import {
  SDS_MOTION_DURATION_FAST,
  SDS_MOTION_EASING_DEFAULT,
} from '@s-group/design-system-tokens/web/tokens/motion.es6'
import { getConfig } from '@shared/config'
import { Popover } from '@sok/design-system'
import { useCollapsibleSidepanelContext } from 'components/CollapsibleSidepanel/CollapsibleSidepanelProvider'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { deliveryModalStateVar } from 'services/Delivery/cache'
import { Notifications, notificationsVar } from 'services/Notifications'
import styled from 'styled-components'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { trackSidepanelToggleClick } from 'utils/tracking/navigation'

import { NavigationActionButton } from './NavigationActions'

const { featureFlags } = getConfig()

const AnimatedIcon = styled(IconNavigationChevronLeft)<{ $isOpen?: boolean | null }>(
  ({ $isOpen }) => ({
    visibility: $isOpen === null ? 'hidden' : 'visible',
    transform: `rotate(${$isOpen ? '180deg' : '0deg'})`,
    transition: `${SDS_MOTION_DURATION_FAST} transform cubic-bezier(${SDS_MOTION_EASING_DEFAULT})`,
  }),
)

const _PopOverContent: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const handleDismissClick = () => Notifications.dismiss('collapsible-cart-introduction')

  return (
    <div className={className} data-test-id="collapsible-cart-introduction-tooltip">
      <p>{t('Collapsible cart tooltip content')}</p>
      <Button plain sizing="xsmall" onClick={handleDismissClick}>
        {t('Collapsible cart tooltip CTA')}
      </Button>
    </div>
  )
}

const PopOverContent = styled(_PopOverContent)({
  '> * + *': {
    marginTop: '1rem',
  },

  [Button]: {
    cursor: 'pointer',
    width: '100%',
  },
})

const filterByGroup =
  (filteredGroup: NotificationGroup) =>
  ({ group }: Notification) =>
    group === filteredGroup

const filterMessages = (x: Notification): x is IntroductionTooltipMessage =>
  x.__kind === 'IntroductionTooltip'

const IntroductionPopover: FC<{ children?: ReactNode }> = ({ children }) => {
  const { selectedStoreId } = useStoreId()
  const isDeliveryModalOpen = useReactiveVar(deliveryModalStateVar).open
  const { isSidepanelCollapsible } = useCollapsibleSidepanelContext()
  const isClient = useIsClientSide()

  const hasSelectedStore = !!selectedStoreId

  const { dismissed, notifications } = useReactiveVar(notificationsVar)
  const [visibleNotification] = notifications
    .filter(({ id }) => !dismissed.includes(id))
    .filter(filterByGroup('new-feature-announcement'))
    .filter(filterMessages)

  useEffect(() => {
    if (hasSelectedStore && !isDeliveryModalOpen) {
      Notifications.add({
        __kind: 'IntroductionTooltip',
        id: 'collapsible-cart-introduction',
        group: 'new-feature-announcement',
        message: <PopOverContent />,
      })
    }
  }, [hasSelectedStore, isDeliveryModalOpen])

  const isVisible = !!visibleNotification && isSidepanelCollapsible

  if (!isClient) {
    return <>{children}</>
  }

  return (
    <Popover
      zIndex={49}
      arrowPointAtCenter
      visible={window.innerWidth > 1024 ? isVisible : false}
      content={visibleNotification?.message}
      placement={'bottomRight'}
      overlayStyle={{ position: 'fixed', borderRadius: '6px' }}
      overlayInnerStyle={{ marginTop: '12px', marginRight: '12px', width: '250px' }}
    >
      {children}
    </Popover>
  )
}

const _ToggleSidepanelButton: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const { isSidepanelVisible, setIsSidepanelVisible, isSidepanelCollapsible } =
    useCollapsibleSidepanelContext()

  const handleCartToggle = useCallback(() => {
    trackSidepanelToggleClick(isSidepanelVisible ?? true)
    setIsSidepanelVisible((isVisible) => {
      if (isVisible === null) {
        return false
      }

      return !isVisible
    })
  }, [isSidepanelVisible, setIsSidepanelVisible])

  if (!featureFlags.collapsibleSidepanel) {
    return null
  }

  return (
    <div className={className}>
      <IntroductionPopover>
        <NavigationActionButton
          compact
          data-test-id="global-nav-sidepanel-toggle"
          icon={<AnimatedIcon $isOpen={isSidepanelVisible ?? true} />}
          onClick={handleCartToggle}
          aria-label={t('Toggle shopping cart')}
          aria-expanded={isSidepanelVisible ?? true}
          aria-controls={'sidepanel'}
          disabled={!isSidepanelCollapsible}
          variant="tonal"
          color="grey"
        />
      </IntroductionPopover>
    </div>
  )
}

export const NavigationSidepanelToggle = styled(_ToggleSidepanelButton)({
  [NavigationActionButton]: {
    padding: 0,
    minWidth: 24,
    ':disabled': {
      opacity: 0.32,
    },
  },
})
