import type {
  DomainCustomer,
  RemoteUserProfileQuery,
  RemoteUserProfileQueryVariables,
} from '@shared/gql/document-nodes'

import { useApolloClient, useQuery } from '@apollo/client'
import { LocalUserProfileDocument, RemoteUserProfileDocument } from '@shared/gql/document-nodes'
import { pipe } from 'fp-ts/function'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

import { profileMapper } from '../support/profile-mapper'

// query mixed remote/local user profile for delivery page
// this needs to be
// a) local
// b) remote
// if not logged in
//   -> local
// if logged in
//   -> if local is set, then override remote profile with it, otherwise use local
export const useGetMixedAsyncUserProfile = (): (() => Promise<DomainCustomer>) => {
  const isLoggedIn = useIsLoggedIn()
  const client = useApolloClient()
  const localProfileQuery = useQuery(LocalUserProfileDocument)
  const localProfile = localProfileQuery.data
    ? profileMapper.fromLocalProfile(localProfileQuery.data)
    : profileMapper.empty()

  return async () => {
    if (!isLoggedIn) {
      return localProfile
    }

    const remoteQuery = await client.query<RemoteUserProfileQuery, RemoteUserProfileQueryVariables>(
      {
        query: RemoteUserProfileDocument,
      },
    )

    if (!remoteQuery.data) {
      return localProfile
    }

    return pipe(remoteQuery.data, profileMapper.fromRemoteProfile, (remote) => {
      if (localProfile) {
        return profileMapper.merge(localProfile)(remote)
      }
      return remote
    })
  }
}

// query mixed remote/local user profile for delivery page
// this needs to be
// a) local
// b) remote
// if not logged in
//   -> local
// if logged in
//   -> if local is set, then override remote profile with it, otherwise use local
export const useMixedUserProfile = (): DomainCustomer => {
  const isLoggedIn = useIsLoggedIn()

  const localProfileQuery = useQuery(LocalUserProfileDocument)
  const localProfile = localProfileQuery.data
    ? profileMapper.fromLocalProfile(localProfileQuery.data)
    : profileMapper.empty()
  const remoteQuery = useQuery(RemoteUserProfileDocument)

  if (!isLoggedIn) {
    return localProfile
  }

  if (!remoteQuery.data) {
    return localProfile
  }

  return pipe(remoteQuery.data, profileMapper.fromRemoteProfile, (remote) => {
    if (localProfile) {
      return profileMapper.merge(localProfile)(remote)
    }
    return remote
  })
}
