import type { NextRouter } from 'next/router'

import { SortKey, SortOrder } from '@shared/gql/document-nodes'

export { SortKey, SortOrder }

export const SORT_PARAM = 'sort'

export type Sort = [SortKey | null, SortOrder | null]

/** Sort option [0] = SortKey [1] = SortOrder [2] = i18n key */
export type SortOption = [...Sort, string]

/** Type guard for string that is a `SortKey` used for GraphQL queries */
export const isSortKey = (key: string): key is SortKey =>
  Object.values(SortKey).includes(key as SortKey)

/** Type guard for string that is a `SortOrder` */
export const isSortOrder = (key: string): key is SortOrder =>
  Object.values(SortOrder).includes(key as SortOrder)

/** Get the current sort order from the Next.js Router `query.sort` value */
export const queryValueToSort = (sort: NextRouter['query'][string] | null): Sort | null => {
  if (!sort) return null

  const orderKey = Array.isArray(sort) ? sort[sort.length - 1] : sort
  if (typeof orderKey !== 'string') return null
  const [sortKey, sortOrder] = orderKey.split(':')

  if (isSortKey(sortKey)) {
    return [sortKey, isSortOrder(sortOrder) ? sortOrder : null]
  }

  return null
}

export const sortToQueryValue = (sort: Sort): string | null => {
  const [sortKey, sortOrder] = sort
  if (!sortKey || !sortOrder) return null
  return `${sortKey}:${sortOrder}`
}

/** The default sort option */
export const DEFAULT_SORT: Sort = [null, null]

/** Sort options for category listings */
export const SORT_OPTIONS_CATEGORY_LIST: SortOption[] = [
  [null, null, 'sort_popularity_desc'],
  [SortKey.Price, SortOrder.Asc, 'sort_price_asc'],
  [SortKey.Price, SortOrder.Desc, 'sort_price_desc'],
  [SortKey.ComparisonPrice, SortOrder.Asc, 'sort_comparisonPrice_asc'],
  [SortKey.ComparisonPrice, SortOrder.Desc, 'sort_comparisonPrice_desc'],
  [SortKey.Name, SortOrder.Asc, 'sort_name_asc'],
  [SortKey.Name, SortOrder.Desc, 'sort_name_desc'],
]

/** Sort options for search results */
export const SORT_OPTIONS_SEARCH_RESULTS: SortOption[] = [
  [null, null, 'sort_relevance_desc'],
  [SortKey.Price, SortOrder.Asc, 'sort_price_asc'],
  [SortKey.Price, SortOrder.Desc, 'sort_price_desc'],
  [SortKey.ComparisonPrice, SortOrder.Asc, 'sort_comparisonPrice_asc'],
  [SortKey.ComparisonPrice, SortOrder.Desc, 'sort_comparisonPrice_desc'],
  [SortKey.Name, SortOrder.Asc, 'sort_name_asc'],
  [SortKey.Name, SortOrder.Desc, 'sort_name_desc'],
]
