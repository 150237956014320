import type { ServiceId } from 'config/usercentrics'

import { useHasServiceConsent } from '@s-group/react-usercentrics'
import { useEffect, useState } from 'react'
import { IS_BROWSER } from 'utils/is-browser'
import { v4 as uuidv4 } from 'uuid'

/**
 * Get a session-specific unique id for a specific service,
 * mostly useful for non-logged-in users.
 * Will be `null` without browser consent.
 *
 * @example
 * const id = useUsercentricsServiceSessionId(ServiceId.Loop54)
 * if (id) {
 *   // User has given consent for Loop54, and id can be used
 * } else {
 *   // User has not given consent for Loop54, id will be `null`
 * }
 */
export const useUsercentricsServiceSessionId = (serviceId: ServiceId): string | null => {
  const hasConsent = useHasServiceConsent(serviceId)

  const storageKey = `persistedSessionId-${serviceId}`

  const [consentSessionId, setConsentSessionId] = useState<string | null>(
    (IS_BROWSER && hasConsent && localStorage.getItem?.(storageKey)) || null,
  )

  useEffect(() => {
    /**
     * Refuse to do anything without consent.
     * This is `null` while loading and `false` when denied.
     */
    if (hasConsent === false) {
      localStorage.removeItem?.(storageKey)
      setConsentSessionId(null)
      return
    } else if (hasConsent) {
      const sessionIdFromStorage = localStorage.getItem?.(storageKey)

      if (sessionIdFromStorage) {
        setConsentSessionId(sessionIdFromStorage)
      } else {
        const generatedSessionId = uuidv4()
        localStorage.setItem?.(storageKey, generatedSessionId)
        setConsentSessionId(generatedSessionId)
      }
    }
  }, [hasConsent, storageKey])

  return consentSessionId
}
