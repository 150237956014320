import type { ServiceSubscriptionServiceType } from '@shared/gql/document-nodes'
import type { RemoteData } from 'domain/remote-data'

import { useQuery } from '@apollo/client'
import { RemoteServiceSubscriptionsDocument } from '@shared/gql/document-nodes'

import { useIsLoggedIn } from './use-is-logged-in'

export interface ServiceSubscriptions {
  purchaseDataAndProfilingDenied: boolean
}

export const useServiceSubscriptions = (
  serviceTypes: ServiceSubscriptionServiceType[],
): RemoteData<Error, ServiceSubscriptions> => {
  const isLoggedIn = useIsLoggedIn()

  const { data, loading, error } = useQuery(RemoteServiceSubscriptionsDocument, {
    variables: {
      serviceTypes,
    },
    skip: !isLoggedIn,
    // Fetches new serviceSubscriptions every one hour so changed settings get updated on client
    pollInterval: 3600000,
  })

  if (!isLoggedIn) {
    return { type: 'NOT_ASKED' }
  }

  if (loading) {
    return { type: 'LOADING' }
  }

  if (error) {
    return {
      type: 'FAILURE',
      error: new Error('Could not load RemoteServiceSubscriptionsDocument'),
    }
  }

  const serviceSubscriptions = data?.serviceSubscriptions

  /**
   * For checking purchase and profiling permissions.
   * subscribed === ´true´ means the permission has been set to `denied`.
   * It is enough that one of them is set.
   */
  const purchaseDataAndProfilingDenied = serviceSubscriptions?.length
    ? serviceSubscriptions.some((subscription) => subscription.subscribed)
    : false

  return {
    type: 'SUCCESS',
    data: {
      purchaseDataAndProfilingDenied,
    },
  }
}
