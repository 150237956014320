import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { BORDER_RADIUS } from 'components/Button/border-radius'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'
import { smallMobile } from 'styles/layout'

export interface ProductCardLoadingProps {
  className?: string
  'data-test-id'?: string
}

export const DetailsLoader = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const ImageLoader = styled(SkeletonLoader)({
  ...BORDER_RADIUS,
  height: '100%',
  width: '80px',
  alignSelf: 'center',
})

export const FavoriteLoader = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '40px',
  height: '40px',
  justifySelf: 'end',
  alignSelf: 'start',
})

const _QuantityModifierLoader: FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <SkeletonLoader width="40px" height="40px" />
    <SkeletonLoader width="45px" height="24px" />
    <SkeletonLoader width="40px" height="40px" />
  </div>
)

export const QuantityModifierLoader = styled(_QuantityModifierLoader)(
  {
    height: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    ['>' + SkeletonLoader]: {
      ...BORDER_RADIUS,
    },
  },
  ({ theme }) => ({
    gap: theme.spacings.medium,
    marginTop: theme.spacings.xSmall,
  }),
)

export const PriceLoader = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    gridRow: 4,
    gridColumn: '1 / 3',

    ['>' + SkeletonLoader]: {
      width: '30%',
      height: '20px',

      '&:first-child': {
        width: '40%',
        height: '30px',
      },
    },
  },
  ({ theme }) => ({
    marginTop: theme.spacings.medium,
    rowGap: theme.spacings.xxSmall,
  }),
)

export const QuantityLoader = styled(SkeletonLoader)({
  width: '40px',
  height: '40px',
  ...BORDER_RADIUS,
})

export const defaultLoadingStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({
  theme,
  disabled,
}) => ({
  ...BORDER_RADIUS,
  backgroundColor: theme.color.white,
  borderBottom: `1px solid ${theme.colors.grey100}`,
  color: theme.colors.grey50,
  cursor: disabled ? 'default' : 'auto',
  display: 'grid',
  opacity: 1,
  padding: '6px 8px',
  paddingBottom: theme.spacings.xxSmall,
})

export const loadingRowStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  gridTemplateColumns: 'min-content 1fr',
  padding: theme.spacings.xSmall,
  gap: theme.spacings.xxSmall,
  alignItems: 'center',

  [DetailsLoader]: {
    gridRow: 1,
    gap: theme.spacings.xxSmall,
    gridColumn: '2 / 4',
    alignSelf: 'center',

    ['>' + SkeletonLoader]: {
      ...BORDER_RADIUS,
      height: '20px',
      width: '100%',

      '&:nth-child(3)': {
        width: '50%',
      },
    },

    [smallMobile]: {
      gridColumn: '2 / 3',
    },
  },
})
