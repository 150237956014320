import { favoritesLoginPromptVisibleVar } from 'lib/apollo/reactive-vars'
import { useTranslation } from 'react-i18next'
import { handleRequestErrors } from 'services/error-handler'
import { addSnackbar } from 'services/Snackbar/Snackbars'
import { useThrottledCallback } from 'use-debounce'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

import { useIsFavorite } from './use-is-favorite'
import { useUpdateFavorite } from './use-update-favorite'

export const useFavorite = (ean: string) => {
  const { t } = useTranslation()
  const isFavorite = useIsFavorite(ean)
  const update = useUpdateFavorite(ean)

  const isAuthenticated = useIsLoggedIn()
  const toggle = useThrottledCallback(
    async () => {
      if (!isAuthenticated) {
        favoritesLoginPromptVisibleVar(true)
        return
      }

      try {
        if (isFavorite) {
          await update.remove()
          addSnackbar({
            text: t('Removed from favorite list'),
          })
        } else {
          await update.add()
          addSnackbar({
            text: t('Added to favorite list'),
          })
        }
      } catch (e) {
        handleRequestErrors([e])
      }
    },
    300,
    { trailing: false },
  )
  return {
    toggle,
    isFavorite,
  }
}
