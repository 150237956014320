import type { ForwardedRef, RefObject } from 'react'

import { useEffect, useRef } from 'react'
import { isNonNullable } from 'utils/nullable'

/**
 * For multiple refs, get a new ref to which all other refs will point to.
 * Useful for using an "inner ref" inside a `React.forwardRef` component,
 * when the external ref needs to point to the same element.
 *
 * @example
 * const Component = forwardRef((props, forwardedRef) => {
 *   const ref = useForwardedRefs(forwardedRef)
 *   return <div ref={ref} />
 * })
 */
export const useForwardedRefs = <T>(...refs: ForwardedRef<T>[]): RefObject<T> => {
  const innerRef = useRef<T>(null)

  useEffect(() => {
    refs.filter(isNonNullable).forEach((ref) => {
      if (typeof ref === 'function') {
        ref(innerRef.current)
      } else {
        ref.current = innerRef.current
      }
    })
  }, [refs])

  return innerRef
}
