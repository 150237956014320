import type { Asset } from 'contentful'
import type { FC } from 'react'
import type { IInputElementFields } from 'types/contentful/generated'

import { useCallback } from 'react'
import styled from 'styled-components'
import { internalPromotion } from 'utils/tracking/ecommerce'
import { useContentImpressionTracking } from 'utils/tracking/hooks/use-content-impression-tracking'
import { PromotionPosition } from 'utils/tracking/interfaces/data-layer-events'

import { ContentfulImage } from '../../ContentfulImage'
import { truncate } from '../common'
import { CommonContent } from './common-content'
import { StyledContentGridItem, StyledItemBody, StyledItemTitle } from './styled'

export interface ImageOnTopProps {
  count: number | undefined
  image: Asset
  title?: string
  body?: string
  cta?: IInputElementFields
  position: string
}

export const ImageOnTopCard: FC<ImageOnTopProps> = ({ image, title, body, cta, position }) => {
  const promotionPosition =
    PromotionPosition[`HORIZONTAL_GRID${position}` as keyof typeof PromotionPosition]
  const trackImpression = useCallback(() => {
    internalPromotion(promotionPosition, `${title}_${promotionPosition}`, 'view_promotion', title)
  }, [promotionPosition, title])
  const ref = useContentImpressionTracking(trackImpression, promotionPosition)

  return (
    <StyledContentGridItem imagePosition className="content-grid-item" ref={ref}>
      <StyledContentfulImage image={image} lazy />
      {title && <StyledItemTitle>{title}</StyledItemTitle>}
      {body && <StyledItemBody>{truncate(body, 90)}</StyledItemBody>}
      {cta && cta.label && cta.url && <CommonContent cta={cta} position={position} title={title} />}
    </StyledContentGridItem>
  )
}

const StyledContentfulImage = styled(ContentfulImage)({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  maxHeight: '300px',
})
