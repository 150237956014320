import type { FC } from 'react'

import { IconNavigationArrowLeft } from '@s-group/design-system-icons'
import { AutoSuggestSearchBox } from 'components/AutoSuggestSearchBox'
import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
// Hook value is only used after user interaction so no SSR issues
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'

import { NavigationActionButton } from './NavigationActions'

const _CloseSearchButton: FC<{ className?: string }> = ({ className }) => {
  return (
    <NavigationActionButton
      className={className}
      icon={<IconNavigationArrowLeft />}
      onClick={() => {
        navigationVisibleVar(false)
        searchVisibleVar(false)
      }}
      data-test-id="mobile-search-close"
    />
  )
}

export const CloseSearchButton = styled(_CloseSearchButton)({})

const _NavigationSearch: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      <AutoSuggestSearchBox />
    </div>
  )
}

export const NavigationSearch = styled(_NavigationSearch)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 'inherit',
  width: '100%',
})
