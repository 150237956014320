import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconControlMinus, IconControlPlus } from '@s-group/design-system-icons'
import { AddToShoppingCart } from 'components/icons'
import { NumericInput } from 'components/NumericInput'
import { clamp } from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getPrecisionOf, toPrecision } from 'utils/number'

import { ProductQuantity } from './ProductQuantity'

interface QuantityModiferProps {
  disabled?: boolean
  className?: string
  itemAmountMultiplier: number
  max: number
  min: number
  onChange: (v: number) => void
  onAddToCart?: () => void
  quantityUnit: string
  value: number
}

const _QuantityModifer: FC<QuantityModiferProps> = ({
  disabled,
  className,
  min,
  max,
  value,
  quantityUnit,
  onChange,
  onAddToCart,
  itemAmountMultiplier,
}) => {
  const { t } = useTranslation()
  const precision = getPrecisionOf(itemAmountMultiplier)

  const handleButtonClick = (v: number) => () => {
    onChange(toPrecision(precision)(clamp(min, max)(v + value)))
  }

  return (
    <div
      className={className}
      data-test-id="quantity-modifier"
      aria-disabled={disabled ? true : undefined}
    >
      <Button
        alternative
        aria-label={t('Remove product')}
        compact
        data-test-id="quantity-modifier__remove"
        disabled={disabled || value <= min}
        icon={<IconControlMinus />}
        onClick={handleButtonClick(-1 * itemAmountMultiplier)}
        rounding="small"
        sizing="small"
      />

      <InputWrapper>
        <NumericInput
          disabled={disabled}
          dataTestId="quantity-modifier__input"
          max={max}
          min={min}
          onChange={onChange}
          precision={precision}
          value={value ?? 0}
        />
        <ProductQuantity
          amount={value}
          unit={quantityUnit}
          precision={precision}
          data-test-id="quantity-modifier__value"
        />
      </InputWrapper>

      <Button
        alternative
        aria-label={t('Add product')}
        compact
        data-test-id="quantity-modifier__add"
        disabled={disabled || value >= max}
        icon={<IconControlPlus />}
        onClick={handleButtonClick(1 * itemAmountMultiplier)}
        rounding="small"
        sizing="small"
      />

      {onAddToCart && (
        <Button
          alternative
          compact
          rounding="small"
          sizing="small"
          data-test-id="add-to-cart-btn"
          title={t('Add to cart')}
          onClick={onAddToCart}
          icon={<AddToShoppingCart />}
        />
      )}
    </div>
  )
}

_QuantityModifer.displayName = 'QuantityModifier'

export const QuantityModifier = styled(_QuantityModifer)(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    [NumericInput]: {
      border: 'none',
      height: '100%',
      textAlign: 'center',
      width: '55px',
      backgroundColor: 'transparent',
    },

    [ProductQuantity]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  ({ theme, disabled, value }) => ({
    filter: disabled ? 'grayscale(0.5)' : undefined,
    willChange: disabled ? 'transform' : undefined,
    opacity: disabled ? 0.6 : undefined,

    [`${Button} + ${Button}`]: {
      marginLeft: theme.spacings.xxSmall,
    },

    [NumericInput]: {
      ...theme.variants.body1.regular,
      color: theme.color.fontBlack,

      '&:not(:focus)': {
        color: theme.colors.grey800,
      },

      '&:focus': {
        outline: `3px solid ${theme.color.highlightFocusColor}`,
      },
    },

    /** Light-green alternate-style Button should be grey when not in cart */
    ...(value === 0 && {
      [`${Button}:not(:hover)`]: {
        backgroundColor: theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_GREY,

        '.button-icon': {
          color: theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,
        },
      },
    }),
  }),
)

const InputWrapper = styled.div(
  {
    position: 'relative',
    flex: '1',
    /*
        hide actual value input from view when not in focus
        displays only formatted value, which is not pure number, but also contains quantity unit

        opacity: 0 or other-non-visible inputs cannot be focused,
        but changing the text and background to transparent will work
        as the input element itself is "visible"
      */
    [`${NumericInput}:not(:focus)`]: {
      backgroundColor: 'transparent',
      color: 'transparent',
    },

    [`${NumericInput}:focus + ${ProductQuantity}`]: {
      opacity: 0,
    },
  },
  ({ theme }) => ({
    color: theme.color.fontBlack,
  }),
)
