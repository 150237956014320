interface ProductLinkData {
  ean?: string | null
  slug?: string | null
}

/**
 * Get absolute path to a product page, including EAN and slugified name
 *
 * @example <caption>With slug and EAN</caption>
 * "/tuote/monster-energy-green-energiajuoma-tolkki-50cl/5060166692698"
 * @example <caption>Slug missing from product data</caption>
 * "/tuote/5060166692698"
 */
export const getProductLink = ({ ean, slug }: ProductLinkData): string => {
  if (!ean) return ''
  return slug ? `/tuote/${slug}/${ean}` : `/tuote/${ean}`
}
