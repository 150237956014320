import type { FC } from 'react'

import { useMutation } from '@apollo/client'
import { CancelOrderModificationDocument } from '@shared/gql/document-nodes'
import { Button, ButtonType, ConfirmModal } from '@sok/design-system'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import {
  useCancelDeliverySlotReservation,
  useDeliverySlotReservationId,
} from 'services/DeliverySlot/hooks'
import { DeliverySlotReleaseReason } from 'services/DeliverySlot/tracking'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'

interface CancelModificationWarning {
  modalVisible: boolean
  closeModal: () => void
}

export const CancelModificationWarning: FC<CancelModificationWarning> = ({
  closeModal,
  modalVisible,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const orderEditState = useGetOrderEditState()
  const [cancelOrderModification] = useMutation(CancelOrderModificationDocument)

  const reservationId = useDeliverySlotReservationId()
  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()

  const cancelEditHandler = async (orderId: string) => {
    await Promise.all([
      cancelOrderModification(),
      /** reservation exists during editing if the timeslot has been  changed */
      reservationId
        ? cancelDeliverySlotReservation(
            reservationId,
            DeliverySlotReleaseReason.ORDER_EDIT_CANCELLED,
          )
        : undefined,
    ])
    await router.push('/tilaus/[orderId]', `/tilaus/${orderId}`)
    closeModal()
  }

  return (
    <ConfirmModal
      closeLabel={t('Close')}
      visible={modalVisible}
      title={t('Do you want to continue the modification?')}
      onCancel={closeModal}
      data-test-id="cancel-modification-confirm"
      footer={[
        <Button
          key="submit"
          data-test-id="btn-cancel-modification-confirm"
          type={ButtonType.PRIMARY}
          onClick={() => cancelEditHandler(orderEditState?.orderId as string)}
          text={t('Yes')}
        />,
        <Button
          key="back"
          data-test-id="btn-cancel-modification-cancel"
          type={ButtonType.DEFAULT}
          onClick={() => closeModal()}
          text={t('Back')}
        />,
      ]}
    >
      {t('If you select Yes any edits you make will be lost')}
    </ConfirmModal>
  )
}
