import type { FC } from 'react'
import type { ICard, ISection } from 'types/contentful/generated'

import { minWidthFromTheme } from '@sok/design-system'
import { SectionTitle } from 'components/common/Titles'
import { NextLink } from 'components/NextLink'
import { SKAUPAT_HREF_PRODUCTION } from 'constants/href'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

import { StyledProductTitleWithCTA } from './FeaturedProducts'

const StyledMobileCategoryCTAWrapper = styled.a`
  display: block;
  margin: ${({ theme }) => theme.spacings.xxxSmall} 20px 0;
`

const StyledCategorySection = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacings.xxLarge} 0;
  display: flex;
  flex-direction: column;

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    > ${StyledMobileCategoryCTAWrapper} {
      display: none;
    }
  }
`

const StyledCategoryItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0px ${({ theme }) => theme.spacings.small};
  }
`
const StyledCategoryImage = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`

const StyledCategoryImageWrapper = styled.div`
  height: 0;
  padding-bottom: ${100 * (480 / 640) /** aspect ratio from 640x480 */}%;
  position: relative;
  width: 100%;
`

const StyledCategoryName = styled.div`
  ${({ theme }) => css(theme.variants.body1.medium)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  color: ${({ theme }) => theme.color.action};
`

const StyledCategoryItem = styled.a`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  color: inherit;
  width: calc(100% / 4 - 8px);
  outline: none;

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    &:hover,
    :focus {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: calc(100% / 2 - 4px);
    margin-bottom: 8px;
  }
`

interface CategoryProps {
  card: ICard
}

const getCategoryHref = (link?: string): string => {
  if (!link) return '/tuotteet'
  try {
    const url = new URL(link, SKAUPAT_HREF_PRODUCTION)
    return url.toString().replace(SKAUPAT_HREF_PRODUCTION, '')
  } catch {
    return '/tuotteet'
  }
}

const Category: FC<CategoryProps> = ({ card }) => {
  const { image, imageAltTag, productCategorySlug: cardLink, productCategoryName } = card.fields
  const imageUrl = image?.fields?.file?.url ? `https:${image?.fields?.file?.url}` : undefined

  const productCategoryHref = getCategoryHref(cardLink)

  const trackCategoryClick = (): void => {
    trackCustomEvent({
      category: EventCategory.CONTENT_LINKS,
      action: EventAction.CLICK,
      label: `category_${productCategoryName?.replace(' ', '-').toLowerCase()}`,
    })
  }

  return (
    <Link href={productCategoryHref} passHref>
      <StyledCategoryItem onClick={trackCategoryClick}>
        <StyledCategoryImageWrapper>
          <StyledCategoryImage
            src={imageUrl}
            alt={imageAltTag}
            /** without alt tag image is not semantic */
            role={imageAltTag ? undefined : 'presentation'}
          />
        </StyledCategoryImageWrapper>
        <StyledCategoryName>{productCategoryName}</StyledCategoryName>
      </StyledCategoryItem>
    </Link>
  )
}

export const ProductCategoriesGrid: FC<{
  section: ISection
}> = ({ section }) => {
  const { title, cards, functionality } = section.fields
  const { label, openLinkInNewTab, url } = functionality?.[0]?.fields ?? {}

  if (!cards || cards.length === 0) {
    return <></>
  }

  return (
    <StyledCategorySection>
      <StyledProductTitleWithCTA>
        <SectionTitle>{title}</SectionTitle>
        <NextLink href={getValidatedUrl(url)} target={openLinkInNewTab ? '_blank' : undefined}>
          {label}
        </NextLink>
      </StyledProductTitleWithCTA>

      <StyledCategoryItemsWrapper>
        {cards.map((card) => (
          <Category key={card.sys.id} card={card} />
        ))}
      </StyledCategoryItemsWrapper>
    </StyledCategorySection>
  )
}
