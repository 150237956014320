import type { SlideContainerProps } from '../SlideContainer'
import type { ReactElement } from 'react'

import { Children, cloneElement, Fragment } from 'react'
import theme from 'themes/s-kaupat'

export enum CAROUSEL_BREAKPOINTS {
  xlDesktop = parseInt(theme.breakpoints.xlDesktop),
  middleDesktop = parseInt(theme.breakpoints.middleDesktop),
  tablet = parseInt(theme.breakpoints.mobile),
}

// get grid count base on screensize
export const getGridCount = (screenSize: number): number => {
  if (screenSize >= CAROUSEL_BREAKPOINTS.xlDesktop) {
    return 4
  }
  if (screenSize >= CAROUSEL_BREAKPOINTS.middleDesktop) {
    return 3
  }
  if (screenSize >= CAROUSEL_BREAKPOINTS.tablet) {
    return 2
  }
  return 1
}

export const nextIndex = (
  currentGridIndex: number,
  gridCount: number,
  length: number,
  hasFeaturedStyle?: boolean,
): number => {
  // move forward two grids if rightmost or leftmost hidden card is featured card, otherwise move forward one grid
  if (
    hasFeaturedStyle &&
    (currentGridIndex == gridCount ||
      currentGridIndex == gridCount + length + 1 ||
      currentGridIndex == 2 * gridCount ||
      currentGridIndex == 2 * gridCount + length)
  ) {
    return currentGridIndex + 2
  }
  return currentGridIndex + 1
}

export const prevIndex = (
  currentGridIndex: number,
  gridCount: number,
  length: number,
  hasFeaturedStyle?: boolean,
): number => {
  // move back two grids if rightmost or leftmost hidden card is featured card, otherwise move back one grid
  if (
    hasFeaturedStyle &&
    (currentGridIndex == 2 * gridCount + 2 ||
      currentGridIndex == 2 * gridCount + length + 2 ||
      currentGridIndex == 2 * gridCount - (gridCount - 2) ||
      currentGridIndex == 2 * gridCount + length - (gridCount - 2))
  ) {
    return currentGridIndex - 2
  }
  return currentGridIndex - 1
}

export const swipeConfidenceThreshold = 50
export const swipePower = (offset: number) => {
  return Math.abs(offset)
}

export const getInitialGridIndex = (gridCount: number, infiniteLoop?: boolean): number => {
  return infiniteLoop ? 2 * gridCount : 0
}

export const getExtraPreviousItems = (
  carouselId: string,
  items: ReactElement<SlideContainerProps>[],
  gridCount: number | null,
) => {
  if (!gridCount) return []
  // disable featured styles for first item in previous extra array - to simplify the logic of starting transform X point
  const cloned = Children.map(items, (item) =>
    item.props.featured
      ? cloneElement(item, { gridCount, featured: false })
      : cloneElement(item, { gridCount }),
  )
  const output = []
  const length = cloned?.length
  for (let index = 0; index < gridCount * 2; index++) {
    let indexToPush = length - 1 - index
    if (indexToPush < 0) {
      indexToPush += length
    }
    output.push(
      <Fragment key={`${carouselId}-extra-prev-${index}`}>{cloned[indexToPush]}</Fragment>,
    )
  }
  output.reverse()
  return output
}

export const getExtraNextItems = (
  carouselId: string,
  items: ReactElement<SlideContainerProps>[],
  gridCount: number | null,
) => {
  if (!gridCount) return []
  const cloned = Children.map(items, (item) => cloneElement(item, { gridCount }))
  const output = []
  const length = items.length
  for (let index = 0; index < gridCount * 2; index++) {
    let indexToPush = index
    if (index > length - 1) {
      indexToPush -= length
    }
    output.push(
      <Fragment key={`${carouselId}-extra-next-${index}`}>{cloned[indexToPush]}</Fragment>,
    )
  }
  return output
}
