import type { FC, ReactNode } from 'react'
import type { ServiceBannerEntry } from 'types/contentful'

import { useReactiveVar } from '@apollo/client'
import { getConfig } from '@shared/config'
import { minWidthFromTheme } from '@sok/design-system'
import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
import { useEffect } from 'react'
import { useContentfulContent } from 'services/Contentful/contentful-provider'
import { openSelectDeliverySlotModal } from 'services/Delivery/cache'
import styled from 'styled-components'
import {
  LAYOUT_MAX_WIDTH,
  MIN_HEIGHT_FULLSCREEN,
  NARROW_PAGE_MAX_WIDTH,
  NAVBAR_HEIGHT,
  SERVICE_INFORMATION_BANNER_HEIGHT,
} from 'styles/layout'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import {
  removeDeliveryTimeslotAfterLoginToken,
  shouldOpenDeliveryTimeslotAfterLogin,
} from 'views/delivery-modal/delivery-timeslot-after-login'
import { setShouldSkipDeliveryLogin } from 'views/delivery-modal/skip-delivery-login'

import { ServiceBanner } from '../../views/contentful/components/contentful-section/service-banner/ServiceBanner'
import { Footer } from './footer/Footer'

const { featureFlags } = getConfig()

const Main = styled.div({})

const MainAndFooter = styled.div({})

const Sidebar = styled.div(({ theme }) => ({
  backgroundColor: theme.color.white,
  flexDirection: 'column',
}))

const MainAndSidebar = styled.div({})

export const PageContent = styled.div(({ theme }) => ({
  backgroundColor: theme.color.white,

  '> *': {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: NARROW_PAGE_MAX_WIDTH,
  },
}))

interface BaseLayoutProps {
  'data-test-id'?: string
  className?: string
  mainContent: ReactNode
  headerContent: ReactNode
  showFooter?: boolean
  showSidebarInMobile?: boolean
  sidebarContent?: ReactNode
  serviceBannerInUse?: boolean
  criticalBanner?: ServiceBannerEntry[]
}

const _BaseLayout: FC<BaseLayoutProps> = ({
  'data-test-id': dataTestId,
  className,
  headerContent,
  mainContent,
  showFooter = true,
  sidebarContent,
  criticalBanner,
}) => {
  const { footer, serviceBanners } = useContentfulContent()
  const isLoggedIn = useIsLoggedIn()

  const isSearchOpen = useReactiveVar(searchVisibleVar)
  const isProductNavigationVisible = useReactiveVar(navigationVisibleVar)

  useEffect(() => {
    const openTimeSelection = shouldOpenDeliveryTimeslotAfterLogin()
    if (openTimeSelection) {
      openSelectDeliverySlotModal()
      removeDeliveryTimeslotAfterLoginToken()
      if (!isLoggedIn) {
        setShouldSkipDeliveryLogin()
      }
    }
  }, [isLoggedIn])

  const bannerItems = featureFlags.serviceBannerFeatureFlag
    ? criticalBanner || serviceBanners
    : serviceBanners

  return (
    <div className={className} data-test-id={dataTestId}>
      {bannerItems && (
        <ServiceBanner
          bannerItems={bannerItems}
          isSearchOpen={isSearchOpen}
          isProductNavigationVisible={isProductNavigationVisible}
        />
      )}
      {headerContent}

      <MainAndSidebar>
        <MainAndFooter>
          <Main>{mainContent}</Main>
          {showFooter && footer && <Footer fields={footer} />}
        </MainAndFooter>
        {sidebarContent && <Sidebar>{sidebarContent}</Sidebar>}
      </MainAndSidebar>
    </div>
  )
}

export const BaseLayout = styled(_BaseLayout)(
  MIN_HEIGHT_FULLSCREEN,
  ({ showSidebarInMobile, serviceBannerInUse, sidebarContent, theme }) => {
    return {
      backgroundColor: theme.color.lightestGrey100,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      [Main]: {
        maxWidth: '1460px',
        margin: '0 auto',
      },

      [ServiceBanner]: {
        [minWidthFromTheme(theme).desktop]: {
          marginBottom: `-${theme.spacings.medium}`, // hide margin from Navigation-element
        },
      },

      [MainAndFooter]: {
        flex: '1 1 100%',
        minWidth: 0,
      },

      [Sidebar]: {
        display: showSidebarInMobile ? 'flex' : 'none',
        marginTop: `-${theme.spacings.medium}` /** Reset the row-gap */,
      },

      [MainAndSidebar]: {
        gap: theme.spacings.medium,
        margin: '0 auto',
        maxWidth: LAYOUT_MAX_WIDTH,
        width: '100%',
      },

      [minWidthFromTheme(theme).desktop]: {
        rowGap: theme.spacings.medium,

        [MainAndFooter]: {
          marginLeft: theme.spacings.medium,
          // width: `calc(100% - 400px - 2 * ${theme.spacings.medium})`,
          marginRight: sidebarContent ? 0 : theme.spacings.medium,
        },

        [Sidebar]: {
          bottom: 'auto',
          display: 'flex',
          flex: '0 0 auto',
          height: `calc(100vh - ${NAVBAR_HEIGHT.desktop}px)`,
          overflow: 'hidden auto',
          position: 'sticky',
          top: NAVBAR_HEIGHT.desktop,
          // ServiceBanner + CheckoutButton height, so that buttons are visible for the last cartItem
          paddingBottom: serviceBannerInUse
            ? `calc(${SERVICE_INFORMATION_BANNER_HEIGHT}px + 68px)`
            : 0,
        },

        [MainAndSidebar]: {
          display: 'flex',
        },
      },

      [`@media (min-width: ${LAYOUT_MAX_WIDTH}px)`]: {
        [MainAndFooter]: {
          marginLeft: 0,
          // width: `calc(100% - 400px - ${theme.spacings.medium})`,
        },

        [MainAndSidebar]: {
          padding: `0 ${theme.spacings.medium}`,
        },
      },
    }
  },
)
