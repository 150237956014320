import type { IconName } from 'components/icons/icon-name'
import type { FC } from 'react'

import { BORDER_RADIUS } from 'components/Button/border-radius'
import { Icon } from 'components/icons'
import { NextLink } from 'components/NextLink'
import { StyledImgContainer } from 'components/ProductListItem'
import styled from 'styled-components'

interface Props {
  caption: string
  className?: string
  href: string
  icon: IconName
}

const _PromptCard: FC<Props> = ({ caption, className, href, icon }) => {
  return (
    <NextLink className={className} href={href} role="listitem">
      <StyledImgContainer>
        <Icon name={icon} width={128} height={128} />
      </StyledImgContainer>
      <p>{caption}</p>
    </NextLink>
  )
}

export const PromptCard = styled(_PromptCard)(({ theme }) => ({
  backgroundColor: theme.colors.primary100,
  color: theme.colors.primary900,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  maxWidth: '212px',
  minWidth: '168px',
  ...BORDER_RADIUS,

  ':hover, :focus-within': {
    backgroundColor: theme.colors.primary300,
    color: theme.colors.primary900,
  },

  [`${StyledImgContainer}`]: {
    [`&, svg, ${Icon}`]: {
      maxWidth: '100%',
    },
  },

  '> p': {
    padding: theme.spacings.small,
  },
}))
