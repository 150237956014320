import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { getConfig } from '@shared/config'
import { Button, ButtonBlock, ButtonSize, ButtonType, minWidthFromTheme } from '@sok/design-system'
import { Carousel } from 'components/Carousel'
import { SectionTitle, TextBlock } from 'components/common/Titles'
import { NextLink } from 'components/NextLink'
import { ProductCardsGrid } from 'components/Product/ProductCardsGrid'
import { ProductListItem } from 'components/ProductListItem'
import { getValidatedUrl } from 'domain/link-validation'
import Link from 'next/link'
import { useMemo, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { LAYOUT_MAX_WIDTH } from 'styles/layout'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'
import { useSkippableProductInfoQuery } from 'utils/hooks/use-skippable-product-info-query'
import {
  TrackingContextProvider,
  useTrackingContext,
} from 'utils/tracking/components/TrackingContextProvider'
import { useProductsImpressionTracking } from 'utils/tracking/hooks/use-products-impression-tracking'
import { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'

const { featureFlags } = getConfig()

/** Sort global fallbacks to the end of list, */
const sortGlobalFallbacks = (
  a: { isGlobalFallback: boolean | null } | null,
  b: { isGlobalFallback: boolean | null } | null,
) => {
  if (!a?.isGlobalFallback && !b?.isGlobalFallback) return 0
  if (b?.isGlobalFallback && !a?.isGlobalFallback) return -1
  return 0
}

const StyledFeaturedContainer = styled.article(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '72px 0',
  },

  {
    [TextBlock]: {
      margin: '20px 0 36px 0',
    },
  },
)

const StyledProductsCTAWrapper = styled.div`
  margin-top: 20px;
  width: 100%;

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    display: none;
  }
`

const StyledFeaturedContainerContentPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacings.xxLarge} 0;

  ${Carousel} {
    margin: 0 auto;
    max-width: ${LAYOUT_MAX_WIDTH}px;
    width: 100%;
  }
`

export const StyledProductTitleWithCTA = styled.div(({ theme }) => ({
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  columnGap: theme.spacings.medium,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacings.small} ${theme.spacings.small}`,
  maxWidth: `${LAYOUT_MAX_WIDTH}px`,
  rowGap: theme.spacings.xxSmall,
  width: '100%',

  [NextLink]: {
    ...theme.variants.body1.medium,
    flexShrink: 0,
  },

  [minWidthFromTheme(theme).tablet]: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },

  [minWidthFromTheme(theme).desktop]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

interface ProductGridProps {
  productGrid: string[]
  type?: string
}

enum ProductGridType {
  CAROUSEL = 'carousel',
}

const ProductGrid: FC<ProductGridProps> = ({ productGrid, type }) => {
  const { selectedStoreId, storeId } = useStoreId()
  const trackingContext = useTrackingContext()

  const { data, loading } = useSkippableProductInfoQuery({
    eans: productGrid,
    /** display marketing placements even if not available in specific store */
    fallbackToGlobal: true,
    includeAvailabilities: featureFlags.productAvailabilities && !!selectedStoreId,
    selectedStoreId: storeId,
  })

  const products = useMemo(() => {
    return [...(data?.store?.products?.items ?? [])].sort(sortGlobalFallbacks)
  }, [data?.store?.products?.items])

  const ref = useRef<HTMLDivElement>(null)

  useProductsImpressionTracking({
    listName: trackingContext.listName || '',
    trackingContext:
      type === ProductGridType.CAROUSEL
        ? TrackingContext.FEATURED_PRODUCTS_CAROUSEL
        : TrackingContext.FEATURED_PRODUCTS_LIST,
    items: products,
    ref,
  })
  if (!loading && !products.length) {
    return null
  }

  if (type === ProductGridType.CAROUSEL) {
    return (
      <TrackingContextProvider trackingContext={TrackingContext.FEATURED_PRODUCTS_CAROUSEL}>
        <Carousel
          placeholderEans={loading ? productGrid : undefined}
          products={products}
          ref={ref}
        />
      </TrackingContextProvider>
    )
  }

  return (
    <ProductCardsGrid.Grid ref={ref}>
      {products.map(
        (product, i) =>
          product && (
            <TrackingContextProvider
              key={`${product.id}-${i}`}
              trackingContext={TrackingContext.FEATURED_PRODUCTS_LIST}
              listPosition={i}
            >
              <ProductListItem disabled={!!product.isGlobalFallback} product={product} />
            </TrackingContextProvider>
          ),
      )}
    </ProductCardsGrid.Grid>
  )
}

interface FeaturedProductsProps {
  section: ISection
}

export const FeaturedProducts: FC<FeaturedProductsProps> = ({ section }) => {
  const theme = useTheme()
  const {
    title: componentTitle,
    body: componentBody,
    productGrid: productsCollection,
    displayname,
    functionality,
    tagTitle,
  } = section.fields
  const products = productsCollection?.fields?.products
  const parsedSectionBody = useParsedMarkdown(componentBody)
  const { label, url, openLinkInNewTab } = functionality?.[0]?.fields ?? {}
  const href = getValidatedUrl(url)

  if (displayname === 'FeaturedProducts') {
    return (
      <StyledFeaturedContainer data-test-id="featured-products">
        <SectionTitle>{componentTitle}</SectionTitle>
        <TextBlock>{parsedSectionBody}</TextBlock>
        {products && (
          <TrackingContextProvider
            listName={`${TrackingContext.FEATURED_PRODUCTS_LIST}: ${componentTitle}`}
          >
            <ProductGrid productGrid={products} />
          </TrackingContextProvider>
        )}
      </StyledFeaturedContainer>
    )
  }

  if (displayname === 'FeaturedProductsContentPage') {
    return (
      <StyledFeaturedContainerContentPage
        id={tagTitle}
        className="featured-products__content-page"
        data-test-id="featured-products__content-page"
      >
        <StyledProductTitleWithCTA>
          <SectionTitle>{componentTitle}</SectionTitle>
          <NextLink href={href}>{label}</NextLink>
        </StyledProductTitleWithCTA>

        {products && (
          <TrackingContextProvider
            listName={`${TrackingContext.FEATURED_PRODUCTS_LIST}: ${componentTitle}`}
          >
            <ProductGrid productGrid={products} />
          </TrackingContextProvider>
        )}

        <StyledProductsCTAWrapper>
          <Link href={href} passHref>
            <Button
              size={ButtonSize.MIDDLE}
              type={ButtonType.DEFAULT}
              blockOn={ButtonBlock.DESKTOP_DOWN}
              style={{ backgroundColor: theme.color.lightestGrey100 }}
              target={openLinkInNewTab ? '_blank' : undefined}
              text={label}
            />
          </Link>
        </StyledProductsCTAWrapper>
      </StyledFeaturedContainerContentPage>
    )
  }

  if (displayname === 'ProductGridCarousel') {
    return (
      <StyledFeaturedContainerContentPage
        id={tagTitle}
        data-test-id={'product-grid__carousel'}
        className={'product-grid__carousel'}
      >
        <StyledProductTitleWithCTA>
          <SectionTitle>{componentTitle}</SectionTitle>
          <NextLink href={href}>{label}</NextLink>
        </StyledProductTitleWithCTA>

        {products && (
          <TrackingContextProvider
            listName={`${TrackingContext.FEATURED_PRODUCTS_CAROUSEL}: ${componentTitle}`}
          >
            <ProductGrid productGrid={products} type={ProductGridType.CAROUSEL} />
          </TrackingContextProvider>
        )}
      </StyledFeaturedContainerContentPage>
    )
  }

  return null
}
