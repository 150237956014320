import type { FC } from 'react'

import { SyncLoader } from 'components/loaders/SyncLoader'
import styled, { useTheme } from 'styled-components'

const StyledLoader = styled.div`
  position: fixed;
  overflow: scroll;
  width: 100vw;
  height: 100%;
  z-index: 32;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OverlaySyncLoad: FC = () => {
  const { color } = useTheme()

  return (
    <StyledLoader>
      <SyncLoader color={color.primaryBrand} />
    </StyledLoader>
  )
}
