import type { FC, ReactNode } from 'react'

import { Checkbox, CheckboxSize } from '@sok/design-system'
import styled from 'styled-components'

interface ReplaceCheckBoxProps {
  size?: CheckboxSize
  className?: string
  checked?: boolean
  disabled?: boolean
  onChange?: () => void
  'data-test-id'?: string
  children?: ReactNode
}

const _ReplaceCheckBox: FC<ReplaceCheckBoxProps> = ({
  size = CheckboxSize.SMALL,
  className,
  checked,
  disabled,
  onChange,
  'data-test-id': dataTestId = 'replace-checkbox',
  children,
}) => (
  <Checkbox
    disabled={disabled}
    checked={checked}
    onChange={onChange}
    data-test-id={dataTestId}
    size={size}
    className={className}
    id="replace_checkbox"
  >
    {children}
  </Checkbox>
)

export const ReplaceCheckBox = styled(_ReplaceCheckBox)(
  {
    display: 'flex',
    alignItems: 'center',

    /* Overrides design-system checkbox to align label and checkbox in center */
    '.ant-checkbox': {
      top: 0,
    },

    '&.checkbox-small.ant-checkbox-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '> .ant-checkbox-checked': {
        top: 0,
      },
    },
  },
  ({ theme }) => ({
    '&.checkbox-small.ant-checkbox-wrapper': {
      '> span:nth-child(2)': {
        ...theme.variants.body1.regular,
        color: theme.color.fontBlack,
      },
    },

    '.ant-checkbox': {
      '> input[type="checkbox"]': {
        '&:focus': {
          /* removing default appearance */
          appearance: 'none',
          opacity: 'unset',
          outline: `3px solid ${theme.colors.success300}`,
          outlineOffset: 3,
        },
      },
    },
  }),
)
