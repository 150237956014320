import type { FC } from 'react'

import { useHasServiceConsent, useIsFailed, useIsInitialized } from '@s-group/react-usercentrics'
import { UsercentricsConsentGate } from 'components/UsercentricsConsentGate'
import { ServiceId } from 'config/usercentrics'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { addGiosgCustomFieldsToAPI } from 'utils/giosg-util'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'

const AdblockText = styled.h1({ fontSize: 'inherit', fontWeight: 'inherit' })

const CenteredContainer = styled.div(({ theme }) => ({
  margin: 'auto auto auto 0',
  maxWidth: 400,
  padding: theme.spacings.medium,
}))

const StyledSurveyContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const Giosg: FC = () => {
  const isUsercentricsInitialized = useIsInitialized()
  const isClientSide = useIsClientSide()
  const hasGiosgConsent = useHasServiceConsent(ServiceId.Giosg)
  const isUsercentricsInitBlocked = useIsFailed()
  const { t } = useTranslation()

  const { selectedStoreId } = useStoreId()
  const theme = useTheme()
  const { params } = useURLSearchParams()

  useEffect(() => {
    addGiosgCustomFieldsToAPI({
      storeId: params.get('store') || selectedStoreId,
      brand: params.get('brand') || theme.brandName,
      orderRef: params.get('orderRef') ?? '',
    })
  }, [selectedStoreId, theme.brandName, params])

  if (isUsercentricsInitBlocked) {
    return (
      <StyledSurveyContainer className="survey">
        <CenteredContainer>
          <AdblockText>{t`Usercentrics not initialized`}</AdblockText>
        </CenteredContainer>
      </StyledSurveyContainer>
    )
  }

  if (isUsercentricsInitialized && !hasGiosgConsent) {
    return (
      <StyledSurveyContainer className="survey">
        <CenteredContainer>
          <UsercentricsConsentGate serviceId={ServiceId.Giosg} />
        </CenteredContainer>
      </StyledSurveyContainer>
    )
  }

  if (isClientSide && window.location.hash === '#email') {
    return (
      <StyledSurveyContainer
        style={{
          paddingTop: theme.spacings.medium,
          backgroundColor: theme.colors.grey100,
        }}
        className="survey"
      />
    )
  }

  return <StyledSurveyContainer className="survey" />
}
