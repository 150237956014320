import { makeVar, useReactiveVar } from '@apollo/client'
import { navigationVisibleVar, searchVisibleVar } from 'lib/apollo/reactive-vars'
import React from 'react'
import { RemoveScroll } from 'react-remove-scroll'
// Used to remove scroll when search is open on mobile sizes
import { useMediaQuery } from 'react-responsive'
import { useTheme } from 'styled-components'

import { ContentMenu } from './ContentMenu'
import { NavigationContainer } from './nav-styles'
import { TopNavigation } from './TopNavigation'
import { UserMenu } from './UserMenu'

export const menuDrawerStateVar = makeVar<'menu' | 'user' | 'closed'>('closed')

export const TopNavigationContainer = () => {
  const isProductNavigationVisible = useReactiveVar(navigationVisibleVar)
  const isSearchOpen = useReactiveVar(searchVisibleVar)

  const theme = useTheme()
  const isDesktop = useMediaQuery({ minWidth: theme.breakpoints.tablet })

  const menuDrawerState = useReactiveVar(menuDrawerStateVar)

  return (
    <RemoveScroll enabled={(isSearchOpen && !isDesktop) || isProductNavigationVisible} forwardProps>
      <NavigationContainer data-test-id="navigation">
        <TopNavigation
          isSearchOpen={isSearchOpen}
          isProductNavigationVisible={isProductNavigationVisible}
        >
          <TopNavigation.Menu />

          <TopNavigation.ProductCategories />

          <TopNavigation.CloseSearchButton />
          <TopNavigation.Search />

          <TopNavigation.DeliverySelect />

          <TopNavigation.User />
          <TopNavigation.ShoppingCart />
          <TopNavigation.Sidepaneltoggle />
        </TopNavigation>

        <ContentMenu
          isOpen={menuDrawerState === 'menu'}
          onClose={() => menuDrawerStateVar('closed')}
        />

        <UserMenu
          isOpen={menuDrawerState === 'user'}
          onClose={() => menuDrawerStateVar('closed')}
        />
      </NavigationContainer>
    </RemoveScroll>
  )
}
