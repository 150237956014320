import type { NavState } from './nav-styles'
import type { ReactNode } from 'react'

import React from 'react'

import { NavigationHeader, NavigationLayout } from './nav-styles'
import { NavigationActionButton } from './NavigationActions'
import { NavigationDeliverySelect } from './NavigationDeliverySelect'
import { BrandLogo, NavigationMenu } from './NavigationMenu'
import { NavigationProducts } from './NavigationProducts'
import { CloseSearchButton, NavigationSearch } from './NavigationSearch'
import { NavigationShoppingCart } from './NavigationShoppingCart'
import { NavigationSidepanelToggle } from './NavigationSidepanelToggle'
import { NavigationUserLogin } from './NavigationUserLogin'
import { useStickyNav } from './use-sticky-nav'

export const TopNavigation = ({
  children,
  className,
  isSearchOpen = false,
  isProductNavigationVisible = false,
}: {
  children: ReactNode
  className?: string
  isSearchOpen?: boolean
  isProductNavigationVisible?: boolean
}) => {
  const position = useStickyNav(isSearchOpen || isProductNavigationVisible)

  const getNavState = (): NavState => {
    if (isSearchOpen) {
      return 'searchOpen'
    }

    if (isProductNavigationVisible) {
      return 'productCategoriesOpen'
    }

    return null
  }

  return (
    <NavigationHeader className={className} style={{ y: position }}>
      <NavigationLayout navState={getNavState()}>{children}</NavigationLayout>
    </NavigationHeader>
  )
}

TopNavigation.Search = NavigationSearch
TopNavigation.CloseSearchButton = CloseSearchButton
TopNavigation.Button = NavigationActionButton
TopNavigation.ProductCategories = NavigationProducts
TopNavigation.BrandLogo = BrandLogo
TopNavigation.DeliverySelect = NavigationDeliverySelect
TopNavigation.Menu = NavigationMenu
TopNavigation.User = NavigationUserLogin
TopNavigation.ShoppingCart = NavigationShoppingCart
TopNavigation.Sidepaneltoggle = NavigationSidepanelToggle
