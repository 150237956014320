import type { FC, ReactNode } from 'react'

import styled from 'styled-components'

const LabelContainer = styled.div({
  position: 'absolute',
  top: '0',
  right: '0',
  transform: 'translate(30%,-30%)',
  display: 'flex',
})

const Label = styled.span(({ theme }) => ({
  borderRadius: '100px',
  padding: '1px 6px 2px 6px',
  backgroundColor: theme.colors.SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY,
  color: theme.colors.SDS_BRAND_COLOR_TEXT_INVERSE_GREY,
  lineHeight: '1',
  ...theme.variants.caption.medium,
}))

const _IconWithLabel: FC<{
  className?: string
  icon: ReactNode
  label: ReactNode
}> & { Label: typeof Label } = ({ className, icon, label }) => {
  return (
    <span className={`${className} icon`}>
      {icon}
      {label && <LabelContainer>{label}</LabelContainer>}
    </span>
  )
}

_IconWithLabel.Label = Label

export const IconWithLabel = styled(_IconWithLabel)({
  position: 'relative',
  display: 'flex',
})
