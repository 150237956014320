import { useApolloClient, useMutation } from '@apollo/client'
import {
  RemoteAddFavoriteDocument,
  RemoteRemoveFavoriteDocument,
  RemoteUserFavoriteProductsDocument,
  RemoteUserFavoritesListDocument,
} from '@shared/gql/document-nodes'
import { useStoreId } from 'utils/hooks/store/use-store-id'

export const useUpdateFavorite = (ean: string) => {
  const client = useApolloClient()
  const { storeId } = useStoreId()
  const [add] = useMutation(RemoteAddFavoriteDocument, {
    variables: { ean },
    optimisticResponse: () => {
      const q = client.readQuery({ query: RemoteUserFavoritesListDocument })
      return {
        __typename: 'Mutation' as const,
        userFavoritesAddItem: {
          __typename: 'FavoritesList' as const,
          modifiedAt: new Date().toISOString(),
          items: [...(q?.userFavorites.items || []), { __typename: 'FavoritesItem', ean }],
        },
      }
    },
    update: (cache, response) => {
      const q = client.readQuery({ query: RemoteUserFavoritesListDocument })
      if (response.data?.userFavoritesAddItem) {
        cache.writeQuery({
          query: RemoteUserFavoritesListDocument,
          data: {
            userFavorites: {
              __typename: 'FavoritesList' as const,
              items: [...(q?.userFavorites.items || []), { __typename: 'FavoritesItem', ean }],
            },
            __typename: 'Query',
          },
        })
      }
    },
  })
  const [remove] = useMutation(RemoteRemoveFavoriteDocument, {
    variables: { ean },
    optimisticResponse: () => {
      const q = client.readQuery({ query: RemoteUserFavoritesListDocument })
      return {
        __typename: 'Mutation' as const,
        userFavoritesRemoveItem: {
          __typename: 'FavoritesList' as const,
          modifiedAt: new Date().toISOString(),
          items: [...(q?.userFavorites.items.filter((x) => x.ean !== ean) || [])],
        },
      }
    },
    update: (cache, response) => {
      const q = client.readQuery({ query: RemoteUserFavoritesListDocument })
      if (response.data?.userFavoritesRemoveItem) {
        cache.writeQuery({
          query: RemoteUserFavoritesListDocument,
          data: {
            userFavorites: {
              ...response.data.userFavoritesRemoveItem,
              items: [...(q?.userFavorites.items.filter((x) => x.ean !== ean) || [])],
            },
            __typename: 'Query',
          },
        })
        // in /suosikit view, there is a list of products also,
        // this will remove items from that query, if its present
        // so changes are immediately visible on the screen
        const productsList = cache.readQuery({
          query: RemoteUserFavoriteProductsDocument,
          variables: {
            storeId,
          },
        })

        // only write query, if it is not null, as it will remove all favorites from ui if written empty
        if (productsList) {
          cache.writeQuery({
            query: RemoteUserFavoriteProductsDocument,
            variables: {
              storeId,
            },
            data: {
              userFavorites: {
                __typename: 'FavoritesList',
                items: productsList?.userFavorites.items.filter((x) => x.ean !== ean) || [],
              },
              __typename: 'Query',
            },
          })
        }
      }
    },
  })

  return {
    add,
    remove,
  }
}
