import { useQuery } from '@apollo/client'
import { RemoteNavigationDocument } from '@shared/gql/document-nodes'
import { useStoreId } from 'utils/hooks/store/use-store-id'

export const useNavigation = () => {
  const { storeId } = useStoreId()
  const { data, loading, error } = useQuery(RemoteNavigationDocument, {
    variables: { id: storeId },
  })
  return { data, loading, error }
}
