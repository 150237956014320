import { useGetFavorites } from './use-get-favorites'

export const useIsFavorite = (ean: string) => {
  const items = useGetFavorites()

  switch (items.type) {
    case 'SUCCESS':
      return items.data.some((x) => x.ean === ean)
    default:
      return false
  }
}
