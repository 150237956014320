import type { Price } from 'domain/price'
import type { Product } from 'domain/product'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { formattedItemPrice, formattedPrice } from 'domain/price'
import styled from 'styled-components'

interface UnitPriceProps {
  className?: string
  'data-test-id'?: string
  price: Price
}

const _UnitPrice: FC<UnitPriceProps> = ({
  className,
  'data-test-id': dataTestId = 'unit-price',
  price,
}) => (
  <span className={className} data-test-id={dataTestId}>
    {formattedPrice(price)}
  </span>
)

_UnitPrice.displayName = 'UnitPrice'

const unitStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  ...theme.variants.body2.medium,
  color: theme.colors.grey800,
})

export const UnitPrice = styled(_UnitPrice)(unitStyles)

interface UnitPriceDynamicProps {
  className?: string
  'data-test-id'?: string
  product: NonNullable<Pick<Product, 'priceUnit' | 'price' | 'approxPrice'>>
  itemCount: number
}

const _UnitPriceDynamic: FC<UnitPriceDynamicProps> = ({
  className,
  'data-test-id': dataTestId = 'dynamic-unit-price',
  product,
  itemCount = 1,
}) => (
  <span className={className} data-test-id={dataTestId}>
    {formattedItemPrice({
      price: product.price,
      approxPrice: product.approxPrice,
      itemCount,
    })}
  </span>
)

export const UnitPriceDynamic = styled(_UnitPriceDynamic)(unitStyles)
