import type { LoggableProduct } from './log-missing-units'
import type { FC } from 'react'

import {
  getProductComparisonPrice,
  getProductDepositPrice,
  getProductLowest30DayPrice,
  getProductPrice,
} from 'domain/product-price'
import { useEffect } from 'react'
import styled from 'styled-components'

import { ComparisonPrice } from './ComparisonPrice'
import { DepositPrice } from './DepositPrice'
import { logMissingUnits } from './log-missing-units'
import { Lowest30DayPrice } from './Lowest30DayPrice'
import { UnitPrice, UnitPriceDynamic } from './UnitPrice'

interface ProductPriceProps {
  approximate?: boolean
  className?: string
  'data-test-id'?: string
  showDeposit?: boolean
  product: LoggableProduct
  itemCount?: number
}

const _ProductPrice: FC<ProductPriceProps> = ({
  approximate,
  className,
  product,
  showDeposit,
  itemCount,
  'data-test-id': dataTestId = 'product-price',
}) => {
  useEffect(() => {
    logMissingUnits(product)
  }, [product])

  const price = getProductPrice(approximate ? { ...product, approxPrice: true } : product)

  if (!price) {
    return null
  }

  const comparisonPrice = getProductComparisonPrice(product)
  /** Deposit price needs to be enabled explicitly, to only show it on product pages */
  const depositPrice = showDeposit ? getProductDepositPrice(product) : null
  const lowest30DayPrice = getProductLowest30DayPrice(product)

  return (
    <div
      className={className}
      data-test-id={dataTestId}
      data-approximate={approximate ? 'true' : 'false'}
    >
      <ProductPriceContainer>
        <PriceTopWrapper>
          {itemCount ? (
            <UnitPriceDynamic
              product={product}
              itemCount={itemCount}
              data-test-id="product-price__dynamic-unitPrice"
            />
          ) : (
            <UnitPrice price={price} data-test-id="product-price__unitPrice" />
          )}
          {lowest30DayPrice && (
            <Lowest30DayPrice
              data-test-id="product-price__lowest30DayPrice"
              price={lowest30DayPrice}
              validUntil={product.pricing?.campaignPriceValidUntil ?? undefined}
            />
          )}
        </PriceTopWrapper>
        {comparisonPrice && (
          <ComparisonPrice
            price={comparisonPrice}
            data-test-id={`${dataTestId}__comparisonPrice`}
          />
        )}
        {depositPrice && (
          <DepositPrice price={depositPrice} data-test-id={`${dataTestId}__depositPrice`} />
        )}
      </ProductPriceContainer>
    </div>
  )
}

_ProductPrice.displayName = 'ProductPrice'

export const ProductPrice = styled(_ProductPrice)(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({ theme }) => ({
    columnGap: theme.spacings.xxSmall,
  }),

  ({ showDeposit, theme }) =>
    showDeposit && {
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: theme.spacings.xxxSmall,

      [UnitPrice]: {
        ...theme.variants.title1,
      },
    },
)

const PriceTopWrapper = styled.div({
  display: 'flex',
})

export const ProductPriceContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  whiteSpace: 'nowrap',
})
