import { Brand } from '@shared/domain/brand'

export const BRAND_COLORS: Record<Brand, string> = {
  [Brand.ABC]: '#007841',
  [Brand.Alepa]: '#FFE300',
  [Brand.Herkku]: '#5A685D',
  [Brand.MestarinHerkku]: '#5A685D',
  [Brand.Prisma]: '#007841',
  [Brand.Sale]: '#FBF22C',
  [Brand.Skaupat]: '#007841',
  [Brand.SMarket]: '#002058',
  [Brand.SokosHerkku]: '#5A685D',
  [Brand.Yhteishyvä]: '#007841',
  [Brand.Eprisma]: '#007841',
}

export enum LABEL_COLORS {
  Offers = '#B70000',
  New = '#004628',
  Recipe = '#885600',
  Inspiration = '#885600',
  Seasonal = '#885600',
  ForYou = '#885600',
  GetToKnow = '#1F1F1F',
  Sponsored = '#006E87',
}
