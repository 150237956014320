import { minWidthFromTheme } from '@sok/design-system'
import { SectionTitle } from 'components/common/Titles'
import styled from 'styled-components'

interface StyledItemProps {
  topPadding?: string
}

export const StyledItem = styled.div<StyledItemProps>`
  display: block;

  p {
    padding: 20px;
    text-align: center;
  }

  a {
    color: ${({ theme }) => theme.color.action};

    &:hover {
      color: ${({ theme }) => theme.color.actionVariant};
    }
  }

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
interface RowContainerProps {
  bgColor?: string
  count?: number
}

export const StyledContainer = styled.div<RowContainerProps>(({ bgColor, theme }) => ({
  backgroundColor: !bgColor ? theme.color.white : bgColor,
  marginBottom: theme.spacings.xxLarge,
  padding: theme.spacings.xLarge,

  [SectionTitle]: {
    marginBottom: theme.spacings.medium,
    textAlign: 'center',
  },
}))

/**
 * The `flex` prop is a bit of a hack to enable the "brands" list's layout on the
 * landing page (/) to work better on mobile by allowing the brand logos to go on multiple rows.
 * This same component is also used at least on the support page (/tuki) to display some
 * text content side-by-side, so that should not be affected.
 *
 * Maybe the brand logos should be split into a separate Contentful component?
 */
export const StyledRowItem = styled.ul<{ flex?: boolean }>(({ flex, theme }) => ({
  display: 'flex',
  flexDirection: flex ? 'row' : 'column',
  flexWrap: 'wrap',
  gap: theme.spacings.medium,
  justifyContent: 'center',
  listStyle: 'none',
  margin: '0 auto',
  textAlign: 'center',

  '> li': {
    /**
     * Random number that looks nice. The default combo of `flex-basis:0;flex-grow:1`
     * forces items on a single row.
     */
    flexBasis: flex ? 96 : 'min-content',
    flexGrow: 1,
  },

  [minWidthFromTheme(theme).tablet]: {
    flexDirection: 'row',
  },
}))

export const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  margin: ${({ theme }) => theme.spacings.medium};
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
