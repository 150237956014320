import type { Brand } from '@shared/domain/brand'
import type { CoOperative } from '@shared/gql/document-nodes'
import type { ServiceBannerEntry } from 'types/contentful'

import { BannerType } from 'types/contentful'

interface TargetingParams {
  brand: Brand
  storeId: string
  coOp?: CoOperative | null
  content: ServiceBannerEntry[]
}

/**
 * Service Banners are prioritized in the following order
 *    1. store
 *    2. co-op
 *    3. brand
 *    4. global
 * @param param0
 * @returns
 */
const getStoreBanner = ({
  brand,
  storeId,
  coOp,
  content,
}: TargetingParams): ServiceBannerEntry | undefined => {
  const storeIdMatch = content.find(({ banner }: ServiceBannerEntry) =>
    banner.storeVisibilityFilter?.find((filtered) => filtered === storeId),
  )

  if (storeIdMatch) {
    return storeIdMatch
  }

  const coOpMatch = content.find(({ banner }: ServiceBannerEntry) =>
    banner.storeVisibilityFilter?.find((filtered) => filtered === coOp),
  )

  if (coOpMatch) {
    return coOpMatch
  }

  const brandMatch = content.find(({ banner }: ServiceBannerEntry) =>
    banner.limitVisibilityByBrand?.find((filtered) => filtered === brand),
  )

  if (brandMatch) {
    return brandMatch
  }

  const globalBanner = content.find(
    ({ banner }: ServiceBannerEntry) =>
      !banner.storeVisibilityFilter && !banner.limitVisibilityByBrand,
  )

  if (globalBanner) {
    return globalBanner
  }
}

/**
 * Service Banners are targeted in the following order and based on type
 * 1. Critical error banner
 * 2. Error banners
 * 3. Info banners
 * 4. Marketing banners
 * @param param0
 * @returns
 */
export const getTargetedServiceBanner = ({
  brand,
  storeId,
  coOp,
  content,
}: TargetingParams): ServiceBannerEntry | undefined => {
  // return error banners in order
  const errorBanners = content.filter((b) => b.banner.bannerType === BannerType.Error)
  // critical error banner goes over the other priorizations
  const criticalErrorBanner = errorBanners.find(
    (item: ServiceBannerEntry) => !!item.banner.isCritical,
  )

  if (criticalErrorBanner) {
    return criticalErrorBanner
  }

  const errorBanner = getStoreBanner({ content: errorBanners, storeId, coOp, brand })

  if (errorBanner) {
    return errorBanner
  }

  const infoBanner = getStoreBanner({
    content: content.filter((b) => b.banner.bannerType === BannerType.Info),
    storeId,
    coOp,
    brand,
  })

  if (infoBanner) {
    return infoBanner
  }

  const marketingBanner = getStoreBanner({
    content: content.filter((b) => b.banner.bannerType === BannerType.Marketing),
    storeId,
    coOp,
    brand,
  })

  if (marketingBanner) {
    return marketingBanner
  }
}
