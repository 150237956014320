import type { MaybeProductListItem } from 'domain/product-list'
import type { ReactNode } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconNavigationArrowLeft, IconNavigationArrowRight } from '@s-group/design-system-icons'
import { SDS_SPACE_XSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { minWidthFromTheme } from '@sok/design-system'
import { ProductListCarouselItemLoading, ProductListItem } from 'components/ProductListItem'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'
import { useForwardedRefs } from 'utils/hooks/use-forwarded-refs'
import { TrackingContextProvider } from 'utils/tracking/components/TrackingContextProvider'

const Spacer = styled.span({ flexShrink: 0, visibility: 'hidden' })

const Control = styled.div<{ left?: boolean }>(({ theme, left }) => ({
  display: 'none',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: left ? SDS_SPACE_XSMALL : undefined,
  right: left ? undefined : SDS_SPACE_XSMALL,
  zIndex: 10,
  backgroundColor: 'rgba(255, 255, 255, 0)',
  border: 0,
  borderRadius: '50%',
  overflow: 'visible',
  [Button]: {
    height: '48px',
    width: '48px',
    minWidth: 0,
    padding: '12px',
  },
  [customMinWidthFromTheme(theme).desktop]: {
    left: left ? `-${SDS_SPACE_XSMALL}` : undefined,
    right: left ? undefined : `-${SDS_SPACE_XSMALL}`,
  },
  [customMinWidthFromTheme(theme).tablet]: {
    display: 'block',
  },
}))

interface CarouselProps {
  ['data-test-id']?: string
  className?: string
  placeholderEans?: string[]
  prefixCard?: ReactNode
  products: MaybeProductListItem[]
}

const _Carousel = forwardRef<HTMLDivElement, CarouselProps>(
  ({ className, 'data-test-id': dataTestId, placeholderEans, prefixCard, products }, ref) => {
    const scrollableRef = useForwardedRefs(ref)
    const [position, setPosition] = useState<'left' | 'right' | null>('left')
    const [isScrollable, setIsScrollable] = useState<boolean>()

    const isAtLeft = position === 'left'
    const isAtRight = position === 'right'

    const updateScrollIndicators = (element: HTMLElement) => {
      if (element.scrollLeft < 48) {
        setPosition('left')
      } else if (element.scrollLeft + element.clientWidth >= element.scrollWidth - 48) {
        setPosition('right')
      } else {
        setPosition(null)
      }

      setIsScrollable(element.scrollWidth > element.clientWidth)
    }

    /**
     * Add scroll listener to hide and show scroll buttons when necessary.
     * The `passive` option means this listener shouldn't block UI too much.
     */
    useEffect(() => {
      const element = scrollableRef.current
      if (!element) return

      const handleScroll: EventListener = (event) => {
        if (event.currentTarget) {
          updateScrollIndicators(event.currentTarget as HTMLElement)
        }
      }

      element.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll)
        }
      }
    }, [scrollableRef])

    /**
     * Same as above, but detect resizing of the element.
     */
    useEffect(() => {
      const element = scrollableRef.current
      if (!element || !window.ResizeObserver) return

      const observer = new ResizeObserver(() => {
        updateScrollIndicators(element)
      })
      observer.observe(element)

      return () => void observer.disconnect()
    }, [scrollableRef])

    const handleScroll = useCallback(
      (scrollToLeft = false) => {
        if (!scrollableRef.current) return

        const width = scrollableRef.current.clientWidth
        const left = scrollToLeft ? -width : width
        scrollableRef.current.scrollBy({ behavior: 'smooth', left })
      },
      [scrollableRef],
    )

    return (
      <div className={className}>
        <div data-test-id={dataTestId} role="list" ref={scrollableRef}>
          <Spacer />

          {prefixCard}

          {placeholderEans
            ? placeholderEans.map((ean, i) => (
                <ProductListCarouselItemLoading
                  data-test-id="product-card-loading__feature-product"
                  ean={ean}
                  key={i}
                />
              ))
            : products.map((product, i) => (
                <TrackingContextProvider listPosition={i} key={product?.id}>
                  <ProductListItem
                    data-test-id="product-card__feature-product"
                    disabled={!!product?.isGlobalFallback}
                    product={product}
                  />
                </TrackingContextProvider>
              ))}

          <Spacer />
        </div>

        {isScrollable && !isAtLeft && (
          <Control left>
            <Button
              primary
              rounding="pill"
              onClick={() => handleScroll(true)}
              aria-hidden="true"
              tabIndex={-1}
            >
              <IconNavigationArrowLeft />
            </Button>
          </Control>
        )}

        {isScrollable && !isAtRight && (
          <Control>
            <Button
              primary
              rounding="pill"
              onClick={() => handleScroll()}
              aria-hidden="true"
              tabIndex={-1}
            >
              <IconNavigationArrowRight />
            </Button>
          </Control>
        )}
      </div>
    )
  },
)

_Carousel.displayName = 'Carousel'

export const Carousel = styled(_Carousel)(({ theme }) => ({
  position: 'relative',
  width: '100%',

  '> [role="list"]': {
    display: 'flex',
    overflowX: 'auto',
    paddingTop: theme.spacings.small,
    paddingBottom: theme.spacings.small,
    columnGap: theme.spacings.xxSmall,

    [`${ProductListItem}, ${ProductListCarouselItemLoading}`]: {
      flex: '0 0 200px',
    },
  },

  [Spacer]: {
    width: theme.spacings.xxSmall,
  },

  [minWidthFromTheme(theme).desktop]: {
    [Spacer]: {
      display: 'none',
    },

    '> [role="list"]': {
      paddingLeft: theme.spacings.xxxSmall,
      paddingRight: theme.spacings.xxxSmall,
    },
  },
}))
