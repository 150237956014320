import { AutoSuggest } from './AutoSuggest'
import { Card } from './Card'
import { Cart } from './Cart'
import { OrderConfirmation } from './OrderConfirmation'
import { OrderHistory } from './OrderHistory'
import { Recipe } from './Recipe'
import { Row } from './Row'
import { SideCart } from './SideCart'

export const ProductCard = {
  AutoSuggest,
  Card,
  Cart,
  SideCart,
  Recipe,
  Row,
  OrderConfirmation,
  OrderHistory,
}
