import type { FC } from 'react'
import type { IPageLayoutFields, ISection } from 'types/contentful/generated'

import { useButtonListHref } from 'utils/hooks/store/use-button-list-href'

import { withContentfulXray } from '../../../hocs/with-contentful-xray'
import { Accordion } from './contentful-section/Accordion'
import { ArticleGrid } from './contentful-section/ArticleGrid'
import { CustomerServiceForm } from './contentful-section/CustomerServiceForm'
import { FeaturedProducts } from './contentful-section/FeaturedProducts'
import { Giosg } from './contentful-section/giosg'
import { HeroBanner, HeroBannerVariant } from './contentful-section/HeroBanner'
import { HorizontalContentGrid } from './contentful-section/HorizontalContentGrid'
import { ImageAndQuote } from './contentful-section/ImageAndQuote'
import { ImageAndText } from './contentful-section/ImageAndText'
import { ImageWithDescription } from './contentful-section/ImageWithDescription'
import { LinkToOtherPages } from './contentful-section/LinkToOtherPages'
import { List } from './contentful-section/List'
import { NotificationBanner } from './contentful-section/NotificationBanner'
import {
  PersonalizedRecommendations,
  RecommendationType,
} from './contentful-section/PersonalizedRecommendations'
import { ProductCategoriesGrid } from './contentful-section/ProductCategoriesGrid'
import { RowComponent } from './contentful-section/RowComponent'
import { SocialMediaLinks } from './contentful-section/SocialMediaLinks'
import { Tags } from './contentful-section/Tags'
import { TitleWithParagraph } from './contentful-section/TitleWithParagraph'
import { Video } from './contentful-section/Video'

interface ContentfulSectionProps {
  pageSections?: IPageLayoutFields['sections']
  section: ISection
  context?: 'menu-ajankohtaista'
}

const _ContentfulSection: FC<ContentfulSectionProps> = ({ section, pageSections, context }) => {
  const { displayname } = section.fields
  const linkHref = useButtonListHref()
  switch (displayname) {
    case 'HeroBanner':
      return <HeroBanner variant={HeroBannerVariant.Default} section={section} />
    case 'HeroBannerLeftText':
      return <HeroBanner variant={HeroBannerVariant.LeftText} section={section} />
    case 'HeroBannerFullWidth':
      return <HeroBanner variant={HeroBannerVariant.FullWidth} section={section} />
    case 'RowComponent':
      return <RowComponent fields={section.fields} href={linkHref} />
    case 'FeaturedProducts':
    case 'FeaturedProductsContentPage':
    case 'ProductGridCarousel':
      return <FeaturedProducts section={section} />
    case 'PersonalizedRecommendations-RecentPurchases':
      return (
        <PersonalizedRecommendations type={RecommendationType.RECENT_PURCHASES} section={section} />
      )
    case 'PersonalizedRecommendations-PurchaseHistory':
      return (
        <PersonalizedRecommendations type={RecommendationType.PURCHASE_HISTORY} section={section} />
      )
    case 'PersonalizedRecommendations-Other':
      return <PersonalizedRecommendations type={RecommendationType.OTHER} section={section} />
    case 'TitleWithParagraph':
      return <TitleWithParagraph section={section} />
    case 'ImageAndText':
      return <ImageAndText fields={section.fields} />
    case 'ArticleGrid':
      return <ArticleGrid section={section} context={context} />
    case 'ImageAndQuote':
      return <ImageAndQuote section={section} />
    case 'SocialMedia':
      return <SocialMediaLinks section={section} />
    case 'Video':
      return <Video section={section} />
    case 'Tags':
      return <Tags section={section} sections={pageSections} />
    case 'Accordion':
      return <Accordion section={section} />
    case 'NotificationBanner':
      return <NotificationBanner section={section} />
    case 'HorizontalContentGrid':
      return <HorizontalContentGrid section={section} />
    case 'LinkToOtherPages':
      return <LinkToOtherPages section={section} />
    case 'List':
      return <List section={section} />
    case 'CustomerServiceForm':
      return <CustomerServiceForm section={section} />
    case 'ProductCategories':
      return <ProductCategoriesGrid section={section} />
    case 'giosg':
      return <Giosg />
    case 'ImageWithDescription':
      return <ImageWithDescription section={section} />
    default:
      return null
  }
}

export const ContentfulSection = withContentfulXray(_ContentfulSection)
