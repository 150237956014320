import type { ProductAvailabilities } from '@shared/gql/document-nodes'

import { ProductAvailabilityLabel } from '@shared/gql/document-nodes'

/** Whether a product availability is "bad", and customers should be notified */
export const isBadAvailability = (
  availability?: Pick<ProductAvailabilities, 'label'> | null | undefined,
) => {
  /** Can't be bad if data is not loaded… */
  if (!availability) return false
  return availability.label === ProductAvailabilityLabel.OutOfStock
}
