import type { ApolloQueryResult } from '@apollo/client'
import type {
  CreateOrderMutation,
  RemoteProductAvailabilitiesQuery,
  UpdateOrderMutation,
} from '@shared/gql/document-nodes'

import { useApolloClient, useQuery } from '@apollo/client'
import {
  GetPaymentDetailsDocument,
  PaymentMethod,
  RemoteProductAvailabilitiesDocument,
  RemoteStoreNameDocument,
} from '@shared/gql/document-nodes'
import { useCallback } from 'react'
import { useCitrusAdUserEvent } from 'services/CitrusAd/hooks/use-citrusad-user-event'
import { useProductUserEvent } from 'services/ProductUserEvent/product-user-event'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { trackTransactionCreate, trackTransactionEdit } from 'utils/tracking/ecommerce'

const mapCartItemAvailabilities = (
  cartItems: NonNullable<CreateOrderMutation['createOrder']>['cartItems'],
  availabilitiesQueryResult: ApolloQueryResult<RemoteProductAvailabilitiesQuery>,
) =>
  cartItems?.map((cartItem) => ({
    ...cartItem,
    availabilities:
      availabilitiesQueryResult.data.store?.products?.items?.find(
        (item) => item?.id === cartItem.id,
      )?.availabilities ?? null,
  })) || null

type UseTrackTransactionCreate = <T extends NonNullable<CreateOrderMutation['createOrder']>>(
  x: T,
) => Promise<T>

const getPaymentMethod = (
  paymentMethod: PaymentMethod | null,
  selectedPaymentCardId: string | null,
): PaymentMethod | 'SAVED_CARD_PAYMENT' | null => {
  if (paymentMethod !== PaymentMethod.CardPayment) {
    return paymentMethod
  }
  return selectedPaymentCardId ? 'SAVED_CARD_PAYMENT' : PaymentMethod.CardPayment
}

export const useTrackTransactionCreate = (): UseTrackTransactionCreate => {
  const { data: paymentData } = useQuery(GetPaymentDetailsDocument)
  const apolloClient = useApolloClient()
  const { sendProductUserPurchaseEvent } = useProductUserEvent()
  const availabilityDate = useDeliveryDate()
  const { sendCitrusAdUserPurchaseEvent } = useCitrusAdUserEvent()

  return useCallback(
    async (order) => {
      // have to fetch the storeName for analytics
      // it resides in cache
      try {
        const [storeNameQuery, availabilitiesQuery] = await Promise.all([
          apolloClient.query({
            fetchPolicy: 'cache-only',
            query: RemoteStoreNameDocument,
            variables: { id: order.storeId },
          }),
          apolloClient.query({
            fetchPolicy: 'cache-first',
            query: RemoteProductAvailabilitiesDocument,
            variables: {
              availabilityDate,
              storeId: order.storeId,
              productIds: order.cartItems?.map((cartItem) => cartItem.id) ?? [],
            },
          }),
        ])

        const cartItemsWithAvailability = mapCartItemAvailabilities(
          order.cartItems,
          availabilitiesQuery,
        )

        trackTransactionCreate({
          availabilityDate,
          order: { ...order, cartItems: cartItemsWithAvailability },
          storeName: storeNameQuery?.data.store?.name ?? '',
          paymentMethod: getPaymentMethod(
            order.paymentMethod,
            paymentData?.paymentDetails?.selectedPaymentCardId ?? null,
          ),
        })
        await sendCitrusAdUserPurchaseEvent(order.id)
      } catch {
        // ignore errors with analytics
      }

      await sendProductUserPurchaseEvent(order.cartItems?.map((cartItem) => cartItem.id) ?? [])

      return order
    },
    [
      apolloClient,
      availabilityDate,
      paymentData?.paymentDetails?.selectedPaymentCardId,
      sendProductUserPurchaseEvent,
      sendCitrusAdUserPurchaseEvent,
    ],
  )
}

export const useTrackTransactionEdit = (): (<
  T extends NonNullable<UpdateOrderMutation['updateOrder']>,
  V extends CreateOrderMutation['createOrder'],
>(
  newOrder: T,
  oldOder: V,
) => Promise<T>) => {
  const { data: paymentData } = useQuery(GetPaymentDetailsDocument)
  const apolloClient = useApolloClient()
  const availabilityDate = useDeliveryDate()

  return useCallback(
    async (newOrder, oldOrder) => {
      if (oldOrder?.cartItems) {
        // have to fetch the storeName for analytics
        // it resides in cache
        try {
          const [storeNameQuery, newAvailabilitiesQuery, oldAvailabilitiesQuery] =
            await Promise.all([
              apolloClient.query({
                query: RemoteStoreNameDocument,
                variables: { id: newOrder.storeId },
                fetchPolicy: 'cache-only',
              }),
              apolloClient.query({
                fetchPolicy: 'cache-first',
                query: RemoteProductAvailabilitiesDocument,
                variables: {
                  availabilityDate,
                  storeId: newOrder.storeId,
                  productIds: newOrder.cartItems?.map((cartItem) => cartItem.id) ?? [],
                },
              }),
              apolloClient.query({
                fetchPolicy: 'cache-first',
                query: RemoteProductAvailabilitiesDocument,
                variables: {
                  availabilityDate,
                  storeId: oldOrder.storeId,
                  productIds: oldOrder.cartItems?.map((cartItem) => cartItem.id) ?? [],
                },
              }),
            ])

          const newCartItemsWithAvailability = mapCartItemAvailabilities(
            newOrder.cartItems,
            newAvailabilitiesQuery,
          )

          const oldCartItemsWithAvailability = mapCartItemAvailabilities(
            oldOrder.cartItems,
            oldAvailabilitiesQuery,
          )

          trackTransactionEdit({
            availabilityDate,
            newOrder: { ...newOrder, cartItems: newCartItemsWithAvailability },
            oldOrder: { ...oldOrder, cartItems: oldCartItemsWithAvailability },
            paymentMethod: getPaymentMethod(
              newOrder.paymentMethod,
              paymentData?.paymentDetails?.selectedPaymentCardId ?? null,
            ),
            storeName: storeNameQuery.data?.store?.name ?? '',
          })
        } catch {
          // ignore errors with analytics
        }
      }

      return newOrder
    },
    [apolloClient, availabilityDate, paymentData?.paymentDetails?.selectedPaymentCardId],
  )
}
