import type {
  DomainCustomer,
  LocalUserProfileQuery,
  RemoteUserProfileQuery,
} from '@shared/gql/document-nodes'

import { CustomerType } from '@shared/gql/document-nodes'
import { CardType } from 'domain/customer-detail'

export const profileMapper = {
  empty: (): DomainCustomer => ({
    __typename: 'DomainCustomer',
    type: CustomerType.B2c,
    companyName: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    stockmannCardNumber: null,
    cardType: null,
  }),
  fromLocalProfile: (x: LocalUserProfileQuery): DomainCustomer => ({
    __typename: 'DomainCustomer',
    type: x.domainOrder.customer.type ?? CustomerType.B2c,
    companyName: x.domainOrder.customer.companyName,
    firstName: x.domainOrder.customer.firstName,
    lastName: x.domainOrder.customer.lastName,
    phone: x.domainOrder.customer.phone,
    email: x.domainOrder.customer.email,
    stockmannCardNumber: x.domainOrder.customer?.stockmannCardNumber ?? null,
    cardType: x.domainOrder.customer.stockmannCardNumber ? CardType.STOCKMANN : CardType.SCARD,
  }),

  fromRemoteProfile: (x: RemoteUserProfileQuery): DomainCustomer => ({
    __typename: 'DomainCustomer',
    type: x.userProfile?.customerType === CustomerType.B2b ? CustomerType.B2b : CustomerType.B2c,
    companyName: x.userProfile?.company?.name || '',
    firstName: x.userProfile?.firstName || '',
    lastName: x.userProfile?.lastName || '',
    phone: x.userProfile?.phoneNumber || '',
    email: x.userProfile?.email || '',
    stockmannCardNumber: null,
    cardType: x.bonusInfo?.membershipNumber ? CardType.SCARD : null,
  }),

  merge:
    (a: DomainCustomer) =>
    (b: DomainCustomer): DomainCustomer => ({
      __typename: 'DomainCustomer',
      type: b.type || CustomerType.B2c, // override customer type to remote type, if found
      companyName: defaultNilOrEmpty(a.companyName, b.companyName),
      firstName: defaultNilOrEmpty(a.firstName, b.firstName),
      lastName: defaultNilOrEmpty(a.lastName, b.lastName),
      phone: defaultNilOrEmpty(a.phone, b.phone),
      email: defaultNilOrEmpty(a.email, b.email),
      stockmannCardNumber: defaultNilOrEmpty(a.stockmannCardNumber, b.stockmannCardNumber),
      cardType: defaultNilOrEmpty(a.cardType, b.cardType),
    }),
}

const defaultNilOrEmpty = (a: string | null | undefined, b: string | null | undefined): string =>
  a || b || ''
