import type { FC } from 'react'

import { StyledContentContainer, StyledLoginActions } from 'components/Account/styled-login-content'
import { SLogo } from 'components/icons'
import { useTranslation } from 'react-i18next'

import { LoginButtons } from './LoginButtons'
import { LoginReadMoreLink } from './LoginReadMoreLink'

export const LoginContent: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledContentContainer>
      <SLogo />
      <div>
        <h3>{t('S-account streamlines your shopping experience')}</h3>
        <p>
          {t('With S-account you can leverage your purchase history across all S-group stores')}
        </p>
        <p>
          {t(
            'You will automatically see your previous purchases make a new cart out of your existing order',
          )}
        </p>
      </div>
      <LoginReadMoreLink />
      <StyledLoginActions>
        <LoginButtons />
      </StyledLoginActions>
    </StyledContentContainer>
  )
}
