export const DtoToDomainUnitMap = {
  KG: 'kg',
  KGM: 'kg',
  KPL: 'kpl',
  L: 'l',
  LTK: 'ltk',
  LTR: 'l',
  LY1: 'kpl',
  LY2: 'kpl',
  MTR: 'm',
  NIP: 'nip',
  PAR: 'pari',
  PCE: 'kpl',
  PLL: 'pll',
  PRK: 'prk',
  PSS: 'pss',
  RAS: 'ras',
  TLK: 'tlk',
  PKT: 'pkt',
  KOT: 'kot',
  PR: 'pr',
} as const

type UnitDto = keyof typeof DtoToDomainUnitMap

export type Unit = (typeof DtoToDomainUnitMap)[UnitDto]

const isUnitDto = (x: string): x is UnitDto =>
  Object.keys(DtoToDomainUnitMap).includes(x as UnitDto)

export const getUnit = (x: unknown): Unit | null => {
  if (typeof x === 'string' && isUnitDto(x)) {
    return DtoToDomainUnitMap[x]
  }

  return null
}
