import type { Validator } from './validation'

import * as E from 'fp-ts/Either'
import { pipe } from 'ramda'

const NON_PLAINTEXT_CHARACTERS = /[\\{}[\]$@]|:\/\//

export const plaintext =
  (path: readonly string[]): Validator<string, string> =>
  (value) =>
    pipe(
      (v) => v.trim(),
      (v) =>
        NON_PLAINTEXT_CHARACTERS.test(v)
          ? E.left([
              {
                path,
                tag: 'validationError',
                message: 'invalidPlaintext',
              },
            ])
          : E.right(value),
    )(value)
