import type { FC } from 'react'

import styled from 'styled-components'

interface SwitcherProps {
  handleChange: () => void
  label: string
  className?: string
  checked: boolean
}
const _Switcher: FC<SwitcherProps> = ({ handleChange, label, className, checked }) => {
  return (
    <label className={className}>
      <span className="label">{label}</span>
      <div>
        <input className="checkbox" type="checkbox" onChange={handleChange} checked={checked} />
        <span className="switch"></span>
      </div>
    </label>
  )
}

export const Switcher = styled(_Switcher)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${theme.spacings.small} 0 0`,
  '.switch': {
    display: 'inline-block',
    background: theme.colors.grey500,
    borderRadius: '16px',
    width: '58px',
    height: '32px',
    position: 'relative',
    verticalAlign: 'middle',
    transition: 'background 0.25s',
    '&:before': {
      content: '""',
      display: 'block',
      background: 'linear-gradient(to bottom, #fff 0%,#eee 100%)',
      borderRadius: '50%',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.1)',
      width: '24px',
      height: '24px',
      position: 'absolute',
      top: '4px',
      left: '4px',
      transition: 'left 0.25s',
    },
    '&:hover:before': {
      background: 'linear-gradient(to bottom, #fff 0%,#fff 100%)',
      boxShadow: '0 0 0 1px rgba(0,0,0,0.3)',
    },
    '&:after': {
      content: '""',
    },
  },
  '.checkbox': {
    position: 'absolute',
    visibility: 'hidden',
    '&:checked + .switch': {
      background: theme.colors.primary500,
      '&:before': {
        left: '30px',
      },
    },
  },
  '.label': {
    marginLeft: '5px',
    position: 'relative',
    top: '2px',
  },
}))
