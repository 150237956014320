import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { IconUserProfile, IconUserProfileFilled } from '@s-group/design-system-icons'
import { getConfig } from '@shared/config'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'
import { useGetCurrentProfile } from 'utils/hooks/account/use-get-current-profile'
import { trackUserClick } from 'utils/tracking/navigation'

import { NavigationActionButton } from './NavigationActions'
import { menuDrawerStateVar } from './TopNavigationContainer'

const { domain } = getConfig()

const Label = styled.span(({ theme }) => ({
  textIndent: -99999,
  lineHeight: 0,

  [customMinWidthFromTheme(theme).middleDesktop]: {
    textIndent: 0,
    lineHeight: 'revert',
  },
}))

const _NavigationUserLogin: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const profile = useGetCurrentProfile()
  const isDrawerOpen = useReactiveVar(menuDrawerStateVar) === 'user'

  const { isSIdEnabled } = domain

  const onLoginClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    trackUserClick(!!profile)
    menuDrawerStateVar(isDrawerOpen ? 'closed' : 'user')
  }

  if (!isSIdEnabled) {
    return null
  }

  return (
    <NavigationActionButton
      data-test-id="global-nav-login"
      onClick={onLoginClick}
      aria-label={profile ? t('Your profile', { user: profile.firstName }) : t('Log in')}
      icon={profile ? <IconUserProfileFilled /> : <IconUserProfile />}
      className={className}
    >
      <Label>{profile ? profile.firstName : t('Log in')}</Label>
    </NavigationActionButton>
  )
}

export const NavigationUserLogin = styled(_NavigationUserLogin)({})
