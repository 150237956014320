import { useMutation } from '@apollo/client'
import { useHasServiceConsent } from '@s-group/react-usercentrics'
import {
  ProductUserEventType,
  SearchProvider,
  SendProductUserEventDocument,
} from '@shared/gql/document-nodes'
import { ServiceId } from 'config/usercentrics'
import { useCurrentSearchProvider } from 'services/Search/hooks/use-search-provider'
import { useServiceSubscriptions } from 'utils/hooks/account/use-get-service-subscriptions'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useUsercentricsServiceSessionId } from 'utils/hooks/use-usercentrics-service-session-id'
import {
  getPurchaseDataAndProfilingDenied,
  SERVICE_SUBSCRIPTIONS,
} from 'utils/service-subscriptions-util'

export const useProductUserEvent = () => {
  const { storeId } = useStoreId()
  const [sendProductUserEventMutation] = useMutation(SendProductUserEventDocument)
  const serviceSubscriptions = useServiceSubscriptions(SERVICE_SUBSCRIPTIONS)

  const purchaseDataAndProfilingDenied = getPurchaseDataAndProfilingDenied(serviceSubscriptions)

  const hasLoop54Consent = useHasServiceConsent(ServiceId.Loop54)
  const sessionId = useUsercentricsServiceSessionId(ServiceId.Loop54) || ''
  const searchProviderIsLoop54 = useCurrentSearchProvider() === SearchProvider.Loop54

  const sendProductUserEvent = async (ids: string[], eventType: ProductUserEventType) => {
    if (searchProviderIsLoop54 && !purchaseDataAndProfilingDenied && hasLoop54Consent) {
      const productAndStoreId = ids.map((id) => `${id}_${storeId}`)
      await sendProductUserEventMutation({
        variables: {
          sessionId,
          productIds: productAndStoreId,
          eventType,
        },
      })
    }
  }

  return {
    sendProductUserClickEvent: (id: string) =>
      sendProductUserEvent([id], ProductUserEventType.ProductClick),
    sendProductUserAddToCartEvent: (id: string) =>
      sendProductUserEvent([id], ProductUserEventType.AddToCart),
    sendProductUserPurchaseEvent: (ids: string[]) =>
      sendProductUserEvent(ids, ProductUserEventType.Purchase),
  }
}
