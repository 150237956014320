import type { CurrentLink } from './FooterLinks'
import type { Entry } from 'contentful'
import type { FC } from 'react'
import type { IFooterFields, ILinkContainer } from 'types/contentful/generated'

import { useState } from 'react'
import styled from 'styled-components'

import { Copyright } from './Copyright'
import { DownloadMobileApp } from './DownloadMobileApp'
import { FooterLinks } from './FooterLinks'

export interface FooterProps {
  className?: string
  fields: IFooterFields
}

const isLinkContainer = (entry: Entry<unknown>): entry is ILinkContainer =>
  entry.sys.contentType.sys.id === 'linkContainer'

const _Footer: FC<FooterProps> = ({ className, fields }) => {
  const [currentLink, setCurrentLink] = useState<CurrentLink>({
    id: null,
    isOpen: false,
  })

  const toggleLinks = (id: number, isOpen: boolean) => {
    setCurrentLink({ id, isOpen: !isOpen })
  }

  const copyrightImage = fields.copyright?.fields?.image
  const copyrightLogo = copyrightImage?.fields?.file?.url
  const copyrightBody = fields.copyright?.fields?.body

  return (
    <footer className={className} data-test-id="footer">
      {fields.footerLinks?.length && (
        <FooterLinks
          data-test-id="footer-links"
          footerLinks={fields.footerLinks}
          currentLink={currentLink}
          toggleLinks={toggleLinks}
        />
      )}

      {fields.mobileAppDownloadLinks && isLinkContainer(fields.mobileAppDownloadLinks) && (
        <DownloadMobileApp
          links={fields.mobileAppDownloadLinks.fields.links}
          data-test-id="download-mobile-app"
        />
      )}

      {fields.copyright && (
        <Copyright logo={copyrightLogo} body={copyrightBody} data-test-id="copyright" />
      )}
    </footer>
  )
}

_Footer.displayName = 'Footer'

export const Footer = styled(_Footer)`
  background-color: ${({ fields }) => fields.backgroundColor};
  color: ${({ theme }) => theme.color.darkGrey800};
  word-wrap: break-word;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xLarge} 0;
  margin-top: ${({ theme }) => theme.spacings.medium};
`
