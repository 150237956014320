import type { Product } from '@shared/gql/document-nodes'

import { getConfig } from '@shared/config'
import { AlcoholSellingStatus } from '@shared/gql/document-nodes'
import { useCallback } from 'react'
import { openDeliveryModal } from 'services/Delivery/cache'
import { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'
import { useProductUserEvent } from 'services/ProductUserEvent/product-user-event'
import { DeliverySelectionStage } from 'types/global'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useTrackingContext } from 'utils/tracking/components/TrackingContextProvider'
import { GA4_addToCart, GA4_removeFromCart } from 'utils/tracking/track-add-to-cart'

import { pendingProductVar } from './cache'
import { useSetCartItemCount } from './mutations/set-cart-item-count'

type ProductItem = Pick<
  Product,
  'id' | 'basicQuantityUnit' | 'quantityMultiplier' | 'name' | 'isAgeLimitedByAlcohol'
>

const { domain } = getConfig()

export const useItemCountHandler = (product: ProductItem, cartItemCount: number) => {
  const { selectedStoreId, storeId } = useStoreId()
  const setItemCount = useSetCartItemCount()
  const trackingContext = useTrackingContext()
  const selectedSlot = useSelectedDeliverySlot()

  const { isSIdEnabled } = domain

  const alcoholSellingNotAllowedForSlot =
    selectedSlot.type === 'SUCCESS' &&
    selectedSlot.data.alcoholSellingAllowed === AlcoholSellingStatus.NotAllowed

  const { sendProductUserAddToCartEvent } = useProductUserEvent()

  return useCallback(
    async (value: number) => {
      if (isSIdEnabled && !selectedStoreId) {
        openDeliveryModal()
        // Product without selected store is added to a pending queue until store is selected
        pendingProductVar({ productId: product.id, storeId, amount: value })
        return
      }

      await setItemCount(product.id, value)

      const isAddingToCart = value > cartItemCount || cartItemCount === 0

      if (isAddingToCart) {
        await sendProductUserAddToCartEvent(product.id)
        GA4_addToCart({
          ...trackingContext,
          products: [
            {
              ...product,
              quantity: Math.abs(cartItemCount - value),
            },
          ],
        })
      } else {
        GA4_removeFromCart({
          ...trackingContext,
          products: [
            {
              ...product,
              quantity: Math.abs(cartItemCount - value),
            },
          ],
        })
      }

      if (
        // Only open delivery modal if adding items, not when removing them
        isAddingToCart &&
        product.isAgeLimitedByAlcohol &&
        alcoholSellingNotAllowedForSlot
      ) {
        openDeliveryModal(DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT)
      }
    },
    [
      alcoholSellingNotAllowedForSlot,
      cartItemCount,
      product,
      selectedStoreId,
      sendProductUserAddToCartEvent,
      setItemCount,
      storeId,
      trackingContext,
      isSIdEnabled,
    ],
  )
}
