import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import styled from 'styled-components'

import { ContentfulImage } from '../ContentfulImage'
import { ImageOnTopCard } from './horizontal-content-grid/image-on-top'
import { ImageWithOverlay } from './horizontal-content-grid/image-with-overlay'
import {
  StyledContentGridItem,
  StyledHorizontalContentGrid,
} from './horizontal-content-grid/styled'
import { TextOnlyBlock } from './horizontal-content-grid/text-only-block'

export const HorizontalContentGrid: FC<{
  section: ISection
}> = ({ section }) => {
  const cards = section.fields.cards
  if (!cards?.length) return null

  return (
    <StyledHorizontalContentGrid className="horizontal-content-grid">
      {cards.map((card, index) => {
        const { title, body, image, imagePosition, functionality } = card.fields
        const count = (cards && cards.length) || undefined
        const cta = functionality?.[0]?.fields
        const position = `_${Number(index) + 1}`

        if (image && imagePosition && imagePosition === 'Top') {
          return (
            <ImageOnTopCard
              key={card.sys.id}
              count={count}
              image={image}
              title={title}
              body={body}
              cta={cta}
              position={position}
            />
          )
        }

        if (image && !title && !body) {
          return (
            <StyledContentGridItem key={card.sys.id} className="content-grid-item">
              <StyledContentfulImage image={image} lazy />
            </StyledContentGridItem>
          )
        }

        if (!image && body) {
          return <TextOnlyBlock key={card.sys.id} title={title} body={body} />
        }

        if (image) {
          return (
            <ImageWithOverlay
              key={card.sys.id}
              image={image}
              title={title}
              body={body}
              cta={cta}
              position={position}
            />
          )
        }

        return null
      })}
    </StyledHorizontalContentGrid>
  )
}

const StyledContentfulImage = styled(ContentfulImage)({
  height: '100%',
  objectFit: 'cover',
})
