import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { FavoriteBtn } from 'components/Product/ExtraActions'
import { useTranslation } from 'react-i18next'

import { useFavorite } from '../hooks/use-favorite'

interface Props {
  ean: string
  onClick?: () => void
}

const { domain } = getConfig()

export const FavoriteButtonContainer: FC<Props> = ({ ean, onClick }) => {
  const { t } = useTranslation()
  const favorite = useFavorite(ean)

  // For domains without S-ID, we should never render the FavoriteButton.
  if (!domain.isSIdEnabled) {
    return null
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }

    favorite.toggle()
  }

  return (
    <FavoriteBtn
      isFavorite={!!favorite.isFavorite}
      title={favorite.isFavorite ? t('Remove from favorites') : t('Add to favorites')}
      onClick={handleClick}
    />
  )
}
