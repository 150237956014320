import type { Product } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { minWidthFromTheme } from '@sok/design-system'
import { searchVisibleVar } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler'
import { useMediaQuery } from 'react-responsive'
import { useEvictSearchCache } from 'services/Search/hooks/use-evict-search-cache'
import { useProductSearch } from 'services/Search/hooks/use-product-search'
import { useQueryStringParam } from 'services/Search/hooks/use-query-string-param'
import { useSortParam } from 'services/Search/hooks/use-sort-param'
import { SUPPORTED_FACETS } from 'services/Search/utils/filters'
import { DEFAULT_SORT, sortToQueryValue } from 'services/Search/utils/sort'
import styled, { useTheme } from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { useDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { useSelectedStoreName } from 'utils/hooks/use-selected-store-name'
import { isNonNullable } from 'utils/nullable'
import { TrackingContextProvider } from 'utils/tracking/components/TrackingContextProvider'
import { pushData, trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { mapGa4Availabilities } from 'utils/tracking/event-product-mapper'
import {
  EventAction,
  EventCategory,
  TrackingContext,
} from 'utils/tracking/interfaces/data-layer-events'
import { trackPageview } from 'utils/tracking/pageview'
import { GA4_trackImpressions } from 'utils/tracking/track-product-impressions'
import { useGetInitialDeliverySelections } from 'views/delivery-modal/method-and-store/use-get-initial-delivery-selections'

import { InputComponent } from './auto-suggest-search-box/InputComponent'
import { ProductSuggestion } from './auto-suggest-search-box/ProductSuggestion'
import { SuggestionsContainer } from './auto-suggest-search-box/SuggestionsContainer'
import { BORDER_RADIUS } from './Button/border-radius'

const MAX_SEARCH_RESULTS = 10
// Average search time in prod at the time of writing is 300-1000ms
const SEARCH_INPUT_DEBOUNCE_TIME_MS = 1000

const StyledInputContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${({ theme }) => theme.spacings.xxLarge};
  flex: 2 1;
  position: relative;
  border-radius: ${BORDER_RADIUS.borderRadius}px;

  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    width: 100%;
    background-color: ${({ theme }) => theme.color.lightestGrey100};
    &:focus {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`

const sliceSuggestions = <T,>(items: T[] | undefined | null): NonNullable<T>[] =>
  items?.filter(isNonNullable).slice(0, MAX_SEARCH_RESULTS) || []

export const AutoSuggestSearchBox: FC = () => {
  // Get initial value from url param
  const queryStringParam = useQueryStringParam()

  const [searchString, setSearchString] = useState(queryStringParam)

  const setQueryString = useDebouncedCallback(
    (value: string) => setSearchString(value),
    SEARCH_INPUT_DEBOUNCE_TIME_MS,
  )
  const searchVisible = useReactiveVar(searchVisibleVar)

  const { t } = useTranslation()
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery({ maxWidth: theme.breakpoints.mobile })
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: theme.breakpoints.mobile })
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()

  const { initialDeliveryMethod } = useGetInitialDeliverySelections()
  const isHomeDelivery = initialDeliveryMethod === DeliveryMethod.HomeDelivery

  const { storeName } = useSelectedStoreName()

  const placeholderText =
    isMobile || isMobileDevice || !storeName
      ? t('Search products')
      : t('Search products from store', { storeName })

  const availabilityDate = useDeliveryDate()
  const listName = `${TrackingContext.AUTOSUGGEST_SEARCHBOX}: ${searchString}`

  const {
    data: productsData,
    loading: productsLoading,
    variables: productsVariables,
  } = useProductSearch(
    {
      context: TrackingContext.AUTOSUGGEST_SEARCHBOX,
      disabled: !searchString.trim() || !searchVisible,
      facets: SUPPORTED_FACETS,
      filters: null,
      includeAgeLimitedByAlcohol: !!alcoholSellingAllowed && !isHomeDelivery,
      loop54DirectSearch: true,
      queryString: searchString,
      orderBy: DEFAULT_SORT[0],
      order: DEFAULT_SORT[1],
    },
    {
      onCompleted(data) {
        // Track same amount of suggestions as is displayed
        const suggestions = sliceSuggestions(data?.store?.products?.items)

        // Report product impressions once per result set, and only
        // when the search suggestions are visible
        const searchedQueryString = productsVariables?.queryString

        if (!searchedQueryString) {
          return
        }

        // Tracking side effect for no results search
        // Only track if search term is long enough to be proper
        if (searchedQueryString.length > 2) {
          pushData({ searchResultsCount: suggestions.length })
          trackPageview({
            path: '/search-suggestions-dialog',
            query: `?queryString=${searchedQueryString}&gasc=product-box`,
          }) // tracking side effect as a virtual page view for GA site search report
        }

        if (suggestions.length === 0) {
          searchVisibleVar(false)
          pushData({ searchResultsCount: 0 })
          trackCustomEvent({
            category: EventCategory.SEARCH,
            action: EventAction.NO_RESULTS,
            label: searchedQueryString,
          })
        } else {
          // The if below fixes issue where the suggestion are shown when the search page is force reloaded.
          if (queryStringParam !== searchString) searchVisibleVar(true)
        }

        pushData({
          viewedCategory: {
            slug: listName,
            id: listName,
          },
        })

        if (suggestions.length) {
          GA4_trackImpressions({
            listName,
            trackingContext: TrackingContext.AUTOSUGGEST_SEARCHBOX,
            products: suggestions.map(mapGa4Availabilities(availabilityDate)),
          })
        }
      },
    },
  )

  // Display only at most 10 products but fetch the entire first page of
  // search results, so that it will be cached into one request.
  const suggestions = useMemo(
    () => sliceSuggestions(productsData?.store?.products?.items),
    [productsData?.store?.products?.items],
  )

  const handleOutsideClick = useCallback(() => {
    searchVisibleVar(false)
  }, [])

  const handleEscKey = useCallback(() => {
    searchVisibleVar(false)
  }, [])

  const [activeSort] = useSortParam()

  const evictSearchCache = useEvictSearchCache()

  const handleSearchAndRedirect = useCallback(
    async (value: string) => {
      if (!value) return
      evictSearchCache()
      setQueryString.cancel()
      const newParams = new URLSearchParams({ queryString: value })
      const sort = sortToQueryValue(activeSort)
      if (sort) newParams.set('sort', sort)
      await router.push(`/hakutulokset?${newParams}`)
      searchVisibleVar(false)
    },
    [activeSort, evictSearchCache, router, setQueryString],
  )

  const onChange = useCallback(
    (newValue: string) => {
      if (newValue != searchString) {
        setQueryString(newValue)
        // On desktop, hide the results when there is no query, otherwise keep it open.
        if (!isMobile && !newValue) {
          searchVisibleVar(false)
        } else {
          searchVisibleVar(true)
        }
      }
    },
    [isMobile, searchString, setQueryString],
  )

  const onFocus = useCallback(
    (hasFocus: boolean) => {
      if (hasFocus && searchString) {
        searchVisibleVar(true)
      }
    },
    [searchString],
  )

  return (
    <TrackingContextProvider
      searchProvider={productsVariables?.searchProvider}
      listName={listName}
      trackingContext={TrackingContext.AUTOSUGGEST_SEARCHBOX}
    >
      <StyledInputContainer data-test-id="querystring">
        <OutsideClickHandler
          disabled={!searchVisible}
          onOutsideClick={handleOutsideClick}
          display="contents"
        >
          <InputComponent
            handleEscKey={handleEscKey}
            handleSearchAndRedirect={handleSearchAndRedirect}
            handleSetFocus={onFocus}
            loading={productsLoading}
            placeholder={placeholderText}
            searchString={searchString}
            onChange={onChange}
          />
          {searchVisible && (
            <SuggestionsContainer
              onShowResults={handleSearchAndRedirect}
              searchString={searchString}
              searchInProgress={productsLoading}
            >
              {suggestions.map((product, suggestionIndex) => (
                <TrackingContextProvider listPosition={suggestionIndex} key={product.ean}>
                  <ProductSuggestion
                    handleResetSearch={() => {}}
                    isMobile={isMobile || isMobileDevice}
                    product={product as Product}
                    suggestionIndex={suggestionIndex}
                  />
                </TrackingContextProvider>
              ))}
            </SuggestionsContainer>
          )}
        </OutsideClickHandler>
      </StyledInputContainer>
    </TrackingContextProvider>
  )
}
