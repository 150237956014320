import type { FC } from 'react'

import {
  SArtCrafts,
  SBreadsBiscuitsPastries,
  SCheese,
  SChildren,
  SChristmas,
  SCleaning,
  SCounterProducts,
  SDrinks,
  SDryProductsAndBaking,
  SFishAndSeaFood,
  SFrozenGoods,
  SFruitsAndVegetables,
  SHomeAppliance,
  SHomeTextile,
  SHygieneAndCosmetics,
  SKitchenware,
  SMeatVegetableProtein,
  SMilkEggsFats,
  SNaturalProductsAndSupplements,
  SNewProducts,
  SOilSpicesSeasoning,
  SPartyAndCatering,
  SPets,
  SPlaceholder,
  SReadyFood,
  SRecommended,
  SSports,
  SSweetsAndSnacks,
} from 'components/icons'

const ICON_MAP: Record<string | number, JSX.Element> = {
  [1591691233334707]: <SHygieneAndCosmetics />,
  [1591691234054950]: <SKitchenware />,
  [1591691234596256]: <SCleaning />,
  [1591691237515281]: <SChristmas />,
  [1591691237515363]: <SChristmas />,
  [1591691237793209]: <SHomeTextile />,
  [1591691238190624]: <SHomeAppliance />,
  [1591691243164290]: <SSports />,
  [1591691259195579]: <SChristmas />,
  [1591691265158201]: <SNewProducts />,
  ['Herkku_00000001']: <SFruitsAndVegetables />,
  ['Herkku_00000002']: <SArtCrafts />,
  ['Herkku_00000003']: <SCheese />,
  ['Herkku_00000004']: <SFishAndSeaFood />,
  ['Herkku_00000005']: <SDrinks />,
  ['Herkku_00000006']: <SMilkEggsFats />,
  ['Herkku_00000007']: <SDryProductsAndBaking />,
  ['Herkku_00000008']: <SBreadsBiscuitsPastries />,
  ['Herkku_00000009']: <SSweetsAndSnacks />,
  ['Herkku_00000010']: <SChildren />,
  ['Herkku_00000011']: <SOilSpicesSeasoning />,
  ['Herkku_00000012']: <SPets />,
  ['Herkku_00000013']: <SFrozenGoods />,
  ['Herkku_00000014']: <SMeatVegetableProtein />,
  ['Herkku_00000016']: <SNaturalProductsAndSupplements />,
  ['StructureGroup_1356670909914497']: <SReadyFood />,
  ['StructureGroup_1796559627348384']: <SCounterProducts />,
  ['StructureGroup_2399377157954654']: <SRecommended />,
  ['StructureGroup_397118047104']: <SPartyAndCatering />,
}

export const NavigationIcon: FC<{ id?: string | number | null }> = ({ id }) =>
  id && id in ICON_MAP ? ICON_MAP[id] : <SPlaceholder />
