import type { FC } from 'react'

// Chevrons

export const ChevronDown: FC = () => (
  <svg width="17" height="9" fill="none" data-test-id="chevron-down-icon">
    <path d="m8.178 9-8-8.167.817-.833 7.183 7.31 7.182-7.31.818.833z" fill="#484848" />
  </svg>
)

export const ChevronUp: FC = () => (
  <svg width="17" height="9" fill="none" data-test-id="chevron-up-icon">
    <path d="m8.998 0-8 8.167.818.833 7.182-7.31 7.182 7.31.818-.833z" fill="#484848" />
  </svg>
)

export const ChevronLeft: FC = () => (
  <svg width="10" height="16" fill="none" data-test-id="chevron-left-icon">
    <path d="m9.045 16 .833-.818-7.31-7.182 7.31-7.182-.833-.818-8.167 8z" fill="#484848" />
  </svg>
)

export const ChevronRight: FC = () => (
  <svg width="10" height="16" fill="none" data-test-id="chevron-right-icon">
    <path d="M1.74 16L1 15.182 7.497 8 1 .818 1.74 0 9 8l-7.26 8z" fill="#484848" />
  </svg>
)

// Arrows

export const ArrowRight: FC = () => (
  <svg width="16" height="11" fill="none" data-test-id="arrow-right-icon">
    <path
      d="m10.754 11 5.246-5.5-5.246-5.5-.808.847 3.866 4.054h-13.812v1.198h13.812l-3.866 4.054z"
      fill="#484848"
    />
  </svg>
)

export const ArrowLeft: FC = () => (
  <svg width="16" height="10" fill="none" data-test-id="arrow-left-icon">
    <path
      d="m6.23 0-5.246 5.5 5.246 5.5.808-.847-3.866-4.054h13.812v-1.198h-13.812l3.866-4.054z"
      fill="#484848"
    />
  </svg>
)

export const Clear: FC = () => (
  <svg width="24" height="24" fill="none" data-test-id="clear-icon">
    <path
      d="m24 .687-.687-.687-11.313 11.313-11.314-11.313-.686.687 11.313 11.313-11.313 11.313.687.687 11.313-11.313 11.313 11.313.687-.687-11.313-11.313 11.313-11.314z"
      fill="#484848"
    />
  </svg>
)
