import { createContext, useContext } from 'react'

interface CTAProps {
  label?: string
  url?: string
  openLinkInNewTab?: boolean
  handleClick?: () => void
}

interface TrackingProps {
  trackingName?: string
  trackingPosition?: string
  trackingLabel?: string
  trackingCreative?: string
}

export interface SlideContext {
  tracking: TrackingProps | null
  cta: CTAProps
}

export const HeroCarouselSlideContext = createContext<SlideContext | null>(null)

export const useHeroCarouselSlide = () => {
  const ctx = useContext(HeroCarouselSlideContext)
  if (!ctx) {
    throw new Error('Must be wrapped inside SlideContext')
  }
  return ctx
}
