import type { ReactNode } from 'react'
import type { Validator } from 'validation/validators'

import { createContext, useContext, useEffect, useState } from 'react'

import { mapFpTsValidationErrors } from './map-fp-ts-validation-errors'

export type Model = Record<string, string | null>

type State<T extends Model = Model> = {
  isSubmitted: boolean
  isValid: boolean
  errors: Record<keyof T, string> | null
  form: T
  dirty: Record<string, boolean>
}

type Context<T extends Model = Model> = State & {
  setValue: (name: string, value: T[keyof T]) => void
}

interface FormProps<T extends Model, ValidatedT> {
  children: ReactNode
  className?: string
  model: T
  onSubmit: (x: T) => void
  validator: Validator<T, ValidatedT>
  id?: string
  onChange?: (x: T) => void
}

const FormContext = createContext<Context | null>(null)

export const Form = <T extends Model, ValidatedT>({
  children,
  className,
  model,
  onChange,
  onSubmit,
  validator,
  id: formId,
}: FormProps<T, ValidatedT>) => {
  const [state, setState] = useState<State<T>>({
    isValid: false,
    errors: null,
    form: model,
    isSubmitted: false,
    dirty: {},
  })

  const getValidatedState = (newState: State<T>) => {
    const errors = mapFpTsValidationErrors<T>(validator(newState.form))

    return {
      ...newState,
      isValid: !errors,
      errors,
    }
  }

  const setValue = (name: string, value: string | null) => {
    if (state.form[name] === value && state.dirty[name]) return

    setState((oldState) =>
      getValidatedState({
        ...oldState,
        dirty: {
          ...oldState.dirty,
          [name]: true,
        },
        form: {
          ...oldState.form,
          [name]: value,
        },
      }),
    )
  }

  useEffect(() => {
    onChange?.(state.form)
  }, [onChange, state.form])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newState = getValidatedState({
      ...state,
      isSubmitted: true,
    })
    onSubmit(newState.form)
    setState(newState)
  }

  return (
    <FormContext.Provider
      value={{
        ...state,
        setValue,
      }}
    >
      <form id={formId} className={className} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

export const useFormField = <T extends string | null>(name: string) => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('Must be used inside FormContext.Provider')
  }
  const isDirty = context.dirty[name]
  const handleSetValue = (value: T) => {
    context.setValue(name, value)
  }

  return {
    error: isDirty || context.isSubmitted ? context.errors?.[name] || null : null,
    isDirty,
    setValue: handleSetValue,
    value: context.form[name] as T,
  }
}
