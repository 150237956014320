import type { Order } from '@shared/gql/document-nodes'

import { useMutation } from '@apollo/client'
import {
  CancelOrderDocument,
  ClearCartAndOrderDetailsDocument,
  GetOrderByIdDocument,
} from '@shared/gql/document-nodes'
import { useRouter } from 'next/router'
import { handleRequestErrors } from 'services/error-handler'
import { useLinkVerificationToken } from 'services/LinkVerificationToken/use-link-verification-token'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

type UseCancelOrder = () => (order: Pick<Order, 'id'>) => Promise<void>

export const useCancelOrder: UseCancelOrder = () => {
  const [cancelOrder] = useMutation(CancelOrderDocument)
  const [clearInfo] = useMutation(ClearCartAndOrderDetailsDocument)
  const linkVerificationToken = useLinkVerificationToken()

  const isLoggedIn = useIsLoggedIn()
  const router = useRouter()
  return async ({ id }) => {
    try {
      await cancelOrder({
        variables: { id },
        refetchQueries: [
          {
            query: GetOrderByIdDocument,
            variables: { id, linkVerificationToken },
          },
        ],
      })

      await clearInfo({ variables: { isLoggedIn } })
      await router.push(`/tilaus/${id}`)
    } catch (e) {
      handleRequestErrors([e])
    }
  }
}
