import type { ChangeEventHandler, FC } from 'react'

import { FormControl, FormControlStatus, Input, InputSize } from '@sok/design-system'
import { memo } from 'react'

import { useFormField } from './Form'

interface Props {
  'data-test-id': string
  disabled?: boolean
  id?: string
  label?: string
  maxLength?: number
  name: string
  placeholder?: string
  className?: string
}

type FieldProps = Props & {
  value: string
  setValue: (x: string) => void
  error: string | null
}

const _TextField: FC<FieldProps> = memo(
  ({
    'data-test-id': dataTestId,
    disabled,
    maxLength,
    placeholder,
    label,
    name,
    className,
    value,
    setValue,
    error,
  }) => {
    const handleUpdate: ChangeEventHandler<HTMLInputElement> = (e) => setValue(e.target.value)

    return (
      <FormControl
        className={className}
        data-test-id={dataTestId}
        label={label || ''}
        status={error ? FormControlStatus.ERROR : undefined}
        helpText={error || undefined}
      >
        <Input
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          name={name}
          onBlur={handleUpdate}
          onChange={handleUpdate}
          placeholder={placeholder}
          size={InputSize.LARGE}
        />
      </FormControl>
    )
  },
  (a, b) => a.value === b.value && a.error === b.error,
)
_TextField.displayName = 'TextField'

export const TextField = (props: Props) => {
  const { error, setValue, value } = useFormField<string>(props.name)
  return <_TextField {...props} value={value} setValue={setValue} error={error} />
}
