import type { Sort, SortKey, SortOrder } from '../utils/sort'

import { useCallback, useMemo } from 'react'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'

import { DEFAULT_SORT, queryValueToSort, SORT_PARAM, sortToQueryValue } from '../utils/sort'

type SetSort = (sort: SortKey | null, order: SortOrder | null) => void

/** Get active sort order from the current Next.js Router `query`. */
export const useSortParam = (): [Sort, SetSort] => {
  const { params, removeParams, replaceParam } = useURLSearchParams()
  const isClientSide = useIsClientSide()

  const sortParams = params.getAll(SORT_PARAM)

  const sort = useMemo(() => {
    const querySort = queryValueToSort(sortParams)
    if (querySort) return querySort

    if (isClientSide) {
      const sessionSort = queryValueToSort(
        typeof sessionStorage !== 'undefined' ? sessionStorage.getItem?.(SORT_PARAM) : null,
      )
      if (sessionSort) return sessionSort
    }

    return DEFAULT_SORT
  }, [sortParams, isClientSide])

  const setSort = useCallback<SetSort>(
    (sortKey, sortOrder) => {
      const sortValue = sortToQueryValue([sortKey, sortOrder])
      if (sortValue) {
        replaceParam(SORT_PARAM, sortValue)

        try {
          sessionStorage?.setItem?.(SORT_PARAM, sortValue)
        } catch {} // eslint-disable-line no-empty
      } else {
        removeParams([SORT_PARAM, undefined])
        sessionStorage?.removeItem?.(SORT_PARAM)
      }
    },
    [removeParams, replaceParam],
  )

  return [sort, setSort]
}
