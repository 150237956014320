import type { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { PromptCard } from './PromptCard'

export const LoginPromptCard: FC = () => {
  const { t } = useTranslation()

  return (
    <PromptCard
      icon="recommendations"
      caption={t('Sign in to see your recommendations')}
      href="/suosittelemme-sinulle"
    />
  )
}
