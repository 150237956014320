import type { FC, ReactNode } from 'react'

import styled from 'styled-components'
import { clampLines } from 'styles/clamp-lines'

interface ProductNameProps {
  className?: string
  lines?: number
  children?: ReactNode
  ['data-test-id']?: string
}

/**
 *
 * @example <caption>Default usage</caption>
 * <ProductName>{'Long Product Name'}</ProductName>
 * Clamps to 1 line, suitable for Product row component
 *
 *
 * @example <caption>Card usage</caption>
 * <ProductName lines={2}>{'Long Product Name'}</ProductName>
 * Clamps to 2 line, suitable for Product card component
 *
 */
const _ProductName: FC<ProductNameProps> = ({
  className,
  children,
  ['data-test-id']: dataTestId,
}) => (
  <span className={className} data-test-id={dataTestId}>
    {children}
  </span>
)

export const ProductName = styled(_ProductName)(
  {
    position: 'relative',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  ({ lines = 1 }) => clampLines(lines),
  ({ theme }) => ({ ...theme.variants.body1.regular, color: theme.colors.grey800 }),
)
