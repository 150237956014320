import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { SDS_SPACE_XSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { NavProfileItem, trackUserItemClick } from 'utils/tracking/navigation'

interface Props {
  className?: string
  loginIsPrimaryCta?: boolean
  onLogin?: () => void
  onRegister?: () => void
}

const onLoginItemClick = () => trackUserItemClick(NavProfileItem.LOGGED_OUT_LOGIN)
const onRegisterItemClick = () => trackUserItemClick(NavProfileItem.LOGGED_OUT_CREATE_ACCOUNT)

const _LoginButtons: FC<Props> = ({ className, loginIsPrimaryCta = true, onLogin, onRegister }) => {
  const { t } = useTranslation()
  const { onLoginClick, onRegisterClick } = useGetProfileActionHandlers()

  const handleLogin = () => {
    onLoginClick()
    onLoginItemClick()
    onLogin?.()
  }
  const handleRegister = () => {
    onRegisterClick()
    onRegisterItemClick()
    onRegister?.()
  }

  const loginCopy = t('Log in with S-ID')
  const registerCopy = t('Create account')

  return (
    <div className={className} data-test-id="login-buttons">
      <Button primary rounding="small" onClick={loginIsPrimaryCta ? handleLogin : handleRegister}>
        {loginIsPrimaryCta ? loginCopy : registerCopy}
      </Button>
      <Button rounding="small" onClick={loginIsPrimaryCta ? handleRegister : handleLogin}>
        {loginIsPrimaryCta ? registerCopy : loginCopy}
      </Button>
    </div>
  )
}

_LoginButtons.displayName = 'LoginButtons'

export const LoginButtons = styled(_LoginButtons)({
  display: 'flex',
  flexDirection: 'column',
  'button + button': {
    marginTop: SDS_SPACE_XSMALL,
  },
})
