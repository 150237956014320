import type { UserProfile } from '@shared/gql/document-nodes'
import type { CurrentUser } from 'domain/current-user'
import type { FC } from 'react'
import type { ISection } from 'types/contentful/generated'

import { useQuery } from '@apollo/client'
import { getConfig } from '@shared/config'
import { GetSelectedStoreIdDocument } from '@shared/gql/document-nodes'
import { ArrowLeftOutline16, Button, ButtonType } from '@sok/design-system'
import { TextBlock } from 'components/common/Titles'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetCurrentProfile } from 'utils/hooks/account/use-get-current-profile'
import { useGetRemoteUserProfile } from 'utils/hooks/account/use-get-remote-user-profile'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'
import { commonLogger } from 'utils/log/common-logger'

const { stage } = getConfig()

interface CustomerServiceFormProps {
  section: ISection
}

const StyledFormContainer = styled.div`
  padding-top: 16px;
`

const StyledLinkContainer = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  button {
    padding: 0 !important;
  }
`

const StyledFormDescription = styled(StyledLinkContainer)`
  margin-top: 1rem;
  p {
    margin: 1rem 0;
  }
  ul {
    margin-left: 1rem;
  }
`

const getFormUrlWithParams = ({
  formUrl,
  storeId,
  userProfile,
  remoteProfile,
}: {
  formUrl?: string
  storeId?: string
  userProfile: CurrentUser | null
  remoteProfile?: Pick<UserProfile, 'email' | 'phoneNumber'> | null
}): string | null => {
  if (!formUrl) return null
  try {
    const formUrlWithParams = new URL(formUrl)
    if (storeId) {
      formUrlWithParams.searchParams.set('pobId', storeId)
    }
    if (userProfile?.customerId) {
      formUrlWithParams.searchParams.set('casId', userProfile.customerId)
    }
    if (remoteProfile?.email) {
      formUrlWithParams.searchParams.set('email', remoteProfile.email)
    }
    if (remoteProfile?.phoneNumber) {
      formUrlWithParams.searchParams.set('phone', remoteProfile.phoneNumber)
    }
    return formUrlWithParams.toString()
  } catch (_error) {
    commonLogger.warnSync({
      message: 'Failed to parse customer service form url',
      data: { formUrl },
    })
  }
  return null
}

export const CustomerServiceForm: FC<CustomerServiceFormProps> = ({ section }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const userProfile = useGetCurrentProfile()
  const { userProfile: remoteProfile, loading } = useGetRemoteUserProfile()
  const { customerServiceFormUrl, customerServiceFormUrlTest } = section.fields
  const parsedSectionBody = useParsedMarkdown(section.fields.body)
  // FIXME [VOIK-3754] Replace with Contentful environments
  const formUrl = stage === 'production' ? customerServiceFormUrl : customerServiceFormUrlTest
  const { data: selectedStoreIdData } = useQuery(GetSelectedStoreIdDocument)
  const storeId = selectedStoreIdData?.selectedStoreId ?? undefined
  const formUrlWithParams = getFormUrlWithParams({
    formUrl,
    storeId,
    userProfile,
    remoteProfile,
  })
  if (!formUrlWithParams || loading) return null
  return (
    <>
      <StyledLinkContainer>
        <Button
          type={ButtonType.LINK}
          onClick={() => router.back()}
          icon={<ArrowLeftOutline16 />}
          text={t('Back')}
        />
      </StyledLinkContainer>
      {parsedSectionBody && (
        <StyledFormDescription>
          <TextBlock>{parsedSectionBody}</TextBlock>
        </StyledFormDescription>
      )}
      <StyledFormContainer>
        <iframe
          title="Customer Service Form"
          src={formUrlWithParams}
          style={{
            width: '100%',
            height: '200vh',
          }}
          frameBorder="0"
          allow="fullscreen"
        ></iframe>
      </StyledFormContainer>
    </>
  )
}
