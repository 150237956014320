import type { ICardFields } from 'types/contentful/generated'

import { minWidthFromTheme } from '@sok/design-system'
import styled from 'styled-components'

export const StyledSectionWrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => `${theme.spacings.xxLarge} 0`};
`

export const StyledCard = styled.article<ICardFields>`
  display: flex;
  width: ${({ imageAndTextWidth }) => (imageAndTextWidth ? imageAndTextWidth : '100%')};
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  max-width: 1200px;
  margin: 0 auto;
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    flex-direction: ${({ imagePosition }) => (imagePosition === 'Right' ? 'row-reverse' : 'row')};
  }
`

export const StyledMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${({ theme }) => theme.spacings.large};
  padding-bottom: ${({ theme }) => theme.spacings.xxLarge};
  ${({ theme }) => minWidthFromTheme(theme).tablet} {
    width: 60%;
  }
`

export const StyledCTA = styled.a`
  text-decoration: none;
  margin-top: ${({ theme }) => theme.spacings.xxLarge};
`
