import type { FC } from 'react'

import { Link } from '@s-group/design-system-components'
import {
  IconCommerceShoppingcart,
  IconCommerceStore,
  IconCommunicationCustomerservice,
  IconContentRecipes,
  IconControlBulb,
  IconPaymentInvoice,
  IconStatusHelp,
} from '@s-group/design-system-icons'
import { showFirstLayer, useIsInitialized } from '@s-group/react-usercentrics'
import { ExternalLinkIcon } from 'components/icons/ExternalLinkIcon'
import NextLink from 'next/link'
import { useTranslation } from 'react-i18next'
import { trackMenuItemClick } from 'utils/tracking/navigation'

import { MenuDrawer } from './components/MenuDrawer/MenuDrawer'
import { MenuFooter, MenuGroup, MenuLink, MenuLinks } from './components/MenuDrawer/MenuSection'
import { BrandLogo } from './NavigationMenu'

export const ContentMenu: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen: isMenuOpen,
  onClose,
}) => {
  const { t } = useTranslation()
  const isUserCentricsInitialized = useIsInitialized()

  const handleCookieSettingsChangeClick = () => {
    trackMenuItemClick(t('menuDrawer_placeholder Change cookie settings'))()
    onClose()
    if (isUserCentricsInitialized) {
      showFirstLayer()
    }
  }

  return (
    <MenuDrawer isOpen={isMenuOpen} onClose={onClose} position="left" data-test-id="content-menu">
      <nav aria-label={t('Main menu')}>
        <BrandLogo onClick={trackMenuItemClick(t('Homepage'))} />

        <MenuGroup label={t('menuDrawer_placeholder Ideas')}>
          <MenuLink
            href="/sivu/ajankohtaista"
            icon={<IconControlBulb />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder Topical'))}
          >
            {t('menuDrawer_placeholder Topical')}
          </MenuLink>
          <MenuLink
            href="/sivu/reseptit"
            icon={<IconContentRecipes />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder Recipes'))}
          >
            {t('menuDrawer_placeholder Recipes')}
          </MenuLink>
        </MenuGroup>

        <MenuGroup label={t('menuDrawer_placeholder Instructions')}>
          <MenuLink
            href="/tuki"
            icon={<IconStatusHelp />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder All instructions and tips'))}
          >
            {t('menuDrawer_placeholder All instructions and tips')}
          </MenuLink>
          <MenuLink
            href="/tuki/maksaminen"
            icon={<IconPaymentInvoice />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder How to pay'))}
          >
            {t('menuDrawer_placeholder How to pay')}
          </MenuLink>
          <MenuLink
            href="/tuki/tilaaminen"
            icon={<IconCommerceShoppingcart />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder How to order'))}
          >
            {t('menuDrawer_placeholder How to order')}
          </MenuLink>
        </MenuGroup>

        <MenuGroup label={t('menuDrawer_placeholder Contact info')}>
          <MenuLink
            href="/asiakaspalvelu"
            icon={<IconCommunicationCustomerservice />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder Customer service'))}
          >
            {t('menuDrawer_placeholder Customer service')}
          </MenuLink>
          <MenuLink
            href="/myymalat"
            icon={<IconCommerceStore />}
            onClick={trackMenuItemClick(t('menuDrawer_placeholder Stores and opening times'))}
          >
            {t('menuDrawer_placeholder Stores and opening times')}
          </MenuLink>
        </MenuGroup>
      </nav>

      <MenuLinks>
        <ul>
          <li>
            <NextLink href="/sivu/tilaus-ja-toimitusehdot" passHref>
              <Link
                onClick={trackMenuItemClick(t('menuDrawer_placeholder Conditions of contract'))}
                standalone
              >
                {t('menuDrawer_placeholder Conditions of contract')}
              </Link>
            </NextLink>
          </li>
          <li>
            <NextLink href="/sivu/tietosuoja-ja-turvallisuus" passHref>
              <Link
                onClick={trackMenuItemClick(t('menuDrawer_placeholder Data privacy and security'))}
                standalone
              >
                {t('menuDrawer_placeholder Data privacy and security')}
              </Link>
            </NextLink>
          </li>
          <li>
            <Link standalone aria-haspopup onClick={handleCookieSettingsChangeClick}>
              {t('menuDrawer_placeholder Change cookie settings')}
            </Link>
          </li>
          <li>
            <NextLink href="https://www.oivahymy.fi/hae-yrityksia" target="_blank" passHref>
              <Link
                onClick={trackMenuItemClick(t('menuDrawer_placeholder Oiva-reports'))}
                standalone
              >
                {t('menuDrawer_placeholder Oiva-reports')} <ExternalLinkIcon />
              </Link>
            </NextLink>
          </li>
        </ul>
      </MenuLinks>

      <MenuFooter>
        <NextLink
          href="https://apps.apple.com/fi/app/s-kaupat/id1528187064"
          target="_blank"
          onClick={trackMenuItemClick(t('menuDrawer_placeholder Download from App Store'))}
          legacyBehavior={false}
        >
          <img
            src="//images.ctfassets.net/0o5z0rfk7er2/5Md8HTYwh0ieSUrA2De2qJ/bf7c80ff0d23dffeb5fe88f007e09917/Download_on_the_App_Store_Badge_FI_RGB_blk_100217.svg"
            alt={t('menuDrawer_placeholder Download from App Store')}
          />
        </NextLink>
        <NextLink
          href="https://play.google.com/store/apps/details?id=fi.sok.skaupat"
          target="_blank"
          onClick={trackMenuItemClick(t('menuDrawer_placeholder Download from Google Play'))}
          legacyBehavior={false}
        >
          <img
            src="//images.ctfassets.net/0o5z0rfk7er2/1P7Se9XH4BCge1A4S42In3/1fe4e5bd1c7720f6f20d13dabe0187ac/playstore.svg"
            alt={t('menuDrawer_placeholder Download from Google Play')}
          />
        </NextLink>
        <p>{t('menuDrawer_placeholder Copyright')}</p>
      </MenuFooter>
    </MenuDrawer>
  )
}
