import type { ProductCardLoadingProps } from './ProductCardLoading'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { minWidthFromTheme } from '@sok/design-system'
import { StyledProductImageContainer } from 'components/ProductImage'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'

import { _ProductCard, defaultStyles } from './ProductCard'
import {
  defaultLoadingStyles,
  DetailsLoader,
  ImageLoader,
  loadingRowStyles,
  QuantityLoader,
} from './ProductCardLoading'
import { ProductQuantity } from './ProductQuantity'
import { ReplaceCheckBox } from './ReplaceCheckBox'
import { rowStyles } from './Row'

/* Modified Row Component Styles for OrderHistory
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=5899%3A186595
 */
const orderHistoryStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  gridTemplateColumns: 'min-content 1fr min-content',
  columnGap: theme.spacings.xxxSmall,

  [StyledProductImageContainer]: {
    width: 40,
  },

  [ProductQuantity]: {
    gridColumn: 3,
    width: 40,
    height: 40,
    alignSelf: 'center',

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: 4,
    },
  },

  [ReplaceCheckBox]: {
    marginTop: theme.spacings.xSmall,
    paddingLeft: theme.spacings.xSmall,
    gridRow: 3,
    gridColumn: '1 / 3',

    [minWidthFromTheme(theme).tablet]: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: theme.spacings.small,
      alignSelf: 'center',
      gridColumn: 3,
      gridRow: '1 / 2',
    },
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, orderHistoryStyles)

/** Product Card Loading Styles for OrderHistory
  https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=6373%3A318370
  **/
const orderHistoryLoadingStyles: ThemedObjectStyleFunction = () => ({
  [ImageLoader]: {
    width: '40px',
    height: '40px',
  },

  [DetailsLoader]: {
    gridColumn: '2 / 3',

    ['>' + SkeletonLoader]: {
      '&:nth-child(2)': {
        width: '70%',
      },
    },
  },

  [QuantityLoader]: {
    gridColumn: 3,
  },
})

const _OrderHistoryLoading: FC<ProductCardLoadingProps> = ({
  className,
  'data-test-id': dataTestId = 'product-orderHistory-loading',
}) => (
  <article className={className} data-test-id={dataTestId}>
    <ImageLoader />
    <DetailsLoader>
      <SkeletonLoader />
      <SkeletonLoader />
    </DetailsLoader>
    <QuantityLoader />
  </article>
)

const Loading = styled(_OrderHistoryLoading)(
  defaultLoadingStyles,
  loadingRowStyles,
  orderHistoryLoadingStyles,
)

export const OrderHistory = { Loading, Success }

OrderHistory.Success.defaultProps = {
  readOnlyImage: true,
  readOnlyName: true,
}
