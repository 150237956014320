import { useMutation } from '@apollo/client'
import { CitrusAdUserEventType, SendCitrusAdUserEventDocument } from '@shared/gql/document-nodes'
import { ServiceId } from 'config/usercentrics'
import { useCallback } from 'react'
import { useUsercentricsServiceSessionId } from 'utils/hooks/use-usercentrics-service-session-id'

export const useCitrusAdUserEvent = () => {
  const [sendCitrusAdUserEventMutation] = useMutation(SendCitrusAdUserEventDocument)
  const sessionId = useUsercentricsServiceSessionId(ServiceId.CitrusAd)

  const sendCitrusAdUserEvent = useCallback(
    async (eventType: CitrusAdUserEventType, adId: string | null, orderId: string | null) => {
      if (sessionId) {
        await sendCitrusAdUserEventMutation({
          variables: {
            sessionId,
            adId: adId ?? '',
            eventType,
            orderId,
          },
        })
      }
    },
    [sessionId, sendCitrusAdUserEventMutation],
  )
  return {
    sendCitrusAdUserClickEvent: useCallback(
      (adId: string) => sendCitrusAdUserEvent(CitrusAdUserEventType.Click, adId, null),
      [sendCitrusAdUserEvent],
    ),
    sendCitrusAdUserImpressionEvent: useCallback(
      (adId: string) => sendCitrusAdUserEvent(CitrusAdUserEventType.Impression, adId, null),
      [sendCitrusAdUserEvent],
    ),
    sendCitrusAdUserPurchaseEvent: useCallback(
      (orderId: string) => sendCitrusAdUserEvent(CitrusAdUserEventType.Purchase, null, orderId),
      [sendCitrusAdUserEvent],
    ),
  }
}
