import { IconCommerceDelivery, IconCommerceLocker } from '@s-group/design-system-icons'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import React from 'react'
import styled from 'styled-components'

const AlertStatus = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09558 1.24188L0.200301 7.8183C-0.0320561 8.21722 -0.0632504 8.60301 0.111917 8.90568C0.287485 9.20835 0.63942 9.375 1.10253 9.375H8.8975C9.36061 9.375 9.71255 9.20835 9.88811 8.90568C10.0633 8.60301 10.0321 8.21722 9.79933 7.8171L5.90485 1.24227C5.67289 0.844148 5.35095 0.625 4.99982 0.625C4.64828 0.625 4.32674 0.844545 4.09558 1.24188Z"
      fill="#C6161A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 6.73466V2.75H5.5V6.73466H4.5Z"
      fill="white"
    />
    <circle cx="5.02344" cy="7.875" r="0.625" fill="white" />
  </svg>
)

const IncompleteStatus = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="#484848" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.60156 5.98466V2H5.60156V5.98466H4.60156Z"
      fill="white"
    />
    <circle cx="5.125" cy="7.125" r="0.625" fill="white" />
  </svg>
)

const ClosedStatus = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="5" r="5" fill="#C6161A" />
    <path d="M7.12109 2.87891L2.87845 7.12155" stroke="white" />
    <path d="M2.87891 2.87891L7.12155 7.12155" stroke="white" />
  </svg>
)

const CompleteStatus = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C2.24286 0 0 2.24286 0 5C0 7.75643 2.24286 10 5 10C7.75643 10 10 7.75643 10 5C10 2.24286 7.75643 0 5 0Z"
      fill="#00AA46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95718 3.50008L4.25008 7.20718L2.04297 5.00008L2.75008 4.29297L4.25008 5.79297L7.25008 2.79297L7.95718 3.50008Z"
      fill="white"
    />
  </svg>
)

const Status: React.FC<{
  status: 'incomplete' | 'alert' | 'closed' | 'complete'
}> = ({ status }) => {
  switch (status) {
    case 'alert':
      return <AlertStatus />
    case 'incomplete':
      return <IncompleteStatus />
    case 'closed':
      return <ClosedStatus />
    case 'complete':
      return <CompleteStatus />
  }
}

export const DeliveryMethodIcon: React.FC<{
  deliveryMethod: DeliveryMethod
}> = ({ deliveryMethod }) => {
  switch (deliveryMethod) {
    case DeliveryMethod.HomeDelivery:
      return <IconCommerceDelivery />
    case DeliveryMethod.Pickup:
      return <IconCommerceLocker />
    default:
      return null
  }
}

const _DeliverySelectionStatus: React.FC<{
  className?: string
  deliveryMethod: DeliveryMethod
  status: 'incomplete' | 'alert' | 'closed' | 'complete'
}> = ({ className, deliveryMethod, status }) => {
  return (
    <i className={className}>
      <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
      <div className="status">
        <Status status={status} />
      </div>
    </i>
  )
}

export const DeliverySelectionStatus = styled(_DeliverySelectionStatus)({
  display: 'block',
  position: 'relative',
  lineHeight: 0,

  '.status': {
    position: 'absolute',
    top: '0',
    left: '0',
  },
})
