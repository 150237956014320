import type {
  DomainDelivery,
  DomainOrder,
  RemoteDeliverySlotQuery,
  SlotAvailability,
} from '@shared/gql/document-nodes'
import type { ValidDraftDomainOrder } from 'domain/order'
import type { ValidationError } from 'validation/validators'

import { DeliveryMethod, PaymentMethod } from '@shared/gql/document-nodes'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { setValidationErrorMessage } from 'validation/set-validation-error-message'
import { validationErrorOf } from 'validation/validators'
import * as V from 'validation/validators'

import {
  containsAlcoholProducts,
  containsOtherThanAlcoholProducts,
  filterOutAlcoholProducts,
} from '../support/filter-alcohol-products'
import { isProductAvailableInStore } from '../support/is-product-available-in-store'

export type RemoteDeliverySlot = Omit<
  NonNullable<RemoteDeliverySlotQuery['deliverySlot']>,
  '__typename'
>

export const customerV =
  (
    path: readonly string[],
  ): V.Validator<DomainOrder['customer'], ValidDraftDomainOrder['customer']> =>
  (x) =>
    V.validate({
      __typename: E.right(x.__typename),
      type: pipe(x.type, V.nonNullable([...path, 'type'])),
      email: pipe(x.email, V.required([...path, 'email'])),
      firstName: pipe(x.firstName, V.required([...path, 'firstName'])),
      lastName: pipe(x.lastName, V.required([...path, 'lastName'])),
      phone: pipe(x.phone, V.required([...path, 'phone'])),
      companyName: E.right(x.companyName),
      stockmannCardNumber: E.right(x.stockmannCardNumber),
      cardType: E.right(x.cardType),
    })

export const deliveryV =
  (path: readonly string[], method: DeliveryMethod) =>
  (
    x: Omit<DomainDelivery, '__typename'> | null,
  ): E.Either<ValidationError[], ValidDraftDomainOrder['deliveryAddress']> => {
    switch (method) {
      case DeliveryMethod.Pickup:
        return E.right({
          __typename: 'DomainDelivery',
          address: null,
          city: null,
          postalCode: null,
        })
      case DeliveryMethod.HomeDelivery:
        return V.validate({
          __typename: E.right('DomainDelivery' as const),
          address: V.required([...path, 'address'])(x?.address),
          city: V.required([...path, 'city'])(x?.city),
          postalCode: V.required([...path, 'postalCode'])(x?.postalCode),
        })
      default:
        return E.left([
          validationErrorOf('errorMessages_deliveryMethodNotAvailable', path, 'deliveryMethod'),
        ])
    }
  }

const paymentMethodV =
  (
    path: readonly string[],
  ): V.Validator<
    DomainOrder['payment']['paymentMethod'],
    ValidDraftDomainOrder['payment']['paymentMethod']
  > =>
  (x) => {
    switch (x) {
      case PaymentMethod.CardPayment:
      case PaymentMethod.OnDelivery:
      case PaymentMethod.Invoice:
        return E.right(x)
      default:
        return E.left([
          validationErrorOf('errorMessages_invalidPaymentMethod', path, 'paymentMethod'),
        ])
    }
  }
const invoiceNumberV =
  (path: readonly string[], method: PaymentMethod | null) =>
  (
    x: DomainOrder['payment']['invoiceNumber'],
  ): E.Either<ValidationError[], ValidDraftDomainOrder['payment']['invoiceNumber']> => {
    switch (method) {
      case PaymentMethod.Invoice:
        return V.required([...path])(x)
      default:
        return E.right(null)
    }
  }

const paymentV =
  (
    path: readonly string[],
  ): V.Validator<DomainOrder['payment'], ValidDraftDomainOrder['payment']> =>
  (x) =>
    V.validate({
      __typename: E.right(x.__typename),
      invoiceNumber: invoiceNumberV([...path, 'invoiceNumber'], x.paymentMethod)(x.invoiceNumber),
      paymentMethod: paymentMethodV([...path, 'paymentMethod'])(x.paymentMethod),
      paymentStatus: E.right(x.paymentStatus),
    })

export const cartItemsV =
  (
    path: readonly string[],
    deliverySlot: AvailableDeliverySlot,
  ): V.Validator<DomainOrder['cartItems'], ValidDraftDomainOrder['cartItems']> =>
  (items) =>
    pipe(
      items,
      V.arrayMinLength([...path], 1),
      E.chain((xs) =>
        xs.every(isProductAvailableInStore)
          ? E.right(xs)
          : E.left([validationErrorOf('errorMessages_cartItemsNotAvailable', path, 'cartItems')]),
      ),
      E.chain(ageRestrictedProductsV(path, deliverySlot)),
    )

const ageRestrictedProductsV =
  (
    path: readonly string[],
    deliverySlot: AvailableDeliverySlot,
  ): V.Validator<ValidDraftDomainOrder['cartItems'], ValidDraftDomainOrder['cartItems']> =>
  (items) => {
    if (!containsAlcoholProducts(items)) {
      return E.right(items)
    }

    if (deliverySlot.alcoholSellingAllowed) {
      return E.right(items)
    }

    if (!containsOtherThanAlcoholProducts(items)) {
      return E.left([validationErrorOf('errorMessages_cartWouldBeEmptyAfter', path, 'cartItems')])
    }

    return E.right(filterOutAlcoholProducts(items))
  }

const reservationIdV =
  (isDeliverySlotReservationEnabled = false) =>
  (
    path: readonly string[],
    order: DomainOrder,
  ): V.Validator<DomainOrder['reservationId'], ValidDraftDomainOrder['reservationId']> => {
    return (reservationId) => {
      /** Coerce id to `null` when disabled. */
      if (!isDeliverySlotReservationEnabled) {
        return E.right(null)
      }

      /** Reservation id not required when editing an order */
      return order.id ? E.right(reservationId) : V.required(path)(reservationId)
    }
  }

export const orderV =
  (isDeliverySlotReservationEnabled = false) =>
  (deliverySlot: AvailableDeliverySlot): V.Validator<DomainOrder, ValidDraftDomainOrder> =>
  (x) =>
    V.validate({
      __typename: E.right(x.__typename),
      id: E.right(x.id),
      orderStatus: E.right(x.orderStatus),
      customer: pipe(
        customerV(['customer'])(x.customer),
        E.mapLeft(() => [validationErrorOf('errorMessages_customer')]),
      ),
      cartItems: pipe(
        x.cartItems,
        cartItemsV(['cartItems'], deliverySlot),
        E.mapLeft(() => [validationErrorOf('errorMessages_cartItems')]),
      ),
      storeId: pipe(
        x.storeId,
        V.required(['storeId']),
        E.mapLeft(setValidationErrorMessage('errorMessages_storeNotSelected')),
      ),
      additionalInfo: E.right(x.additionalInfo),
      comment: pipe(
        x.comment || '',
        V.plaintext(['comment']),
        E.mapLeft(setValidationErrorMessage('errorMessages_Comment')),
      ),
      discountCode: E.right(x.discountCode),
      /**
       * Delivery slot reservation hidden behind feature flag.
       * This will coerce id to `null` when disabled, and have it required when enabled.
       * Note that the backend treats the value as optional, but we still want to require
       * it in the frontend.
       */
      reservationId: pipe(
        x.reservationId,
        reservationIdV(isDeliverySlotReservationEnabled)(['reservationId'], x),
        E.mapLeft(setValidationErrorMessage('errorMessages_deliverySlotIsMissing')),
      ),
      deliverySlotId: pipe(
        x.deliverySlotId,
        V.required(['deliverySlotId']),
        E.mapLeft(setValidationErrorMessage('errorMessages_deliverySlotIsMissing')),
      ),
      deliveryAreaId: pipe(
        x.deliveryAreaId,
        V.required(['deliveryAreaId']),
        E.mapLeft(setValidationErrorMessage('errorMessages_deliveryAreaNotSelected')),
      ),
      payment: pipe(
        x.payment,
        paymentV(['payment']),
        E.mapLeft(() => [validationErrorOf('errorMessages_payment')]),
      ),
      deliveryAddress: pipe(
        x.deliveryAddress,
        deliveryV(['deliveryAddress'], deliverySlot.deliveryMethod),
        E.mapLeft(() => [validationErrorOf('errorMessages_delivery')]),
      ),
    })

export type AvailableDeliverySlot = {
  isClosed: false
  availability: SlotAvailability
  deliveryMethod: DeliveryMethod
  alcoholSellingAllowed: boolean
}
