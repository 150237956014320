import { Button, DeleteOutline16 } from '@sok/design-system'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import styled from 'styled-components'

export const RemoveCartItem = styled(Button).attrs({
  icon: <DeleteOutline16 />,
})(({ theme }) => ({
  '&.sok-btn': {
    ...BORDER_RADIUS,
    padding: `0 ${theme.spacings.small}`,

    '&.sok-btn-primary': {
      backgroundColor: theme.colors.error50,
      color: theme.colors.error800,
      svg: {
        color: theme.colors.error800,
      },

      '&:focus': {
        boxShadow: `0 0 0 3px ${theme.colors.error300}`,
      },

      '&[disabled]': {
        backgroundColor: theme.colors.primary50,
        svg: {
          color: theme.colors.error200,
        },

        ':hover': {
          backgroundColor: theme.colors.error50,
        },
      },

      '&:not([disabled]):hover': {
        backgroundColor: theme.colors.error100,
      },
    },
  },
}))
