// div click on modal container requires onclick to stop propagation
// jsx-a11y complains about it
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import type { FC, ReactNode } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconNavigationClose } from '@s-group/design-system-icons'
import {
  SDS_FONT_SIZE_HEADING_SMALL,
  SDS_FONT_WEIGHT_REGULAR,
} from '@s-group/design-system-tokens/web/tokens/font.es6'
import { SDS_SPACE_XXXSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { maxWidthFromTheme, minWidthFromTheme } from '@sok/design-system'
import { BORDER_RADIUS_LARGE } from 'components/Button/border-radius'
import FocusTrap from 'focus-trap-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  className?: string
  onClose: () => void
  title?: ReactNode
  footer?: ReactNode
  dataTestId?: string
}

const stopPropagation: React.MouseEventHandler<HTMLElement> = (e) => {
  e.stopPropagation()
}

const _Modal: FC<Props> = ({ children, className, onClose, title, footer, dataTestId }) => {
  const { t } = useTranslation()

  return (
    <ModalBackdrop onMouseUp={onClose}>
      <FocusTrap>
        <div
          className={className}
          onClick={stopPropagation}
          onMouseUp={stopPropagation}
          data-test-id={dataTestId || 'modal'}
          role="dialog"
          aria-labelledby="modal-title"
          aria-describedby="modal-body"
        >
          <Header>
            <h1 id="modal-title">{title}</h1>
          </Header>

          <ModalContent id="modal-body">{children}</ModalContent>

          {footer ? <Footer>{footer}</Footer> : null}
          <CloseButton role="button" aria-label={t('Close')} onClick={onClose}>
            <IconNavigationClose />
          </CloseButton>
        </div>
      </FocusTrap>
    </ModalBackdrop>
  )
}

const Footer = styled.div(({ theme }) => ({
  padding: theme.spacings.medium,
}))

const ModalContent = styled.div(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  maxWidth: '100vw',
  position: 'relative',
  flex: '1',
  padding: theme.spacings.medium,
}))

const Header = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacings.medium,
  paddingBottom: theme.spacings.xSmall,
  position: 'relative',

  h1: {
    ...theme.sizes.s,
    fontSize: SDS_FONT_SIZE_HEADING_SMALL,
    fontWeight: SDS_FONT_WEIGHT_REGULAR,
    paddingRight: 24,
  },
}))

const CloseButton = styled(Button).attrs({
  plain: true,
  rounding: 'small',
  sizing: 'small',
})(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  color: 'inherit',
  position: 'absolute',
  top: theme.spacings.medium,
  right: theme.spacings.medium,

  // Design system has a polyfill for safari, it will apply position: relative while active, this will override it for now
  ['&:active']: {
    position: 'absolute',
  },
}))

const ModalBackdrop = styled.div({
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.1)',
  zIndex: '100',
  display: 'flex',
  justifyContent: ' center',
  alignItems: 'center',
})

const ModalComponent = styled(_Modal)(BORDER_RADIUS_LARGE, ({ theme }) => ({
  height: '100vh',
  width: '100vw',
  position: 'relative',
  // stylelint-disable
  maxHeight: '-webkit-fill-available',
  // stylelint-enable

  [minWidthFromTheme(theme).mobile]: {
    minHeight: '300px', // safe to assume min screen size
    maxHeight: '100vh',
  },
  [minWidthFromTheme(theme).tablet]: {
    height: 'auto',
    width: 'auto',
    maxHeight: '90vh',
  },
  backgroundColor: theme.colors.white,
  boxShadow: '0 0 30px -10px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden', // rounds also the scrollbar at the corner
}))

// Smaller modal for mobile, does not take up whole screen
const SmallModal = styled(ModalComponent)(({ theme }) => ({
  maxHeight: '75vh',
  alignSelf: 'flex-end',

  [maxWidthFromTheme(theme).mobile]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  [minWidthFromTheme(theme).tablet]: {
    alignSelf: 'auto',
  },
}))

// Narrower modal for small dialogs
const Dialogue = styled(ModalComponent)(({ theme }) => ({
  borderRadius: SDS_SPACE_XXXSMALL,

  [minWidthFromTheme(theme).tablet]: {
    minHeight: '260px',
    width: '480px',
    maxHeight: '90vh',
  },
}))

const CommentModal = styled(ModalComponent)(({ theme }) => ({
  borderRadius: SDS_SPACE_XXXSMALL,

  [minWidthFromTheme(theme).tablet]: {
    height: '450px',
    width: '480px',
  },
}))

export const Modal = {
  Modal: ModalComponent,
  SmallModal,
  Dialogue,
  CommentModal,
  Footer,
  ModalContent,
  Header,
  CloseButton,
  ModalBackdrop,
}
