import type { ProductCardLoadingProps } from './ProductCardLoading'
import type { ThemedObjectStyleFunction } from 'domain/styled'
import type { FC } from 'react'

import { minWidthFromTheme } from '@sok/design-system'
import { ComparisonPrice } from 'components/product-price/ComparisonPrice'
import { Lowest30DayPrice } from 'components/product-price/Lowest30DayPrice'
import { ProductPriceContainer } from 'components/product-price/ProductPrice'
import { ProductLabels } from 'components/ProductLabels'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'
import { smallMobile } from 'styles/layout'

import { AddTo } from './AddTo'
import { FavoriteBtn } from './ExtraActions'
import { _ProductCard, defaultStyles, lessOpacity, ProductDetails } from './ProductCard'
import {
  defaultLoadingStyles,
  DetailsLoader,
  FavoriteLoader,
  ImageLoader,
  loadingRowStyles,
  QuantityModifierLoader,
} from './ProductCardLoading'
import { QuantityModifier } from './QuantityModifier'
import { rowStyles } from './Row'

/**
 * Modified Row Component Styles for AutoSuggestion
 @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=4358%3A426623
**/
const autoSuggestStyles: ThemedObjectStyleFunction<{ disabled?: boolean }> = ({
  theme,
  disabled,
}) => ({
  gridTemplateRows: 'auto min-content 1fr',
  gridTemplateColumns: 'min-content 1fr min-content',
  [smallMobile]: {
    gridTemplateColumns: 'min-content 1fr auto min-content',
  },

  [ProductDetails]: {
    gridColumn: '2 / 3',

    [smallMobile]: {
      gridColumn: '2 / 4',
    },

    [minWidthFromTheme(theme).mobile]: {
      gridColumn: '2 / 3',
    },

    [Lowest30DayPrice]: {
      fontSize: '0.6875rem', // display smaller font in AutoSuggest
      color: theme.colors.grey800,
    },

    [ProductPriceContainer]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,

      [ComparisonPrice]: {
        paddingLeft: theme.spacings.xxSmall,
      },
    },
  },

  [ProductLabels]: {
    gridRow: 2,
    gridColumn: '2 / 4',
    alignSelf: 'center',

    '> li': {
      flexDirection: 'row',
    },

    [smallMobile]: {
      gridColumn: '2 / 3',
    },
  },

  [`${QuantityModifier}, ${AddTo}`]: {
    ...lessOpacity(disabled),
    gridRow: 3,
    gridColumn: '2 / 4',
    marginTop: theme.spacings.xSmall,

    /*
        Fills up the extra space
        Note: This will be removed once quantity modifier with animation is implemented
      */
    [smallMobile]: {
      marginTop: 0,
      gridRow: 2,
      gridColumn: '3 / 5',
      justifySelf: 'end',
      alignSelf: 'center',
    },

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '4 / 5',
      gridRow: '1 / 3',
      alignSelf: 'center',
    },
  },

  [FavoriteBtn]: {
    gridColumn: 3,
    gridRow: '1 / 2',
    justifySelf: 'end',
    alignSelf: 'start',
    ...lessOpacity(disabled),

    [smallMobile]: {
      gridColumn: '4 / 5',
    },

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '3 / 4',
      gridRow: '1 / 3',
      alignSelf: 'center',
      marginRight: theme.spacings.small,
    },
  },
})

const Success = styled(_ProductCard)(defaultStyles, rowStyles, autoSuggestStyles)

/** Product Card Loading Styles for AutoSuggest
 * @see https://www.figma.com/file/dZoWi3HmHZx6jftXPZZgU6/00-2-Concept-idea-lab?node-id=6373%3A318370
 */
const autoSuggestLoadingStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  gridTemplateRows: 'auto min-content 1fr',

  [QuantityModifierLoader]: {
    gridRow: 2,
    gridColumn: '2 / 4',
    alignSelf: 'center',

    [smallMobile]: {
      marginTop: 0,
      gridRow: 2,
      width: '100%',
      gap: theme.spacings.xxxSmall,
    },

    [minWidthFromTheme(theme).tablet]: {
      gridColumn: '4 / 5',
      gridRow: '1 / 3',
    },
  },

  [FavoriteLoader]: {
    gridRow: 1,
    gridColumn: 3,

    [minWidthFromTheme(theme).tablet]: {
      alignSelf: 'center',
      gridColumn: '3 / 4',
      gridRow: '1 / 3',
      marginRight: theme.spacings.xxSmall,
    },
  },

  [DetailsLoader]: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
})

const _AutoSuggestLoading: FC<ProductCardLoadingProps> = ({
  className,
  'data-test-id': dataTestId = 'product-autoSuggest-loading',
}) => (
  <article className={className} data-test-id={dataTestId}>
    <ImageLoader />
    <DetailsLoader>
      <SkeletonLoader />
      <SkeletonLoader />
      <SkeletonLoader />
    </DetailsLoader>
    <FavoriteLoader />
    <QuantityModifierLoader />
  </article>
)

const Loading = styled(_AutoSuggestLoading)(
  defaultLoadingStyles,
  loadingRowStyles,
  autoSuggestLoadingStyles,
)

export const AutoSuggest = { Loading, Success }
