import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconNavigationClose } from '@s-group/design-system-icons'
import { SDS_COLOR_WHITE } from '@s-group/design-system-tokens/web/tokens/colors.es6'
import FocusTrap from 'focus-trap-react'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import { MIN_HEIGHT_FULLSCREEN } from 'styles/layout'

import { MenuOverlay } from './MenuOverlay'

const MenuDrawerWrapper = styled.div<{ isVisible: boolean; position: 'left' | 'right' }>(
  ({ theme }) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,

    ...MIN_HEIGHT_FULLSCREEN,

    overflowY: 'auto',

    padding: `${theme.spacings.xSmall} ${theme.spacings.small} ${theme.spacings.medium}`,
    backgroundColor: SDS_COLOR_WHITE,
    width: '100%',

    transition: 'transform 200ms ease-out',

    [`@media (min-width: ${theme.breakpoints.mobile})`]: {
      width: '360px',
    },
  }),
  ({ position, isVisible }) => {
    switch (position) {
      case 'right': {
        return {
          right: 0,
          transform: isVisible ? 'translateX(0%)' : 'translateX(100%)',
        }
      }

      case 'left': {
        return {
          left: 0,
          transform: isVisible ? 'translateX(0%)' : 'translateX(-100%)',
        }
      }
    }
  },
)

const CloseButtonContainer = styled.div({
  position: 'absolute',
  top: '12px',
  right: '8px',
})

const MenuContents = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '> * + *': {
    marginTop: theme.spacings.large,
  },
}))

export const MenuDrawer: FC<{
  isOpen: boolean
  onClose: () => void
  onBackButtonClick?: () => void
  children: React.ReactNode
  position: 'left' | 'right'
  'data-test-id'?: string
}> = ({ isOpen, onClose, children, position, 'data-test-id': dataTestId }) => {
  const { t } = useTranslation()

  const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden')
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    clearTimeout(timeout.current)

    if (isOpen) {
      setVisibility('visible')
    } else {
      timeout.current = setTimeout(() => {
        setVisibility('hidden')
      }, 200)
    }

    return () => {
      clearTimeout(timeout.current)
    }
  }, [isOpen])

  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', onClose)

    return () => router.events.off('routeChangeStart', onClose)
  }, [onClose, router.events])

  return (
    <FocusTrap active={isOpen} focusTrapOptions={{ initialFocus: false }}>
      <div style={{ visibility: isOpen ? 'visible' : visibility }}>
        <MenuOverlay onClick={onClose} $isVisible={isOpen} aria-hidden="true" />

        <RemoveScroll enabled={isOpen}>
          <MenuDrawerWrapper
            isVisible={isOpen}
            position={position}
            role="dialog"
            aria-modal="true"
            data-test-id={dataTestId}
          >
            <CloseButtonContainer>
              <Button
                icon={<IconNavigationClose />}
                onClick={onClose}
                aria-label={t('Close')}
                data-test-id="side-menu-close"
                variant="plain"
                rounding="small"
                color="grey"
              />
            </CloseButtonContainer>

            <MenuContents>{children}</MenuContents>
          </MenuDrawerWrapper>
        </RemoveScroll>
      </div>
    </FocusTrap>
  )
}
