import type { Validator } from './validation'

import * as E from 'fp-ts/Either'

export const arrayMinLength =
  <T>(path: readonly string[], minLength: number): Validator<T[], T[]> =>
  (value: T[]) =>
    value.length >= minLength
      ? E.right(value)
      : E.left([
          {
            path,
            tag: 'validationError',
            message: 'minLength',
          },
        ])
