/**
 * This util is the hook that is triggered by the Giosg NPS Survey. It has custom JS at the end
 * that triggers window.giosgNpsHandler().
 *
 * It is stored in giosg account 6355.
 *
 * The custom JS looks like this:
 *
 * const obj = {
 *   love: $i.elements.textlove.value,
 *   like: $i.elements.textlike.value,
 *   fine: $i.elements.textfine.value,
 *   dislike: $i.elements.textdislike.value,
 *   hate: $i.elements.texthate.value,
 * }

 * if (window.giosgNpsHandler) {
 *   window.giosgNpsHandler('hate', obj)
 * }
 */

import { IS_BROWSER } from './is-browser'

interface GiosgFields {
  storeId: string
  brand: string
  orderRef?: string // Encrypted orderNumber
}

/**
 * Adds a global function window.giosgAPIConnector, that is triggered by a giosg interaction via
 * their JavaScript editor.
 *
 * This allows us to attach on custom data to an interaction (such as the current brand)
 */
export const addGiosgCustomFieldsToAPI = (fields: GiosgFields): void => {
  if (!IS_BROWSER) {
    return
  }

  window.giosgAPIConnector = (giosgAPI) => {
    giosgAPI.store.data.dataFields = {
      ...giosgAPI.store.data.dataFields,
      ...fields,
    }
  }
}
