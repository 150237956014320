import type { FC, ReactNode } from 'react'

import { SDS_SPACE_XXXSMALL } from '@s-group/design-system-tokens/web/tokens/space.es6'
import styled from 'styled-components'
import { customMinWidthFromTheme } from 'styles/layout'

export interface SlideContainerProps {
  className?: string
  children: ReactNode
  featured?: boolean
  label?: string
  gridCount?: number | null
}

const _SlideContainer: FC<SlideContainerProps> = ({ className, children, label }) => {
  return (
    <li
      aria-roledescription="slide"
      aria-label={label}
      className={className}
      data-test-id="carousel-slide-container"
    >
      {children}
    </li>
  )
}

export const SlideContainer = styled(_SlideContainer)(({ theme, featured, gridCount }) => {
  const coef = featured ? 2 : 1
  const gridCountBasedWidth = gridCount ? 100 / gridCount : null
  return {
    padding: `0 ${SDS_SPACE_XXXSMALL}`,
    flexBasis: `${gridCountBasedWidth ?? 100}%`,
    flexShrink: 0,
    flexGrow: 1,
    [customMinWidthFromTheme(theme).tablet]: {
      flexBasis: `${gridCountBasedWidth ?? 50}%`,
    },
    [customMinWidthFromTheme(theme).middleDesktop]: {
      flexBasis: `${coef * (gridCountBasedWidth ?? 33.33)}%`,
    },
    [customMinWidthFromTheme(theme).xLargeDesktop]: {
      flexBasis: `${coef * (gridCountBasedWidth ?? 25)}%`,
    },
  }
})
