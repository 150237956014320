import type { Order, PaymentDetailsResponse } from '@shared/gql/document-nodes'

import { PaymentMethod, PaymentStatus } from '@shared/gql/document-nodes'

export const isPaymentPending = (
  paymentDetails: Pick<PaymentDetailsResponse, 'paymentMethod' | 'paymentStatus'>,
): boolean => {
  if (paymentDetails.paymentMethod === PaymentMethod.CardPayment) {
    return (
      !paymentDetails.paymentStatus ||
      paymentDetails.paymentStatus === PaymentStatus.Pending ||
      paymentDetails.paymentStatus === PaymentStatus.CardAuthorizationFailed ||
      paymentDetails.paymentStatus === PaymentStatus.Canceled
    )
  }

  return false
}

export const isCardAuthorized = (paymentStatus: PaymentStatus): boolean => {
  const cardNotAuthorized = [
    PaymentStatus.Pending,
    PaymentStatus.Unavailable,
    PaymentStatus.CardAuthorizationFailed,
  ].includes(paymentStatus)

  return !cardNotAuthorized
}

export const isOrderCaptured = (order: Order): boolean => {
  const { paymentMethod, paymentStatus } = order
  return (
    paymentMethod === PaymentMethod.CardPayment && paymentStatus === PaymentStatus.CaptureCompleted
  )
}

export const getExpiredAt = (expired: boolean, expiryDate: string): string | undefined => {
  if (expired) {
    return formatExpiryDate(expiryDate)
  }

  return undefined
}

export const formatExpiryDate = (expiryDate: string): string => {
  /** Expired date is represented as '2811' where '28' is year and '11' is month */
  const [year, month] = expiryDate.match(/.{1,2}/g) || []
  return `${month}/${year}`
}
