import type { HTMLReactParserOptions } from 'html-react-parser'

import { ExternalLinkIcon } from 'components/icons/ExternalLinkIcon'
import { getValidatedUrl, isExternalLink } from 'domain/link-validation'
import parse, { domToReact } from 'html-react-parser'
import { marked } from 'marked'
import { gfmHeadingId } from 'marked-gfm-heading-id'
import Link from 'next/link'
import { useMemo } from 'react'
import sanitize from 'sanitize-html'

marked.use(gfmHeadingId({}))

const isTelOrMailtoHref = (href: string): boolean => /^(tel|mailto)/.test(href)

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if ('name' in domNode && domNode.name === 'a' && 'attribs' in domNode) {
      const { href, ...rest } = domNode.attribs

      if (isTelOrMailtoHref(href)) {
        return <a href={href}>{domToReact(domNode.children)}</a>
      }

      const externalLink = isExternalLink(href)

      return (
        <Link href={getValidatedUrl(href)} passHref>
          <a
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            target={externalLink ? '_blank' : undefined}
            rel={externalLink ? 'noopener' : undefined}
          >
            {domToReact(domNode.children)}
            {externalLink ? <ExternalLinkIcon /> : null}
          </a>
        </Link>
      )
    }
  },
}

type ParsedMarkdown = JSX.Element | JSX.Element[] | string | null

export const useParsedMarkdown = (markdown?: string | null): ParsedMarkdown =>
  useMemo(() => {
    if (markdown) {
      const html = marked(sanitize(markdown))
      const jsx = parse(html, options)
      return jsx || null
    }

    return null
  }, [markdown])
