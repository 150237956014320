import type { FC } from 'react'
import type { ILinkContainerFields } from 'types/contentful/generated'

import { ImageLinkButton } from 'components/common/Buttons/ImageLinkButton'

import { StyledDownloadMobileApp } from './styled-footer'

interface DownloadMobileAppProps {
  links: ILinkContainerFields['links']
  ['data-test-id']?: string
}

export const DownloadMobileApp: FC<DownloadMobileAppProps> = ({
  links,
  ['data-test-id']: dataTestId,
}) => (
  <StyledDownloadMobileApp data-test-id={dataTestId}>
    {links?.map(
      ({ fields }) =>
        fields.url &&
        fields.icon && (
          <ImageLinkButton
            key={fields.name}
            data-test-id={`${fields.name}-get-app-button`}
            url={fields.url}
            alt={`Get the mobile app : ${fields.name}`}
            imageUrl={fields.icon.fields.file.url}
          />
        ),
    )}
  </StyledDownloadMobileApp>
)
