import type { FC } from 'react'
import type { IInputElement } from 'types/contentful/generated'

import { Brand } from '@shared/domain/brand'
import { Button, ButtonBlock, ButtonSize, ButtonType } from '@sok/design-system'
import { getValidatedUrl } from 'domain/link-validation'
import NextLink from 'next/link'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

interface ButtonListProps {
  items: IInputElement[]
  openDeliveryModal?: () => void
  href?: string
  topPadding?: string
}

export const ButtonList: FC<ButtonListProps> = ({ items, openDeliveryModal, href }) => {
  if (items.length === 0) {
    return null
  }

  const buttons = items.map(({ fields }) => {
    if (fields.fieldType === Brand.Prisma && href) {
      const validatedHref = getValidatedUrl(href.toString())
      return (
        <NextLink href={validatedHref} key={fields.name} passHref>
          <Button
            data-test-id="shop-prisma-button"
            onClick={(): void => {
              trackCustomEvent({
                category: EventCategory.CONTENT_LINKS,
                action: EventAction.CLICK,
                label: `brand-button-prisma_${fields.name || 'not-set'}`,
              })
            }}
            size={ButtonSize.LARGE}
            text={fields.label}
            blockOn={ButtonBlock.MOBILE_DOWN}
          />
        </NextLink>
      )
    }

    if (fields.url) {
      const validatedHref = getValidatedUrl(fields.url)
      return (
        <NextLink href={validatedHref} key={fields.name} passHref>
          <Button
            blockOn={ButtonBlock.MOBILE_DOWN}
            key={fields.name}
            size={ButtonSize.LARGE}
            text={fields.label}
            type={fields.fieldType === 'secondaryButton' ? ButtonType.DEFAULT : ButtonType.PRIMARY}
            target={fields.openLinkInNewTab ? '_blank' : '_self'}
          />
        </NextLink>
      )
    }

    if (fields.fieldType === 'primaryButton') {
      return (
        <Button
          key={fields.name}
          size={ButtonSize.LARGE}
          onClick={openDeliveryModal}
          text={fields.label}
          blockOn={ButtonBlock.MOBILE_DOWN}
        />
      )
    }
  })

  return <>{buttons}</>
}
