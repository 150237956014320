import type { FC } from 'react'

import { StyledCopyright, StyledCopyrightBody, StyledFooterImage } from './styled-footer'

interface CopyrightProps {
  logo?: string
  body?: string
  ['data-test-id']?: string
}
export const Copyright: FC<CopyrightProps> = ({ logo, body, ['data-test-id']: dataTestId }) => (
  <StyledCopyright data-test-id={dataTestId}>
    {logo && <StyledFooterImage src={logo} alt="copyright-url" />}
    {body && <StyledCopyrightBody data-test-id="copyright-body">{body}</StyledCopyrightBody>}
  </StyledCopyright>
)
