import type { MaybeProductListItem } from 'domain/product-list'
import type { FC, ReactNode } from 'react'

import { Carousel } from 'components/Carousel'
import { SectionTitle } from 'components/common/Titles'
import { NextLink } from 'components/NextLink'
import { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TrackingContextProvider } from 'utils/tracking/components/TrackingContextProvider'
import { useProductsImpressionTracking } from 'utils/tracking/hooks/use-products-impression-tracking'
import { TrackingContext } from 'utils/tracking/interfaces/data-layer-events'

import { StyledProductTitleWithCTA } from '../FeaturedProducts'

const PLACEHOLDERS: string[] = Array(6).fill('')

export const View: FC<{
  products: MaybeProductListItem[]
  loading: boolean
  listName: string
  title: string
  prefixCard?: ReactNode
  className?: string
}> = memo(({ products, loading, listName, title, prefixCard, className }) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  useProductsImpressionTracking({
    listName,
    trackingContext: TrackingContext.RECOMMENDED_PRODUCTS_CAROUSEL,
    items: products,
    ref,
  })
  return (
    <div className={className}>
      <TrackingContextProvider
        trackingContext={TrackingContext.RECOMMENDED_PRODUCTS_CAROUSEL}
        listName={listName}
      >
        <StyledProductTitleWithCTA>
          <SectionTitle>{title}</SectionTitle>
          <NextLink href="/suosittelemme-sinulle">{t('See all')}</NextLink>
        </StyledProductTitleWithCTA>
        <Carousel
          placeholderEans={loading ? PLACEHOLDERS : undefined}
          prefixCard={prefixCard}
          products={products}
          ref={ref}
        />
      </TrackingContextProvider>
    </div>
  )
})
View.displayName = 'RecommendationsView'
