import type { AccordionItem } from '@sok/design-system'
import type { FC } from 'react'
import type { ISection, ISectionFields } from 'types/contentful/generated'

import { Accordion as DSAccordion } from '@sok/design-system'
import styled from 'styled-components'
import { TEXT_CONTENT_MAX_WIDTH } from 'styles/layout'
import { useParsedMarkdown } from 'utils/hooks/use-parsed-markdown'

interface AccordionProps {
  section: ISection
}

interface AccordionContentProps {
  body?: string
}

const StyledContentWrapper = styled.div`
  max-width: ${TEXT_CONTENT_MAX_WIDTH}px;
  margin: auto;
  margin-bottom: 48px;
  .accordion-content .accordion-content-box.accordion-default {
    background-color: ${({ theme }) => theme.color.lightestGrey100};
  }
  p {
    padding-bottom: 16px;
    a {
      color: ${({ theme }) => theme.color.darkGrey800};
    }
  }
`

const AccordionContent: FC<AccordionContentProps> = ({ body }) => {
  return <>{useParsedMarkdown(body)}</>
}

const getContent = (cards: ISectionFields['cards']): AccordionItem[] => {
  return cards
    ?.map((card, index): AccordionItem | undefined => {
      const { title, body } = card.fields

      if (!title || !body) {
        return undefined
      }

      return {
        id: `${index}`,
        title,
        content: <AccordionContent body={body} />,
      }
    })
    .filter(Boolean) as AccordionItem[]
}

export const Accordion: FC<AccordionProps> = ({ section }) => {
  const { cards } = section.fields

  if (!cards || cards.length <= 0) {
    return <></>
  }

  return (
    <StyledContentWrapper>
      <DSAccordion items={getContent(cards)} />
    </StyledContentWrapper>
  )
}
