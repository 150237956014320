import type { ChangeEvent, FC } from 'react'

import { Button, Icon } from '@s-group/design-system-components'
import { IconControlSearch, IconNavigationClose } from '@s-group/design-system-icons'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import { StyledSyncLoader } from 'components/common/StyledSyncLoader'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryStringParam } from 'services/Search/hooks/use-query-string-param'
import styled, { css } from 'styled-components'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

const Loader = styled(StyledSyncLoader)(({ theme }) => ({
  display: 'flex', // Make sure dots stay on single line
  left: '-110%',
  position: 'static',
  top: '25%',
  transform: 'scale(0.5)',
  marginRight: `-${theme.spacings.xxSmall}`,
}))

// background-clip is for ios safari to remove boxshadow
const StyledSearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  background-color: ${({ theme }) => theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_GREY};
  border-radius: ${BORDER_RADIUS.borderRadius}px;

  width: 100%;
  height: 100%;

  gap: 0.5rem;
  padding: 0 0.5rem;

  transition: box-shadow 0.3s;

  &:focus-within {
    box-shadow: ${({ theme }) => theme.colors.primary300} 0px 0px 0px 3px !important;
  }

  input {
    background-clip: padding-box !important;
    ${({ theme }) => css(theme.variants.body1.regular)};
    width: 100%;
    height: 100%;
    padding: 0;
    outline: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border: none;
    background-color: transparent;
    order: 0;

    color: ${({ theme }) => theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY};
    &::placeholder {
      color: ${({ theme }) => theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_GREY};
    }
  }
`

const trackAction = (): void => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: 'main-header_search-bar',
  })
}

enum KeyCode {
  ENTER = 13,
  ESCAPE = 27,
}

interface InputComponentProps {
  handleEscKey: () => void
  handleSearchAndRedirect: (value: string) => void
  handleSetFocus: (hasFocus: boolean) => void
  loading?: boolean
  placeholder?: string
  onChange: (value: string) => void
  searchString: string
}

const StyledButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',

  ['.button-icon']: {
    // old DS icons are not properly centered with SDS buttons
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.SDS_BRAND_COLOR_TEXT_DEFAULT_GREY,
  },
}))

const SearchButton = styled(StyledButton)({
  order: -1,
})

export const InputComponent: FC<InputComponentProps> = ({
  handleEscKey,
  handleSearchAndRedirect,
  handleSetFocus,
  loading,
  placeholder,
  onChange,
  searchString,
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(searchString)

  const queryStringParam = useQueryStringParam()
  const handleInput = useCallback(
    (event: { keyCode: number }) => {
      const { keyCode } = event
      if (keyCode === KeyCode.ENTER) {
        handleSearchAndRedirect(value)
      } else if (keyCode === KeyCode.ESCAPE) {
        handleEscKey()
      }
    },
    [handleEscKey, handleSearchAndRedirect, value],
  )

  const [handleBlur, handleFocus] = useMemo(
    () => [() => void handleSetFocus(false), () => void handleSetFocus(true)],
    [handleSetFocus],
  )

  // Submit the change in value to the parent components debounced state.
  useEffect(() => {
    onChange(value)
  }, [value, onChange])

  // Synchronize input value with a possible query string.
  useEffect(() => {
    if (queryStringParam) {
      setValue(queryStringParam)
    }
  }, [queryStringParam])

  const inputOnClear = useCallback(() => {
    setValue('')
  }, [])

  const inputOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [],
  )

  return (
    <StyledSearchInputContainer>
      <input
        data-test-id="search-input"
        onBlur={handleBlur}
        onChange={inputOnChange}
        onClick={trackAction}
        onFocus={handleFocus}
        onKeyDown={handleInput}
        placeholder={placeholder}
        value={value}
      />

      {!!value &&
        (loading ? (
          <Loader />
        ) : (
          <StyledButton
            plain
            icon={<Icon svg={<IconNavigationClose />} />}
            sizing="xsmall"
            rounding="small"
            data-test-id="search-input-clear"
            onClick={inputOnClear}
            aria-label={t('Clear search')}
          />
        ))}
      <SearchButton
        plain
        icon={<IconControlSearch />}
        sizing="xsmall"
        rounding="small"
        onClick={() => handleSearchAndRedirect(value)}
        aria-label={t('Search')}
      />
    </StyledSearchInputContainer>
  )
}
