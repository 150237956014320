import type { CurrentUser } from 'domain/current-user'
import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import {
  IconCommerceList,
  IconControlHeart,
  IconControlStar,
  IconTimeHistory,
  IconUserProfile,
} from '@s-group/design-system-icons'
import { SDS_SPACE_XLARGE } from '@s-group/design-system-tokens/web/tokens/space.es6'
import { ProductType } from '@shared/gql/document-nodes'
import { LoginContent } from 'components/Account/LoginContent'
import { LogoutConfirmation } from 'components/Modal/LogoutConfirmation'
import { useTranslation } from 'react-i18next'
import { ModalService } from 'services/Modal'
import styled from 'styled-components'
import { useGetClientCartItemsByType } from 'utils/cart/use-get-client-cart-items-by-type'
import { useGetCurrentProfile } from 'utils/hooks/account/use-get-current-profile'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { NavProfileItem, trackUserItemClick } from 'utils/tracking/navigation'
import { removeSkipDeliveryLoginToken } from 'views/delivery-modal/skip-delivery-login'

import { MenuDrawer } from './components/MenuDrawer/MenuDrawer'
import { MenuGroup, MenuLink } from './components/MenuDrawer/MenuSection'

const NewFeatureLabel = styled('span')(({ theme }) => ({
  background: theme.colors.SDS_BRAND_COLOR_ELEMENT_INFO,
  color: theme.colors.white,
  fontSize: theme.sizes.xxxs.fontSize,
  borderRadius: '24px',
  padding: '0 8px',
}))

const UserProfileContent: FC<{
  profile: CurrentUser
  onClose: () => void
}> = ({ profile, onClose }) => {
  const { t } = useTranslation()
  const { onLogoutClick } = useGetProfileActionHandlers()

  const cartItems = useGetClientCartItemsByType(ProductType.Product)

  const handleLogoutClick = () => {
    onClose()

    if (cartItems?.length) {
      // Open a confirmation dialog if there are items in cart.
      ModalService.open<void>(<LogoutConfirmation />, {
        onSubmit: () => onLogoutClick(),
      })
    } else {
      onLogoutClick()
      removeSkipDeliveryLoginToken()
    }
    trackUserItemClick(NavProfileItem.LOG_OUT)()
  }

  return (
    <>
      <nav aria-label={t('User menu')}>
        <MenuGroup label={profile.firstName}>
          <MenuLink
            href="/tilaus/historia"
            icon={<IconTimeHistory />}
            onClick={trackUserItemClick(NavProfileItem.ORDERS)}
          >
            {t('My Orders')}
          </MenuLink>
          <MenuLink
            href="/profiili"
            icon={<IconUserProfile />}
            onClick={trackUserItemClick(NavProfileItem.PROFILE)}
          >
            {t('My profile info')}
          </MenuLink>
          <MenuLink
            href="/suosikit"
            icon={<IconControlHeart />}
            onClick={trackUserItemClick(NavProfileItem.FAVORITES)}
          >
            {t('My favorites')}
          </MenuLink>
          <MenuLink
            href="/ostoslistat"
            icon={<IconCommerceList />}
            onClick={trackUserItemClick(NavProfileItem.SHOPPINGLISTS)}
          >
            {t('My Shopping lists')}
            <NewFeatureLabel>{t('Menu new feature')}</NewFeatureLabel>
          </MenuLink>
          <MenuLink
            href="/suosittelemme-sinulle"
            icon={<IconControlStar />}
            onClick={trackUserItemClick(NavProfileItem.SMART_CART)}
          >
            {t('Recommendations')}
          </MenuLink>
        </MenuGroup>
      </nav>

      <StyledButtonWrapper>
        <Button onClick={handleLogoutClick} data-test-id="logout-button" rounding="small">
          {t('Logout')}
        </Button>
      </StyledButtonWrapper>
    </>
  )
}

const StyledButtonWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: SDS_SPACE_XLARGE,
})

export const UserMenu: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  const profile = useGetCurrentProfile()

  return (
    <MenuDrawer isOpen={isOpen} onClose={onClose} position="right" data-test-id="user-menu">
      {profile ? <UserProfileContent profile={profile} onClose={onClose} /> : <LoginContent />}
    </MenuDrawer>
  )
}
