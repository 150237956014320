import type { ServiceId } from 'config/usercentrics'
import type { FC } from 'react'

import {
  acceptService,
  ConsentType,
  showSecondLayer,
  useServiceFullInfo,
} from '@s-group/react-usercentrics'
import { Button, ButtonType } from '@sok/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const _UsercentricsConsentGate: FC<{ className?: string; serviceId: ServiceId }> = ({
  className,
  serviceId,
}) => {
  const { t } = useTranslation()

  const serviceInfo = useServiceFullInfo(serviceId)

  if (!serviceInfo) return null

  return (
    <article className={className}>
      <h1>{t`This functionality requires consent`}</h1>
      <p>
        <small>
          <span>{serviceInfo.description} </span>
          <em>({serviceInfo.name})</em>
        </small>
      </p>
      <Button
        onClick={() => acceptService(serviceId, ConsentType.Explicit)}
        size="small"
        text={t`Accept`}
      />
      <Button
        onClick={() => showSecondLayer(serviceId)}
        size="small"
        text={t`Read more`}
        type={ButtonType.TEXT}
      />
    </article>
  )
}

_UsercentricsConsentGate.displayName = 'UsercentricsConsentGate'

export const UsercentricsConsentGate = styled(_UsercentricsConsentGate)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.xxSmall,
  minWidth: '200px',
  textAlign: 'center',

  h1: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  button: {
    width: '100%',
  },
}))
